import React from 'react';
import { Button, Label, Table } from 'semantic-ui-react';
import {
  DependencySelection,
  RequirementSourceDetails,
  SelectionType,
} from '../../../../graphql/generated/graphql';

type Props = {
  authorized: boolean;
  sourceDetails: RequirementSourceDetails;
  currentSelections: DependencySelection[];
  setCurrentSelections: (currentSelections: DependencySelection[]) => void;
};
/**
 *@returns {JSX.Element} Dependant product of a requirement in the list
 @param {Props} props { authorized, product }
 */
const RequirementComponent = (props: Props): JSX.Element => {
  const { sourceDetails, authorized, currentSelections, setCurrentSelections } =
    props;

  // Displays component/dependency with current selection
  const componentSelection = currentSelections.find(
    (d) => d.dependencyId === sourceDetails.dependencyId
  )?.selectionType;

  // Selecting a component will change the source- currentSelections
  const handleSelection = (selection: SelectionType) => {
    if (selection === componentSelection) return;

    // Updating currentSelections with new selection
    setCurrentSelections([
      ...currentSelections.filter(
        (d) => d.dependencyId !== sourceDetails.dependencyId
      ),
      { dependencyId: sourceDetails.dependencyId, selectionType: selection },
    ]);
  };

  return (
    <Table.Body>
      <Table.Row>
        <Table.Cell>{sourceDetails.componentName}</Table.Cell>
        <Table.Cell>
          {sourceDetails.spdxIds.map((r) => (
            <Label
              id="AllUsersInfoTableActiveRole"
              key={r}
              content={r.charAt(0).toUpperCase() + r.slice(1)}
            />
          ))}
        </Table.Cell>
        <Table.Cell>{sourceDetails.componentVersion}</Table.Cell>
        <Table.Cell textAlign="center">
          <Button.Group
            id="ProductCheckButtonsGroup"
            size="mini"
            style={{ padding: '0 20px' }}
          >
            <Button
              id="SelectedButton"
              icon="check"
              disabled={!authorized}
              primary={componentSelection === SelectionType.userselected}
              onClick={() => {
                handleSelection(SelectionType.userselected);
              }}
            />
            <Button
              id="DeselectedButton"
              icon="ban"
              disabled={!authorized}
              primary={componentSelection === SelectionType.userdeselected}
              onClick={() => {
                handleSelection(SelectionType.userdeselected);
              }}
            />
            <Button
              id="QuestionButton"
              icon="question"
              disabled={!authorized}
              primary={componentSelection === SelectionType.unselected}
              onClick={() => {
                handleSelection(SelectionType.unselected);
              }}
            />
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  );
};

export default RequirementComponent;
