import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, Popup, Segment } from 'semantic-ui-react';
import i18n from '../../../../i18n/i18n';
import ToDoCheckboxToggle from './ToDoCheckboxToggle';
import ToDoTask from './ToDoTask';
import useOptimisticSelection from './helpers/customHooks';
import { ToDoType } from './helpers/types';
import ToDoIcons from './ToDoIcons';
import TodoBadges from './TodoBadges';
import { siblingTasksVar } from './helpers/utils';

interface ToDoProps {
  todos: ToDoType[];
  versionId: string | undefined;
  todo: ToDoType[][number];
  selectDisabled: boolean;
}
/**
 * ToDo Component. May contain Tasks (children).
 *
 * @returns {JSX.Element} The TO DO Component
 * @param {ToDoProps}props description
 */
const ToDo = (props: ToDoProps): JSX.Element => {
  const { todos, versionId, todo, selectDisabled } = props;
  const [t] = useTranslation('todos');
  const { name_en: toDoNameEn, name_de: toDoNameDe, id: todoId } = todo.toDo;
  const { language } = i18n;
  const { selectionType, toggleSelection } = useOptimisticSelection(
    todo.selectionType,
    versionId,
    todoId
  );
  const tasks = todo.toDo?.tasks || [];
  const toDoName = language === 'de' ? toDoNameDe : toDoNameEn;
  const filteredTasks = tasks.filter((task) =>
    todos
      .filter((td) => td.parentToDoId === todoId)
      .map((td) => td.toDo.id)
      .includes(task.id)
  );

  // reactive var of tasks holds their immediate state, so we avoid the delay. Optimistic response.
  const siblingTasks = useReactiveVar(siblingTasksVar);
  const isAnyTaskSelected = siblingTasks
    .filter((sibling) => sibling.parentToDoId === todoId)
    .some(
      (sibling) =>
        sibling.selectionType === 'userselected' ||
        sibling.selectionType === 'autoselected'
    );

  const canToDoBeSelected = selectDisabled || !isAnyTaskSelected;
  const isParent = filteredTasks.length > 0;
  const selected =
    selectionType === 'userselected' || selectionType === 'autoselected';

  return (
    <Segment
      id="id"
      className={`todo-list-item ${selected ? 'selected' : ''}`}
      as="li"
    >
      <div className="todo-item-container">
        <Popup
          id="ParentCheckboxPopup"
          content={t('selectToDoPopup')}
          on="hover"
          offset={[-10, -1]}
          disabled={!isParent || isAnyTaskSelected || selectDisabled}
          trigger={
            <span className="todo-checkbox">
              <ToDoCheckboxToggle
                key={todoId}
                todoId={todoId}
                selectionType={selectionType}
                onToggle={toggleSelection}
                isDisabled={isParent ? canToDoBeSelected : selectDisabled}
              />
            </span>
          }
        />
        <span className={`todo-name ${selectDisabled ? 'read-only' : ''}`}>
          {toDoName}
          <TodoBadges internal={todo.toDo.internal} />
        </span>
        <ToDoIcons
          toDoId={todoId}
          toDoName={toDoName}
          versionId={versionId}
          commentCount={todo.commentCount}
        />
      </div>
      {/* TASKS PART */}
      {filteredTasks.length > 0 && (
        <List id="TaskList" className="task-list">
          {filteredTasks.map((task) => {
            return (
              <ToDoTask
                key={`task_${task.id}`}
                versionId={versionId}
                todos={todos}
                taskName={language === 'de' ? task.name_de : task.name_en}
                taskId={task.id}
                isInternal={task.internal}
                selectDisabled={selectDisabled}
              />
            );
          })}
        </List>
      )}
    </Segment>
  );
};
export default ToDo;
