import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DropdownProps,
  Form,
  Icon,
  Item,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import WizardContext from '../../../context/WizardContext';
import { Role, Scalars } from '../../../graphql/generated/graphql';
import useWizardPartInputForTeams from './useWizardPartInputForTeams';
import { WizardTeamDropdownOption, WizardTeamModalProps } from './helper/types';
/**
 * @param {object} usersOptions allUsers as options for a Dropdown selection *
 * @param {[]} usersOptions.usersOptions type
 * @returns {JSX.Element} Returns a modal with developers and suppliers to be chosen for 1 of 4 Roles ina a product
 */
const WizardTeamModal = ({
  usersOptions,
}: WizardTeamModalProps): JSX.Element => {
  const { t } = useTranslation(['newProductWizard', 'buttons']);
  const TeamsUpdate = useWizardPartInputForTeams(); // teams update Wizard hook
  const {
    setOpenTeamModal,
    openTeamModal,
    setStepComplete,
    setPartValidated,
    currentPart,
    wizardInput,
  } = useContext(WizardContext);
  // [true, 'name of the Column/Team which opens the Modal'] incoming default array from WizardInput
  const currentTeamName = openTeamModal[1];
  const [, setInFromWizard] = useState<Array<Scalars['String']>>([]);
  const [openSession, setOpenSession] = useState<Array<Scalars['String']>>([]); // *currentArr in the modal-populated only on opened modal
  const [emptyArrMsg, setEmptyArrMsg] = useState(false); // a message when by default no users in currentArray

  useEffect(() => {
    if (currentTeamName === 'Manager') {
      setOpenSession(wizardInput.partInput[currentPart - 1].manager || []);
    }
    if (currentTeamName === 'Developer') {
      setOpenSession(wizardInput.partInput[currentPart - 1].developer || []);
    }
    if (currentTeamName === 'Supplier') {
      setOpenSession(wizardInput.partInput[currentPart - 1].supplier || []);
    }
    if (currentTeamName === 'Guest') {
      setOpenSession(wizardInput.partInput[currentPart - 1].guest || []);
    }
  }, [currentPart, currentTeamName, wizardInput.partInput]);

  // removing a user from the modal aka opened Session
  const removeUser = (ereased: string | undefined) => {
    setOpenSession(openSession.filter((usersID) => usersID !== ereased));
  };
  useEffect(() => {
    if (openTeamModal && openSession.length === 0) {
      setEmptyArrMsg(true);
    } else setEmptyArrMsg(false);
  }, [openSession, openTeamModal]);
  const fromIdToName = openSession
    .filter((uID) => uID !== '')
    .map((uID) => usersOptions.find((user) => user.value === uID));

  // removing every chosen user from search to avoid duplicates!
  const checkDuplicates = usersOptions.filter(
    (user) => !openSession.includes(user.value)
  );
  let dropdownOptions: WizardTeamDropdownOption[] = [];
  if (currentTeamName === 'Manager' || currentTeamName === 'Developer') {
    dropdownOptions = checkDuplicates.filter((user) =>
      user.roles.includes(Role.developer)
    );
  }
  if (currentTeamName === 'Supplier') {
    dropdownOptions = checkDuplicates.filter((user) =>
      user.roles.includes(Role.supplier)
    );
  }
  if (currentTeamName === 'Guest') {
    dropdownOptions = checkDuplicates.filter(
      (user) => !user.roles.includes(Role.admin)
    );
  }

  const closeAndReset = () => {
    setOpenTeamModal([false, '']);
    setOpenSession([]); // if not applied/confirmed -doesn`t save chosen users
    setInFromWizard([]); // reset the local state shown only on opened Modal!
  };

  const showEmptyMessage = (): JSX.Element => {
    return currentTeamName === 'Manager' ? (
      <Message
        id="WizardTeamModalEmptyArrMsgManager"
        error
        icon="times circle"
        content={t('wizardsTeam.requiredArr.manager')}
      />
    ) : (
      <Message
        id="WizardTeamModalEmptyArrMsg"
        info
        icon="info circle"
        content={t(`wizardsTeam.emptyArrMsg.${currentTeamName.toLowerCase()}`, {
          ns: 'newProductWizard',
        })}
      />
    );
  };

  return (
    <Modal
      size="tiny"
      id="WizardTeamModal"
      open={openTeamModal[0] === true}
      onOpen={() => setOpenTeamModal([true, currentTeamName])}
      onClose={() => {
        closeAndReset();
      }}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {`${t('wizardsTeam.teamInModal', {
          currentTeamName: t(`wizardsTeam.${currentTeamName.toLowerCase()}`),
          ns: 'newProductWizard',
        })} (${fromIdToName.length})`}
        <Icon
          id="UserDetailsCloseModal"
          data-cy="CloseUserDetails"
          name="close"
          link
          onClick={closeAndReset}
        />
      </Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ overflow: 'auto', maxHeight: '128px' }}>
          <Segment.Group>
            {emptyArrMsg
              ? showEmptyMessage()
              : fromIdToName.map((pax) => (
                  <Segment
                    id="WizardTeamModalSegmentTeam"
                    key={`${pax?.value}${currentTeamName}${fromIdToName}`}
                  >
                    <Item id="UserItem" header={pax?.text} meta={pax?.email} />
                    <Icon
                      id="RemoveButton"
                      name="trash alternate outline"
                      color="red"
                      link
                      size="large"
                      onClick={() => {
                        removeUser(pax?.value);
                      }}
                    />
                  </Segment>
                ))}
          </Segment.Group>
        </Modal.Description>
        <Form id="WizardTeamMemberSearchForm" style={{ marginTop: '30px' }}>
          <Form.Dropdown
            key={openSession.join(',')}
            id="WizardTeamMemberSearch"
            name="TeamMemberSearch"
            icon="search"
            noResultsMessage="No results found."
            closeOnChange
            closeOnBlur
            selectOnBlur={false}
            placeholder={t('wizardsTeam.dropdownPlaceholder', {
              ns: 'newProductWizard',
            })}
            fluid
            search
            selection
            onChange={(
              event: React.SyntheticEvent<HTMLElement, Event>,
              data: DropdownProps
            ) => {
              setOpenSession([data.value as string, ...openSession]);
              setEmptyArrMsg(false); // onChange set first user- not more empty
            }}
            options={dropdownOptions}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions style={{ textAlign: 'center' }}>
        <Button
          disabled={openSession.length < 1 && currentTeamName === 'Manager'}
          primary
          id="WizardTeamModalApplyChangesBtn"
          content={`(${openSession.length}) ${t('apply', {
            ns: 'buttons',
          })}`}
          onClick={() => {
            TeamsUpdate(openSession, currentTeamName.toLowerCase());
            setOpenTeamModal([false, '']);
            setOpenSession([]);
            setPartValidated(true);
            setStepComplete(true);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default WizardTeamModal;
