import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Popup, StrictPopupProps } from 'semantic-ui-react';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import { Maybe } from '../../graphql/generated/graphql';

type PropsType = {
  elementName?: string;
  copyContent?: Maybe<string>;
  styleClass?: string;
  size?: IconSizeProp;
  position?: StrictPopupProps['position'];
};

/**
 * CopyIconPopup
 * small popup that informs the user that they just copied something to clipboard
 * and self destructs after a seconds
 *
 * @param {PropsType} props takes in copyContent, which is the content of the element that is copied - do not use the combined id.
 * - elementId, identifier...
 * @returns {JSX.Element} Popup
 */
const CopyIconPopup = (props: PropsType): JSX.Element => {
  const [text] = useTranslation('dashboard');
  const {
    elementName = 'id',
    copyContent,
    size = undefined,
    styleClass = '',
    position = 'left center',
  } = props;
  const defaultHoverContent = copyContent
    ? text(`tableElements.hoverCopyIconPopup.${elementName}`)
    : text('tableElements.hoverCopyIconPopupInvalid');
  const [openCopyIconPopup, setOpenCopyIconPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(defaultHoverContent);

  const handleClick = () => {
    if (copyContent) {
      navigator.clipboard.writeText(copyContent);
      setPopupContent(text(`tableElements.copiedPopup.${elementName}`));
      setTimeout(() => {
        setPopupContent(defaultHoverContent);
        setOpenCopyIconPopup(false);
      }, 2000);
    }
  };
  return (
    <Popup
      id={`CopyIconPopup.${copyContent}`}
      trigger={
        <Icon
          id="CopyIconPopup.Trigger"
          name="copy outline"
          link
          size={size}
          className={styleClass}
          onClick={handleClick}
          onMouseEnter={() => {
            setOpenCopyIconPopup(true);
          }}
          onMouseLeave={() => {
            setOpenCopyIconPopup(false);
          }}
        />
      }
      className="copy-icon-popup"
      content={popupContent}
      open={openCopyIconPopup}
      position={position}
    />
  );
};

export default CopyIconPopup;
