import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from '@apollo/client';
import { Button, Icon, Segment, Divider } from 'semantic-ui-react';
import { Comment } from '../../../../graphql/generated/graphql';
import CommentContentComponent from './CommentContentComponent';
import CreateCommentComponent from './CreateCommentComponent';
import { filterReplies } from './helper/logics';
import { displayedInputType } from './helper/types';

/**
 *  @param {DiscussionsReset} reset function exposed to parent components
 */
export type DiscussionsReset = {
  reset: () => void;
};

type DiscussionsProps = {
  comments: Comment[] | undefined;
  refersToId?: string | undefined;
  isModal?: boolean;
  sort?: 'descending' | 'ascending';
  refetch: (string | DocumentNode)[];
  shouldRefetch?: string[] | undefined;
};

/**
 * Discussions, comments and replies. 
 * 
 *@returns {JSX.Element} Stream of the Discussion sections, their comments and replies.
 @param {DiscussionsProps} props Props
 */
const Discussions = forwardRef<DiscussionsReset, DiscussionsProps>(
  (props, ref): JSX.Element => {
    const {
      comments,
      refersToId,
      isModal = false,
      refetch,
      sort = 'ascending',
      shouldRefetch,
    } = props;

    const [displayedInput, setDisplayedInput] =
      useState<displayedInputType>('');
    const [discussionId, setDiscussionId] = useState('');
    const [createComment, setCreateComment] = useState(false);

    useImperativeHandle(ref, () => ({
      reset() {
        setCreateComment(false);
        setDiscussionId('');
        setDisplayedInput('');
      },
    }));

    const [t] = useTranslation('comments');
    const hasComments = comments && comments.length > 0;
    const discussions = comments?.filter((comment) => !comment.replied);

    const sortedDiscussions = discussions?.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return sort === 'descending' ? dateB - dateA : dateA - dateB;
    });

    // purpose of the code below is basically making sure that only one input field is open at given time.

    useEffect(() => {
      if (!createComment) {
        setDiscussionId('');
      }
    }, [createComment]);

    const newComment = (id: string) => {
      setDisplayedInput('comment');
      setCreateComment(true);
      setDiscussionId(id);
    };

    const newDiscussion = () => {
      setDisplayedInput('discussion');
      setDiscussionId('');
      setCreateComment(true);
    };

    const modalStyles = {
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      overflowY: 'auto',
    };
    const regularStyles = {
      border: 'none',
      boxShadow: 'none',
    };

    return (
      <div>
        {hasComments && (
          <Segment
            id="CommentsModalWindowSegment"
            style={isModal ? modalStyles : regularStyles}
          >
            {sortedDiscussions?.map((c) => (
              <div key={`CommentsSegment${c.id}`}>
                <CommentContentComponent
                  replies={filterReplies(c, comments)}
                  allComments={comments}
                  comment={c}
                  commentIndex={
                    sort === 'ascending'
                      ? sortedDiscussions.indexOf(c)
                      : sortedDiscussions.slice(0).reverse().indexOf(c)
                  } // handles index number wihtin Label
                  refersToId={refersToId}
                  refetch={refetch}
                  displayedInput={displayedInput}
                  setDisplayedInput={setDisplayedInput}
                  shouldRefetch={shouldRefetch}
                />
                {displayedInput === 'comment' && discussionId === c.id && (
                  <CreateCommentComponent
                    createComment={createComment}
                    setCreateComment={setCreateComment}
                    refersToId={refersToId}
                    noComments={!hasComments}
                    isReplied={c.id}
                    refetch={refetch}
                    setDisplayedInput={setDisplayedInput}
                    shouldRefetch={shouldRefetch}
                  />
                )}
                <div style={{ margin: '15px 0', textAlign: 'left' }}>
                  {discussionId !== c.id && (
                    <Button
                      id="CommentsModalWindowAddCommentBtn"
                      disabled={!hasComments}
                      onClick={() => newComment(c.id)}
                    >
                      <Icon
                        id="CommentsModalWindowAddCommentBtnIcon"
                        name="add"
                        bordered
                        inverted
                        color="blue"
                      />
                      {t('addNewComment')}
                    </Button>
                  )}
                </div>

                <Divider style={{ marginBottom: '15px' }} />
              </div>
            ))}
          </Segment>
        )}

        <div
          style={{
            paddingBottom: '15px',
            textAlign: 'center',
          }}
        >
          {(displayedInput === 'discussion' || !hasComments) && (
            <CreateCommentComponent
              createComment={createComment}
              setCreateComment={setCreateComment}
              refersToId={refersToId}
              noComments={!hasComments}
              refetch={refetch}
              setDisplayedInput={setDisplayedInput}
              shouldRefetch={shouldRefetch}
            />
          )}
          <Button
            id="CommentsModalWindowAddDiscussionBtn"
            disabled={!hasComments || displayedInput === 'discussion'}
            content={t('addNewDiscussion')}
            icon="add circle"
            onClick={() => newDiscussion()}
            primary
          />
        </div>
      </div>
    );
  }
);

export default Discussions;
