import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { siblingTasksVar } from './helpers/utils';
import { SelectionType } from '../../../../graphql/generated/graphql';

interface CheckboxProps {
  selectionType: SelectionType;
  onToggle: () => void;
  parentId?: string | null;
  todoId: string;
  isDisabled?: boolean;
}

/**
 * Appears either as Checkbox for standalone toDos and toDo parents,
 * or as a Radio-button for Tasks..
 *
 * @param {SelectionType} selectType Selection type.
 *@returns {JSX.Element} Checkbox that toggles : selected, autoselected or unselected for TODOs/TASKs
 */
const ToDoCheckboxToggle = ({
  selectionType,
  onToggle,
  parentId,
  isDisabled = false,
  todoId,
}: CheckboxProps): JSX.Element => {
  // Access the sibling tasks state from the reactive variable
  const tasks = useReactiveVar(siblingTasksVar);

  // Check if the current task's selection type needs to be updated from the reactive variable
  const reactiveTask = tasks.find((task) => task.toDo.id === todoId);
  const effectiveSelectionType = reactiveTask
    ? reactiveTask.selectionType
    : selectionType;

  const isChecked =
    effectiveSelectionType === SelectionType.autoselected ||
    effectiveSelectionType === SelectionType.userselected;

  const checkboxId = `ToDoCheckbox_${todoId}`;

  return (
    <Checkbox
      id={checkboxId}
      radio={!!parentId}
      className="todo-checkbox"
      checked={isChecked}
      value={selectionType}
      disabled={isDisabled}
      onChange={onToggle}
    />
  );
};

export default ToDoCheckboxToggle;
