import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from 'semantic-ui-react';
import {
  ProductDetailsContext,
  ProductInfoBoxContext,
} from '../../../../../context/ProductContext';

/**
 * @returns {JSX.Element}  Edit Button Component for Info Box
 */
const EditProductButton = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { authorizations } = useContext(ProductDetailsContext);
  const {
    version,
    versionInput,
    editing,
    setEditing,
    setVersionInput,
    updateVersionMutation,
  } = useContext(ProductInfoBoxContext);
  const types = versionInput.types || version?.product?.type;

  if (editing)
    return (
      <Button
        id="EditProductButton.Save"
        primary
        disabled={types && types[1].length < 1} // disabled until both inputs are selected!
        icon="check"
        content={t('saveDetails', { ns: 'buttons' })}
        labelPosition="left"
        onClick={() => {
          setEditing(false);
          updateVersionMutation();
          setVersionInput({});
        }}
      />
    );
  return (
    <Popup
      id="EditProductButton.Popup"
      disabled={authorizations.includes('UpdateVersion')}
      content={t('noChangesAuthorized')}
      position="top center"
      trigger={
        <div>
          <Button
            id="EditProductButton.Edit"
            icon="pencil"
            content={t('editDetails', { ns: 'buttons' })}
            labelPosition="left"
            basic
            disabled={!authorizations.includes('UpdateVersion')}
            color="blue"
            onClick={() => setEditing(true)}
          />
        </div>
      }
    />
  );
};

export default EditProductButton;
