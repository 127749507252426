import { gql } from '@apollo/client';

/**
 * Data for the Header in Legal Product Details View.
 */
export const LEGAL_DETAILS_HEADER = gql`
  query LegalDetailsHeader($id: ID!) {
    Version(id: $id) {
      id
      name
      processStatusAsString
      product {
        id
        name
        type
        tags
        brandDirectory {
          name
        }
      }
    }
  }
`;

/**
 * Data for the Header in User Product Details View.
 */
export const USER_DETAILS_HEADER = gql`
  query UserDetailsHeader($id: ID!) {
    Version(id: $id) {
      id
      name
      processStatusAsString
      createdAt
      createdBy {
        name
        email
      }
      product {
        id
        name
        type
        tags
        brandDirectory {
          name
        }
      }
    }
  }
`;

/**
 * Data for the Header in Supplier Product Details View.
 */
export const SUPPLIER_DETAILS_HEADER = gql`
  query SupplierDetailsHeader($id: ID!) {
    Version(id: $id) {
      id
      name
      processStatusAsString
      createdAt
      createdBy {
        name
        email
      }
      product {
        id
        name
        type
        tags
        brandDirectory {
          name
        }
      }
    }
  }
`;

/**
 * Data for the Header in Guest Product Details View.
 */
export const GUEST_DETAILS_HEADER = gql`
  query GuestDetailsHeader($id: ID!) {
    Version(id: $id) {
      id
      name
      processStatusAsString
      createdAt
      createdBy {
        name
        email
      }
      product {
        id
        name
        type
        tags
        brandDirectory {
          name
        }
      }
    }
  }
`;

/**
 * Data for the Header in Oso Product Details View.
 */
export const OSO_DETAILS_HEADER = gql`
  query OsoDetailsHeader($id: ID!) {
    Version(id: $id) {
      id
      name
      processStatusAsString
      createdAt
      createdBy {
        name
        email
      }
      product {
        id
        name
        type
        tags
        brandDirectory {
          name
        }
      }
    }
  }
`;

/**
 * Data for the Contacts Segment in User Product Details View.
 */
export const PRODUCT_DETAILS_CONTACTS = gql`
  query ProductDetailsContacts($id: ID!) {
    Version(id: $id) {
      id
      name
      legalReviewer {
        id
        name
        email
      }
      osoReviewer {
        id
        name
        email
      }
      product {
        id
        brandDirectory {
          legals {
            id
            name
            email
          }
          osos {
            id
            name
            email
          }
        }
        managerTeam {
          id
          name
          email
        }
        developmentTeam {
          id
          name
          email
        }
        supplierTeam {
          id
          name
          email
        }
        guestTeam {
          id
          name
          email
        }
        inLegalCheckOrDone
      }
    }
  }
`;

/**
 * Data for the Info Box for the Applicant in Product Details View.
 */
export const PRODUCT_INFOBOX = gql`
  query ProductInfoBox($id: ID!) {
    Version(id: $id) {
      id
      processStatusAsString
      targetDate
      priority
      product {
        id
        description
        highPriorityRequest
        protection
        type
        standardSoftware
        selectedDistribution {
          id
          name_en
          name_de
        }
      }
    }
  }
`;

/**
 * Info required to check if the user is authorized to upload a LC.
 */
export const LC_UPLOAD_INFO = gql`
  query LcUploadInfo($versionId: ID!) {
    Version(id: $versionId) {
      id
      product {
        id
        selectedDistribution {
          id
        }
        supplierTeam {
          id
        }
        transfer {
          groupCompany
        }
      }
    }
    GetToDos(versionId: $versionId) {
      lcToDos {
        toDo {
          id
        }
      }
    }
  }
`;

/**
 * Data for the Info Box for the Applicant in Project Details View.
 *
 */
export const PROJECT_INFOBOX = gql`
  query ProjectInfoBox($id: ID!) {
    Version(id: $id) {
      id
      processStatusAsString
      targetDate
      priority
      product {
        id
        description
        type
        standardSoftware
      }
    }
  }
`;

/**
 * Product Details Context Query.
 */
export const PRODUCT_DETAILS_CONTEXT = gql`
  query ProductDetailsContext($id: ID!, $selectedRole: Role) {
    Version(id: $id) {
      id
      accessRoles
      processStatusAsString
      importPossible
      product {
        id
        isProject
        tags
        inLegalCheckOrDone
        transfer {
          externalId
          groupCompany
          contactPerson
        }
        selectedDistribution {
          id
        }
        supplierTeam {
          id
        }
      }
    }
    Authorizations(versionId: $id, selectedRole: $selectedRole)
  }
`;

/**
 * Check if a Product can be set to "COMPLETED"
 */
export const COMPLETION_POSSIBLE = gql`
  query CompletionPossible($versionId: ID!) {
    CompletionPossible(versionId: $versionId) {
      stateChangePossible
      reason
      steps {
        step
        fulfilled
      }
    }
  }
`;


/**
 * Check if a Product can be set to "LEGAL_CHECK_DONE"
 */
export const FINISH_LEGAL_CHECK_POSSIBLE = gql`
  query FinishLegalCheckPossible($versionId: ID!) {
    FinishLegalCheckPossible(versionId: $versionId) {
      stateChangePossible
      reason
      steps {
        step
        fulfilled
      }
    }
  }
`;

/**
 * Check if a Product can be set to "LEGAL_CHECK"
 */
export const LEGAL_CHECK_POSSIBLE = gql`
  query LegalCheckPossible($versionId: ID!) {
    LegalCheckPossible(versionId: $versionId) {
      stateChangePossible
      reason
      steps {
        step
        fulfilled
      }
    }
  }
`;

/**
 * Check if a Product can be set to "SUPPLIERTODOS"
 */
export const SUPPLIER_TODOS_POSSIBLE = gql`
  query SupplierToDosPossible($versionId: ID!) {
    SupplierToDosPossible(versionId: $versionId) {
      stateChangePossible
      reason
      steps {
        step
        fulfilled
      }
    }
  }
`;
 /**
  * Checks if all todos are selected wenn in SUPPLIERTODOS and the Product can be set to "LEGALCHECKDONE"
  */
export const SUPPLIER_TODOS_DONE_POSSIBLE = gql`
  query SupplierToDosDonePossible($versionId: ID!) {
    SupplierToDosDonePossible(versionId: $versionId) {
      stateChangePossible
      reason
      steps {
        step
        fulfilled
      }
    }
  }
`;
/**
 * Check if a Product can be set to "SUPPLIER_INPUT"
 */
export const SUPPLIER_INPUT_POSSIBLE = gql`
  query SupplierInputPossible($versionId: ID!) {
    SupplierInputPossible(versionId: $versionId) {
      stateChangePossible
      reason
      steps {
        step
        fulfilled
      }
    }
  }
`;
/**
 * Check if all user requirements are answered and the Product can be set to "INPROGRESS"
 */
export const SUPPLIER_INPUT_DONE_POSSIBLE = gql`
  query SupplierInputDonePossible($versionId: ID!) {
    SupplierInputDonePossible(versionId: $versionId) {
      stateChangePossible
      reason
      steps {
        step
        fulfilled
      }
    }
  }
`;

/**
 * Check if a Product can be set to "REPORT"
 */
export const FINISH_SUPPLIER_REPORT_POSSIBLE = gql`
  query finishSupplierReportPossible($versionId: ID!) {
    FinishSupplierReportPossible(versionId: $versionId) {
      stateChangePossible
      reason
      steps {
        step
        fulfilled
      }
    }
  }
`;

/**
 * Check if a PROJECT can be set to "COMPLETED"
 */
export const PROJECT_COMPLETION_POSSIBLE = gql`
  query ProjectCompletionPossible($versionId: ID!) {
    ProjectCompletionPossible(versionId: $versionId) {
      stateChangePossible
      reason
      steps {
        step
        fulfilled
      }
    }
  }
`;

/**
 * Get all Authorizations for a Product Version.
 */
export const AUTHORIZATIONS = gql`
  query Authorizations($versionId: ID, $selectedRole: Role) {
    Authorizations(versionId: $versionId, selectedRole: $selectedRole)
  }
`;

/**
 * get product version dependencies by version id
 */
export const DIRECT_PRODUCT_VERSION_DEPEPENDENCIES_BY_VERSION_ID = gql`
  query DirectProductVersionDependenciesByVersionId(
    $options: VersionOptions = { sort: [{ name: ASC }], offset: 0, limit: 5 }
    $where: VersionWhere
    $id: ID!
    $processStatus: [ProcessStatusInput]
  ) {
    DirectProductVersionDependenciesByVersionId(
      options: $options
      where: $where
      id: $id
      processStatus: $processStatus
    ) {
      id
      name
      product {
        id
        name
      }
      priority
      legalReviewer {
        name
      }
      targetDate
      processStatusAsString
      createdBy {
        id
        name
        email
      }
      legalResult {
        id
        status
        statusNr
      }
      resolverResult {
        id
        status
        statusNr
      }
      overallResult {
        id
        status
        statusNr
      }
      comments {
        id
      }
      numberOfDependenciesToProof
      numberOfDirectDependencies
      numberOfTransitiveDependencies
    }
    DirectProductVersionDependenciesByVersionIdAmount(id: $id, where: $where)
  }
`;
// Amount needs to consider the processStatusAsString filter

/**
 * get requestors of product version dependencies by version id
 */
export const DIRECT_PV_DEPEPENDENCIES_BY_VERSION_ID_REQUESTOR_LIST = gql`
  query DirectPVDependenciesByVersionIdRequestorList($id: ID!) {
    DirectProductVersionDependenciesByVersionId(id: $id) {
      createdBy {
        id
        name
      }
    }
  }
`;

/**
 * Products transfer properties when given.
 */
export const PRODUCT_TRANSFER_DATA = gql`
  query ProductTransferData($id: ID!) {
    Version(id: $id) {
      id
      name
      product {
        name
        id
        name
        transfer {
          externalId
          groupCompany
          contactPerson
        }
      }
      overallResult {
        id
        status
        note
        lastModifiedAt
      }      
    }
  }
`;

/**
 * Product type.
 */
export const PRODUCT_TYPE = gql`
  query ProductType($id: ID!) {
    Version(id: $id) {
      id
      product {
        id
        type
      }
    }
  }
`;

/**
 * Product name by id
 */
export const PRODUCT_IMPORT_MODAL = gql`
  query ProductImportModal($id: ID!) {
    Version(id: $id) {
      id
      product {
        id
        name
      }
    }
  }
`;

/**
 * Version resolver & overall results
 */
export const VERSION_RESULTS = gql`
  query VersionResults($id: ID!) {
    Version(id: $id) {
      id
      resolverResult {
        id
        status
        note
        lastModifiedAt
      }
      overallResult {
        id
        status
        note
        lastModifiedAt
      }
    }
  }
`;

/**
 * Product version history
 */
export const PRODUCT_VERSION_HISTORY = gql`
  query ProductVersionHistory($id: ID!) {
    Version(id: $id) {
      id
      name
      history {
        __typename
        ... on PropertyChange {
          id
          changeType
          category
          createdAt
          createdById
          createdByRole
          createdByRoleOld
          createdBy {
            id
            name
            email
          }
          refId
          property
          prevValue
          newValue
          commentCount
        }
        ... on NodeChange {
          id
          changeType
          category
          createdAt
          createdById
          createdByRole
          createdByRoleOld
          createdBy {
            id
            name
            email
          }
          refNode
          commentCount
        }
        ... on RelationshipChange {
          id
          changeType
          category
          createdAt
          createdById
          createdByRole
          createdByRoleOld
          createdBy {
            id
            name
            email
          }
          refNode
          refInfo
          refType
          property
          prevValue
          newValue
          commentCount
        }
        ... on StorageChange {
          id
          changeType
          category
          createdAt
          createdById
          createdByRole
          createdByRoleOld
          createdBy {
            id
            name
            email
          }
          refNode
          fileName
          fileCategory
          commentCount
        }
      }
      product {
        id
        name
        history {
          __typename
          ... on PropertyChange {
            id
            changeType
            category
            createdAt
            createdById
            createdByRole
            createdByRoleOld
            createdBy {
              id
              name
              email
            }
            refId
            property
            prevValue
            newValue
            commentCount
          }
          ... on NodeChange {
            id
            changeType
            category
            createdAt
            createdById
            createdByRole
            createdByRoleOld
            createdBy {
              id
              name
              email
            }
            refNode
            commentCount
          }
          ... on RelationshipChange {
            id
            changeType
            category
            createdAt
            createdById
            createdByRole
            createdByRoleOld
            createdBy {
              id
              name
              email
            }
            refNode
            refType
            property
            prevValue
            newValue
            commentCount
          }
          ... on StorageChange {
            id
            changeType
            category
            createdAt
            createdById
            createdByRole
            createdByRoleOld
            createdBy {
              id
              name
              email
            }
            refNode
            fileName
            fileCategory
            commentCount
          }
        }
      }
    }
  }
`;


/**
 * Product version dependencies
 */
export const GET_PROJECT_VERSION_PARTS = gql`
query ProjectVersionParts($id: ID!) {
  Version(id: $id) {
    id
    name    
    productDependencies { 
      id
      name
      product {
       id
       name
      }
    }
  }
}
`;

/**
 *
 */
export const CHECK_IF_VERSION_DELETABLE = gql`
  query IsVersionDeletable($id: ID!) {
    VersionDeletable(id: $id) {
      deletable
      reason
    }
  }
`;

/**
 * Return true if an Import is currently running
 * 
 * @param id Version ID
 */
export const RUNNING_IMPORT = gql`
  query RunningImport($id: ID!) {
    Version(id: $id) {
      id
      runningImport
      importPossible
    }
  }
`;