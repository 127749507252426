import React, { useState, ChangeEvent, useEffect } from 'react';
import { Modal, Form, Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Product } from '../../../../../graphql/generated/graphql';
import { UPDATE_TRANSFER_PARAMS } from '../../../../../graphql/mutations/VersionMutations';
import { PRODUCT_TRANSFER_DATA } from '../../../../../graphql/queries/VersionQuerys';
import settingTransferProps from './GroupTakeoverConstantsAndTypes';
import { transferCompanyOptions } from '../../../../wizard/WizardConstants';
import useTranslateOptions from '../../../../user/userComponents/helpers/useTranslateOptions';

type GroupTakeoverModalProps = {
  openEdit: [boolean, string];
  setOpenEdit: (openEdit: [boolean, string]) => void;
  versionProduct: Product;
  versionId: string;
};

/**
 * Group Takeover Editing Modal Component
 *
 * @param {string} openEdit boolean
 * @returns {JSX.Element} Component takes in one of 3 properties in group takeover process and displays an update field
 */
const GroupTakeOverEditingModal = ({
  openEdit,
  setOpenEdit,
  versionProduct,
  versionId,
}: GroupTakeoverModalProps): JSX.Element => {
  const [t] = useTranslation(['newProductWizard', 'productDetails', 'buttons']);
  const translate = useTranslateOptions('users');
  const { transfer } = versionProduct; // transfer has all 3 property-Data
  const currentProperty = openEdit[1]; // name/key of the chosen transfer-property given onClick!

  // updated will be only value of current property
  const [transferProps, setTransferProps] = useState({
    transferExternalId: transfer?.externalId?.trim(),
    transferGroupCompany: transfer?.groupCompany?.trim(),
    transferContactPerson: transfer?.contactPerson?.trim(),
  });

  const translationKeys: { [key: string]: string } = {
    transferExternalId: 'newProductWizard:productDetails.uniqueIdentifier-info',
    transferGroupCompany: 'newProductWizard:productDetails.groupCompany-info',
    transferContactPerson: 'newProductWizard:productDetails.contactPerson-info',
  };

  const [currentPropValue, setCurrentPropValue] = useState('');

  const [UpdateTransfer] = useMutation(UPDATE_TRANSFER_PARAMS, {
    refetchQueries: [PRODUCT_TRANSFER_DATA],
  });

  const handleUpdate = async () => {
    UpdateTransfer({
      variables: {
        versionId,
        transferExternalId: transferProps.transferExternalId,
        transferGroupCompany: transferProps.transferGroupCompany,
        transferContactPerson: transferProps.transferContactPerson,
      },
    });
    setOpenEdit([false, '']);
    setCurrentPropValue('');
  };

  const handleClose = () => {
    setOpenEdit([false, '']);
    setCurrentPropValue('');
    settingTransferProps(setTransferProps, transfer);
  };

  useEffect(() => {
    if (currentProperty === 'transferExternalId') {
      setCurrentPropValue(transferProps.transferExternalId || '');
    }
    if (currentProperty === 'transferGroupCompany') {
      setCurrentPropValue(transferProps.transferGroupCompany || '');
    }
    if (currentProperty === 'transferContactPerson') {
      setCurrentPropValue(transferProps.transferContactPerson || '');
    }
  }, [currentProperty, setCurrentPropValue, transferProps, currentPropValue]);

  return (
    <Modal
      size="tiny"
      id="GroupTakeOverEditingModal"
      open={openEdit[0] === true}
      onOpen={() => setOpenEdit([true, currentProperty])}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {t(`groupTakeover`, { ns: 'productDetails' })}
        <Icon
          id="iconCloseTakeOverModal"
          name="close"
          link
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Content>
        <span>{t('description', { ns: 'productDetails' })}</span>
        <Modal.Description
          style={{ margin: '14px 0 ', color: 'black', fontSize: '14px' }}
        >
          {t(translationKeys[currentProperty])}
        </Modal.Description>
        <Form id="GroupTakeOverEditingModalForm">
          {currentProperty && currentProperty === 'transferGroupCompany' ? (
            <Form.Dropdown
              required
              fluid
              selection
              style={{
                margin: '16px 0 ',
              }}
              label={
                <span
                  style={{ color: 'black', fontSize: 16, fontWeight: 'bold' }}
                >
                  {t(`${currentProperty}`, { ns: 'productDetails' })}
                </span>
              }
              value={currentPropValue}
              options={translate(transferCompanyOptions)}
              onChange={(_e, { value }) => {
                setTransferProps({
                  ...transferProps,
                  [currentProperty]: value as string,
                });
              }}
            />
          ) : (
            <Form.Input
              required
              style={{
                margin: '7px 0 ',
              }}
              label={
                <span
                  style={{ color: 'black', fontSize: 16, fontWeight: 'bold' }}
                >
                  {t(`${currentProperty}`, { ns: 'productDetails' })}
                </span>
              }
              value={currentPropValue}
              onChange={(_e: ChangeEvent, { value }) => {
                setTransferProps({
                  ...transferProps,
                  [currentProperty]: value,
                });
              }}
              error={
                currentPropValue?.trim().length < 2
                  ? t(`productDetails.error.${currentProperty}`)
                  : null
              }
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions style={{ textAlign: 'center' }}>
        <Button
          id="GroupTakeOverEditingModalSaveBtn"
          primary
          disabled={!currentPropValue?.trim() || currentPropValue?.length < 2}
          content={t('saveChanges', { ns: 'buttons' })}
          onClick={handleUpdate}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default GroupTakeOverEditingModal;
