import { useQuery } from '@apollo/client/react/hooks/useQuery';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Table } from 'semantic-ui-react';
import { SelectedRoleContext } from '../../../../../../context/UserContext';
import { ProductDetailsContext } from '../../../../../../context/ProductContext';
import {
  DirectProductVersionDependenciesByVersionIdQueryVariables,
  Version,
} from '../../../../../../graphql/generated/graphql';
import { DIRECT_PRODUCT_VERSION_DEPEPENDENCIES_BY_VERSION_ID } from '../../../../../../graphql/queries/VersionQuerys';
import Search from '../../../../listComponents/Search';
import CustomPagination from '../../../../listComponents/pagination/CustomPagination';
import ItemsPerPage from '../../../../listComponents/pagination/ItemsPerPage';
import LoadingTable from '../../../../productsList/listComponents/LoadingTable';
import {
  Column,
  Columns,
} from '../../../../productsList/listComponents/ProductListTypes';
import ResolverSegment from '../../ResolverSegment';
import DependencyListBody from '../commonListComponents/DependencyListBody';
import DependencyListHeader from '../commonListComponents/DependencyListHeader';
import {
  useFetchRequestorList,
  usePagination,
  useRefetchSortedAndFilteredPVDepList,
} from './PVDependencyListHooks';
import getDefaultColumns from './PVDependencyListTypes';
import ProductFilterPills from '../../../../../ui/ProductFilterPills';

/**
 * ProductVersionDependencyList
 *
 * @returns {JSX.Element} table containing list with dependencies
 */
const PVDependencyList = (): JSX.Element => {
  const [text] = useTranslation('dashboard');
  const { versionId } = useContext(ProductDetailsContext);

  const { data, loading, error, refetch } = useQuery<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any, // TODO: Check how to get the correct type here without having "Maybe" everywhere.
    DirectProductVersionDependenciesByVersionIdQueryVariables
  >(DIRECT_PRODUCT_VERSION_DEPEPENDENCIES_BY_VERSION_ID, {
    variables: {
      id: versionId,
      options: {},
      where: {},
      processStatus: [],
    },
  });
  const productVersionDependencies: Array<Version> =
    data?.DirectProductVersionDependenciesByVersionId;
  const productVersionDependenciesAmount: number =
    data?.DirectProductVersionDependenciesByVersionIdAmount;

  const roleContext = useContext(SelectedRoleContext);
  const { selectedRole: role } = roleContext;

  const [columns, setColumns] = useState<Columns>(getDefaultColumns(role));
  const [activePageState, setActivePageState] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pages, setPages] = useState(1);
  const [searchString, setSearchString] = useState('');

  useRefetchSortedAndFilteredPVDepList(
    searchString,
    columns,
    activePageState,
    itemsPerPage,
    versionId,
    refetch
  );

  useFetchRequestorList(setColumns, versionId);

  usePagination(
    activePageState,
    productVersionDependenciesAmount,
    pages,
    setPages,
    setActivePageState,
    itemsPerPage
  );

  return (
    <>
      {data && <ResolverSegment refetch={refetch} />}
      <Grid>
        <Grid.Column width={6}>
          <Search
            loading={loading}
            error={error}
            id="ProductsList.Search"
            searchString={searchString}
            onSearchChange={(searchInput: string) => {
              setSearchString(searchInput);
            }}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <p style={{ textAlign: 'right' }} id="PVDependencyListPVAmount">
            {text('productCounter', {
              visibleProductAmount: productVersionDependencies?.length,
              productAmount: productVersionDependenciesAmount,
            })}
          </p>
        </Grid.Column>
      </Grid>
      <hr style={{ margin: '1em 0em' }} />
      <ProductFilterPills
        column={columns.find(
          (column: Column) => column.name === 'projectStatus'
        )}
        setColumns={setColumns}
      />
      <Table
        style={{ width: '100%' }}
        fixed
        singleLine
        selectable
        id="DependencyListTable"
      >
        <Table.Header>
          <DependencyListHeader columns={columns} setColumns={setColumns} />
        </Table.Header>
        <Table.Body>
          {data && (
            <DependencyListBody
              columns={columns}
              pVDependencies={productVersionDependencies}
            />
          )}
          {(error || loading) && (
            <LoadingTable
              rowAmount={itemsPerPage}
              columnAmount={
                columns.filter((col: Column) => col.permitted === true).length
              }
            />
          )}
        </Table.Body>
      </Table>
      <Grid columns="equal" className="tableFooter">
        <Grid.Column />
        <Grid.Column width={6}>
          {data && (
            <CustomPagination
              loading={loading}
              error={error}
              totalPages={pages}
              activePage={activePageState}
              setActivePage={setActivePageState}
            />
          )}
        </Grid.Column>
        <Grid.Column>
          <ItemsPerPage
            loading={loading}
            error={error}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            itemAmount={productVersionDependenciesAmount}
            activePage={activePageState}
          />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default PVDependencyList;
