import { gql } from '@apollo/client';

/**
 * Product ToDo selection mutation.
 *
 */
export const SELECT_TODO = gql`
  mutation SelectToDo($versionId: ID!, $toDoId: ID!) {
    SelectToDo(versionId: $versionId, toDoId: $toDoId)
  }
`;

/**
 * Creates select_feature relationships between version and default todos.
 *
 */
export const INITIALIZE_DEFAULT_TODOS = gql`
  mutation InitializeDefaultToDos($versionId: ID!) {
    InitializeDefaultToDos(versionId: $versionId)
  }
`
