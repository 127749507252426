import React, { useEffect, useState } from 'react';
import {
  Divider,
  Dropdown,
  DropdownProps,
  Icon,
  Label,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  DirectoryAccess,
  DirectoryRole,
  InputMaybe,
  Role,
} from '../../../graphql/generated/graphql';
import { AccessRoles } from './helpers/DropdownOptions';
import { checkingRolesForAccess } from './helpers/logics';

type Props = {
  directory: DirectoryAccess;
  usersGlobalRoles?: InputMaybe<Role | Role[]> | undefined;
  directoryAccessArr: DirectoryAccess[];
  setDirectoryAccessArr: React.Dispatch<
    React.SetStateAction<DirectoryAccess[]>
  >;
  indexOfDirectory: number;
};

/**
 *@returns {JSX.Element} Directory access and granting either OSO or Legal access role for users
 @param  {Props} props - Props
 */
const DirectoryAccessComponent = (props: Props): JSX.Element => {
  const { t } = useTranslation('users');
  const {
    directory,
    usersGlobalRoles,
    directoryAccessArr,
    setDirectoryAccessArr,
    indexOfDirectory: index,
  } = props;
  // if the user has only one of these roles-should not get a dropdown but access automaticaly
  const legalAndoso = checkingRolesForAccess(usersGlobalRoles, 'legal&oso');
  const onlyLegal = checkingRolesForAccess(usersGlobalRoles, 'onlyLegal');
  const onlyOso = checkingRolesForAccess(usersGlobalRoles, 'onlyOso');

  const [onlyLegalState, setOnlyLegalState] = useState(onlyLegal);
  const [onlyOsoState, setOnlyOsoState] = useState(onlyOso);

  const removeDirectory = () => {
    setDirectoryAccessArr(
      directoryAccessArr.filter(
        (dir) => dir.directoryId !== directory.directoryId
      )
    );
  };

  useEffect(() => {
    if (onlyLegalState) {
      setDirectoryAccessArr([
        ...directoryAccessArr.slice(0, index),
        {
          ...directoryAccessArr[index],
          directoryRoles: [DirectoryRole.legal],
        },
        ...directoryAccessArr.slice(index + 1),
      ]);
      setOnlyLegalState(false);
    } else if (onlyOsoState) {
      setDirectoryAccessArr([
        ...directoryAccessArr.slice(0, index),
        {
          ...directoryAccessArr[index],
          directoryRoles: [DirectoryRole.oso],
        },
        ...directoryAccessArr.slice(index + 1),
      ]);
      setOnlyOsoState(false);
    }
  }, [
    directoryAccessArr,
    index,
    onlyLegalState,
    onlyOsoState,
    setDirectoryAccessArr,
  ]);

  return (
    <>
      <div>
        <span>{t(`brand.${directory.directoryName}`)}</span>
        <span id="DirectoryAccessDropdownElement">
          {legalAndoso && (
            <Dropdown
              id="DirectoryAccessComponentAccessRole"
              options={AccessRoles}
              value={directoryAccessArr[index].directoryRoles}
              placeholder={t('directories.selectAccessRights')}
              closeOnChange
              multiple
              selectOnBlur={false}
              pointing="right"
              icon="angle down"
              onChange={(_e, data: DropdownProps) =>
                setDirectoryAccessArr([
                  ...directoryAccessArr.slice(0, index),
                  {
                    ...directoryAccessArr[index],
                    directoryRoles: data.value as DirectoryRole[],
                  },
                  ...directoryAccessArr.slice(index + 1),
                ])
              }
            />
          )}
          {onlyLegal && (
            <Label
              id="DirectoryAccessComponentAccessRoleLegal"
              content={DirectoryRole.legal}
              size="large"
            />
          )}
          {onlyOso && (
            <Label
              id="DirectoryAccessComponentAccessRoleOso"
              content={DirectoryRole.oso}
              size="large"
            />
          )}
          <Icon
            id="DirectoryAccessComponentRemoveAccessRight"
            style={{ margin: '0 1.5rem' }}
            name="trash alternate outline"
            link
            color="red"
            onClick={removeDirectory}
          />
        </span>
      </div>
      <Divider style={{ margin: '0.5rem 0 0 0' }} />
    </>
  );
};

export default DirectoryAccessComponent;
