import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Item } from 'semantic-ui-react';
import {
  ProductDetailsContext,
  ProductInfoBoxContext,
} from '../../../../../context/ProductContext';
import InfoIconPopup from '../../../../ui/InfoIconPopup';
import ProtectionModal from './ProtectionModal';

/**
 * @returns {JSX.Element}  Protection Component for Info Box
 */
const ProtectionItem = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { inLegalCheckOrDone } = useContext(ProductDetailsContext);
  const { version, versionInput, editing } = useContext(ProductInfoBoxContext);
  const protection =
    versionInput.protection ??
    (version?.product && 'protection' in version.product
      ? version.product.protection
      : undefined);
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <ProtectionModal open={openModal} setOpen={setOpenModal} />
      <Item id="Protection">
        <Item.Content>
          <Item.Meta>
            {/* ICON INFO IN CIRCLE */}
            {t('protection.meta')}
            <InfoIconPopup infoText={t('protection-info')} />
            {editing && !inLegalCheckOrDone && (
              <Item.Extra
                className="edit-link"
                onClick={() => setOpenModal(true)}
              >
                {t('editProtection', { ns: 'buttons' })}
              </Item.Extra>
            )}
          </Item.Meta>
          <span>
            <Icon id="Protection.Icon" name={protection ? 'check' : 'x'} />
            <strong>{t('protection')}</strong>
          </span>
        </Item.Content>
      </Item>
    </>
  );
};

export default ProtectionItem;
