import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  Item,
  Label,
  Modal,
  Message,
  Popup,
  TextArea,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../../../../context/ProductContext';
import ProductLicenseDetailsUpdate from './ProductLicenseDetailsUpdate';
import {
  DependencySubComponentsRelationship,
  Subcomponent,
} from '../../../../../../../../graphql/generated/graphql';

type Props = {
  dependencyId: string | undefined;
  licenseText: string | undefined;
  licenseTextLoading: boolean;
  subComponent: Subcomponent;
  subComponentEdge: DependencySubComponentsRelationship;
};

/**
 * License Elements Modals First Tab
 *
 *@param {Props} props Props from LicenseElement => licenses and subComponentsConnections
 *@returns {JSX.Element} Returning First Tab of the modal => Product License Details
 */
const LicenseElementModalsProductDetails = (props: Props): JSX.Element => {
  const {
    dependencyId,
    licenseText,
    licenseTextLoading,
    subComponent,
    subComponentEdge,
  } = props;
  const [text] = useTranslation('productDetails');

  const { authorizations } = useContext(ProductDetailsContext);

  const canEdit = authorizations.includes('UpdateProductLicenseDetails');
  const canEditPermissionNote = authorizations.includes('UpdatePermissionNote');
  const [openUpdate, setOpenUpdate] = useState(false);

  const copyrightsArr =
    subComponentEdge && subComponentEdge?.copyrights.length > 0
      ? subComponentEdge.copyrights
      : [];
  const permissionNote = subComponentEdge?.permissionNote;

  if (openUpdate)
    return (
      <ProductLicenseDetailsUpdate
        licenseText={licenseText}
        copyrightsArr={copyrightsArr}
        setOpenUpdate={setOpenUpdate}
        dependencyId={dependencyId}
        permissionState={permissionNote}
        canEditPermissionNote={canEditPermissionNote}
        licenseTextLoading={licenseTextLoading}
        subComponent={subComponent}
        subComponentEdge={subComponentEdge}
      />
    );
  return (
    <Modal.Content>
      <Item.Group>
        <Item id="LicensesElementModalCopyrights">
          <Item.Content>
            <Item.Meta
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <Header as="h5">
                  {text('dependencyList.licenseModal.copyrights')}
                </Header>
                <Label
                  id="LicensesElementModalCopyrightsNumberLabel"
                  content={copyrightsArr.length}
                />
              </div>
              <Popup
                id="NotAllowedPopup"
                disabled={canEdit}
                content={text('dependencyList.licenseModal.noEdit')}
                on="hover"
                position="top center"
                eventsEnabled
                trigger={
                  <span>
                    <Button
                      className="LicenseElementModalsEditProductDetailsButtons"
                      floated="right"
                      disabled={!canEdit}
                      id="LicenseElementModalsEditProductDetails"
                      size="medium"
                      icon="edit outline"
                      color="blue"
                      content={text('dependencyList.licenseModal.edit')}
                      onClick={() => setOpenUpdate(true)}
                    />
                  </span>
                }
              />
            </Item.Meta>
            <div className="LicenseElementModalsEditProductDetailsCopyrights">
              {copyrightsArr.length > 0
                ? copyrightsArr.map((c: string) => (
                    <Item.Description key={`LicenseElement${c}`}>
                      <Icon
                        id="LicensesElementModalCopyrightsIcon"
                        name="copyright outline"
                      />
                      {c}
                    </Item.Description>
                  ))
                : text('dependencyList.tableBody.noCopyright')}
            </div>
          </Item.Content>
        </Item>
        <Item id="LicensesElementModalLicenseText">
          <Item.Content>
            <div className="PermisssionNote">
              <Header as="h5">
                {text('dependencyList.licenseModal.licenseText')}
              </Header>
              <Checkbox
                id="PermissionNoteCheckboxToggleDisabled"
                disabled
                toggle
                checked={permissionNote}
                label={text(`dependencyList.licenseModal.permissionNote`)}
              />
            </div>
            {!licenseText && !licenseTextLoading ? (
              <Message
                id="ProductDetailsNoLicenseTextMessage"
                error
                icon="times circle"
                header={text('dependencyList.licenseModal.noLicenseTextHeader')}
                content={text('dependencyList.licenseModal.noLicenseText')}
              />
            ) : (
              <Form id="DescriptionModal.TextArea">
                <TextArea
                  rows={40}
                  readOnly
                  value={licenseText}
                  className="LicensesElementModalLicenseTextEdit"
                />
              </Form>
            )}
          </Item.Content>
        </Item>
      </Item.Group>
    </Modal.Content>
  );
};

export default LicenseElementModalsProductDetails;
