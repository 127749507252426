import React, { useEffect, useState } from 'react';
import { Popup, Dropdown, DropdownItemProps, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  DirectoryAccess,
  DirectoryRole,
  Maybe,
} from '../../../graphql/generated/graphql';
import getIdFromKey, {
  useBuildOptions,
  filteringOptions,
  noticeWhenEmptyOptions,
} from './helpers/logics';

type Props = {
  directoryAccessArr: DirectoryAccess[];
  setDirectoryAccessArr: React.Dispatch<
    React.SetStateAction<DirectoryAccess[]>
  >;
  brandDirectories: Maybe<Array<Maybe<DirectoryAccess>>> | undefined;
  usersWithAccess: boolean;
};

/**
 *@returns {JSX.Element} Add brand directories dropdown as a button component that adds directories for a user and to be given access to him
 *@param {Props} props props
 */
const AddDirectoriesDropdownButton = (props: Props): JSX.Element => {
  const { t } = useTranslation('users');
  const {
    directoryAccessArr,
    setDirectoryAccessArr,
    usersWithAccess,
    brandDirectories,
  } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const timeoutLength = 2000; // 2 seconds
  const noMoreoptions = noticeWhenEmptyOptions(
    brandDirectories,
    directoryAccessArr
  );
  const buildOptions = useBuildOptions(
    filteringOptions(brandDirectories, directoryAccessArr)
  );
  // if there are no options/directories left- notice with timeout pops up
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (openPopup) {
      timeout = setTimeout(() => {
        setOpenPopup(false);
      }, timeoutLength);
    }

    return () => clearTimeout(timeout);
  }, [openPopup]);

  return (
    <div>
      {noMoreoptions ? (
        <Popup
          id="UserDirectoriesDropdownErrorMessage"
          open={openPopup}
          content={t('allDistsChosen')}
          position="top left"
          on="click"
          trigger={
            <Button
              id="UserDirectoriesAddDirectoriesWhenPopup"
              onClick={() => setOpenPopup(true)}
              icon="add"
              content={t('directories.add')}
            />
          }
        />
      ) : (
        <Dropdown
          id="UserDirectoriesAddDirectories"
          disabled={!usersWithAccess}
          button
          selectOnBlur={false}
          value="" // disables automatic selection of items-we are not using the input filed
          className="icon"
          floating
          labeled
          icon="add"
          options={buildOptions || undefined}
          onChange={(_e, data) => {
            setDirectoryAccessArr([
              ...directoryAccessArr,
              {
                directoryId: getIdFromKey(
                  data.options as DropdownItemProps[],
                  data.value as string
                ),
                directoryName: data.value as string,
                directoryRoles: [] as DirectoryRole[],
              },
            ]);
          }}
          text={t('directories.add')}
        />
      )}
    </div>
  );
};

export default AddDirectoriesDropdownButton;
