import { gql } from '@apollo/client';
import { User } from '../generated/graphql';

/**
 * Current User Query Document
 *
 * @returns {User} The user that is currently logged in.
 */
export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    CurrentUser {
      id
      name
      roles
      email
      status
      brand
      groups {
        id
        name
      }
    }
  }
`;

/**
 *  All users Query Document
 *
 * @returns {User} array of all users as objects with data
 */
export const ALL_USERS_QUERY = gql`
  query AllUsers {
    User {
      id
      name
      roles
      groups {
        id
        name
      }
      email
      status
      brand
    }
  }
`;

/**
 *  Querrying users by ID
 *
 *@returns {User} by ID
 */
export const USER_BY_ID = gql`
  query UserById($id: ID) {
    User(id: $id) {
      id
      name
      email
      roles
      groups {
        id
        name
      }
      status
      createdAt
    }
  }
`;
/**
 * Get all Global Authorizations based on the Users selected Role
 */
export const GLOBAL_AUTHORIZATIONS = gql`
  query GlobalAuthorizations($selectedRole: Role) {
    Authorizations(selectedRole: $selectedRole)
  }
`;
/**
 * Querying all usernames
 *
 * @returns {User} Username and the ID for each
 */
export const GET_ALL_USERNAMES = gql`
  query GetAllUsers {
    User {
      name
      id
    }
  }
`;

/**
 *  Query if database and artifactory are online
 *
 *@returns {boolean}
 */
export const SYSTEMS_ONLINE_QUERY = gql`
  query SystemsAllOnline {
    SystemsAllOnline
  }
`;
/**
 *  Query if aosd is in maintenance mode
 *
 *@returns {boolean}
 */
export const MAINTENANCE_MODE_QUERY = gql`
  query CheckMaintenanceMode {
    CheckMaintenanceMode
  }
`;
