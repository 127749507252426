import React, { useState } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import {
  ChangeCategory,
  ChangeLog,
  ChangeType,
} from '../../../../graphql/generated/graphql';
import i18n from '../../../../i18n/i18n';
import { toLocalDateAndTime } from '../../productDetails/helpers/logics';
import {
  Column,
  Columns,
} from '../../productsList/listComponents/ProductListTypes';
import NodeChangeElement from './columnComponents/NodeChangeElement';
import PropertyChangeElement from './columnComponents/PropertyChangeElement';
import RoleElement from './columnComponents/RoleElement';
import StorageChangeElement from './columnComponents/StorageChangeElement';
import OverflowPopup from '../../productsList/listComponents/columnComponents/OverflowPopup';
import { OpenCommentsModalState } from '../../productDetails/comments/helper/types';
import CommentsModalWindow from '../../productDetails/comments/CommentsModalWindow';
import { CommentsModalContext } from '../../productDetails/requirements/helper/Constants';

type PropsType = {
  columns: Columns;
  element: ChangeLog;
  useCommentIcon?: boolean;
};

/**
 * list element of products list
 *
 * @param {PropsType} props takes in version or Product
 * Checks which one came in and delivers the according table row.
 * @returns {JSX.Element} table row containing table cells with the according values.
 */
const ProductHistoryListElement = (props: PropsType): JSX.Element => {
  const { columns, element, useCommentIcon = true } = props;
  const [userText] = useTranslation('users');
  const [openCommentsModal, setOpenCommentsModal] =
    useState<OpenCommentsModalState>([false, null]);
  const elementId = element.id;
  const [JSXAsElementName, setJSXAsElementName] =
    useState<React.ReactNode | null>(null);

  const getRoles = (logEntry: ChangeLog) => {
    if (logEntry.createdByRole && logEntry.createdByRole.length > 0) {
      return logEntry.createdByRole;
    }
    if (logEntry.createdByRoleOld && logEntry.createdByRoleOld.length > 0) {
      return logEntry.createdByRoleOld;
    }
    // automatic change from AOSD without any persisted roles
    return [];
  };
  const handleOpenCommentsModal = (jsxElement: React.ReactNode) => {
    setJSXAsElementName(jsxElement);
    setOpenCommentsModal([true, CommentsModalContext.History]);
  };

  return (
    <>
      <Table.Row id={`ProductsList.${elementId}`} key={elementId}>
        {columns.map((column: Column) => {
          if (column.permitted && column.shown) {
            return (
              <Table.Cell key={column.name} width={column.width}>
                {(() => {
                  switch (column.name) {
                    case 'date':
                      return (
                        <span className={column.name}>
                          {toLocalDateAndTime(element.createdAt, i18n.language)}
                        </span>
                      );
                    case 'author':
                      return (
                        <span className={column.name}>
                          {element.createdBy?.name ||
                            userText(`internalUserName`)}
                        </span>
                      );
                    case 'role':
                      return <RoleElement roleArray={getRoles(element)} />;
                    case 'category':
                      return (
                        <OverflowPopup
                          content={
                            <span className={column.name}>
                              {element.category}
                            </span>
                          }
                        />
                      );
                    case 'operation':
                      switch (element.__typename) {
                        case 'PropertyChange':
                        case 'RelationshipChange':
                          return (
                            <div className="HistoryOperationsContainer">
                              <PropertyChangeElement
                                changeType={element.changeType as ChangeType}
                                refNode={element.refNode}
                                refInfo={
                                  element.__typename === 'RelationshipChange'
                                    ? element.refInfo
                                    : null
                                }
                                refType={
                                  element.__typename === 'RelationshipChange'
                                    ? element.refType
                                    : null
                                }
                                property={element.property}
                                prevValue={element.prevValue}
                                newValue={element.newValue}
                              />
                              {useCommentIcon && (
                                <Icon
                                  id="RelationshipChange"
                                  name="comment outline"
                                  color="blue"
                                  link
                                  onClick={() =>
                                    handleOpenCommentsModal(
                                      <PropertyChangeElement
                                        changeType={
                                          element.changeType as ChangeType
                                        }
                                        refNode={element.refNode}
                                        refType={
                                          element.__typename ===
                                          'RelationshipChange'
                                            ? element.refType
                                            : null
                                        }
                                        property={element.property}
                                        prevValue={element.prevValue}
                                        newValue={element.newValue}
                                      />
                                    )
                                  }
                                />
                              )}
                            </div>
                          );
                        case 'NodeChange':
                          return (
                            <div className="HistoryOperationsContainer">
                              <NodeChangeElement
                                changeType={element.changeType as ChangeType}
                                changeCategory={
                                  element.category as ChangeCategory
                                }
                                refNode={element.refNode}
                                createdAt={element.createdAt}
                              />
                              {useCommentIcon && (
                                <Icon
                                  id="NodeChange"
                                  name="comment outline"
                                  color="blue"
                                  link
                                  onClick={() =>
                                    handleOpenCommentsModal(
                                      <NodeChangeElement
                                        changeType={
                                          element.changeType as ChangeType
                                        }
                                        changeCategory={
                                          element.category as ChangeCategory
                                        }
                                        refNode={element.refNode}
                                        createdAt={element.createdAt}
                                      />
                                    )
                                  }
                                />
                              )}
                            </div>
                          );
                        case 'StorageChange':
                          return (
                            <div className="HistoryOperationsContainer">
                              <StorageChangeElement
                                changeType={element.changeType as ChangeType}
                                fileName={element.fileName as string}
                                fileCategory={element.fileCategory as string}
                              />
                              {useCommentIcon && (
                                <Icon
                                  id="StorageChange"
                                  name="comment outline"
                                  color="blue"
                                  link
                                  onClick={() =>
                                    handleOpenCommentsModal(
                                      <StorageChangeElement
                                        changeType={
                                          element.changeType as ChangeType
                                        }
                                        fileName={element.fileName as string}
                                        fileCategory={
                                          element.fileCategory as string
                                        }
                                      />
                                    )
                                  }
                                />
                              )}
                            </div>
                          );
                        default:
                          return null;
                      }
                    default:
                      return null;
                  }
                })()}
              </Table.Cell>
            );
          }
          return null;
        })}
      </Table.Row>
      <CommentsModalWindow
        openCommentsModal={openCommentsModal}
        setOpenCommentsModal={setOpenCommentsModal}
        elementName={JSXAsElementName}
        refersToId={elementId}
      />
    </>
  );
};

export default ProductHistoryListElement;
