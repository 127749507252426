import React, { useContext } from 'react';
import { List } from 'semantic-ui-react';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { Role, SelectionType } from '../../../../graphql/generated/graphql';
import useOptimisticSelection from './helpers/customHooks';
import { ToDoType } from './helpers/types';
import TodosBadgesIcons from './ToDoBadgesIcons';
import ToDoCheckboxToggle from './ToDoCheckboxToggle';

interface ToDosTaskProps {
  todos: ToDoType[];
  versionId: string | undefined;
  taskId: string;
  taskName: string;
  isInternal: boolean;
  isAlternative: boolean;
  selectDisabled: boolean;
}

/**
 * Renders Task, child of ToDo.
 *
 *
 * @returns {JSX.Element} The Task Component
 * @param {string} versionId The ID of the version needed for the Popups Parts
 */
const ToDoTask = ({
  todos,
  versionId,
  taskId,
  taskName,
  isInternal,
  isAlternative,
  selectDisabled,
}: ToDosTaskProps): JSX.Element => {
  const { selectedRole } = useContext(SelectedRoleContext);
  const role: Role = selectedRole;
  const taskSelectionInfo = todos.find((t) => t.toDo.id === taskId)!; //eslint-disable-line

  const { selectionType, toggleSelection } = useOptimisticSelection(
    taskSelectionInfo.selectionType,
    versionId,
    taskId
  );

  // Find out if another task under the same parent is already selected
  const isAnotherTaskSelected = todos.some(
    (todo) =>
      todo.parentToDoId === taskSelectionInfo?.parentToDoId &&
      todo.selectionType === SelectionType.userselected
  );
  // Disable task if another task under the same parent is selected
  const isDisabled =
    isAnotherTaskSelected &&
    taskSelectionInfo?.selectionType !== SelectionType.userselected;

  const hideInternal = role.includes('supplier') && isInternal;

  return hideInternal ? (
    <></> // eslint-disable-line
  ) : (
    <List.Item>
      <List.Content className={selectDisabled ? 'read_only_task' : ''}>
        <List.Icon
          id={`EditableToDosChildrenChildIcon_${taskId}`}
          className="task_arrow"
          name="level up alternate"
          rotated="clockwise"
          size="large"
          verticalAlign="middle"
        />
        {taskSelectionInfo?.selectionType && (
          <ToDoCheckboxToggle
            selectionType={selectionType}
            onToggle={toggleSelection}
            isDisabled={isDisabled}
          />
        )}
        <p
          id="EditableToDosChildrenToDoDescription"
          className={`task_name ${isDisabled ? 'disabled' : ''}`}
        >
          {taskName}
        </p>
        <TodosBadgesIcons
          toDoId={taskId}
          toDoName={taskName}
          versionId={versionId}
          isAlternative={isAlternative}
          isInternal={isInternal}
          commentCount={taskSelectionInfo.commentCount}
        />
      </List.Content>
    </List.Item>
  );
};
export default ToDoTask;
