import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Segment,
  Item,
  Icon,
  Checkbox,
  Dimmer,
  Divider,
} from 'semantic-ui-react';
import {
  Role,
  UpdateUserMutationVariables,
  User,
  UserStatus,
} from '../../../graphql/generated/graphql';

type UserDataDisplayProps = {
  user: User;
  onlyAdmin: boolean | undefined;
  userDetails: UpdateUserMutationVariables;
  setUpdateDetails: (userDetails: UpdateUserMutationVariables) => void;
};
/**
 *@returns {JSX.Element} Element
 @param {User} user chosen User object
 */
const UserDetailsDataDisplay = ({
  user,
  onlyAdmin,
  userDetails,
  setUpdateDetails,
}: UserDataDisplayProps): JSX.Element => {
  const [t] = useTranslation(['users', 'buttons']);

  // user in users list that has only admin role should not have "brand"
  const userRoleAdmin =
    user.roles?.length === 1 && user.roles[0] === Role.admin;
  const deactivated = userDetails.status === UserStatus.inactive;
  // Checkbox Toggle for deactivating & activating users
  const handleActivationToggle = () => {
    if (userDetails.status === 'active') {
      setUpdateDetails({ ...userDetails, status: UserStatus.inactive });
    } else setUpdateDetails({ ...userDetails, status: UserStatus.active });
  };

  return (
    <>
      <Dimmer.Dimmable dimmed={deactivated}>
        <Dimmer active={deactivated} inverted />
        <Segment id="UserDetailsDataDisplay">
          <Item.Group>
            <Item id="UserDetailsDataDisplayImage">
              <Item.Image>
                <Icon
                  id="UserDetailsDataDisplayImageIcon"
                  name="user outline"
                  circular
                  size="huge"
                />
              </Item.Image>
              <Item.Content className="UserDetailsDataDisplayContent">
                <Item.Header id="UserDetailsDataDisplayName">
                  <span>{user.name}</span>
                  {!userRoleAdmin && (
                    <>
                      <span style={{ marginLeft: '0.7rem' }}>{` | `}</span>
                      &nbsp;
                      <Item.Extra
                        id="UserUserDetailsDataDisplayBrand"
                        content={
                          user.brand === null || user.brand === 'No_brand'
                            ? t('modal.noBrandYet', { ns: 'users' })
                            : t(`brand.${user.brand}`)
                        }
                      />
                    </>
                  )}
                </Item.Header>
                <Item.Meta content={user.email} />
                <Item.Description
                  content={
                    user.roles &&
                    user.roles.map((r, i) => (
                      <Fragment key={r}>
                        <span id="UserDetailsDataDisplayRole">
                          {r.charAt(0).toUpperCase() + r.slice(1)}
                        </span>
                        {i !== user.roles.length - 1 && <span> | </span>}
                      </Fragment>
                    ))
                  }
                />
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      </Dimmer.Dimmable>
      {/* USER STATUS */}
      {onlyAdmin && (
        <>
          <Segment id="UserDetailsDataDisplayToggle">
            <Item id="UserDetailsUserStatus">
              <Item.Description>
                <Checkbox
                  id="UserActivationToggleButton"
                  toggle
                  checked={userDetails.status === 'active'}
                  onChange={handleActivationToggle}
                  label={t(`modal.status.${userDetails.status}`)}
                />
              </Item.Description>
            </Item>
          </Segment>
          <Divider fitted />
        </>
      )}
    </>
  );
};

export default UserDetailsDataDisplay;
