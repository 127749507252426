import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Label, Menu, Message, Segment } from 'semantic-ui-react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_TODOS } from '../../../../graphql/queries/ToDoQueries';
import GenericClosedSegmentSkeleton from '../skeletons/GenericClosedSegmentSkeleton';
import ToDosList from './ToDoList';
import {
  GetToDosQuery,
  GetToDosQueryVariables,
  Role,
} from '../../../../graphql/generated/graphql';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import { ToDoTabs, ToDoType } from './helpers/types';
import { selectedToDos } from './helpers/utils';
import { shouldRefetchQueryVar } from '../../../../hooks/helpers/logics';

/**
 * View for ProductToDos
 *
 *@returns {JSX.Element} The static accordion header-component for To-Dos list in a chosen product.
 */
const ToDosViewHeader = (): JSX.Element => {
  const [t] = useTranslation(['todos']);
  const [open, setOpen] = useState(true);
  const [activeTab, setActiveTab] = useState<ToDoTabs>('general');

  const { versionId, authorizations } = useContext(ProductDetailsContext);
  const { selectedRole } = useContext(SelectedRoleContext);
  const role: Role = selectedRole;

  const hasPermissionToSee = authorizations?.includes('GetToDos');
  const hasPermissionToSelect =
    authorizations?.includes('SelectToDo') &&
    (role?.includes('developer') || role?.includes('supplier'));
  const shouldRefetchTodos = useReactiveVar(shouldRefetchQueryVar);
  const { loading, data, error, refetch } = useQuery<
    GetToDosQuery,
    GetToDosQueryVariables
  >(GET_TODOS, {
    variables: {
      versionId,
    },
    skip: !hasPermissionToSee,
  });

  useEffect(() => {
    if (hasPermissionToSee && shouldRefetchTodos?.includes('Todos')) {
      refetch().finally(() => {
        shouldRefetchQueryVar([]); // reset after fetching
      });
    }
  }, [hasPermissionToSee, shouldRefetchTodos, refetch]);

  if (loading) {
    return (
      <GenericClosedSegmentSkeleton
        height={105}
        image
        lines={1}
        labels="all"
        linesLength="short"
      />
    );
  }

  if (error) {
    return (
      <Message
        id="TodosViewHeaderError"
        error
        icon="times circle"
        header="Error"
        content={`Error ${error.message}`}
      />
    );
  }

  const lcToDos: ToDoType[] =
    hasPermissionToSee && data ? data?.GetToDos.lcToDos : [];
  const parentLcToDos = lcToDos.filter((td) => !td.parentToDoId);
  const selectedLcToDos = selectedToDos(parentLcToDos);
  const allLcToDosSelected = selectedLcToDos.length === parentLcToDos.length;

  const generalToDos: ToDoType[] =
    hasPermissionToSee && data ? data?.GetToDos.generalToDos : [];
  const parentGeneralToDos = generalToDos.filter((td) => !td.parentToDoId);
  const selectedGeneralToDos = selectedToDos(parentGeneralToDos);
  const allGeneralToDosSelected =
    selectedGeneralToDos.length === parentGeneralToDos.length;

  const activeSelectedToDos =
    activeTab === 'general' ? selectedGeneralToDos : selectedLcToDos;
  const activeParentToDos =
    activeTab === 'general' ? parentGeneralToDos : parentLcToDos;
  const activeSelectedLength = activeSelectedToDos.length;
  const activeParentLength = activeParentToDos.length;

  let openedClosedToDosText = '';
  if (activeTab === 'general') {
    if (allGeneralToDosSelected) openedClosedToDosText = t('allDone');
    else
      openedClosedToDosText = `${activeParentLength - activeSelectedLength} ${t(
        `openToDos.${activeParentLength - activeSelectedLength === 1}`
      )}!`;
  } else if (activeTab === 'lc') {
    if (allLcToDosSelected) openedClosedToDosText = t('allLcDone');
    else
      openedClosedToDosText = `${activeParentLength - activeSelectedLength} ${t(
        `openLcToDos.${activeParentLength - activeSelectedLength === 1}`
      )}!`;
  }

  return (
    <Segment raised id="EditableToDosViewHeaderContainerSegment">
      <Menu id="ToDosView.Menu-Tabs" pointing secondary compact>
        <Menu.Item>
          <Icon
            id="EditableToDosViewHeaderIcon"
            onClick={() => setOpen(!open)}
            name={open ? 'chevron up' : 'chevron down'}
            color={open ? 'blue' : 'black'}
          />
        </Menu.Item>
        <Menu.Item
          name="To-Dos"
          active={activeTab === 'general'}
          onClick={() => activeTab !== 'general' && setActiveTab('general')}
          color="blue"
        >
          {t('toDos')}{' '}
          <Label
            style={{ marginLeft: '1em' }}
            id="EditableToDosViewHeaderLabel"
            circular
          >
            {parentGeneralToDos.length}
          </Label>
        </Menu.Item>
        {lcToDos.length > 0 && (
          <Menu.Item
            name="To-Dos"
            active={activeTab === 'lc'}
            onClick={() => activeTab !== 'lc' && setActiveTab('lc')}
            color="blue"
          >
            {t('lcToDos')}{' '}
            <Label
              style={{ marginLeft: '1em' }}
              id="LcToDosViewHeaderLabel"
              circular
            >
              {parentLcToDos.length}
            </Label>
          </Menu.Item>
        )}
      </Menu>
      <div style={{ display: 'inline', marginLeft: '45px' }}>
        {activeParentLength !== activeSelectedLength && (
          <Message
            id="TodosViewHeaderMsgCount"
            info
            compact
            icon="info aosd filled"
            content={openedClosedToDosText}
          />
        )}
        {activeParentLength === activeSelectedLength &&
          activeParentLength !== 0 && (
            <Message
              id="TodosViewHeaderMsgCountClosed"
              success
              compact
              icon="check circle"
              content={openedClosedToDosText}
            />
          )}
      </div>
      {/* TO-DOS listing Component */}
      {open && (
        <>
          {!hasPermissionToSee && (
            <Message
              id="TodosViewHeaderMsgNotAuthorizedGetToDo"
              warning
              icon="exclamation triangle"
              content={t('wrongState')}
            />
          )}
          {!hasPermissionToSelect && hasPermissionToSee && (
            <Message
              id="TodosViewHeaderMsgNotAuthorizedSelectToDo"
              warning
              icon="exclamation triangle"
              content={t('readOnly')}
            />
          )}
          {hasPermissionToSee && (
            <div id="ToDosListContainer" className="todo-list-container">
              {activeTab === 'general' ? (
                <ToDosList
                  todos={generalToDos}
                  parentTodos={parentGeneralToDos}
                />
              ) : (
                <ToDosList todos={lcToDos} parentTodos={parentLcToDos} />
              )}
            </div>
          )}
        </>
      )}
    </Segment>
  );
};

export default ToDosViewHeader;
