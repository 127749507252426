import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Icon, List, Loader, Segment } from 'semantic-ui-react';
import {
  LicenseListQuery,
  LicenseListQueryVariables,
  SortDirection,
} from '../../graphql/generated/graphql';
import { LICENSE_LIST } from '../../graphql/queries/LicenseQuery';
import CustomPagination from '../products/listComponents/pagination/CustomPagination';
import Search from '../products/listComponents/Search';
import { useRefetchLicenseList } from './helpers/customHooks';
import LicenseFilterPills from './LicenseFilterPills';
import LicenseListItem from './LicenseListItem';
import { useKeepCurrentPageInBounds } from '../products/productsList/listComponents/helpers/costumHooks';
import useElementInnerSize from '../../hooks/useElementInnerSize';
import calculateItemsPerPage, {
  aggregationDataVar,
  getTotalAmount,
} from './helpers/logics';
import { useLicenseContext } from '../../context/LicenseContext';

/**
 * LicenseDetail component
 * show details of the selected license
 *
 * @returns {JSX.Element} a new beginning.
 */
const LicenseList = (): JSX.Element => {
  const { t } = useTranslation('licenseList');
  const { setWasSaved, wasSaved } = useLicenseContext();

  const [listRef, size] = useElementInnerSize();
  const ITEM_HEIGHT = 43;

  const aggData = useReactiveVar(aggregationDataVar);

  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [lastCalculatedHeight, setLastCalculatedHeight] = useState(0);
  const [activeFilters, setActiveFilters] = useState<
    { [x: string]: boolean }[]
  >([]);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const { data, loading, error, refetch } = useQuery<
    LicenseListQuery,
    LicenseListQueryVariables
  >(LICENSE_LIST, {
    variables: {
      options: {
        sort: [{ name: SortDirection.ASC }],
        offset: 0,
        limit: itemsPerPage,
      },
      shouldFetchAggregation: true,
    },
    skip: itemsPerPage === 0,
  });

  const licenses = data?.LicenseList?.licenses;

  useEffect(() => {
    if (wasSaved || searchString) {
      refetch({ shouldFetchAggregation: true });
      setWasSaved(false);
    }
  }, [wasSaved, searchString, refetch, setWasSaved]);

  useEffect(() => {
    if (data?.LicenseList) {
      const { totalAmount, verifiedCount, unverifiedCount } = data.LicenseList;
      if (totalAmount != null && totalAmount > 0) {
        aggregationDataVar({
          totalAmount,
          verifiedCount: verifiedCount ?? 0,
          unverifiedCount: unverifiedCount ?? 0,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    const heightChangeThreshold = 45; // px
    const hasSignificantHeightChange =
      Math.abs(size.height - lastCalculatedHeight) > heightChangeThreshold;

    if (size.height > 0 && (itemsPerPage === 0 || hasSignificantHeightChange)) {
      const newItemsPerPage = calculateItemsPerPage(size.height, ITEM_HEIGHT);
      setItemsPerPage(newItemsPerPage);
      setLastCalculatedHeight(size.height);
    }
  }, [size.height, itemsPerPage, lastCalculatedHeight]);

  useEffect(() => {
    setActivePage(1);
  }, [searchString, activeFilters]);

  const itemsLoading = itemsPerPage === 0;
  const combinedLoading = itemsLoading || loading;

  const totalAmount = getTotalAmount(activeFilters, aggData);
  const pages = Math.ceil(totalAmount / itemsPerPage);

  useKeepCurrentPageInBounds(pages, activePage, setActivePage);
  useRefetchLicenseList(
    refetch,
    activeFilters,
    activePage,
    itemsPerPage,
    searchString
  );

  return (
    <Segment
      id="LicenseListContainer"
      className={isCollapsed ? 'collapsed' : ''}
    >
      <Header id="LicenseListHeaderContainer">
        <Header.Content content={t('license-list.header')} />
        <Icon
          id="CollapseIcon"
          name="chevron left"
          link
          onClick={toggleSidebar}
        />
      </Header>

      <Button
        id="LicenseListAddNew"
        primary
        fluid
        content={
          isCollapsed ? (
            <Icon id="LicenseListAddNewIcon" name="plus" fitted />
          ) : (
            <span>{t('license-list.addNew')}</span>
          )
        }
      />
      <div id="LicenseListSearchContainer">
        <Search
          loading={loading}
          error={error}
          id="LicenseListSearch"
          searchString={searchString}
          onSearchChange={(searchInput: string) => {
            setSearchString(searchInput);
          }}
        />
        <Button id="LicenseListFilterButton" icon="filter" basic disabled />
      </div>
      <LicenseFilterPills
        verifiedAmount={aggData.verifiedCount}
        notVerifiedAmount={aggData.unverifiedCount}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
      />
      <div id="LicenseListWrapper" ref={listRef}>
        {combinedLoading ? (
          <Loader active inline="centered" />
        ) : (
          <List id="LicenseList" divided bulleted verticalAlign="middle">
            {licenses &&
              licenses.length > 0 &&
              licenses.map((license) => (
                <LicenseListItem
                  key={license?.id}
                  id={license?.id}
                  verified={license?.verified}
                  name={license?.spdxId || license?.name}
                  url={license?.sourceUrl}
                  loading={loading}
                />
              ))}
            {licenses && licenses.length === 0 && (
              <span>{t('license-list.noLicenses')}</span>
            )}
          </List>
        )}
      </div>

      {data && itemsPerPage !== 0 && licenses?.length !== 0 && (
        <CustomPagination
          loading={loading}
          totalPages={pages}
          activePage={activePage}
          setActivePage={setActivePage}
          compact
        />
      )}

      {error && (
        <div>
          <p id="LicenseListError">{error.message}</p>
          <Button id="LicenseListTryAgain" onClick={() => refetch()}>
            {t('license-list.tryAgain')}
          </Button>
        </div>
      )}
    </Segment>
  );
};

export default LicenseList;
