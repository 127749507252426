/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Import Mutations
 */

/**
 * @param {string} jsonImportFile
 * @param {string} productId
 * @param {string} versionId
 * @param {string} filename
 */
export const JSON_IMPORT = gql`
  mutation JsonImport(
    $jsonImportFile: String!    
    $productId: String!
    $versionId: String!
    $filename: String!
  ) {
    JsonImport(
      jsonImportFile: $jsonImportFile
      productId: $productId
      versionId: $versionId
      filename: $filename
    )
  }
`;

/**
 * @param {string} versionId
 */
export const CANCEL_JSON_IMPORT = gql`
  mutation CancelJsonImport($versionId: ID!) {
    CancelJsonImport(versionId: $versionId)
  }
`;