import React from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import { useDownloadPublicFromArtifactoryMutation } from '../../graphql/generated/graphql';

type PropsType = {
  url: string;
  filename: string;
  elementName: string;
};

/**
 * PublicDownloadElement is a reusable component for downloading from artifactory aosd3 repository
 *
 * @param {PropsType} props - object with the following properties
 * @property {string} url - url of the file to be downloaded
 * @property {string} filename - name of the file to be downloaded
 * @property {string} elementName - name or description of the element that uses the component
 * @returns {JSX.Element} PublicDownloadElement
 */
const PublicDownloadElement = (props: PropsType): JSX.Element => {
  const [t] = useTranslation('imprintAndLegal');
  const { url, filename, elementName } = props;
  const [downloadPublicFromArtifactory] =
    useDownloadPublicFromArtifactoryMutation({
      variables: { url },
      onCompleted: (data) => {
        if (!data.DownloadPublicFromArtifactory) {
          return;
        }

        const extension = filename.slice(filename.lastIndexOf('.') + 1);
        const mimetype = extension === 'pdf' ? 'application/pdf' : 'text/plain';
        const blob = new Blob([data.DownloadPublicFromArtifactory], {
          type: mimetype,
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      },
    });
  return (
    <span>
      <Item
        id="DownloadPublicFromArtifactory"
        as="a"
        className="link item"
        onClick={() => {
          downloadPublicFromArtifactory();
        }}
      >
        {t(elementName)}
      </Item>
    </span>
  );
};
export default PublicDownloadElement;
