import { useContext, useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import useDebounce from '../../../../../hooks/useDebounce';
import {
  SelectionType,
  useSelectToDoMutation,
} from '../../../../../graphql/generated/graphql';
import { ProductDetailsContext } from '../../../../../context/ProductContext';

type UseOptimisticSelectionReturnType = {
  selectionType: SelectionType;
  toggleSelection: () => void;
  loading: boolean;
  error: ApolloError | undefined;
};

/**
 * Custom hook for managing optimistic UI updates in selection toggling.
 *
 * This hook encapsulates the logic for toggling selection states and handling corresponding mutations.
 * It provides an optimistic UI update feature, immediately reflecting the new state in the UI
 * while the mutation request is still in progress.
 *
 * @param {SelectionType} initialSelectionType - The initial selection type of the item (ToDo or Task).
 * @param {string} versionId - The version ID.
 * @param {string} toDoId - The unique identifier of the ToDo or Task.
 * @returns {{
 *   selectionType: SelectionType,
 *   toggleSelection: () => void,
 *   loading: boolean,
 *   error: ApolloError | undefined
 * }} An object containing the current selection type, a function to toggle selection,
 *    and states indicating the loading status and any errors.
 */
const useOptimisticSelection = (
  initialSelectionType: SelectionType,
  versionId: string | undefined,
  toDoId: string
): UseOptimisticSelectionReturnType => {
  const { authorizations } = useContext(ProductDetailsContext);
  const refetchQueries = [
    'GetToDos',
    'CompletionPossible',
    'SupplierToDosPossible',
    'SupplierToDosDonePossible',
  ];
  const authorizedQueries = refetchQueries.filter((query) =>
    authorizations.includes(query)
  );
  const [selectionType, setSelectionType] =
    useState<SelectionType>(initialSelectionType);
  const [SelectToDo, { loading, error }] = useSelectToDoMutation({
    refetchQueries: authorizedQueries,
  });

  useEffect(() => {
    setSelectionType(initialSelectionType);
  }, [initialSelectionType]);

  const selectTodoMutation = () => {
    if (versionId) {
      SelectToDo({
        variables: { versionId, toDoId },
      }).catch(() => {
        setSelectionType(initialSelectionType);
      });
    }
  };

  const debouncedMutation = useDebounce(selectTodoMutation, 400);

  // toggle selection that updates UI immediately
  const toggleSelection = () => {
    const isSelected =
      selectionType === SelectionType.userselected ||
      selectionType === SelectionType.autoselected;
    const newSelectionType = isSelected
      ? SelectionType.userdeselected
      : SelectionType.userselected;

    setSelectionType(newSelectionType);

    debouncedMutation();
  };

  return {
    selectionType,
    toggleSelection,
    loading,
    error,
  };
};

export default useOptimisticSelection;
