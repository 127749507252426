import { useQuery } from '@apollo/client';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Label, Menu, Message, Segment } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../context/ProductContext';
import {
  ALL_DEPENDENCY_COMMENTS,
  ALL_COMMENTS_BY_VERSION,
  ALL_REQUIREMENTS_COMMENTS,
} from '../../../../../graphql/queries/CommentQuerys';
import ErrorAndLoadingMessage from './ErrorAndLoadingMessage';
import Discussions, { DiscussionsReset } from '../Discussions';
import GenericClosedSegmentSkeleton from '../../skeletons/GenericClosedSegmentSkeleton';
/**
 *@returns {JSX.Element} The element returning an overview of all General comments of a version/product- not refering to any other
 */
const GeneralCommentsSegment = (): JSX.Element => {
  const [t, i18n] = useTranslation(['comments']);
  const i18nLang = i18n.language;

  const [open, setOpen] = useState(false);
  const { versionId } = useContext(ProductDetailsContext);

  const ref = useRef<DiscussionsReset>(null);

  const resetAndClose = () => {
    ref?.current?.reset();
    setOpen(!open);
  };

  const { data, loading, error } = useQuery(ALL_COMMENTS_BY_VERSION, {
    variables: {
      versionId,
    },
  });

  if (loading && !open) {
    <GenericClosedSegmentSkeleton
      image
      lines={1}
      labels="all"
      linesLength="long"
    />;
  }

  if (data) {
    const generalComments = data.Comments || [];
    const hasComments = generalComments.length > 0;

    return (
      <Segment id="GeneralCommentsSegment">
        <Menu id="GeneralCommentsSegmentMenu" secondary compact>
          <Menu.Item>
            <Icon
              id="GeneralCommentsSegmentMenuAccordion"
              onClick={resetAndClose}
              name={open ? 'chevron up' : 'chevron down'}
              color={open ? 'blue' : 'black'}
            />
          </Menu.Item>
          <Menu.Item
            content={t('onGenerals')}
            style={{ fontWeight: 'bold', fontSize: '16px' }}
          />
          <Menu.Item>
            <Label id="CommentsNumberLabel" circular style={{ margin: 0 }}>
              {generalComments.length}
            </Label>

            <Icon
              size="large"
              name="question circle"
              id={`GeneralCommentGuideIcon-${i18nLang}`}
              style={{
                marginLeft: '0.5rem',
                display: 'none',
                // TODO: delete display none when guide is ready
              }}
            />
          </Menu.Item>
        </Menu>
        {/* Comments View - no refersToId cause they all refer to the version */}
        {open && (
          <div className="AllCommentsSegmentCommentsOverview">
            {!hasComments && (
              <Message
                id="GeneralCommentsSegmentNoCommentsYet"
                info
                icon="info aosd filled"
                header={t('noGeneralCommentsYet')}
                content={t('noGeneralCommentsCreate')}
              />
            )}
            <Discussions
              ref={ref}
              comments={generalComments}
              refetch={[
                ALL_DEPENDENCY_COMMENTS,
                ALL_REQUIREMENTS_COMMENTS,
                ALL_COMMENTS_BY_VERSION,
              ]}
            />
          </div>
        )}
      </Segment>
    );
  }

  if (error || loading) {
    return (
      <ErrorAndLoadingMessage
        error={error}
        loading={loading}
        source="onGenerals"
      />
    );
  }

  return <div />;
};

export default GeneralCommentsSegment;
