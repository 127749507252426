import { SemanticWIDTHS } from 'semantic-ui-react';
import { Role } from '../../../../../../graphql/generated/graphql';
import {
  Columns,
  Sorting,
} from '../../../../productsList/listComponents/ProductListTypes';

const isPermitted = (role: Role, columnName: string): boolean => {
  if (
    [Role.legal, Role.brandlegal, Role.externlegal, Role.rulesetlegal].includes(
      role
    )
  ) {
    return [
      'priority',
      'resolverStatus',
      'targetDate',
      'distribution',
      'projectID',
      'name',
      'todos',
      'projectStatus',
      'legal',
      'comments',
      'supplierParticipation',
    ].includes(columnName);
  }
  if ([Role.oso, Role.ososervices].includes(role)) {
    return [
      'name',
      'projectStatus',
      'projectID',
      'targetDate',
      'distribution',
      'completeness',
      'resolverStatus',
      'todos',
      'standardSoftware',
      'comments',
      'supplierParticipation',
      'requestor',
    ].includes(columnName);
  }
  if ([Role.developer].includes(role)) {
    return [
      'projectStatus',
      'projectID',
      'name',
      'targetDate',
      'resolverStatus',
      'requestor',
      'comments',
      'supplierParticipation',
    ].includes(columnName);
  }
  if ([Role.supplier].includes(role)) {
    return [
      'projectStatus',
      'projectID',
      'name',
      'targetDate',
      'resolverStatus',
      'requestor',
      'comments',
    ].includes(columnName);
  }
  return false;
};

const columnWidth = (role: Role, columnName: string): SemanticWIDTHS => {
  // vergibt verschiedene Breiten für die Spalten abhängig von der Rolle des Nutzers
  if (
    [Role.legal, Role.brandlegal, Role.externlegal, Role.rulesetlegal].includes(
      role
    )
  ) {
    if (
      ['priority', 'todos', 'comments', 'projectID', 'targetDate'].includes(
        columnName
      )
    )
      return 1;
    if (
      [
        'name',
        'projectStatus',
        'resolverStatus',
        'supplierParticipation',
      ].includes(columnName)
    )
      return 2;
    if (['requestor'].includes(columnName)) return 3;
    return 1;
  }
  if ([Role.oso, Role.ososervices].includes(role)) {
    if (['completeness', 'comments', 'projectID', 'todos'].includes(columnName))
      return 1;
    if (
      [
        'name',
        'projectStatus',
        'resolverStatus',
        'targetDate',
        'requestor',
        'supplierParticipation',
      ].includes(columnName)
    )
      return 2;
    return 1;
  }
  if ([Role.developer].includes(role)) {
    if (['projectID', 'comments'].includes(columnName)) return 1;
    if (
      ['projectStatus', 'resolverStatus', 'targetDate', 'requestor'].includes(
        columnName
      )
    )
      return 2;
    if (['supplierParticipation'].includes(columnName)) return 3;
    if (['name'].includes(columnName)) return 4;
    return 1;
  }
  return 1;
};

/**
 * @param {Role} role of user
 * @returns {Columns} array of columns
 * projectStatus, projectID, name, targetDate,
 * resolverStatus, requestor, comments,
 *
 * new: priority, todos, legal, distribution, completeness
 * TODO: distribution, standardSoftware
 */
const getDefaultColumns = (role: Role): Columns => {
  const allColumns: Columns = [
    {
      name: 'priority',
      shown: true,
      active: false,
      width: columnWidth(role, 'priority'),
      permitted: isPermitted(role, 'priority'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'projectStatus',
      shown: true,
      active: false,
      width: columnWidth(role, 'projectStatus'),
      permitted: isPermitted(role, 'projectStatus'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {
          INCREATION: false,
          INPROGRESS: false,
          SUPPLIERINPUT: false,
          OSOREQUESTED: false,
          BRANDOSO: false,
          LEGALCHECK: false,
          REWORK: false,
          LEGALCHECKDONE: false,
          SUPPLIERTODOS: false,
          SUPPLIERREPORT: false,
          REPORT: false,
          COMPLETED: false,
        },
      },
    },
    {
      name: 'resolverStatus',
      shown: true,
      active: false,
      width: columnWidth(role, 'resolverStatus'),
      permitted: isPermitted(role, 'resolverStatus'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'projectID',
      shown: true,
      active: false,
      width: columnWidth(role, 'projectID'),
      permitted: isPermitted(role, 'projectID'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'todos',
      shown: true,
      active: false,
      width: columnWidth(role, 'todos'),
      permitted: isPermitted(role, 'todos'),
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'name',
      shown: true,
      active: false,
      width: columnWidth(role, 'name'),
      permitted: isPermitted(role, 'name'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'supplierParticipation',
      shown: true,
      active: false,
      width: columnWidth(role, 'supplierParticipation'),
      minWidth: '5em',
      permitted: isPermitted(role, 'supplierParticipation'),
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'targetDate',
      shown: true,
      active: false,
      width: columnWidth(role, 'targetDate'),
      permitted: isPermitted(role, 'targetDate'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    // {
    //   name: 'completeness',
    //   shown: true,
    //   active: false,
    //   width: columnWidth(role, 'completeness'),
    //   permitted: isPermitted(role, 'completeness'),
    //   sorterInfo: {
    //     sortable: false,
    //     sorting: Sorting.UNSORTED,
    //   },
    //   filterInfo: {
    //     filterable: true,
    //     filtering: {
    //       '1': false,
    //       '2': false,
    //       '3': false,
    //       '4': false,
    //       '5': false,
    //       '6': false,
    //       '7': false,
    //       '8': false,
    //       '9': false,
    //     },
    //   },
    // },
    // {
    //   name: 'distribution',
    //   shown: true,
    //   active: false,
    //   width: columnWidth(role, 'distribution'),
    //   permitted: isPermitted(role, 'distribution'),
    //   sorterInfo: {
    //     sortable: false,
    //     sorting: Sorting.UNSORTED,
    //   },
    //   filterInfo: {
    //     filterable: false,
    //     filtering: {},
    //   },
    // },
    // {
    //   name: 'standardSoftware',
    //   shown: true,
    //   active: false,
    //   width: columnWidth(role, 'standardSoftware'),
    //   permitted: isPermitted(role, 'standardSoftware'),
    //   sorterInfo: {
    //     sortable: false,
    //     sorting: Sorting.UNSORTED,
    //   },
    //   filterInfo: {
    //     filterable: false,
    //     filtering: {},
    //   },
    // },
    {
      name: 'legal',
      shown: true,
      active: false,
      width: columnWidth(role, 'legal'),
      permitted: isPermitted(role, 'legal'),
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: true,
        filtering: {},
      },
    },
    {
      name: 'comments',
      shown: true,
      active: false,
      width: columnWidth(role, 'comments'),
      permitted: isPermitted(role, 'comments'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'requestor',
      shown: true,
      active: false,
      width: columnWidth(role, 'requestor'),
      permitted: isPermitted(role, 'requestor'),
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: true,
        filtering: {},
      },
    },
  ];
  return allColumns;
};

export default getDefaultColumns;
