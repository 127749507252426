import { TFunction } from 'i18next';

interface SelectFileCategoryOptions {
  key: string | number;
  text: string;
  value: string | number;
  accept: string;
  disabled?: boolean;
}

interface FileTypeAuthorizations {
  canUploadLC: boolean;
  disableWhenProjectCompleted: boolean;
  canUploadSignedReport: boolean;
}

/**
 *
 * @param {TFunction} text - translation function
 * @param {FileTypeAuthorizations} authorizations - can upload LC
 * @returns {SelectFileCategoryOptions[]} option elements for the category dropdown
 */
export const categoryOptions = (
  text: TFunction,
  authorizations: FileTypeAuthorizations
): SelectFileCategoryOptions[] => {
  // based of the element ARTIFACTTYPE in src\backend\artifactory\static\ArtifactoryConstants.ts
  return [
    {
      key: 'sourcecode',
      text: `${text('categoryOptions.sourcecode')}
       + ${text('categorySuffixes.sourcecode')}`,
      value: 'sourcecode',
      accept: '.zip,.rar,.7zip,.tar,.gz,.xz',
      disabled:
        authorizations.disableWhenProjectCompleted ||
        authorizations.canUploadSignedReport,
    },
    {
      key: 'report',
      text: `${text('categoryOptions.report')}
          ${text('categorySuffixes.report')}`,
      value: 'report',
      accept: '.pdf',
      disabled: authorizations.canUploadSignedReport,
    },
    {
      key: 'report_signed',
      text: `${text('categoryOptions.report_signed')}
          ${text('categorySuffixes.report')}`,
      value: 'report_signed',
      accept: '.pdf',
      disabled:
        !authorizations.canUploadSignedReport ||
        authorizations.disableWhenProjectCompleted,
    },
    // disclaimer is an outdated naming for L&C document
    {
      key: 'lc_document',
      text: `${text('categoryOptions.disclaimer_txt')}
          ${text('categorySuffixes.disclaimer_txt')}`,
      value: 'disclaimer_txt',
      accept: '.txt',
      disabled:
        !authorizations.canUploadLC ||
        authorizations.disableWhenProjectCompleted ||
        authorizations.canUploadSignedReport,
    },
    {
      key: 'scan',
      text: `${text('categoryOptions.scan')} 
          ${text('categorySuffixes.scan')}`,
      value: 'scan',
      accept: '.zip,.rar,.7zip,.tar,.gz,.xz',
      disabled:
        authorizations.disableWhenProjectCompleted ||
        authorizations.canUploadSignedReport,
    },
    {
      key: 'additionalFiles',
      text: `${text('categoryOptions.additionalFiles')}
          ${text('categorySuffixes.additionalFiles')}`,
      value: 'additionalFiles',
      accept: '.txt,.json,.pdf,.zip,.rar,.7zip,.tar,.gz,.xz',
      disabled:
        authorizations.disableWhenProjectCompleted ||
        authorizations.canUploadSignedReport,
    },
    {
      key: 'fossDeclaration',
      text: `${text('categoryOptions.fossDeclaration')}
          ${text('categorySuffixes.fossDeclaration')}`,
      value: 'fossDeclaration',
      accept: '.pdf',
      disabled:
        authorizations.disableWhenProjectCompleted ||
        authorizations.canUploadSignedReport,
    },
  ];
};

/**
 * List of accepted file types for the storage upload
 */
export const acceptedFileTypes = '.txt,.pdf,.zip,.rar,.7zip,.tar,.gz,.xz';
