import { useMutation } from '@apollo/client';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Icon,
  Modal,
} from 'semantic-ui-react';
import { UPDATE_TAGS } from '../../../../graphql/mutations/ProductMutations';
import { PRODUCT_DETAILS_CONTEXT } from '../../../../graphql/queries/VersionQuerys';
import WizardFormFieldLabel from '../../../wizard/WizardFormFieldLabel';

type ProductTagsModalProps = {
  openTagsModal: boolean;
  setOpenTagsModal: (openCommentsModal: boolean) => void;
  tags: string[] | null | undefined;
  versionId: string;
};

/**
 * Modal window for editing product tags.
 *
 *@returns {JSX.Element} Authorized user can remove/add tags
 *@param {ProductTagsModalProps} props Props
 */
const ProductTagsModal = (props: ProductTagsModalProps): JSX.Element => {
  const [t] = useTranslation(['productDetails', 'buttons']);
  const { openTagsModal, setOpenTagsModal, tags, versionId } = props;

  const [newTags, setNewTags] = useState<string[]>([]);
  const [tagOptions, setTagOptions] = useState<DropdownItemProps[]>([
    { key: '', value: '', text: '' },
  ]);

  const [updateTags] = useMutation(UPDATE_TAGS, {
    onCompleted: () => {
      setOpenTagsModal(false);
    },
    refetchQueries: [PRODUCT_DETAILS_CONTEXT],
  });

  const toTagOptions = () => {
    const existingTags = tags?.map((tag) => {
      return {
        key: tag,
        text: tag,
        value: tag,
      };
    });
    if (existingTags) {
      setTagOptions(existingTags);
    }
  };

  useEffect(() => {
    if (tags) {
      toTagOptions();
      setNewTags(tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddition = (
    e: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    setTagOptions((prevState) => [
      { key: value, text: value, value } as DropdownItemProps,
      ...prevState,
    ]);
  };

  const handleChange = (
    e: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => setNewTags(value as string[]);

  const closeAndReset = () => {
    toTagOptions();
    if (tags) setNewTags(tags);
    setOpenTagsModal(false);
  };

  return (
    <Modal
      id="ProductDetailsTagsModal"
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      open={openTagsModal}
    >
      <Modal.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: 'none',
        }}
      >
        <span>{`${t('editTagsModal')} `}</span>
        <Icon
          id="ProductDetailsTagsModalCloseButton"
          name="close"
          link
          onClick={closeAndReset}
        />
      </Modal.Header>

      <Modal.Content>
        <WizardFormFieldLabel
          nameText={t('tags')}
          infoText={t('tags-info')}
          htmlFor="versionName"
        />
        <Dropdown
          id="UpdateTagsModalDropdown"
          options={tagOptions}
          placeholder={t('tags-placeholder')}
          additionLabel={t('tags-add')}
          search
          selection
          fluid
          multiple
          allowAdditions
          value={newTags}
          onAddItem={handleAddition}
          onChange={handleChange}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button
          id="ApplyTagUpdatesButton"
          primary
          content={t('apply', { ns: 'buttons' })}
          onClick={() =>
            updateTags({
              variables: {
                versionId,
                tags: newTags,
              },
            })
          }
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ProductTagsModal;
