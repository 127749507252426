import React, { useContext } from 'react';
import { Segment, Menu, Divider } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { SelectedRoleContext, UserContext } from '../../context/UserContext';
import { Role } from '../../graphql/generated/graphql';

/**
 * UserRolesMenu
 * provides the content to the UserRolesPopup.
 * Shows all the roles available for the current user and enables user to change it upon click.
 *
 * @returns {JSX.Element} UserRolesMenu
 */
const UserRolesMenu = (): JSX.Element => {
  const user = useContext(UserContext);
  const { selectedRole, setSelectedRole } = useContext(SelectedRoleContext);
  const [t] = useTranslation('users');
  const { roles = [] } = user;

  const onClickRole = (role: Role) => {
    localStorage.setItem('selectedRole', role);
    setSelectedRole(role);
  };

  return (
    <Segment.Group
      id="UserRolesMenuSegmentGroup"
      style={{ width: 150, padding: 0 }}
    >
      <Menu
        id="UserRolesMenu"
        secondary
        vertical
        inverted
        fluid
        style={{ margin: 0 }}
      >
        {roles.map((r: Role) => (
          <div key={r}>
            <Menu.Item
              name={r}
              active={selectedRole === r}
              icon="check"
              content={t(`role.${r}`)}
              onClick={(_e, { name }) => onClickRole(name as Role)}
            />
            <Divider fitted />
          </div>
        ))}
      </Menu>
      <Segment id="UserRolesMenuSegment2" inverted>
        {t('change-role')}
      </Segment>
    </Segment.Group>
  );
};

export default UserRolesMenu;
