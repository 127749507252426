/**
 * Sanitize the text area change event input
 *
 * @param {string} input - the maximum character limit for the text area input
 * @param {number} maxEmptyLines - limit the amount of empty lines
 * @returns {string} sanitized text
 */
const sanitizeInput = (input: string, maxEmptyLines = 2): string => {
  // Replace tabs with 4 spaces
  let sanitizedInput = input.replace(/\t/g, '    ');
  // Normalize Windows-style (\r\n) line breaks to Unix-style (\n)
  sanitizedInput = sanitizedInput.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
  // limit empty lines
  const regex = new RegExp(`(\\n\\s*){${maxEmptyLines + 1},}`, 'g');
  return sanitizedInput.replace(regex, '\n'.repeat(maxEmptyLines));
};
export default sanitizeInput;
