import { useMutation, useQuery } from '@apollo/client';
import React, { lazy, Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Loader,
  Menu,
  Placeholder,
  Segment,
  Tab,
} from 'semantic-ui-react';
import {
  AdditionalLicenseFeature,
  ConflictRule,
  LicenseByIdQuery,
  LicenseByIdQueryVariables,
  LicenseConflictRule,
  LicenseFeature,
  LicenseFeaturesByIdQuery,
  LicenseFeaturesByIdQueryVariables,
  ToDo,
  UpdateLicenseMutation,
} from '../../graphql/generated/graphql';
import {
  GET_LICENSE_BY_ID,
  LICENSE_FEATURES_BY_ID,
} from '../../graphql/queries/LicenseQuery';
import useUserDetails from './helpers/customHooks';
import { formatDate } from './helpers/logics';
import AutoExpandingTextarea from './AutoExpandingTextarea';
import { useLicenseContext } from '../../context/LicenseContext';
import { UPDATE_LICENSE } from '../../graphql/mutations/LicenseMutations';
import useTextAreaChange from '../../hooks/useTextAreaChange';

const LicenseFeatures = lazy(() => import('./LicenseFeatures'));
/**
 * LicenseDetail component
 * show details of the selected license
 *
 * @returns {JSX.Element} a new beginning.
 */
const LicenseDetail = (): JSX.Element => {
  const { t, i18n } = useTranslation('licenseList');
  const { licenseId } = useParams();
  // TODO: perhaps license properties can be managed in local state rather than context
  const {
    setIsEditMode,
    isEditMode,
    todos,
    licenseConflictRules,
    licenseFeatures,
    conflictRules,
    additionalLicenseFeatures,
    setWasSaved,
    verified,
    setVerified,
    description,
    setDescription,
    setAllFeatures,
  } = useLicenseContext();
  const { maxCharLimit, charCount, handleTextAreaChange } = useTextAreaChange(
    setDescription,
    description
  );

  const licenseVariables = licenseId ? { variables: { licenseId } } : {};

  const { data, loading, error } = useQuery<
    LicenseByIdQuery,
    LicenseByIdQueryVariables
  >(GET_LICENSE_BY_ID, {
    ...licenseVariables,
    skip: !licenseId,
  });

  const {
    data: featuresData,
    loading: loadingFeatures,
    error: featuresErr,
  } = useQuery<LicenseFeaturesByIdQuery, LicenseFeaturesByIdQueryVariables>(
    LICENSE_FEATURES_BY_ID,
    {
      ...licenseVariables,
      skip: !licenseId,
    }
  );

  const [
    updateLicenseDetails,
    { loading: saveChangesLoading, error: saveChangesError },
  ] = useMutation<UpdateLicenseMutation>(UPDATE_LICENSE, {
    refetchQueries: [
      { query: LICENSE_FEATURES_BY_ID, ...licenseVariables },
      { query: GET_LICENSE_BY_ID, ...licenseVariables },
    ],
    onCompleted: (mutationData) => {
      if (mutationData?.UpdateLicense) {
        setWasSaved(true);
      }
    },
  });

  const handleSaveClick = () => {
    if (isEditMode && licenseId) {
      const todoIds = todos.map((todo) => todo.id);
      const taskIds = todos.flatMap((todo) =>
        todo.tasks.map((task) => task.id)
      );
      const allFeaturesSet = new Set<string>([
        ...todoIds,
        ...taskIds,
        ...conflictRules.map((r) => r.id),
        ...licenseFeatures.map((f) => f.id),
        ...licenseConflictRules.map((r) => r.id),
        ...additionalLicenseFeatures.map((f) => f.id),
      ]);
      const allFeatures = Array.from(allFeaturesSet);
      updateLicenseDetails({
        variables: {
          licenseInput: {
            licenseId,
            verified,
            description,
            features: allFeatures,
          },
        },
      });
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
      setWasSaved(false);
    }
  };

  const license = data?.License?.[0] ?? null;

  const {
    user,
    loading: loadingUser,
    error: errorUser,
  } = useUserDetails(license?.lastModifiedById);

  const licenseFeatureData = featuresData?.License && featuresData?.License[0];
  const allFeatures = useMemo(
    () => [
      ...((licenseFeatureData?.licenseFeatures as LicenseFeature[]) || []),
      ...((licenseFeatureData?.licenseConflictRules as LicenseConflictRule[]) ||
        []),
      ...((licenseFeatureData?.conflictRules as ConflictRule[]) || []),
      ...((licenseFeatureData?.toDos as ToDo[]) || []),
      ...((licenseFeatureData?.additionalLicenseFeatures as AdditionalLicenseFeature[]) ||
        []),
    ],
    [licenseFeatureData]
  );

  // init context state
  useEffect(() => {
    if (isEditMode) {
      setAllFeatures(allFeatures);
      setDescription(license?.description ?? '');
      setVerified(license?.verified ?? false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFeatures, isEditMode]);

  if (loading) {
    return <Loader active />;
  }

  if (error || saveChangesError) {
    return <span>{error?.message || saveChangesError?.message}</span>;
  }

  const panes = [
    {
      menuItem: t('license-detail.licenseText'),
      render: () => (
        <Tab.Pane attached={false}>
          <Header as="h5">{t('license-detail.permissionNote')}</Header>
          <Segment id="LicenseDetailPermissionNote">
            <span>
              {license?.permissionNote || t('license-detail.noPermissionNote')}
            </span>
          </Segment>
          <Header as="h5">{t('license-detail.licenseText')}</Header>
          <Form id="LicenseDetailText">
            <AutoExpandingTextarea content={license?.text as string} />
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="Features">
          {t('license-detail.licenseFeatures')}
          <Label
            id="LicenseFeaturesCountLabel"
            circular
            empty={!allFeatures?.length || loadingFeatures} // if empty then its not displayed
            content={allFeatures?.length}
          />
        </Menu.Item>
      ),
      render: () => (
        <Suspense
          fallback={
            <Placeholder>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder>
          }
        >
          <Tab.Pane attached={false}>
            <LicenseFeatures
              licenceFeaturesObject={featuresData}
              featuresErr={featuresErr}
            />
          </Tab.Pane>
        </Suspense>
      ),
    },
    {
      menuItem: t('license-detail.licenseHistory'),
      render: () => (
        <Tab.Pane attached={false}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Segment id="LicenseDetail">
      <Header id="LicenseDetailHeader">
        <Header.Content>
          <Icon
            id="HeaderVerificationStatusIcon"
            name="circle"
            className={license?.verified ? 'verified' : 'unverified'}
          />

          {license?.name}
          <wbr />
          {license?.spdxId && ` | ${license?.spdxId}`}

          {isEditMode && (
            <Button
              id="CancelEditLicenseButton"
              icon="x"
              labelPosition="left"
              content={t('license-detail.cancel')}
              onClick={() => setIsEditMode(false)}
            />
          )}
          <Button
            id="EditLicenseButton"
            icon={isEditMode ? 'save' : 'pencil'}
            content={
              isEditMode ? t('license-detail.save') : t('license-detail.edit')
            }
            primary
            labelPosition="left"
            loading={saveChangesLoading}
            onClick={handleSaveClick}
          />
        </Header.Content>
      </Header>
      <Segment id="TopTogglePanel">
        <Checkbox
          id="VerificationToggle"
          toggle
          checked={isEditMode ? verified : (license?.verified as boolean)}
          disabled={!isEditMode}
          label={
            verified
              ? t('license-detail.verified')
              : t('license-detail.notVerified')
          }
          onChange={() => setVerified(!verified)}
        />
        <Checkbox
          id="DeprecatedToggle"
          toggle
          checked={license?.deprecated as boolean}
          disabled
          label={
            license?.deprecated
              ? t('license-detail.deprecated')
              : t('license-detail.notDeprecated')
          }
        />
        <Checkbox
          id="ExceptionToggle"
          toggle
          checked={license?.exceptionLicense as boolean}
          disabled
          label={
            license?.exceptionLicense
              ? t('license-detail.exception')
              : t('license-detail.notException')
          }
        />
        <span>
          {loadingUser && <Loader size="tiny" />}
          {errorUser && <span>{errorUser.message} </span>}
          {user?.name && <strong>{`${user?.name} | `}</strong>}
          {`${formatDate(license?.lastModifiedAt, i18n.language)} ${t(
            'license-detail.hours'
          )}`}
        </span>
      </Segment>
      <Header as="h5">{t('license-detail.sourceUrl')}</Header>
      <Segment id="LicenseDetailSpdxSegment">
        {license?.sourceUrl ? (
          <a
            id="LicenseDetailSpdxLink"
            target="_blank"
            rel="noopener noreferrer"
            href={license.sourceUrl}
          >
            <span>{license.sourceUrl}</span>
          </a>
        ) : (
          t('license-detail.noUrl')
        )}
      </Segment>
      <Grid>
        <Grid.Row columns={license?.note ? 2 : 1}>
          <Grid.Column>
            <Header as="h5">{t('license-detail.description')}</Header>
            {isEditMode ? (
              <Form id="DescriptionLicenseDetail">
                <Form.TextArea
                  id="DescriptionLicenseDetailTextarea"
                  value={description ?? ''}
                  onChange={handleTextAreaChange}
                />
                <span>
                  {charCount} / {maxCharLimit}
                </span>
              </Form>
            ) : (
              <Segment id="LicenseDetailDescription">
                <span>
                  {license?.description || t('license-detail.noDescription')}
                </span>
              </Segment>
            )}
          </Grid.Column>
          {license?.note && (
            <Grid.Column>
              <Header as="h5">{t('license-detail.note')}</Header>
              <Segment id="LicenseDetailNote">
                <span>{license?.note}</span>
              </Segment>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>

      <Tab
        id="LicenseDetailTabs"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </Segment>
  );
};

export default LicenseDetail;
