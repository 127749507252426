import { useApolloClient } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import TermsOfUseModal from '../components/imprintAndLegal/TermsOfUseModal';
import LoginButton from '../components/user/LoginButton';

type LoginProps = {
  systemOffline: boolean;
};

/**
 * Login
 * Page with Form Input for User email and password
 *
 * @param {LoginProps} props LoginProperties
 * @returns {JSX.Element} Login Page
 */
const Login = ({ systemOffline }: LoginProps): JSX.Element => {
  const [t] = useTranslation('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const client = useApolloClient();
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (systemOffline) {
      intervalIdRef.current = setInterval(() => {
        client.refetchQueries({
          include: ['CurrentUser'],
        });
      }, 5000);
    } else if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    // Cleanup interval on component unmount
    return () => {
      if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    };
  }, [systemOffline, client]);

  return (
    <Grid id="LoginPage" textAlign="center" verticalAlign="middle">
      <Grid.Column>
        <Header as="h2" color="blue" textAlign="center">
          {t('header')}
        </Header>
        <Form id="LoginForm" size="large" disabled={systemOffline}>
          <Segment id="LoginForm.Segment">
            <Form.Input
              id="LoginForm.Segment.EmailInput"
              fluid
              icon="user"
              iconPosition="left"
              placeholder={t('email')}
              value={email}
              disabled={systemOffline}
              data-cy="LoginForm.Segment.EmailInput"
              onChange={(evt, { value }) => {
                setEmail(value);
              }}
            />
            <Form.Input
              id="LoginForm.Segment.PasswordInput"
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={t('password')}
              type="password"
              value={password}
              disabled={systemOffline}
              data-cy="LoginForm.Segment.PasswordInput"
              onChange={(evt, { value }) => setPassword(value)}
            />
            <TermsOfUseModal
              email={email}
              password={password}
              openTermsModal={openTermsModal}
              setOpenTermsModal={setOpenTermsModal}
            />
            <LoginButton
              email={email}
              password={password}
              systemOffline={systemOffline}
              setOpenTermsModal={setOpenTermsModal}
            />
          </Segment>
        </Form>
        {systemOffline ? (
          <Message
            visible
            error
            icon="times circle"
            id="LoginButtonErrorMessageSystemOffline"
            content={
              <>
                {t('systemOffline')}
                <a
                  id="SystemOfflineError.ServiceDeskLink"
                  href="https://devstack.vwgroup.com/jira/servicedesk/customer/portal/174"
                >
                  {t('serviceDesk')}
                </a>
              </>
            }
          />
        ) : (
          // TODO Refactoring - Link ist kein Button
          <Message
            id="LoginPageNoAccountInfo"
            info
            icon="info aosd filled"
            content={
              <>
                {t('noAccount')}
                <Button id="SignUpButton" as={Link} to="/signup">
                  {t('signUp')}
                </Button>
              </>
            }
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export default Login;
