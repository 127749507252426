/**
 * Product mutations regarding User DirectoryRole and Status/State to be rendered regardless of state
 */
export const applicableProductMutations = {
  manager: [
    {
      status: 'INCREATION',
      mutations: [
        // setting
        'JsonImport',
        'RequestSupplierInput',
        'SubmitToOSO',
      ],
    },
    {
      status: 'INPROGRESS',
      mutations: [
        // setting
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'SUPPLIERINPUT',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'OSOREQUESTED',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'LEGALCHECK',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'REWORK',
      mutations: [
        // setting
        'RequestSupplierInput',
        'SubmitToLegal',
        // persist but deactivated
        'SubmitToOSO',
      ],
    },
    {
      status: 'LEGALCHECKDONE',
      mutations: [
        // setting
        'RequestSupplierToDos',
        'FinishProductCheck',
      ],
    },
    {
      status: 'SUPPLIERTODOS',
      mutations: [
        // setting
        'FinishProductCheck',
        'RequestSupplierToDos',
      ],
    },
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
    {
      status: 'SUPPLIERREPORT',
      mutations: [
        // setting
        'RequestSupplierReport',
        'FinishReport',
        'DownloadReport',
      ],
    },
    {
      status: 'REPORT',
      mutations: [
        // setting
        'RequestSupplierReport',
        'FinishReport',
        'DownloadReport',
      ],
    },
  ],
  supplier: [
    {
      status: 'INCREATION',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'INPROGRESS',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'SUPPLIERINPUT',
      mutations: [
        // setting
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'OSOREQUESTED',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'LEGALCHECK',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'REWORK',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'LEGALCHECKDONE',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierToDosDone',
      ],
    },
    {
      status: 'SUPPLIERTODOS',
      mutations: [
        // persist but deactivated
        // setting
        'SupplierToDosDone',
      ],
    },
    {
      status: 'REPORT',
      mutations: ['FinishSupplierReport', 'DownloadReport'],
    },
    {
      status: 'SUPPLIERREPORT',
      mutations: ['FinishSupplierReport', 'DownloadReport'],
    },
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  developer: [
    {
      status: 'INCREATION',
      mutations: [
        // setting
        'JsonImport',
        'RequestSupplierInput',
        'SubmitToOSO',
      ],
    },
    {
      status: 'INPROGRESS',
      mutations: [
        // setting
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'SUPPLIERINPUT',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'OSOREQUESTED',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'LEGALCHECK',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'REWORK',
      mutations: [
        // setting
        'RequestSupplierInput',
        'SubmitToLegal',
        // persist but deactivated
        'SubmitToOSO',
      ],
    },
    {
      status: 'LEGALCHECKDONE',
      mutations: [
        // setting
        'RequestSupplierToDos',
        'FinishProductCheck',
        // persist but deactivated
        'SubmitToOSO',
      ],
    },
    {
      status: 'SUPPLIERTODOS',
      mutations: [
        // persist but deactivated
        'SubmitToOSO',
        'RequestSupplierToDos',
        'FinishProductCheck',
      ],
    },
    {
      status: 'REPORT',
      mutations: [
        // setting
        'RequestSupplierReport',
        'FinishReport',
      ],
    },
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  legal: [
    {
      status: 'INCREATION',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'INPROGRESS',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'SUPPLIERINPUT',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'OSOREQUESTED',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToUser',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'LEGALCHECK',
      mutations: [
        // setting
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'REWORK',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'LEGALCHECKDONE',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
    {
      status: 'SUPPLIERTODOS',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
    {
      status: 'SUPPLIERREPORT',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
    {
      status: 'REPORT',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  oso: [
    {
      status: 'INCREATION',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        // 'SupplierInputDone',
        'RequestSupplierInput',
      ],
    },
    {
      status: 'INPROGRESS',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        // 'SupplierInputDone',
        'RequestSupplierInput',
      ],
    },
    {
      status: 'SUPPLIERINPUT',
      mutations: [
        // setting
        'SupplierInputDone',
        // persist but deactivated
        'RequestSupplierInput',
      ],
    },
    {
      status: 'OSOREQUESTED',
      mutations: [
        // setting
        'SubmitToUser',
        'RequestSupplierInput',
      ],
    },
    {
      status: 'LEGALCHECK',
      mutations: [
        // persist but deactivated
        'SupplierInputDone',
        'RequestSupplierInput',
        'SubmitToOSO',
      ],
    },
    {
      status: 'REWORK',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
      ],
    },
    {
      status: 'LEGALCHECKDONE',
      mutations: [
        // setting
        // persist but deactivated
        'DownloadLcDocument',
        'DownloadReport',
      ],
    },
    {
      status: 'SUPPLIERTODOS',
      mutations: ['SupplierToDosDone', 'DownloadLcDocument', 'DownloadReport'],
    },
    {
      status: 'SUPPLIERREPORT',
      mutations: [
        'FinishSupplierReport',
        'DownloadLcDocument',
        'DownloadReport',
      ],
    },
    {
      status: 'REPORT',
      mutations: [
        // setting
        'RequestSupplierReport',
        'FinishReport',
        'DownloadLcDocument',
        'DownloadReport',
      ],
    },
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  guest: [],
};

/**
 * Project mutations regarding DirectoryRole and Status/State to be rendered regardless of state
 */
export const applicableProjectMutations = {
  manager: [
    {
      status: 'INCREATION',
      mutations: ['SubmitToOSO', 'FinishProjectCheck'],
    },
    {
      status: 'OSOREQUESTED',
      mutations: ['SubmitToOSO', 'FinishProjectCheck'],
    },
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  supplier: [
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  developer: [
    {
      status: 'INCREATION',
      mutations: ['SubmitToOSO', 'FinishProjectCheck'],
    },
    {
      status: 'OSOREQUESTED',
      mutations: ['SubmitToOSO', 'FinishProjectCheck'],
    },
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  legal: [
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  oso: [
    {
      status: 'INCREATION',
      mutations: ['SubmitToUser', 'FinishProjectCheck'],
    },
    {
      status: 'OSOREQUESTED',
      mutations: ['SubmitToUser', 'FinishProjectCheck'],
    },
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  guest: [],
};

/**
 * Group transfer mutations regarding DirectoryRole and Status/State to be rendered
 */
export const applicableGroupTransferMutations = {
  oso: [
    {
      status: 'OSOREQUESTED',
      mutations: ['SubmitToBrandOSO', 'GetEmailsByBrand'],
    },
    {
      status: 'BRANDOSO',
      mutations: ['SubmitToLegal'],
    },
    {
      status: 'LEGALCHECK',
      mutations: ['SubmitToLegal'],
    },
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  legal: [
    {
      status: 'OSOREQUESTED',
      mutations: ['SubmitToBrandOSO', 'FinishProductCheck'],
    },
    {
      status: 'BRANDOSO',
      mutations: ['SubmitToBrandOSO', 'FinishProductCheck'],
    },
    {
      status: 'LEGALCHECK',
      mutations: ['SubmitToBrandOSO', 'FinishProductCheck'],
    },
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  manager: [
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  supplier: [
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  developer: [
    {
      status: 'COMPLETED',
      mutations: ['DownloadLcDocument', 'DownloadReport'],
    },
  ],
  guest: [],
};
