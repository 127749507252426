import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  Maybe,
  PossibleInfo,
  Role,
  useCheckResolverStatusLazyQuery,
} from '../../../../graphql/generated/graphql';
import {
  FINISH_LEGAL_CHECK_POSSIBLE,
  COMPLETION_POSSIBLE,
  PROJECT_COMPLETION_POSSIBLE,
  FINISH_SUPPLIER_REPORT_POSSIBLE,
  LEGAL_CHECK_POSSIBLE,
  PRODUCT_DETAILS_CONTEXT,
  SUPPLIER_INPUT_DONE_POSSIBLE,
  SUPPLIER_INPUT_POSSIBLE,
  SUPPLIER_TODOS_DONE_POSSIBLE,
  SUPPLIER_TODOS_POSSIBLE,
} from '../../../../graphql/queries/VersionQuerys';
import {
  ProcessStatus,
  ProcessStatusType,
  ResolverStatus,
} from '../dependencySegment/dependencyList/helper/types';
import { getPopupInfo, osoAnsweredVar, StateMapping } from './helper/logics';
import { UserContext } from '../../../../context/UserContext';
import SupplierModal from './SupplierModal';

type Props = {
  id: string;
  disabled: boolean;
  state: StateMapping;
  reason: Maybe<PossibleInfo> | undefined;
};

/**
 * Main Segment of the Product Details for Guest. Displays the selection of the Menu in the Header.
 *
 * @param {object} props id of the product.
 * @returns {JSX.Element} GuestProductDetailsSegment.
 */
const StateChangeButton = (props: Props): JSX.Element => {
  const { id, state, disabled, reason } = props;
  const { versionId, processStatusAsString } = useContext(
    ProductDetailsContext
  );
  const { roles } = useContext(UserContext);
  const { t } = useTranslation('productDetails');
  const client = useApolloClient();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const shouldOpenSupplierModal =
    roles.includes(Role.supplier) &&
    ['SubmitToLegal', 'SupplierInputDone'].includes(state.mutationName) &&
    processStatusAsString === ProcessStatus[ProcessStatusType.SUPPLIERINPUT];

  const OSOIsActive =
    state.authorized === true && state.mutationName === 'SubmitToOSO';
  const isButtonDisabled = disabled && !OSOIsActive;

  const { popupId, popupContent } = getPopupInfo(
    state.mutationName,
    state.authorized ? reason : PossibleInfo.DEACTIVATED
  );
  const content = (
    <div>
      {state.authorized ? (
        <>
          {t('stateChangeNotPossibleReason.default')}
          <br />
        </>
      ) : null}
      {t(popupContent)}
    </div>
  );

  const isFinishLegalCheck = () => {
    return state.mutationName === 'FinishLegalCheck';
  };
  const finishCheckLoading =
    state.mutationName === 'FinishLegalCheck' ||
    state.mutationName === 'CompletionPossible' ||
    state.mutationName === 'ProjectCompletionPossible';
  const [checkResolverStatus] = useCheckResolverStatusLazyQuery({
    variables: {
      versionId,
    },
    onCompleted: async (result) => {
      if (result.CheckResolverStatus?.status === ResolverStatus.RUNNING) {
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
        checkResolverStatus({
          variables: {
            versionId,
          },
          fetchPolicy: 'network-only',
        });
      } else {
        client.refetchQueries({
          include: [PRODUCT_DETAILS_CONTEXT, FINISH_LEGAL_CHECK_POSSIBLE],
        });
      }
    },
    fetchPolicy: 'network-only',
  });

  const [StateChangeMutation, { data, loading }] = useMutation(state.mutation, {
    variables: { versionId },
    refetchQueries: [
      PRODUCT_DETAILS_CONTEXT,
      FINISH_LEGAL_CHECK_POSSIBLE,
      COMPLETION_POSSIBLE,
      LEGAL_CHECK_POSSIBLE,
      SUPPLIER_INPUT_POSSIBLE,
      SUPPLIER_INPUT_DONE_POSSIBLE,
      SUPPLIER_TODOS_POSSIBLE,
      SUPPLIER_TODOS_DONE_POSSIBLE,
      PROJECT_COMPLETION_POSSIBLE,
      FINISH_SUPPLIER_REPORT_POSSIBLE,
    ],
    onCompleted: () => {
      if (isFinishLegalCheck()) {
        checkResolverStatus();
      }
      if (state.authorized && state.mutationName === 'SubmitToUser') {
        osoAnsweredVar(!osoAnsweredVar());
      }
    },
  });

  const handleModalStateChange = () => {
    StateChangeMutation();
    setIsModalOpen(false);
  };

  return (
    <>
      <SupplierModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalStateChange}
        stateText={t(state.content)}
        versionId={versionId}
      />
      <Popup
        id={popupId}
        key={popupId}
        disabled={!isButtonDisabled && !OSOIsActive}
        content={OSOIsActive ? t('stateChanges.submitToOsoInfoText') : content}
        position="top center"
        trigger={
          <span>
            <Button
              id={id}
              primary
              basic
              content={
                <>
                  {finishCheckLoading && (data || loading) && (
                    <Popup
                      id="LoadingPopup"
                      content={t('finishCheckLoading')}
                      trigger={<Icon id="Spinner" loading name="spinner" />}
                    />
                  )}
                  {t(state.content)}
                </>
              }
              disabled={isButtonDisabled || loading}
              onClick={
                shouldOpenSupplierModal
                  ? () => setIsModalOpen(true)
                  : () => StateChangeMutation()
              }
            />
          </span>
        }
      />
    </>
  );
};

export default StateChangeButton;
