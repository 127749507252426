import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Label, Menu, Message, Segment } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  RequirementInfo,
  RequirementType,
  useGetRequirementsQuery,
} from '../../../../graphql/generated/graphql';
import GenericClosedSegmentSkeleton from '../skeletons/GenericClosedSegmentSkeleton';
import RequirementsList from './RequirementsList';
import { shouldRefetchQueryVar } from '../../../../hooks/helpers/logics';

/**
 * RequirementsViewHeader component
 *
 *@returns {JSX.Element} The static  accordion header-component for user / legal Requirements list in a chosen product. Separate component , closed on default.
 */
const RequirementsViewHeader = (): JSX.Element => {
  const [t] = useTranslation(['requirements']);
  const [open, setOpen] = useState(true);
  const [activeTab, setActiveTab] = useState(RequirementType.user);
  const { versionId } = useContext(ProductDetailsContext);
  const { loading, data, error, refetch } = useGetRequirementsQuery({
    variables: {
      versionId,
    },
  });
  const shouldRefetchRequirements = useReactiveVar(shouldRefetchQueryVar);
  useEffect(() => {
    if (shouldRefetchRequirements?.includes('Requirements')) {
      refetch().finally(() => {
        shouldRefetchQueryVar([]); // reset after fetching
      });
    }
  }, [shouldRefetchRequirements, refetch]);
  //  LOADING and ERROR situations
  if (loading) {
    return (
      <GenericClosedSegmentSkeleton
        height={105}
        image
        lines={2}
        labels="all"
        linesLength="short"
      />
    );
  }
  if (error) {
    return (
      <Message
        id="RequirementsViewHeaderError"
        error
        icon="times circle"
        content={`Error ${error.message}`}
      />
    );
  }

  const userRequirements = data?.GetRequirements?.filter(
    (requirementInfo) =>
      requirementInfo.requirement.type === RequirementType.user
  );
  const legalRequirements = data?.GetRequirements?.filter(
    (requirementInfo) =>
      requirementInfo.requirement.type === RequirementType.legal
  );

  // Logic for colors and messages dependant on the active tab/array
  const activeArray =
    activeTab === RequirementType.user ? userRequirements : legalRequirements;

  // all selection types are answers, so the requirement is met if all dependencies are answered
  const openRequirements = activeArray?.filter(
    (requirementInfo) => requirementInfo.requirementAnswered === false
  );

  const infoLabelText =
    openRequirements?.length === 0
      ? t('noOpenRequirements', {
          type: t(`${activeTab}.false`),
        })
      : t('openRequirements', {
          notSelectedYet: openRequirements?.length,
          type: t(`${activeTab}.${openRequirements?.length === 1}`),
        });

  return (
    <Segment raised id="RequirementsViewContainerSegment">
      <Menu id="RequirementsViewMenuTabs" pointing secondary compact>
        <Menu.Item>
          <Icon
            id="RequirementsViewAccordion"
            onClick={() => setOpen(!open)}
            name={open ? 'chevron up' : 'chevron down'}
            color={open ? 'blue' : 'black'}
          />
        </Menu.Item>
        <Menu.Item
          name="User Requirements"
          active={activeTab === RequirementType.user}
          onClick={() => setActiveTab(RequirementType.user)}
          color="blue"
        >
          {t('user.false')}
          <Label
            style={{ marginLeft: '1em' }}
            id="RequirementsViewActiveLabelUser"
            circular
          >
            {userRequirements?.length}
          </Label>
        </Menu.Item>
        <Menu.Item
          name="Legal Requirements"
          active={activeTab === RequirementType.legal}
          onClick={() => setActiveTab(RequirementType.legal)}
          color="blue"
        >
          {t('legal.false')}
          <Label
            style={{ marginLeft: '1em' }}
            id="RequirementsViewActiveLabelLegal"
            circular
          >
            {legalRequirements?.length}
          </Label>
        </Menu.Item>
      </Menu>
      {/* DISPLAY OPEN REQUIREMENTS or a SUCCESS MSG  IF NO MORE OPEN ONES! */}
      <div style={{ display: 'inline', marginLeft: '45px' }}>
        {openRequirements?.length !== 0 ? (
          <Message
            id="OpenRequirementsMessage"
            compact
            info
            icon="info circle"
            content={infoLabelText}
          />
        ) : (
          <Message
            id="NoOpenRequirementsMessage"
            compact
            success
            icon="check circle"
            content={infoLabelText}
          />
        )}
      </div>
      {/* Requirements listing Component */}
      {open && (
        <>
          {activeTab === RequirementType.user && (
            <RequirementsList
              requirementInfoArray={
                (userRequirements as RequirementInfo[]) || []
              }
            />
          )}
          {activeTab === RequirementType.legal && (
            <RequirementsList
              requirementInfoArray={
                (legalRequirements as RequirementInfo[]) || []
              }
            />
          )}
        </>
      )}
    </Segment>
  );
};

export default RequirementsViewHeader;
