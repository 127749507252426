import React from 'react';
import { StateMapping } from './logics';
import LCDownloadButton from '../LCDownloadButton';
import ReportDownloadButton from '../ReportDownloadButton';
import EmailBrandOSOButton from '../EmailBrandOSOButton';
import { GetLatestLcDocumentQuery } from '../../../../../graphql/generated/graphql';

/**
 * Render function to render all the other buttons than stateChange
 *
 * @param {StateMapping} state state mapping
 * @param {boolean} groupTransfer group transfer
 * @param {GetLatestLcDocumentQuery} lcDocumentData disclaimerData
 * @param {boolean} preliminary is it preliminary lc doc?
 * @returns {JSX.Element} true if arrays are equal, false otherwise
 */
export const renderAdditionalButtons = (
  state: StateMapping,
  groupTransfer: boolean,
  lcDocumentData: GetLatestLcDocumentQuery | undefined,
  preliminary: boolean
) => {
  switch (state.mutationName) {
    case 'DownloadLcDocument':
      return lcDocumentData?.GetLatestLcDocument.file ? (
        <LCDownloadButton
          disabled={!state.authorized}
          lcDocumentData={lcDocumentData}
          preliminary={preliminary}
        />
      ) : null;
    case 'DownloadReport':
      return <ReportDownloadButton disabled={!state.authorized} />;
    case 'GetEmailsByBrand':
      return groupTransfer ? (
        <EmailBrandOSOButton disabled={!state.authorized} />
      ) : null;
    default:
      return null;
  }
};

export default renderAdditionalButtons;
