import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  useQuery,
} from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  Product,
  ProductsRequestorListQueryResult,
  Role,
  User,
  Version,
} from '../../../../../graphql/generated/graphql';
import {
  CHECK_FOR_PROJECTS,
  PRODUCTS_REQUESTOR_LIST,
  VERSION_REVIEWERS_LIST,
} from '../../../../../graphql/queries/ProductQuerys';
import { Columns, getDefaultColumns } from '../ProductListTypes';
import { collatingElementType } from '../../../productDetails/dependencySegment/dependencyList/helper/types';
import { mergeColumns } from './logics';
import { getProcessStatusType } from '../../../productDetails/dependencySegment/dependencyList/helper/logics';

/**
 * @description fetches the requestor list from the db and sets the filterInfo
 * @returns {object|null} An object mapping requestor name__ID to boolean, or null if the data isn't yet available.
 */
export const useFetchRequestorList = (): { [K: string]: boolean } | null => {
  const { data: requestorListData } = useQuery(PRODUCTS_REQUESTOR_LIST);
  const [filtering, setFiltering] = useState<{ [K: string]: boolean } | null>(
    null
  );
  useEffect(() => {
    if (requestorListData) {
      const newFiltering = requestorListData.Product.reduce(
        (
          obj: ProductsRequestorListQueryResult,
          requestorObj: { createdBy: { name: string; id: string } }
        ) => ({
          ...obj,
          [`${requestorObj.createdBy.name}__${requestorObj.createdBy.id}`]:
            false,
        }),
        {}
      );
      setFiltering(newFiltering);
    }
  }, [requestorListData]);

  return filtering;
};

/**
 * @param {Role} role - the current user role
 * @description fetches the columns that are specific to the user role
 * @returns {Columns|null} returns array of user specific columns
 */
export const useFetchRoleSpecificColumns = (role: Role): Columns | null => {
  const [newColumns, setNewColumns] = useState<Columns | null>(null);

  useEffect(() => {
    if (role) {
      setNewColumns(getDefaultColumns(role));
    }
  }, [role]);

  return newColumns;
};

/**
 * @description A custom React Hook that handles refetching the list upon a sorting or filtering request.
 * @param {() => Promise<ApolloQueryResult<any>>} refetch The refetch function from useQuery.
 * @param {Array} activeSortersArray An array of objects that hold the current active sorters.
 * @param {Array} activeFiltersArray An array of objects that hold the current active filters.
 * @param {number} activePageState The current active page number.
 * @param {number} itemsPerPage The number of items per page.
 * @param {string} searchString The current search string.
 * @param {boolean} onlyProjects fetch only projects
 * @returns {void}
 */
export const useRefetchList = (
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<unknown>>,
  activeSortersArray: Array<{ [x: string]: string }>,
  activeFiltersArray: Array<{ [x: string]: unknown }>,
  activePageState: number,
  itemsPerPage: number,
  searchString: string,
  onlyProjects: boolean
): void => {
  // used to prevent the hook from running on the initial mount
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const projectStatusFilter: collatingElementType[] =
        activeFiltersArray.filter(
          (filter) => filter.projectStatus
        ) as collatingElementType[];
      const processStatusFilter: collatingElementType[] =
        activeFiltersArray.filter(
          (filter) => filter.processStatus
        ) as collatingElementType[]; // TODO: find out if this is actually possible to get
      const activeFiltersArrayWithoutStatus = activeFiltersArray.filter(
        (filter) => !filter.projectStatus && !filter.processStatus
      );
      const statusFilter: collatingElementType[] =
        projectStatusFilter || processStatusFilter;
      const productFilters = activeFiltersArrayWithoutStatus.filter(
        (filter) => !filter.osoReviewer && !filter.legalReviewer
      );

      refetch({
        options: {
          limit: itemsPerPage,
          offset: (activePageState - 1) * itemsPerPage,
          sort: [...activeSortersArray],
        },
        where: {
          AND: [
            {
              // either projectStatus* from Product or processStatus from one of its Versions
              OR: [
                ...statusFilter, // *unfortunately only projectStatus is considered here
                {
                  versions_SOME: {
                    OR: activeFiltersArray
                      .filter((filter) => filter.projectStatus)
                      .map((filter) => ({
                        processStatus: getProcessStatusType(
                          filter.projectStatus as string
                        ),
                      })), // processStatus from one of Products´ Versions
                  },
                },
              ],
            },
            { OR: [...productFilters] },
            {
              versions_SOME: {
                OR: [...activeFiltersArrayWithoutStatus]
                  .map((filter) => {
                    if (filter.osoReviewer)
                      return { osoReviewer: { id: filter.osoReviewer } };
                    if (filter.legalReviewer)
                      return { legalReviewer: { id: filter.legalReviewer } };
                    return undefined;
                  })
                  .filter(Boolean),
              },
            },
            { OR: onlyProjects ? [{ isProject: true }] : [] },
          ],
        },
        searchString,
      });
    }
  }, [
    refetch,
    activeSortersArray,
    activeFiltersArray,
    activePageState,
    itemsPerPage,
    searchString,
    onlyProjects,
  ]);
};

/**
 * @description A custom React Hook that handles the max amount of pages
 * @param {number} itemAmount The amount of all items
 * @param {number} itemsPerPage The number of items per page.
 * @returns {number} The max amount of pages
 */
export const useTotalPages = (
  itemAmount: number,
  itemsPerPage: number
): number => {
  if (itemsPerPage && itemAmount) {
    return Math.ceil(itemAmount / itemsPerPage);
  }
  return 1;
};

/**
 * @description A custom React Hook that handles keeps the current page in bounds
 * @param {number} maxPages The max amount of pages
 * @param {number} activePageState The current active page number.
 * @param {Function} setActivePageState The setter for the active page state.
 */
export const useKeepCurrentPageInBounds = (
  maxPages: number,
  activePageState: number,
  setActivePageState: React.Dispatch<React.SetStateAction<number>>
): void => {
  useEffect(() => {
    if (maxPages && maxPages < activePageState && maxPages !== 0) {
      setActivePageState(maxPages);
    }
  }, [maxPages, activePageState, setActivePageState]);
};

/**
 * Merges role-specific columns into the main columns object and updates the state.
 *
 * @param {Columns} columns - The current columns object.
 * @param {Columns} roleSpecificColumns - Role-specific columns to be merged.
 * @param {Dispatch<SetStateAction<Columns>>} setColumns - State setter function for updating columns.
 */
export const useMergeRoleSpecificColumns = (
  columns: Columns,
  roleSpecificColumns: Columns | null,
  setColumns: Dispatch<SetStateAction<Columns>>
) => {
  useEffect(() => {
    if (roleSpecificColumns && columns) {
      const mergedColumns = mergeColumns(columns, roleSpecificColumns);
      setColumns(mergedColumns);
    }
  }, [roleSpecificColumns]); // eslint-disable-line
};

/**
 * Updates the requestor-related information in the columns and sets the updated columns.
 *
 * @param {Columns} columns - The current columns object.
 * @param {object|null} requestorList - Information related to requestors to update in the columns.
 * @param {Dispatch<SetStateAction<Columns>>} setColumns - State setter function for updating columns.
 */
export const useUpdateRequestorColumn = (
  columns: Columns,
  requestorList: { [K: string]: boolean } | null,
  setColumns: Dispatch<SetStateAction<Columns>>
) => {
  useEffect(() => {
    if (requestorList && columns) {
      const updatedColumns = [...columns];
      const requestorColumn = updatedColumns.find(
        (col) => col.name === 'requestor'
      );
      if (requestorColumn) {
        const anyRequestorTrue = Object.values(
          requestorColumn.filterInfo.filtering
        ).some(Boolean);
        if (!anyRequestorTrue) {
          requestorColumn.filterInfo.filtering = requestorList;
          setColumns(updatedColumns);
        }
      }
    }
  }, [requestorList]); // eslint-disable-line
};

/**
 * Handles the active page state, updating the state accordingly.
 *
 * @param {number} activePageState - The current active page number.
 * @param {Dispatch<SetStateAction<number>>} setActivePageState - State setter function for updating the active page.
 */
export const useHandleActivePageState = (
  activePageState: number,
  setActivePageState: Dispatch<SetStateAction<number>>
) => {
  useEffect(() => {
    setActivePageState(activePageState);
  }, [activePageState, setActivePageState]);
};

/**
 * Custom hook to fetch and filter reviewers based on role.
 *
 * @param {Role} role - Role of the user ('legal' or 'oso').
 * @returns {User[]} - Array of reviewers that fulfill the role conditions.
 */
export const useFetchReviewers = (role: Role) => {
  const { data: reviewersListData } = useQuery(VERSION_REVIEWERS_LIST);
  const [reviewers, setReviewers] = useState<User[]>([]);

  useEffect(() => {
    if (reviewersListData && (role === 'legal' || role === 'oso')) {
      const fetchedReviewers = reviewersListData.Product.flatMap(
        (product: Product) =>
          product.versions
            .flatMap((version: Version) => ({
              name: version[`${role}Reviewer`]?.name,
              id: version[`${role}Reviewer`]?.id,
            }))
            .filter(
              (reviewer) =>
                reviewer.name !== undefined && reviewer.id !== undefined
            )
      );
      setReviewers(fetchedReviewers);
    }
  }, [reviewersListData, role]);

  return reviewers;
};

/**
 * Custom hook to update the reviewer column in a given columns array.
 *
 * @param {Columns} columns - Existing column configuration.
 * @param {User[]} reviewers - Array of reviewers to update in the column.
 * @param {Role} role - Role based on which the column should be updated ('legal' or 'oso' in this case).
 * @param {Dispatch<SetStateAction<Columns>>} setColumns - Setter function for updating columns.
 */
export const useUpdateReviewerColumn = (
  columns: Columns,
  reviewers: User[],
  role: Role,
  setColumns: Dispatch<SetStateAction<Columns>>
) => {
  useEffect(() => {
    if (reviewers.length > 0 && columns) {
      const updatedColumns = [...columns];
      const reviewerColumn = updatedColumns.find((col) => col.name === role);

      if (reviewerColumn) {
        const reviewerFiltering = reviewers.reduce(
          (obj: { [K: string]: boolean }, reviewer) => ({
            ...obj,
            [`${reviewer.name ? reviewer.name : 'unassigned'}__${reviewer.id}`]:
              false,
          }),
          {}
        );

        const anyReviewerTrue = Object.values(
          reviewerColumn.filterInfo.filtering
        ).some(Boolean);

        if (!anyReviewerTrue) {
          reviewerColumn.filterInfo.filtering = reviewerFiltering;
          setColumns(updatedColumns);
        }
      }
    }
  }, [reviewers]); // eslint-disable-line
};

interface UseProductCountersReturn {
  totalProjects: number;
  loading: boolean;
  error: ApolloError | undefined;
}

/**
 * Custom hook to check total number of projects and products
 *
 * @returns {UseProductCountersReturn} totalProducts, totalProjects
 */
export const useProductCounters = (): UseProductCountersReturn => {
  const { data, loading, error } = useQuery(CHECK_FOR_PROJECTS);

  let totalProjects = 0;

  if (data) {
    totalProjects = data.Product.filter(
      (product: Product) => product.isProject
    ).length;
  }

  return {
    totalProjects,
    loading,
    error,
  };
};
