import { DocumentNode, useMutation } from '@apollo/client';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Icon,
  Message,
  Popup,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import CREATE_A_COMMENT from '../../../../graphql/mutations/CommentMutations';
import { displayedInputType } from './helper/types';
import useTextAreaChange from '../../../../hooks/useTextAreaChange';

// import { CommentsContext } from '../../../../context/CommentsContext';

type CreateCommentProps = {
  refersToId?: string; // element that references the comment
  createComment: boolean;
  setCreateComment: (createComment: boolean) => void;
  setDisplayedInput: (displayedInput: displayedInputType) => void;
  isReplied?: string | []; // element that
  noComments?: boolean | undefined;
  refetch: (string | DocumentNode)[];
};

/**
 * Comment creating component
 *
 *@returns {JSX.Element} create comment element
 *@param {CreateCommentProps} props Props for the comment
 */
const CreateCommentComponent = (props: CreateCommentProps): JSX.Element => {
  const {
    refersToId,
    createComment,
    setCreateComment,
    setDisplayedInput,
    isReplied,
    noComments,
    refetch,
  } = props;
  const [t] = useTranslation('comments');
  const { versionId } = useContext(ProductDetailsContext);
  const [text, setText] = useState('');
  const [errorMsg, setErrorMsg] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const { handleTextAreaChange } = useTextAreaChange(
    setText,
    text,
    'COMMENT_CHAR_LIMIT',
    10 // maxEmptyLines, assuming here is desirable to have more than default 2.
  );

  useEffect(() => {
    if (createComment === false) {
      setText('');
      setErrorMsg(false);
      setShowHint(false);
    }
    if (createComment) {
      const textAreaElement = document.getElementById(
        'CreateCommentComponentTextArea'
      ) as HTMLTextAreaElement;
      if (textAreaElement) {
        textAreaElement.focus();
        textAreaElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [createComment, setText]);
  const [CreateCommentForAnyComponent] = useMutation(CREATE_A_COMMENT, {
    refetchQueries: refetch,
  });
  const handleCreation = () => {
    if (text.trim().length < 2) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
      CreateCommentForAnyComponent({
        variables: {
          versionId,
          text: text.trim(),
          refersToId,
          replyCommentId: isReplied || null,
        },
      });
      setCreateComment(false);
      setShowHint(false);
      setDisplayedInput('');
      setText('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleCreation();
    }
  };

  return (
    <Segment
      id="CreateCommentComponentSegment"
      className="CreateCommentComponentSegment"
    >
      <Form id="CreateCommentComponentTextAreaForm" reply>
        {' '}
        <div>
          <Popup
            id="CreateCommentComponentTextAreaPopup"
            open={errorMsg}
            content={
              <Message
                id="CreateCommentComponentTextAreaMessage"
                warning
                icon="exclamation triangle"
                content={t('invalidComment')}
              />
            }
            position="top center"
            trigger={
              <TextArea
                id="CreateCommentComponentTextArea"
                style={{ resize: 'none' }}
                placeholder={t('addComment')}
                value={text}
                rows={3}
                onChange={(_evt: ChangeEvent<HTMLTextAreaElement>, data) => {
                  setErrorMsg(false); // disables error msg to stay when writing
                  handleTextAreaChange(_evt);
                  setShowHint((data.value?.toString().length ?? 0) > 2);
                }}
                onKeyDown={handleKeyDown}
              />
            }
          />
          <span className="text-info">
            <small
              style={{
                marginLeft: '0.5em',
                lineHeight: '2.5',
                color: 'gray',
                transition: 'opacity ease-in-out 0.2s',
                opacity: showHint ? 1 : 0,
              }}
            >
              {t('shiftEnter')}
            </small>
          </span>
        </div>
        <Icon
          id="CreateCommentComponentConfirmIcon"
          onClick={handleCreation}
          link
          bordered
          inverted
          name="send"
          size="large"
          color="blue"
        />
        {!noComments && (
          <Icon
            id="CreateCommentComponentCancelIcon"
            onClick={() => {
              setCreateComment(false);
              setDisplayedInput('');
              setShowHint(false);
            }}
            name="cancel"
            link
            bordered
            size="large"
            color="grey"
          />
        )}
      </Form>
    </Segment>
  );
};

export default CreateCommentComponent;
