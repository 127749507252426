import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Icon, Modal, Image, Header } from 'semantic-ui-react';
import onboardingImgWithSupp from './downloads/OnboardingGuide_AOSD2-1_mit_LieferantIn.png';
import onboardingImgWithoutSupp from './downloads/OnboardingGuide_AOSD2-1_ohne_LieferantIn.png';
import onboardingPdfWithSupp from './downloads/OnboardingGuide_AOSD2-1_mit_LieferantIn.pdf';
import onboardingPdfWithoutSupp from './downloads/OnboardingGuide_AOSD2-1_ohne_LieferantIn.pdf';
import jsonSchema from './downloads/json-schema.png';
import fossImgEn from './downloads/foss-en.png';
import fossImgDe from './downloads/foss-de.png';
import easyCheckOSSImage from './downloads/easyCheckOSSLogo.png';
import fossPdfEn from './downloads/FOSS_Declaration_EN.pdf';
import fossPdfDe from './downloads/FOSS_Declaration_DE.pdf';
import i18n from '../../i18n/i18n';
import './SideBarGuide';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Modal containing 4 documents for download, link to Service-Desk, Sidebar Guide
 *
 *@param {object} props {open, setOpen}
 *@returns {JSX.Element} The DownloadsLinksModal
 */
const DownloadsLinksModal = (props: Props): JSX.Element => {
  const [t] = useTranslation(['menu']);
  const lang = i18n.language;
  const isGerman = lang === 'de';
  const { open, setOpen } = props;

  return (
    <Modal
      size="large"
      id="DownloadsLinksModal"
      open={open}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Header>
        {t('downloadsModal.title')}
        <Icon
          id="iconClose1"
          name="close"
          link
          floated="right"
          onClick={() => setOpen(false)}
        />
      </Modal.Header>
      <Modal.Content>
        <Header as="h4">{t('downloadsModal.subheader')}</Header>
        <Grid relaxed padded="vertically">
          <Grid.Row columns={5}>
            <Grid.Column
              textAlign="center"
              as="a"
              href={onboardingPdfWithSupp}
              download="OnboardingGuide_AOSD2-1_mit_LieferantIn.pdf"
            >
              <div className="image-wrapper">
                <Icon
                  name="download"
                  id="DownloadIcon1"
                  className="custom-download"
                  size="huge"
                />
                <Image
                  src={onboardingImgWithSupp}
                  size="medium"
                  bordered
                  rounded
                />
              </div>
              <p id="Download1">
                {t('downloadsModal.onboarding_with_supplier')}
              </p>
            </Grid.Column>

            <Grid.Column
              textAlign="center"
              as="a"
              href={onboardingPdfWithoutSupp}
              download="OnboardingGuide_AOSD2-1_ohne_LieferantIn.pdf"
            >
              <div className="image-wrapper">
                <Icon
                  name="download"
                  id="DownloadIcon2"
                  className="custom-download"
                  size="huge"
                />
                <Image
                  src={onboardingImgWithoutSupp}
                  size="medium"
                  bordered
                  rounded
                />
              </div>
              <p id="Download2">
                {t('downloadsModal.onboarding_without_supplier')}
              </p>
            </Grid.Column>

            <Grid.Column
              textAlign="center"
              as="a"
              href={isGerman ? fossPdfDe : fossPdfEn}
              download={
                isGerman ? 'FOSS_Erklärung.pdf' : 'FOSS_Declaration.pdf'
              }
            >
              <div className="image-wrapper">
                <Icon
                  name="download"
                  id="DownloadIcon3"
                  className="custom-download"
                  size="huge"
                />
                <Image
                  src={isGerman ? fossImgDe : fossImgEn}
                  size="medium"
                  bordered
                  rounded
                />
              </div>
              <p id="Download3">{t('downloadsModal.fossDeclaration')}</p>
            </Grid.Column>

            <Grid.Column
              textAlign="center"
              as="a"
              href="/downloads/AOSD2.1.0_JSON_Import_Scheme_V2.1.0.zip"
            >
              <div className="image-wrapper">
                <Icon
                  name="download"
                  id="DownloadIcon4"
                  className="custom-download"
                  size="huge"
                />
                <Image src={jsonSchema} size="medium" bordered rounded />
              </div>
              <p id="Download4">{t('downloadsModal.JSONschema')}</p>
            </Grid.Column>

            <Grid.Column
              textAlign="center"
              as="a"
              href="https://easycheckoss.ai/downloads.html?lang=de#spdx2aosd"
              target="_blank"
            >
              <div className="image-wrapper">
                <Icon
                  name="linkify"
                  id="EasyCheckOssIcon"
                  size="huge"
                  className="custom-download"
                />
                <Image src={easyCheckOSSImage} size="medium" bordered rounded />
              </div>
              <p id="EasyCheckOssLink">
                {t('downloadsModal.easyCheckOSSConverter')}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          id="DownloadsLinksModal.ServiceDesk"
          href="https://devstack.vwgroup.com/jira/servicedesk/customer/portal/174"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('downloadsModal.serviceDesk')}
        </Button>
        <Button
          primary
          id={`SideBarMenuGuideAndDocuMenuItem-${lang}`}
          onClick={() => {
            setOpen(false);
          }}
        >
          {t('downloadsModal.sidebarGuide')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DownloadsLinksModal;
