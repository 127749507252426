import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal, Checkbox, Button, Icon, Popup } from 'semantic-ui-react';

interface SupplierModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  stateText: string;
  versionId: string;
}

/**
 * Modal displayed to Supplier before switching state to remind him to upload certain docs.
 *
 * @param {object} props props.
 * @returns {JSX.Element} SupplierModal.
 */
const SupplierModal = (props: SupplierModalProps): JSX.Element => {
  const { open, onClose, onSubmit, stateText, versionId } = props;
  const { t } = useTranslation('productDetails');
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);

  const allChecked = checkbox1 && checkbox2;
  const navigate = useNavigate();

  const handleStorageButton = () => {
    onClose();
    navigate(`/product-details/${versionId}?view=Storage`);
  };

  return (
    <Modal id="SupplierModal" open={open} onClose={onClose} size="small">
      <Modal.Header>
        {t('supplierModal.header')}
        <Icon id="iconClose1" name="close" link onClick={() => onClose()} />
      </Modal.Header>
      <Modal.Content>
        <Modal.Description content={t('supplierModal.description')} />
        <Checkbox
          id="Checkbox1"
          label={t('supplierModal.checkbox1')}
          checked={checkbox1}
          onChange={() => setCheckbox1((prev) => !prev)}
        />
        <br />
        <Checkbox
          id="Checkbox2"
          label={t('supplierModal.checkbox2')}
          checked={checkbox2}
          onChange={() => setCheckbox2((prev) => !prev)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="GoToStorageBtn"
          secondary
          onClick={handleStorageButton}
          content={t('supplierModal.toStorageBtn')}
        />
        <Popup
          id="ChangeStatusPopup"
          on="hover"
          disabled={allChecked}
          position="top center"
          content={t('supplierModal.stateChangePopup')}
          trigger={
            <span>
              <Button
                id="ChangeStateBtn"
                primary
                onClick={onSubmit}
                disabled={!allChecked}
                content={stateText}
              />
            </span>
          }
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SupplierModal;
