import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup, Radio } from 'semantic-ui-react';

/**
 * Notification Toggle, not yet active. Its disabled and shows informative popup.
 *
 * @returns {JSX.Element} NotificationToggle
 */
const NotificationToggle = (): JSX.Element => {
  const { t } = useTranslation('productDetails');

  return (
    <Popup
      id="ProductDetailsHeader.NotificationRadioPopup"
      content={t('notImplemented')}
      position="bottom center"
      trigger={
        <div id="TriggerPopupButton" style={{ zIndex: 1, cursor: 'pointer' }}>
          <Radio
            toggle
            disabled
            style={{ pointerEvents: 'none' }}
            id="ProductDetailsHeader.NotificationRadio"
            label={t('disableNotifications')}
          />
        </div>
      }
    />
  );
};

export default NotificationToggle;
