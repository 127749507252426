import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Item } from 'semantic-ui-react';

interface TargetDateMessageProps {
  targetDate: string | undefined;
}

/**
 *
 * @param {string} targetDate Target Date
 *@returns {JSX.Element} Icons with message regarding the release Date. If more than 7 days is positive, less is a mild warning and when the date was passed a red critical/negative warning
 */
const TargetDateMessage = ({
  targetDate,
}: TargetDateMessageProps): JSX.Element => {
  const [t] = useTranslation(['productDetails']);

  const currentDate = new Date();
  const targetDateIso = new Date(targetDate || '');
  const subtracted = targetDateIso.getTime() - currentDate.getTime();

  const diffInDays = Math.ceil(subtracted / (1000 * 60 * 60 * 24)); // divide with "one Day" in miliseconds

  // reforming a negative into a positive to use in context when overdue
  let negativeDiff = diffInDays < 0 ? diffInDays : 0;
  negativeDiff = Math.abs(negativeDiff);

  const enoughTime = diffInDays > 7;
  const dueClose = diffInDays <= 7 && diffInDays >= 1;
  const dueToday = diffInDays === 0;
  const overdue = diffInDays < 0;

  return (
    <>
      {/* POSITIVE */}
      {enoughTime && (
        <>
          <Icon
            id="CalendarIconPositive"
            className="margin-left"
            color="green"
            size="large"
            name="calendar check outline"
          />
          <Item.Extra
            style={{ display: 'inline', fontSize: '12px', color: '#21BA45' }}
          >
            {t('enoughTime', { diffInDays })}
          </Item.Extra>
        </>
      )}
      {/* WARNING DUE CLOSE */}
      {dueClose && (
        <>
          <Icon.Group className="margin-left calendar-warning">
            <Icon
              id="CalendarIconWarningCalenderIcon"
              color="orange"
              size="large"
              name="calendar outline"
            />
            <Icon
              color="orange"
              size="small"
              id="CalendarIconWarningWarningIcon"
              name="warning"
            />
          </Icon.Group>
          <Item.Extra
            style={{ display: 'inline', fontSize: '12px', color: '#F2711C' }}
          >
            {t('dueClose', { diffInDays })}
          </Item.Extra>
        </>
      )}
      {/* NEGATIVE WARNING DUE MISSED */}
      {overdue && (
        <>
          <Icon
            id="CalendarIconNegative"
            className="margin-left"
            color="red"
            size="large"
            name="calendar times outline"
          />
          <Item.Extra
            style={{ display: 'inline', fontSize: '12px', color: '#db2828' }}
          >
            {t('overDue', { negativeDiff })}
          </Item.Extra>
        </>
      )}
      {/* NEGATIVE WARNING DUE TODAY */}
      {dueToday && (
        <>
          <Icon
            id="CalendarIconDueToday"
            color="grey"
            size="large"
            name="calendar outline"
          />
          <Item.Extra
            style={{ display: 'inline', fontSize: '12px', color: 'grey' }}
            content={t('dueToday', { diffInDays })}
          />
        </>
      )}
    </>
  );
};

export default TargetDateMessage;
