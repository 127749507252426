/**
 * Enumerates the contexts from which the comments modal can be opened.
 * It is crucial for determining the modal's behavior and rendering.
 */
export enum CommentsModalContext {
  Requirements = 'Requirements',
  Comments = 'Comments',
  Todos = 'Todos',
  History = 'History',
}
/**
 * Context which tab in the Requirements modal is opened
 */
export enum RequirementContext {
  CommentRequirement = 'CommentRequirement',
  AnswerRequirement = 'AnswerRequirement',
}
/**
 * Requirement selection mode is either globalSelection where all components are selected at once with same SelectionType or individually selected
 */
export enum SelectionMode {
  GLOBAL = 'global',
  INDIVIDUAL = 'individual',
}
