import { DocumentNode, useMutation } from '@apollo/client';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Icon,
  Item,
  Message,
  Modal,
  Popup,
  Segment,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  FINISH_LEGAL_CHECK_POSSIBLE,
  LEGAL_CHECK_POSSIBLE,
  PRODUCT_DETAILS_CONTACTS,
  SUPPLIER_INPUT_POSSIBLE,
  SUPPLIER_TODOS_POSSIBLE,
} from '../../../../graphql/queries/VersionQuerys';
import { Contact, Department } from './helper/types';
import { UPDATE_SUPPLIER_TEAM } from '../../../../graphql/mutations/VersionMutations';
import stringArraysAreEqual from './helper/logics';

interface Props {
  team: Contact[];
  department: Department;
  mutation: DocumentNode;
  options: DropdownItemProps[] | undefined;
  trigger?: JSX.Element;
  readOnly?: boolean;
  inLegalCheckOrDone?: boolean;
}

/**
 * @param {object} props Team and Department
 * @returns {JSX.Element} Modal with the full list of developers
 */
const TeamModal = (props: Props): JSX.Element => {
  const {
    team,
    department,
    mutation,
    options,
    trigger,
    readOnly = false,
    inLegalCheckOrDone,
  } = props;
  const [t] = useTranslation(['users']);
  const [open, setOpen] = useState(false);
  const initialTeam: string[] = team
    .map((u) => ({ id: u.id, name: u.name }))
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((u) => u.id);
  const [selectedTeam, setSelectedTeam] = useState<string[]>(initialTeam);
  const [validChange, setValidChange] = useState(true);
  const { versionId } = useContext(ProductDetailsContext);

  // when supplier is present in Product during Legal Check or after its done
  const supplierDuringLegalCheck =
    department === Department.SUPPLIER && inLegalCheckOrDone;
  // when not present
  const noSupplierNoEdit =
    department === Department.SUPPLIER &&
    selectedTeam.length === 0 &&
    !trigger &&
    inLegalCheckOrDone;

  useEffect(() => {
    const isInitialTeamEqual = stringArraysAreEqual(selectedTeam, initialTeam);
    if (department === Department.MANAGER && selectedTeam.length === 0) {
      setValidChange(false);
    } else if (supplierDuringLegalCheck && selectedTeam.length === 0) {
      setValidChange(false);
    } else setValidChange(!isInitialTeamEqual);
  }, [department, initialTeam, selectedTeam, supplierDuringLegalCheck]);

  useEffect(() => {
    setSelectedTeam(initialTeam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const addUser = (userId: string) => {
    setSelectedTeam((prevTeam) => [...prevTeam, userId]);
  };

  const removeUser = (userId: string | undefined) => {
    setSelectedTeam((prevTeam) =>
      prevTeam.filter((prevUser) => prevUser !== userId)
    );
  };

  const [updateMutation] = useMutation(mutation, {
    variables: {
      versionId,
      team: selectedTeam,
    },
    onCompleted: () => {
      setOpen(false);
    },
    refetchQueries:
      mutation === UPDATE_SUPPLIER_TEAM
        ? [
            PRODUCT_DETAILS_CONTACTS,
            SUPPLIER_INPUT_POSSIBLE,
            SUPPLIER_TODOS_POSSIBLE,
            LEGAL_CHECK_POSSIBLE,
            FINISH_LEGAL_CHECK_POSSIBLE,
          ]
        : [PRODUCT_DETAILS_CONTACTS],
  });

  const ModalTrigger = trigger || (
    <Button
      id="EditButton"
      className="borderless fit-content height"
      icon="pencil"
      basic
      color="blue"
      floated="right"
      disabled={noSupplierNoEdit}
    />
  );

  const ConditionalPopupTrigger = noSupplierNoEdit ? (
    <Popup
      id="TeamModalEditButtonPopup"
      on={['hover', 'click']}
      hideOnScroll
      content={t('noEditTeamButton')}
      trigger={<span className="DisabledTeamEditButton">{ModalTrigger}</span>}
    />
  ) : (
    ModalTrigger
  );

  const getWizardMessage = (): ReactNode => {
    if (department === Department.MANAGER || supplierDuringLegalCheck) {
      return (
        <span>
          {t(`wizardsTeam.requiredArr.${department}`, {
            ns: 'newProductWizard',
          })}
        </span>
      );
    }
    if (department === Department.DEVELOPMENT) {
      return (
        <span>
          {t(`wizardsTeam.emptyArrMsg.developer`, {
            ns: 'newProductWizard',
          })}
        </span>
      );
    }
    return (
      <span>
        {t(`wizardsTeam.emptyArrMsg.${department}`, {
          ns: 'newProductWizard',
        })}
      </span>
    );
  };

  const wizardMessage = getWizardMessage();
  const showEmptyMessage = (): JSX.Element => {
    if (department === Department.MANAGER || supplierDuringLegalCheck) {
      return (
        <Message
          id="EmptyTeamMessageManager"
          error
          icon="times circle"
          content={wizardMessage}
        />
      );
    }
    return (
      <Message
        id="EmptyTeamMessage"
        info
        icon="info circle"
        content={wizardMessage}
      />
    );
  };

  return (
    <Modal
      size="tiny"
      id="UpdateTeamModal"
      className={readOnly ? 'read-only' : ''}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      closeOnDimmerClick={false}
      trigger={ConditionalPopupTrigger}
    >
      <Modal.Header>
        {t(`${department}Team`)}&nbsp;{`(${selectedTeam.length})`}
        <Icon
          id="CloseButton"
          name="close"
          link
          fitted
          floated="right"
          onClick={() => setOpen(false)}
        />
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          {selectedTeam.length === 0 ? (
            showEmptyMessage()
          ) : (
            <Segment.Group raised>
              {selectedTeam.map((user) => (
                <Segment id="UserSegment" key={user}>
                  <Item
                    id="UserItem"
                    header={
                      options?.find((option) => option.value === user)?.text ||
                      user
                    }
                    meta={
                      options?.find((option) => option.value === user)?.email
                    }
                  />
                  {!readOnly && (
                    <Icon
                      id="RemoveButton"
                      name="trash alternate outline"
                      color="red"
                      link
                      size="large"
                      onClick={() => {
                        removeUser(user);
                      }}
                    />
                  )}
                </Segment>
              ))}
            </Segment.Group>
          )}
        </Modal.Description>
      </Modal.Content>
      {!readOnly && (
        <>
          <Modal.Content className="readonly">
            <Dropdown
              key={selectedTeam.join(',')}
              id="AddContactDropdown"
              placeholder={t('modal.dropdownPlaceholder')}
              fluid
              search
              selection
              options={options?.filter(
                (option) => !selectedTeam.includes(option.value as string)
              )}
              onChange={(_, { value }) => {
                addUser(value as string);
              }}
              selectOnBlur={false}
            />
          </Modal.Content>
          <Modal.Actions style={{ textAlign: 'center' }}>
            <Button
              primary
              disabled={!validChange}
              id="SaveButton"
              content={t('apply', {
                ns: 'buttons',
              })}
              onClick={() => {
                updateMutation();
              }}
            />
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

export default TeamModal;
