import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Message } from 'semantic-ui-react';

/**
 * Message displayed along with comments after the legal check is completed
 *
 *@returns {JSX.Element} element
 */
const CommentsDisclaimer = (): JSX.Element => {
  const [t] = useTranslation('comments');
  const email = t('legalCheckDoneEmail');
  return (
    <Message id="LegalCheckDoneMessage" info icon>
      <Icon id="LegalCheckDoneMessageIcon" name="info circle" />
      <Message.Content>
        <span>
          <span>{t('legalCheckDoneDisclaimer')}</span>
          <a id="LegalCheckDoneMailto" href={`mailto:${email}`}>
            {email}
          </a>
          .
        </span>
      </Message.Content>
    </Message>
  );
};
export default CommentsDisclaimer;
