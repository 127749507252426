import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Divider,
  Form,
  Grid,
  Icon,
  Input,
  Message,
} from 'semantic-ui-react';
import { FormErrors, FormFields, FormTouches } from './helpers/types';
import validate from './helpers/validateFormHook';

const Field = (
  props: React.ComponentProps<typeof Form.Field> & { name?: keyof FormFields }
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Form.Field {...props} />;
Field.defaultProps = { name: undefined, ...Form.Field.defaultProps };

/**
 * Sign up Form
 *
 * @returns {Form} Form that opens the mailto service with inputed data as a request mail
 */
const SignUpPage = () => {
  const [t] = useTranslation(['login', 'productDetails']);
  const navigate = useNavigate();
  const [signUpInfo, setSignUpInfo] = useState<FormFields>({
    firstname: '',
    lastname: '',
    email: '',
    company: '',
    referenceComp: '',
    contactPax: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touches, setTouches] = useState<FormTouches>({
    firstname: false,
    lastname: false,
    email: false,
    company: false,
    referenceComp: false,
    contactPax: false,
  });
  // Email generating function on Submit
  const supportMailTemplate = {
    receiver: 'aosd@audi.de',
    subject: `${t('templateSubject')} ${signUpInfo.firstname} ${
      signUpInfo.lastname
    }`,
    body: t('templateBody', {
      firstname: signUpInfo.firstname,
      lastname: signUpInfo.lastname,
      email: signUpInfo.email,
      company: signUpInfo.company,
      referenceComp: signUpInfo.referenceComp,
      contactPax: signUpInfo.contactPax,
      url: encodeURI(window.location.href),
      interpolation: { escapeValue: false },
    }),
  };

  const handleSignUp = (e: { target: { name: string; value: string } }) => {
    setSignUpInfo({ ...signUpInfo, [e.target.name]: e.target.value });
    setTouches({ ...touches, [e.target.name]: true });
  };

  useEffect(() => {
    setFormErrors(validate(signUpInfo));
  }, [signUpInfo]);

  // on Button Submit -generate Email
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    window.location.href = `mailto:${
      supportMailTemplate.receiver
    }?subject=${encodeURIComponent(
      supportMailTemplate.subject
    )}&body=${encodeURIComponent(supportMailTemplate.body)}`;
    navigate('/login');
  };

  return (
    <Grid id="SignUpComponent" padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <div className="FormHeader">
            <Icon id="TagIcon" name="tag" size="large" />
            <span>{t('request')}</span>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Divider horizontal />
      {/* FORM STARTS */}
      <Grid.Row centered>
        <Grid.Column width={8}>
          <Form id="SignUpForm">
            <Form.Group widths="equal">
              <Field
                required
                type="text"
                name="firstname"
                control={Input}
                label={t('firstname')}
                placeholder={t('firstname')}
                value={signUpInfo.firstname}
                onChange={handleSignUp}
                error={touches.firstname && formErrors.firstname}
              />

              <Field
                required
                type="text"
                name="lastname"
                control={Input}
                label={t('lastname')}
                placeholder={t('lastname')}
                value={signUpInfo.lastname}
                onChange={handleSignUp}
                error={touches.lastname && formErrors.lastname}
              />
            </Form.Group>
            <Field
              required
              name="email"
              type="email"
              control={Input}
              label={t('email')}
              placeholder={t('emailPlaceholder')}
              value={signUpInfo.email}
              onChange={handleSignUp}
              error={touches.email && formErrors.email}
            />
            <Field
              required
              type="text"
              name="company"
              control={Input}
              label={t('company')}
              placeholder={t('company')}
              value={signUpInfo.company}
              onChange={handleSignUp}
              error={touches.company && formErrors.company}
            />
            {/* REFERENCE */}
            <Field
              required
              type="text"
              name="referenceComp"
              control={Input}
              label={t('department')}
              placeholder={t('departmentPlaceholder')}
              value={signUpInfo.referenceComp}
              onChange={handleSignUp}
              error={touches.referenceComp && formErrors.referenceComp}
            />
            {/* CONTACT PAX */}
            <Field
              required
              type="text"
              name="contactPax"
              control={Input}
              label={t('contactEmail')}
              placeholder={t('contactEmailPlaceholder')}
              value={signUpInfo.contactPax}
              onChange={handleSignUp}
              error={touches.contactPax && formErrors.contactPax}
            />
            <Message
              id="RequestDisclaimer"
              info
              icon="info aosd filled"
              content={t('confirmDisclaimer')}
            />
            <div className="FormControl">
              <Button
                id="BackToLogin"
                icon="long arrow alternate left"
                fluid
                basic
                type="submit"
                content={t('back')}
                onClick={() => navigate('/login')}
              />
              <Button
                id="ConfirmSignUpRequest"
                fluid
                disabled={
                  !signUpInfo.email ||
                  !signUpInfo.firstname ||
                  !signUpInfo.lastname ||
                  !signUpInfo.contactPax ||
                  !signUpInfo.referenceComp ||
                  !signUpInfo.company
                }
                content={t('confirmRequest')}
                primary
                animated="vertical"
                onClick={handleSubmit}
              />
            </div>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SignUpPage;
