import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Header, SemanticWIDTHS } from 'semantic-ui-react';

interface Props {
  brandName: string;
  width: SemanticWIDTHS;
}

/**
 * BrandName component for the Product Details.
 *
 * @param {Props} props - brandName
 * @returns {JSX.Element} BrandName Component
 */
const BrandName = (props: Props) => {
  const { t } = useTranslation(['productDetails', 'users']);
  const { brandName, width } = props;
  return (
    <Grid.Column
      width={width}
      style={{
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <div>
        <Header style={{ marginBottom: 0 }} as="h5" disabled>
          {t('brand')}
        </Header>
        <Header
          textAlign="center"
          style={{ marginTop: 0 }}
          as="h5"
          content={t(`brand.${brandName}`, { ns: 'users' })}
        />
      </div>
    </Grid.Column>
  );
};
export default BrandName;
