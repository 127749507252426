/**
 *@param {string[]} arr1 first array
 *@param {string[]} arr2 first array
 * @returns {boolean} boolean value
 */
const stringArraysAreEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
};
export default stringArraysAreEqual;
