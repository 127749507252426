import React from 'react';
import { Form } from 'semantic-ui-react';
import InfoIconPopup from '../ui/InfoIconPopup';

interface FormFieldLabelProps {
  nameText: string;
  infoText?: string;
  htmlFor?: string;
  optional?: boolean;
}

/**
 * Label with optional "required" attribute and info tooltip on hover
 *
 * @param {string} nameText a string transferring the literal string info form useTranslation
 *  infoText  a string transferring the tooltip information
 *  optional a boolean for required attribute
 * @returns {JSX.Element} A Label
 */
const WizardFormFieldLabel = ({
  nameText,
  infoText = '',
  htmlFor = '',
  optional = false,
}: FormFieldLabelProps): JSX.Element => {
  return (
    <div className="form-field-label">
      <Form.Field required={!optional} inline>
        <label htmlFor={htmlFor}>{nameText}</label>
      </Form.Field>
      <InfoIconPopup infoText={infoText} />
    </div>
  );
};

export default WizardFormFieldLabel;
