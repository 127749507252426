import { FormValues, FormErrors } from './types';

/**
 * Validation for the sign up form
 *
 * @param {FormValues} values Form values
 * @returns {FormErrors}  errors if triggered
 */
const validate = (values: FormValues): FormErrors => {
  const errors: FormErrors = {};

  // Helper functions for common validation logic
  const isEmpty = (field: string) => !field || field.trim() === '';
  const isValidName = (name: string) => /^[a-zA-Z\s]{2,}$/.test(name); // At least 2 letters
  const isValidEmail = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  if (isEmpty(values.firstname)) {
    errors.firstname = 'First name is required!';
  } else if (!isValidName(values.firstname)) {
    errors.firstname =
      'Enter a valid first name! Only letters, min 2 characters.';
  }
  if (isEmpty(values.lastname)) {
    errors.lastname = 'Last name is required!';
  } else if (!isValidName(values.lastname)) {
    errors.lastname =
      'Enter a valid last name! Only letters, min 2 characters.';
  }

  if (isEmpty(values.company)) {
    errors.company = 'Company name is required!';
  }

  if (isEmpty(values.referenceComp)) {
    errors.referenceComp = 'Reference company/department name is required!';
  }

  if (isEmpty(values.contactPax)) {
    errors.contactPax =
      'Contact person @ reference company/department is required!';
  }
  if (isEmpty(values.contactPax)) {
    errors.contactPax = 'Email is required!';
  } else if (!isValidEmail(values.contactPax)) {
    errors.contactPax = 'This is not a valid email format!';
  }
  if (isEmpty(values.email)) {
    errors.email = 'Email is required!';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'This is not a valid email format!';
  }

  return errors;
};

export default validate;
