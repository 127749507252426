import { ApolloError } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Label, Menu, Message, Segment } from 'semantic-ui-react';

type Props = {
  error: ApolloError | undefined;
  loading: boolean;
  source: string;
};

/**
 *@returns {JSX.Element} A message inside of the component to prevent breaking the container or other parts inside it. Will return either Error or Loading Message
 *@param {Props} props props
 */
const ErrorAndLoadingMessage = (props: Props): JSX.Element => {
  const [t] = useTranslation(['comments']);
  const { error, loading, source } = props;
  const [open, setOpen] = useState(false);

  return (
    <Segment id="ErrorAndLoadingMessageCommentsSegment">
      <Menu id="ErrorAndLoadingMessageCommentsSegmentMenu" secondary compact>
        <Menu.Item>
          <Icon
            id="ErrorAndLoadingMessageCommentsSegmentAccordion"
            onClick={() => setOpen(!open)}
            name={open ? 'chevron up' : 'chevron down'}
            color={open ? 'blue' : 'black'}
          />
        </Menu.Item>
        <Menu.Item
          content={t(`${source}`)}
          style={{ fontWeight: 'bold', fontSize: '16px' }}
        />
        <Menu.Item>
          <Label id="CommentsNumberLabel" circular style={{ margin: 0 }}>
            {0}
          </Label>
        </Menu.Item>
      </Menu>
      {/* Error Or Loading Message View */}
      {open && (
        <div className="AllCommentsSegmentCommentsOverview">
          {error && (
            <Message
              id="CommentsSegmentErrorMessage"
              error
              icon="times circle"
              content={t('errorMsg')}
            />
          )}
          {loading && (
            <Message
              id="CommentsSegmentLoadingMessage"
              info
              icon="info aosd filled"
              content={t('loadingMsg')}
            />
          )}{' '}
        </div>
      )}
    </Segment>
  );
};

export default ErrorAndLoadingMessage;
