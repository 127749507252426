import { gql } from '@apollo/client';

/**
 * All general comments for a Version/Product. No referencies
 */
export const ALL_COMMENTS_BY_VERSION = gql`
  query AllCommentsByVersionId($versionId: ID!) {
    Comments(versionId: $versionId, refType: Version) {
      id
      createdAt
      createdBy {
        name
      }
      text
      lastModifiedAt
      lastModifiedBy {
        name
      }
      replied {
        id
      }
      refersTo {
        __typename
        ... on Version {
          id
          name
        }
      }
    }
  }
`;

/**
 * Comments on all dependencies regarding a Version/Product .
 */
export const ALL_DEPENDENCY_COMMENTS = gql`
  query AllDependencyComments($versionId: ID!) {
    Comments(versionId: $versionId, refType: Dependency) {
      id
      createdAt
      createdById
      createdBy {
        name
      }
      text
      lastModifiedAt
      lastModifiedById
      lastModifiedBy {
        name
      }
      replied {
        id
      }
      refersTo {
        __typename
        ... on Dependency {
          id
          componentName
          componentVersion
        }
      }
    }
  }
`;

/**
 * Comments on all requirements/questions regarding a Version/Product .
 */
export const ALL_REQUIREMENTS_COMMENTS = gql`
  query AllRequirementsComments($versionId: ID!) {
    Comments(versionId: $versionId, refType: Requirement) {
      id
      createdAt
      createdBy {
        name
      }
      text
      lastModifiedAt
      lastModifiedBy {
        name
      }
      replied {
        id
      }
      refersTo {
        __typename
        ... on Requirement {
          id
          name_en
          name_de
          description_en
          description_de
        }
      }
    }
  }
`;

/**
 * Comments on all Todos regarding a Version/Product .
 */
export const ALL_TODOS_COMMENTS = gql`
  query AllToDosComments($versionId: ID!) {
    Comments(versionId: $versionId, refType: ToDo) {
      id
      createdAt
      createdBy {
        name
      }
      text
      lastModifiedAt
      lastModifiedBy {
        name
      }
      replied {
        id
      }
      refersTo {
        __typename
        ... on ToDo {
          id
          name_en
          name_de
          description_en
          description_de
        }
      }
    }
  }
`;

/**
 * Comments for particular ToDo of a Version/Product .
 */
export const GET_COMMENTS_FOR_TODO_IN_THIS_VERSION = gql`
  query GetCommentsForToDoInVersion($versionId: ID!, $toDoId: ID!) {
    GetCommentsForToDoInVersion(versionId: $versionId, toDoId: $toDoId) {
      id
      createdAt
      createdBy {
        name
      }
      text
      lastModifiedAt
      lastModifiedBy {
        name
      }
      replied {
        id
      }
    }
  }
`;

/**
 * Comments for particular requirement of a Version/Product .
 */
export const GET_COMMENTS_FOR_REQUIREMENT_IN_THIS_VERSION = gql`
  query GetCommentsForRequirementInVersion($versionId: ID!, $requirementId: ID!) {
    GetCommentsForRequirementInVersion(versionId: $versionId, requirementId: $requirementId) {
      id
      createdAt
      createdBy {
        name
      }
      text
      lastModifiedAt
      lastModifiedBy {
        name
      }
      replied {
        id
      }
    }
  }
`;

/** 
 * Comments on all History Logs regarding a Version/Product .
 */
export const ALL_HISTORY_COMMENTS = gql`
  query AllHistoryComments($versionId: ID!) {
    Comments(versionId: $versionId, refType: ChangeLog) {
      id
      createdAt
      createdById
      createdBy {
        name
      }
      text
      lastModifiedAt
      lastModifiedById
      lastModifiedBy {
        name
      }
      replied {
        id
      }
      refersTo {
      __typename        
        ... on PropertyChange {
        id
        changeType
        category
        createdAt
        createdById
        createdByRole
        createdBy {
          id
          name        
        }
        refId
        property
        prevValue
        newValue
      }
      ... on NodeChange {
        id
        changeType
        category
        createdAt
        createdById
        createdByRole
        createdBy {
          id
          name       
        }
        refNode
      }
      ... on RelationshipChange {
        id
        changeType
        category
        createdAt
        createdById
        createdByRole
        createdBy {
          id
          name
          }
        refNode
        refInfo
        refType
        property
        prevValue
        newValue
      }
      ... on VerificationChange {
        id
        changeType
        category
        createdAt
        createdById
        createdByRole
        createdBy {
          id
          name        
        }
        refNode
        refType
        verificationChecksum
        prevValue
        newValue
      }
      ... on StorageChange {
        id
        changeType
        category
        createdAt
        createdById
        createdByRole
        createdBy {
          id
          name        
        }
        refNode
        fileName
        fileCategory
        }
      }
    }
  }
`;


/**
 * Comments for particular History Log of a Version/Product .
 */
export const GET_COMMENTS_FOR_HISTORY_LOG_IN_THIS_VERSION = gql`
  query GetCommentsForHistoryLogInVersion(
    $versionId: ID!
    $historyLogId: ID!
  ) {
    GetCommentsForHistoryLogInVersion(
      versionId: $versionId
      historyLogId: $historyLogId
    ) {
      id
      createdAt
      createdBy {
        name
      }
      text
      lastModifiedAt
      lastModifiedBy {
        name
      }
      replied {
        id
      }
    }
  }
`;