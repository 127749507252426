import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import CalendarForm from '../../../../ui/CalendarForm';
import TargetDateMessage from './TargetDateMessage';
import { ProductInfoBoxContext } from '../../../../../context/ProductContext';
import {
  ProcessStatus,
  ProcessStatusType,
} from '../../dependencySegment/dependencyList/helper/types';
import InfoIconPopup from '../../../../ui/InfoIconPopup';

/**
 * @returns {JSX.Element}  Target Date Component for Info Box
 */
const TargetDateItem = (): JSX.Element => {
  const { t } = useTranslation('productDetails');

  const { version, versionInput, editing, setPartialVersionInput } = useContext(
    ProductInfoBoxContext
  );
  const targetDate = versionInput.targetDate || version?.targetDate;

  return (
    <Item id="TargetDate" className="targetDate">
      <Item.Content>
        <Item.Meta>
          {t('targetDate')}
          <InfoIconPopup infoText={t('targetDate-info')} />
        </Item.Meta>
        {!editing ? (
          <Item.Header>
            {targetDate
              ? new Date(targetDate).toLocaleDateString(undefined, {
                  timeZone: 'UTC',
                })
              : ''}
            {version?.processStatusAsString !==
              ProcessStatus[ProcessStatusType.COMPLETED] && (
              <TargetDateMessage targetDate={targetDate} />
            )}
          </Item.Header>
        ) : (
          <CalendarForm
            targetDate={targetDate}
            setTargetDate={(date: string) =>
              setPartialVersionInput({ targetDate: date })
            }
            format="short"
          />
        )}
      </Item.Content>
    </Item>
  );
};

export default TargetDateItem;
