import { ApolloError } from '@apollo/client/errors';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps, Grid, Icon } from 'semantic-ui-react';

type PropsType = {
  loading: boolean;
  error?: ApolloError | undefined;
  totalPages: number;
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  compact?: boolean;
};

/**
 * Custom Pagination.
 *
 * @param {PropsType} props takes in loading and error status as
 * well as event function (setActivePage)
 * @returns {JSX.Element} CustomPagination
 */
const CustomPagination = (props: PropsType): JSX.Element => {
  const {
    setActivePage: setArrowPageInput,
    totalPages,
    activePage: arrowPageInput,
    loading,
    error,
    compact = false,
  } = props;
  const [userPageInput, setUserPageInput] = useState<string>(
    String(arrowPageInput)
  );
  const timeoutIDState = useRef(0);
  const [text] = useTranslation('dashboard');

  useEffect(() => {
    if (timeoutIDState.current !== 0) {
      window.clearTimeout(timeoutIDState.current);
    }
    const newPage = Number.parseInt(userPageInput, 10);
    if (!newPage || newPage > totalPages) {
      // includes 0 on purpose
      return;
    }
    timeoutIDState.current = window.setTimeout(
      () => setArrowPageInput(newPage),
      250
    );
  }, [userPageInput, setArrowPageInput, totalPages]);

  useEffect(() => {
    setUserPageInput(String(arrowPageInput));
  }, [arrowPageInput, setUserPageInput]);

  const pageChanged = (selectedPage: string) => {
    setUserPageInput(selectedPage);
  };

  const showNextPage = () => {
    setArrowPageInput((prevActivePage) => {
      if (prevActivePage < totalPages) {
        return prevActivePage + 1;
      }
      return prevActivePage;
    });
  };

  const showPreviousPage = () => {
    setArrowPageInput((prevActivePage) => {
      if (prevActivePage > 1) {
        return prevActivePage - 1;
      }
      // should not happen, due to disabling of the button
      return prevActivePage;
    });
  };

  const showLastPage = () => {
    setArrowPageInput((prevActivePage) => {
      if (prevActivePage < totalPages) {
        return totalPages;
      }
      return prevActivePage;
    });
  };

  const showFirstPage = () => {
    setArrowPageInput((prevActivePage) => {
      if (prevActivePage >= 1) {
        return 1;
      }
      return prevActivePage;
    });
  };

  const transformToOptions = (pages: number): DropdownItemProps[] => {
    return Array(pages)
      .fill(null)
      .map((_, i) => {
        return { key: i + 1, value: i + 1, text: `${i + 1}` };
      });
  };

  const options = transformToOptions(totalPages);

  return (
    <Grid columns="equal" id="PaginationPanel">
      <Grid.Column
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: '0.5rem',
          paddingInline: 0,
        }}
      >
        {!compact && (
          <Icon
            id="CustomPagination.LeftDouble"
            name="angle double left"
            aria-label="first page"
            link
            size="large"
            disabled={arrowPageInput <= 1 || loading || !!error}
            onClick={showFirstPage}
          />
        )}
        <Icon
          id="CustomPagination.Left"
          name="angle left"
          aria-label="previous page"
          link
          size="large"
          disabled={arrowPageInput <= 1 || loading || !!error}
          onClick={showPreviousPage}
        />
      </Grid.Column>
      {/* TODO: Inline-Styling überall raus - Refactoring */}
      <Grid.Column
        width={compact ? 10 : 7}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
          paddingInline: 0,
          zIndex: 600,
        }}
      >
        <p
          style={{ textAlign: 'center', margin: '0.5em' }}
          id="ProductsListPages"
        >
          {text('pageCounter', {
            currentPage: arrowPageInput,
            pageAmount: totalPages,
          })}
        </p>
        <Dropdown
          id="ProductListPagesDropdown"
          placeholder={userPageInput.toString()}
          selection
          compact
          icon="angle down"
          value={arrowPageInput}
          options={options}
          disabled={loading || !!error}
          onChange={(_, { value }) => {
            pageChanged(value as string);
          }}
        />
      </Grid.Column>
      <Grid.Column
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '0.5rem',
          paddingInline: 0,
        }}
      >
        <Icon
          id="CustomPagination.Right"
          name="angle right"
          aria-label="next page"
          link
          size="large"
          disabled={arrowPageInput === totalPages || loading || !!error}
          onClick={showNextPage}
        />
        {!compact && (
          <Icon
            id="CustomPagination.RightDouble"
            name="angle double right"
            aria-label="last page"
            link
            size="large"
            disabled={arrowPageInput === totalPages || loading || !!error}
            onClick={showLastPage}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export default CustomPagination;
