import { SemanticWIDTHS } from 'semantic-ui-react';
import { Role } from '../../../../graphql/generated/graphql';

/**
 * enum containing the possible list types
 */
export enum ListType {
  PRODUCTSLIST,
  FOSSDEPENDENCYLIST,
  PRODUCTVERSIONDEPENDENCYLIST,
}

/**
 * enum containing sorting options
 */
export enum Sorting {
  UNSORTED,
  ASC,
  DESC,
}

/**
 * array type combination of columns
 */
export type Columns = Array<Column>;

/**
 * column type containing all possible types needed for a single column
 */
export type Column = {
  name: string;
  shown: boolean;
  active: boolean;
  width: SemanticWIDTHS;
  minWidth?: string;
  permitted: boolean;
  sorterInfo: {
    sortable: boolean;
    sorting: Sorting;
  };
  filterInfo: {
    filterable: boolean;
    filtering: { [K: string]: boolean };
    labeled?: boolean;
  };
};

const isPermitted = (role: Role, columnName: string): boolean => {
  if (
    [Role.legal, Role.brandlegal, Role.externlegal, Role.rulesetlegal].includes(
      role
    )
  ) {
    return [
      'priority',
      'resolverStatus',
      'targetDate',
      'projectID',
      'name',
      'todos',
      'projectStatus',
      'legal',
      'comments',
      'supplierParticipation',
    ].includes(columnName);
  }
  if ([Role.oso, Role.ososervices].includes(role)) {
    return [
      'priority',
      'name',
      'projectStatus',
      'projectID',
      'targetDate',
      'completeness',
      'resolverStatus',
      'todos',
      'supplierParticipation',
      'comments',
      'oso',
      'delete',
      'legal',
    ].includes(columnName);
  }
  if ([Role.developer].includes(role)) {
    return [
      'projectStatus',
      'projectID',
      'name',
      'targetDate',
      'resolverStatus',
      'requestor',
      'comments',
      'delete',
    ].includes(columnName);
  }
  if ([Role.supplier].includes(role)) {
    return [
      'projectStatus',
      'projectID',
      'name',
      'targetDate',
      'resolverStatus',
      'requestor',
      'comments',
    ].includes(columnName);
  }
  return false;
};

const columnWidth = (role: Role, columnName: string): SemanticWIDTHS => {
  if (
    [Role.legal, Role.brandlegal, Role.externlegal, Role.rulesetlegal].includes(
      role
    )
  ) {
    if (
      [
        'priority',
        'projectID',
        'comments',
        'resolverStatus',
        'legal',
        'supplierParticipation',
      ].includes(columnName)
    )
      return 1;
    if (['name', 'targetDate', 'projectStatus', 'todos'].includes(columnName))
      return 2;
    if (['requestor'].includes(columnName)) return 2;
    return 1;
  }
  if ([Role.oso, Role.ososervices].includes(role)) {
    if (
      [
        'priority',
        'projectID',
        'resolverStatus',
        'completeness',
        'comments',
        'oso',
      ].includes(columnName)
    )
      return 1;
    if (
      [
        'name',
        'todos',
        'projectStatus',
        'supplierParticipation',
        'targetDate',
      ].includes(columnName)
    )
      return 2;
    return 1;
  }
  if ([Role.developer].includes(role)) {
    if (['projectID', 'comments', 'delete'].includes(columnName)) return 1;
    if (['projectStatus', 'resolverStatus', 'targetDate'].includes(columnName))
      return 2;
    if (['requestor'].includes(columnName)) return 2;
    if (['name'].includes(columnName)) return 4;
    return 1;
  }
  if ([Role.supplier].includes(role)) {
    if (['name'].includes(columnName)) return 2;
  }
  return 1;
};

/**
 * @param {Role} role of user
 * @returns {Columns} array of columns
 * priority, projectStatus, resolverStatus, name, targetDate,
 * distribution, projectID, todos, legal, comments
 */
export const getDefaultColumns = (role: Role): Columns => {
  const allColumns: Columns = [
    {
      name: 'priority',
      shown: role !== Role.oso,
      active: false,
      width: columnWidth(role, 'priority'),
      minWidth: '4em',
      permitted: isPermitted(role, 'priority'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'projectStatus',
      shown: true,
      active: false,
      width: columnWidth(role, 'projectStatus'),
      minWidth: '7em',
      permitted: isPermitted(role, 'projectStatus'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: true,
        filtering: {
          INCREATION: false,
          INPROGRESS: false,
          SUPPLIERINPUT: false,
          OSOREQUESTED: false,
          BRANDOSO: false,
          LEGALCHECK: false,
          REWORK: false,
          LEGALCHECKDONE: false,
          SUPPLIERTODOS: false,
          SUPPLIERREPORT: false,
          REPORT: false,
          COMPLETED: false,
        },
      },
    },
    {
      name: 'resolverStatus',
      shown: true,
      active: false,
      width: columnWidth(role, 'resolverStatus'),
      minWidth: '8em',
      permitted: isPermitted(role, 'resolverStatus'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'name',
      shown: true,
      active: false,
      width: columnWidth(role, 'name'),
      minWidth: '8em',
      permitted: isPermitted(role, 'name'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'targetDate',
      shown: true,
      active: false,
      width: columnWidth(role, 'targetDate'),
      minWidth: '7em',
      permitted: isPermitted(role, 'targetDate'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    // {
    //   name: 'completeness',
    //   shown: false, // hidden because of "AOSD-1857-datenmodell-spalte-completeness-in-dashboard-ausblenden"
    //   active: false,
    //   width: columnWidth(role, 'completeness'),
    //   permitted: isPermitted(role, 'completeness'),
    //   sorterInfo: {
    //     sortable: false,
    //     sorting: Sorting.UNSORTED,
    //   },
    //   filterInfo: {
    //     filterable: true,
    //     filtering: {
    //       '1': false,
    //       '2': false,
    //       '3': false,
    //       '4': false,
    //       '5': false,
    //       '6': false,
    //       '7': false,
    //       '8': false,
    //       '9': false,
    //     },
    //   },
    // },
    {
      name: 'projectID',
      shown: true,
      active: false,
      width: columnWidth(role, 'projectID'),
      minWidth: '8em',
      permitted: isPermitted(role, 'projectID'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'todos',
      shown: true,
      active: false,
      width: columnWidth(role, 'todos'),
      minWidth: '8.5em',
      permitted: isPermitted(role, 'todos'),
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'supplierParticipation',
      shown: true,
      active: false,
      width: columnWidth(role, 'supplierParticipation'),
      minWidth: '5em',
      permitted: isPermitted(role, 'supplierParticipation'),
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'comments',
      shown: true,
      active: false,
      width: columnWidth(role, 'comments'),
      minWidth: '8em',
      permitted: isPermitted(role, 'comments'),
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'legal',
      shown: true,
      active: false,
      width: columnWidth(role, 'legal'),
      minWidth: '5em',
      permitted: isPermitted(role, 'legal'),
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: true,
        filtering: {},
      },
    },
    {
      name: 'oso',
      shown: true,
      active: false,
      width: columnWidth(role, 'oso'),
      permitted: isPermitted(role, 'oso'),
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: true,
        filtering: {},
      },
    },
    {
      name: 'requestor',
      shown: true,
      active: false,
      width: columnWidth(role, 'requestor'),
      minWidth: '12em',
      permitted: isPermitted(role, 'requestor'),
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: true,
        filtering: {},
      },
    },
    {
      name: 'delete',
      shown: true,
      active: false,
      width: columnWidth(role, 'delete'),
      permitted: isPermitted(role, 'delete'),
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
  ];
  return allColumns;
};
