/* eslint-disable import/prefer-default-export */
import { makeVar } from '@apollo/client';
import { ToDoType } from './types';

/**
 * Filter out the selected ToDos
 *
 * @param {ToDoType} toDos - The ToDos to filter
 * @returns {ToDoType[]} - The selected ToDos
 */
export const selectedToDos = (toDos: ToDoType[]) => {
  return toDos.filter(
    (toDo) =>
      toDo.selectionType === 'userselected' ||
      toDo.selectionType === 'autoselected'
  );
};
/**
 * Apollo Reactive Variable to refetch GET_TODOS after comments where added to ToDos from AllCommentsSegment part
 */
export const shouldRefetchTodosVar = makeVar<string[]>([]);

/**
 * Apollo Reactive Variable to manage the state of a task group
 */
export const siblingTasksVar = makeVar<ToDoType[]>([]);

/**
 * Updates the siblingTasksVar using the provided updater function.
 * Ensure each update is based on the latest state of the var.
 *
 * @param {function(ToDoType[]): ToDoType[]} updater - A function that receives the current array of tasks
 * and returns the updated array.
 */
export const updateSiblingTasksVar = (
  updater: (current: ToDoType[]) => ToDoType[]
) => {
  const currentTasks = siblingTasksVar();
  const updatedTasks = updater(currentTasks);
  siblingTasksVar(updatedTasks);
};
