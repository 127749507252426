import { useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Menu, Message, Segment } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import { PRODUCT_TRANSFER_DATA } from '../../../../graphql/queries/VersionQuerys';
import ProductDependencySegment from './ProductDependencySegment';
import GroupTakeoverElement from './groupTakeover/GroupTakeoverElement';
import GenericClosedSegmentSkeleton from '../skeletons/GenericClosedSegmentSkeleton';
import InfoIconPopup from '../../../ui/InfoIconPopup';

type Props = {
  show?: boolean;
  readOnly?: boolean;
};

/**
 * Segment containing the either dependency list or group takeover element.
 *
 * @param {Props} show  & readOnly
 * @returns {JSX.Element} Segment containing the either dependency list or group takeover element.
 */
const TakeoverOrUserProductDependencySegment = ({
  show = true,
  readOnly,
}: Props): JSX.Element => {
  const [text] = useTranslation('productDetails');
  const { t } = useTranslation('productDetails');
  const [open, setOpen] = useState(show);
  const { versionId } = useContext(ProductDetailsContext);

  // const [hasAccess, setHasAccess] = useState(false);

  const { data, loading, error } = useQuery(PRODUCT_TRANSFER_DATA, {
    variables: { id: versionId },
  });
  if (loading) {
    return (
      <GenericClosedSegmentSkeleton
        image
        height={105}
        lines={3}
        labels="firstOnly"
        linesLength="full"
      />
    );
  }
  if (error) {
    return (
      <Message
        id="TakeoverOrUserProductDependencySegmentError"
        error
        icon="times circle"
        content={`Error ${error.message}`}
      />
    );
  }

  if (data) {
    const { externalId, groupCompany, contactPerson } =
      data.Version.product.transfer;
    const takeover =
      externalId !== null || groupCompany !== null || contactPerson !== null;
    if (takeover) {
      return (
        <Segment
          raised
          id={`TakeoverOrUserProductDependencySegment${versionId}`}
        >
          <Menu
            id="TakeoverOrUserProductDependencySegmentMenuTab"
            secondary
            compact
          >
            <Menu.Item>
              <Icon
                id="TakeoverOrUserProductDependencySegmentAccordion"
                name={open ? 'chevron up' : 'chevron down'}
                color={open ? 'blue' : 'black'}
                onClick={() =>
                  setOpen((prevOpen) => {
                    return !prevOpen;
                  })
                }
              />
            </Menu.Item>

            <Menu.Item style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {text('groupTakeover')}
              <InfoIconPopup infoText={t('groupTakeoverInfo')} />
            </Menu.Item>
          </Menu>
          {open && (
            <div
              style={{
                margin: '1rem 1rem 0 1rem',
              }}
            >
              <GroupTakeoverElement
                versionProduct={data.Version.product}
                version={data.Version}
                readOnly={readOnly}
              />
            </div>
          )}
        </Segment>
      );
    }
    //  if no takeover than return : DependencySegment
    return <ProductDependencySegment show={show} />;
  }
  return <div />;
};

export default TakeoverOrUserProductDependencySegment;
