import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Message, Modal } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../../../context/ProductContext';
import {
  FINISH_LEGAL_CHECK_POSSIBLE,
  LEGAL_CHECK_POSSIBLE,
  SUPPLIER_INPUT_DONE_POSSIBLE,
} from '../../../../../../../graphql/queries/VersionQuerys';
import { REMOVE_FROM_ARTIFACTORY } from '../../../../../../../graphql/mutations/ArtifactoryMutations';
import { BROWSE_ARTIFACTORY } from '../../../../../../../graphql/queries/ArtifactoryQuerys';

type Props = {
  url: string;
  fileType: string;
  filename: string;
};

/**
 *
 *@returns {JSX.Element} Delete Dependency and all its transitive dependencies
 *@param {string} dependencyId - id of the dependency to be deleted
 */
const DeleteFileElement = ({ url, fileType, filename }: Props): JSX.Element => {
  const [t] = useTranslation('productStorage');
  const [open, setOpen] = useState(false);
  const { versionId, authorizations } = useContext(ProductDetailsContext);

  const canDelete = authorizations.includes('RemoveFromArtifactory');

  const [removeFromArtifactory] = useMutation(REMOVE_FROM_ARTIFACTORY, {
    refetchQueries: [
      BROWSE_ARTIFACTORY,
      LEGAL_CHECK_POSSIBLE,
      FINISH_LEGAL_CHECK_POSSIBLE,
      SUPPLIER_INPUT_DONE_POSSIBLE,
    ],
    variables: { versionId, url, fileType },
  });

  const resetAndClose = () => {
    setOpen(false);
  };

  const saveChanges = () => {
    removeFromArtifactory();
    setOpen(false);
  };

  return (
    <Modal
      id="DeleteFileModal"
      size="small"
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      open={open}
      onOpen={() => setOpen(true)}
      trigger={
        <Icon
          id="DeleteFileIcon"
          className={fileType === 'import' ? 'hidden' : ''}
          name="trash alternate outline"
          color="red"
          link
          disabled={!url || !canDelete}
          onClick={() => setOpen(open)}
        />
      }
    >
      <Modal.Header id="DeleteFileHeader">
        {t('table.deleteFileModal.titleFile', {
          fileName: filename,
        })}
        <Icon
          id="DeleteFileCloseButton"
          name="close"
          link
          onClick={resetAndClose}
        />
      </Modal.Header>
      <Modal.Content>
        <div>
          <Message
            id="Message"
            icon="exclamation triangle"
            warning
            content={t('table.deleteFileModal.warningMsg')}
          />
          <p id="p">{t('table.deleteFileModal.content')}</p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="DeleteFileCancel"
          secondary
          content={t('table.deleteFileModal.cancelBtn')}
          onClick={() => setOpen(false)}
        />
        <Button
          id="DeleteFileSaveChanges"
          color="red"
          content={t('table.deleteFileModal.deleteBtn')}
          onClick={() => saveChanges()}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteFileElement;
