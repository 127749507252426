/**
 * Maximum number for allowed characters in product description
 */
export const PRODUCT_DESCRIPTION_CHAR_LIMIT = 9510;

/**
 * Maximum number for allowed characters in storage description
 */
export const STORAGE_DESCRIPTION_CHAR_LIMIT = 280;

/**
 * Maximum number for allowed characters in a comment
 */
export const COMMENT_CHAR_LIMIT = Infinity;
