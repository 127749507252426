import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Popup, Segment } from 'semantic-ui-react';
import { StateChangeStep } from '../../../../graphql/generated/graphql';

interface StepsToProceedProps {
  steps: StateChangeStep[];
  duplicateSteps: boolean;
  disabled: boolean;
}

/**
 * Step component for rendering reasons preventing state change with dynamic button control.
 *
 * @param {StepsToProceedProps} props { steps, newSteps }
 * @returns {JSX.Element} The StepComponent rendering reasons.
 */
const StepsToProceed: React.FC<StepsToProceedProps> = (
  props: StepsToProceedProps
): JSX.Element => {
  const { steps, duplicateSteps, disabled } = props;
  const { t } = useTranslation('productDetails');

  return (
    <Segment id="StepsToProceed" basic>
      <div className={`steps-container ${disabled ? 'disabled' : ''}`}>
        {duplicateSteps && (
          <span
            className={`stepLine${
              steps.every((step) => step.fulfilled) ? ' fulfilled' : ''
            }`}
          />
        )}
        {!duplicateSteps &&
          steps.map((step, index) => (
            <Popup
              id="StepsToProceedPopup"
              key={step.step}
              content={t(
                `stateChangeSteps.${
                  step.fulfilled ? 'fulfilled' : 'unfulfilled'
                }.${step.step}`
              )}
              trigger={
                <span
                  className={`step ${
                    step.fulfilled ? 'fulfilled' : 'deactivated'
                  }`}
                >
                  {step.fulfilled ? (
                    <Icon
                      id="StepsToProceedIcon"
                      name="checkmark"
                      size="small"
                    />
                  ) : (
                    index + 1
                  )}
                </span>
              }
            />
          ))}
      </div>
    </Segment>
  );
};

export default StepsToProceed;
