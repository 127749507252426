import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Item } from 'semantic-ui-react';
import { ProductInfoBoxContext } from '../../../../../context/ProductContext';
import { ProductType } from '../../../../../graphql/generated/graphql';
import InfoIconPopup from '../../../../ui/InfoIconPopup';
import { mainTypes, subTypeCar, subTypeEnterprise } from '../helper/constants';

/**
 * Dropdown to select the product type. Consists of 2 Selections-Main Type & Subtype
 *
 * @returns {JSX.Element} TypeDropdown as an Array of 2 values- Important!
 */
const TypeDropdown = (): JSX.Element => {
  const [t] = useTranslation(['productDetails']);

  const { version, versionInput, setPartialVersionInput } = useContext(
    ProductInfoBoxContext
  );

  const type = versionInput.types || version?.product?.type || [];

  // props are coming as an Array=  ["Main project type", "project subtype"]
  // states are directly in parent component.
  return (
    <>
      <Item.Meta>
        {t('productType')}
        <InfoIconPopup infoText={t('productType-info')} />
      </Item.Meta>
      <Dropdown
        id="DropdownProductTypeMain"
        fluid
        closeOnChange
        selection
        onChange={(_e, { value }) => {
          setPartialVersionInput({ types: [value, ''] as ProductType[] }); // needed to secure validation that Subtype Input is selected-disables the Save Details Button
        }}
        value={type[0]}
        options={mainTypes}
      />
      <Item.Meta>
        {t('productSubType')}
        <InfoIconPopup infoText={t('productSubType-info')} />
      </Item.Meta>

      <Dropdown
        id="DropdownProductTypeSubType"
        fluid
        closeOnChange
        disabled={!type[0]}
        selection
        onChange={(_e, { value }) => {
          setPartialVersionInput({
            types: [type[0], value] as ProductType[],
          });
        }}
        value={type[1]}
        options={type[0] === ProductType.Car ? subTypeCar : subTypeEnterprise}
      />
    </>
  );
};

export default TypeDropdown;
