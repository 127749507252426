import { useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Menu, Popup, Segment } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  DependenciesByVersionIdAmountQuery,
  DependenciesByVersionIdAmountQueryVariables,
} from '../../../../graphql/generated/graphql';
import DependencyList from './dependencyList/fossDependencyList/DependencyList';
import PVDependencyList from './dependencyList/productVersionDependencyList/PVDependencyList';
import EditProductPartsModal from './editProductParts/EditProductPartsModal';
import ImportModalButton from './ImportModalButton';
import { DEPENDENCIES_BY_VERSION_ID_AMOUNT } from '../../../../graphql/queries/DependencyQuerys';

type Props = {
  show: boolean;
};

/**
 * Collapsible segment containing the dependency list
 *
 * @param {object} props {show: boolean}
 * @returns {JSX.Element} Collapsible segment containing the dependency list
 */
const ProductDependencySegment = (props: Props): JSX.Element => {
  const { show = true } = props;
  const [text, i18n] = useTranslation('productDetails');
  const i18nLang = i18n.language;
  const [open, setOpen] = useState(show);
  const { versionId, isProject, authorizations, processStatusAsString } =
    useContext(ProductDetailsContext);

  const {
    data: dataAmount,
    error: errorAmount,
    loading: loadingAmount,
    refetch: refetchAmount,
  } = useQuery<
    DependenciesByVersionIdAmountQuery,
    DependenciesByVersionIdAmountQueryVariables
  >(DEPENDENCIES_BY_VERSION_ID_AMOUNT, {
    variables: { versionId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const dependenciesAmount: number =
    dataAmount?.DependenciesByVersionIdAmount || 0;

  return (
    <Segment raised id={`DependencySegment${versionId}`}>
      <Menu id="ProductDependencyMenuTab" secondary compact>
        <Menu.Item>
          <Icon
            id="ProductDependencyAccordion"
            name={open ? 'chevron up' : 'chevron down'}
            color={open ? 'blue' : 'black'}
            onClick={() =>
              setOpen((prevOpen) => {
                return !prevOpen;
              })
            }
          />
        </Menu.Item>
        <Menu.Item className="ViewSegmentsMenuTabTitleItem">
          {text('dependencyList.productDependencyList')}
          <span>
            <Icon
              size="large"
              name="question circle"
              id={`ProductDependencyGuideIcon-${i18nLang}-${
                isProject ? 'fossDep' : 'pvDep'
              }`}
              style={{ marginLeft: '0.5rem', display: 'none' }} // TODO: remove display none when guide is ready
            />
          </span>
        </Menu.Item>
        {isProject ? (
          <Popup
            id="Popup"
            disabled={authorizations.includes('UpdateProductParts')}
            position="top center"
            hideOnScroll
            content={text(
              `dependencyList.editPartsModal.cannotUpdate.${
                processStatusAsString === 'COMPLETED'
              }` // if not completed than shows not authorised msg
            )}
            trigger={
              <div className="ProductDependencyMenuModals">
                <Menu.Item>
                  <EditProductPartsModal
                    projectVersionId={versionId}
                    updatePartsEnabled={authorizations.includes(
                      'UpdateProductParts'
                    )}
                  />
                </Menu.Item>
              </div>
            }
          />
        ) : (
          <div className="ProductDependencyMenuModals">
            <Menu.Item>
              <ImportModalButton />
            </Menu.Item>
          </div>
        )}
      </Menu>
      {open &&
        (isProject ? (
          <PVDependencyList />
        ) : (
          <DependencyList
            dependenciesAmount={dependenciesAmount}
            errorAmount={errorAmount}
            loadingAmount={loadingAmount}
            refetchAmount={refetchAmount}
          />
        ))}
    </Segment>
  );
};

export default ProductDependencySegment;
