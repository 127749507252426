import React, { useContext } from 'react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { Role } from '../../../../graphql/generated/graphql';
import { ToDoType } from './helpers/types';
import ToDo from './ToDo';

interface ToDoProps {
  todos: ToDoType[];
  parentTodos: ToDoType[];
}

/**
 * List of all Todos
 *
 *@returns {JSX.Element} The ToDos List in the chosen product
 *@param {ToDoType[]} parentTodos array of parent todos
 */
const ToDosList = ({ todos, parentTodos }: ToDoProps): JSX.Element => {
  const { versionId, authorizations } = useContext(ProductDetailsContext);
  const { selectedRole } = useContext(SelectedRoleContext);
  const role: Role = selectedRole;

  const hasPermissionToSelect =
    authorizations?.includes('SelectToDo') &&
    (role?.includes('developer') || role?.includes('supplier'));

  return (
    <>
      {parentTodos.map((todo) => {
        return role.includes('supplier') && todo.toDo.internal ? null : (
          <ToDo
            key={todo.toDo.id}
            versionId={versionId}
            todo={todo}
            todos={todos}
            selectDisabled={!hasPermissionToSelect}
          />
        );
      })}
    </>
  );
};

export default ToDosList;
