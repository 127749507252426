import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Header, Message, Segment } from 'semantic-ui-react';

/**
 * Login
 * Page with Form Input for User email and password
 *
 * @returns {JSX.Element} Maintenance Page
 */
const Maintenance = (): JSX.Element => {
  const [text] = useTranslation('maintenance');
  const email = text('email');
  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 650 }}>
        <Segment id="MaintenanceBox">
          <Header as="h2" color="blue" textAlign="center">
            {text('header')}
          </Header>
          <Message
            id="MaintenanceModeMessage"
            warning
            icon="exclamation triangle"
            content={
              <span>
                <span>{text('description_1')}</span>
                <br />
                <br />
                <span>{text('description_2')}</span>
                <a id="MaintenanceEmail" href={`mailto:${email}`}>
                  {email}
                </a>
                <span>{text('description_3')}</span>
              </span>
            }
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};
export default Maintenance;
