import React, { useContext, useMemo } from 'react';
import { List } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { Role } from '../../../../graphql/generated/graphql';
import { ToDoType } from './helpers/types';
import ToDo from './ToDo';

interface ToDoProps {
  todos: ToDoType[];
  parentTodos: ToDoType[];
}

/**
 * List of all Todos
 *
 *@returns {JSX.Element} The ToDos List in the chosen product
 *@param {ToDoType[]} parentTodos array of parent todos
 */
const ToDosList = ({ todos, parentTodos }: ToDoProps): JSX.Element => {
  const { versionId, authorizations } = useContext(ProductDetailsContext);
  const { selectedRole } = useContext(SelectedRoleContext);
  const role: Role = selectedRole;

  // list all active toDos WITH their tasks for the 'breakingpoint'
  const activeTodos: string[] = parentTodos.flatMap((todo) => [
    todo.toDo.id,
    ...(todo.toDo.tasks?.map((task) => task.id) || []),
  ]);

  const breakpoint = Math.ceil(activeTodos.length / 2);
  const beforeBreak = activeTodos.slice(0, breakpoint);
  const afterBreak = activeTodos.slice(breakpoint);
  const firstColumn = useMemo(
    () => parentTodos.filter((td) => beforeBreak.includes(td.toDo.id)),
    [parentTodos, beforeBreak]
  );
  const secondColumn = useMemo(
    () => parentTodos.filter((td) => afterBreak.includes(td.toDo.id)),
    [parentTodos, afterBreak]
  );
  const hasPermissionToSelect =
    authorizations?.includes('SelectToDo') &&
    (role?.includes('developer') || role?.includes('supplier'));

  const renderColumn = (
    column: ToDoType[],
    vId: string | undefined,
    topLevelTodos: ToDoType[],
    allTodos: ToDoType[],
    identifier: number
  ) => (
    <List.Item>
      <List.Content>
        <List id={`EditableToDosListOfToDos_${identifier}`}>
          {column.map((todo) => {
            return role.includes('supplier') && todo.toDo.internal ? null : (
              <ToDo
                key={todo.toDo.id}
                versionId={vId}
                todo={todo}
                toDosIndex={topLevelTodos.indexOf(todo)}
                todos={allTodos}
                selectDisabled={!hasPermissionToSelect}
              />
            );
          })}
        </List>
      </List.Content>
    </List.Item>
  );

  return (
    <div>
      {parentTodos.length > 0 ? (
        <List id="EditableToDosList" horizontal relaxed verticalAlign="top">
          {renderColumn(firstColumn, versionId, parentTodos, todos, 1)}
          {renderColumn(secondColumn, versionId, parentTodos, todos, 2)}
        </List>
      ) : null}
    </div>
  );
};

export default ToDosList;
