import {
  DependencySubComponentsRelationship,
  Subcomponent,
} from '../../../../../../../graphql/generated/graphql';
import {
  Columns,
  Sorting,
} from '../../../../../productsList/listComponents/ProductListTypes';

// TODO: remove/rework types from here till marked position
/* *************************************************** */

/**
 *
 */
export type LicenseElement = {
  subComponents: Array<Subcomponent>;
  subComponentEdges: Array<DependencySubComponentsRelationship> | undefined;
};

/**
 *
 */
export type LicenseDataProps = {
  subcomponentId: string;
  licenseId: string;
  name: string;
  spdxId: string;
};

/* ***************************************************** */

/**
 * @returns {Columns} array of columns
 */
const getDefaultColumns = (): Columns => {
  const allColumns: Columns = [
    {
      name: 'resolverStatus',
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {
          UNKNOWN: false,
          OK: false,
          OK_NOT_VERIFIED: false,
          OPEN_REQ: false,
          CONFLICT: false,
        },
      },
    },
    {
      name: 'name',
      shown: true,
      active: false,
      width: 3,
      permitted: true,
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'componentVersion',
      shown: true,
      active: false,
      width: 1,
      permitted: true,
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'linking',
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: true,
        // TODO: probably should write a query to get these names instead of hardcoding them
        filtering: {
          static: false,
          dynamic: false,
          system_call: false,
          process_call: false,
          build_tool: false,
        },
      },
    },
    {
      name: 'modified',
      shown: true,
      active: false,
      width: 1,
      permitted: true,
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'linkedTo',
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'comments',
      shown: true,
      active: false,
      width: 1,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'licenses',
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: true,
        filtering: {},
      },
    },
    {
      name: 'copyleft',
      shown: true,
      active: false,
      width: 1,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: true,
        filtering: {
          copyleft: false,
          no_copyleft: false,
        },
        labeled: true, // used to indicate if there is a language variable for the filter that should be used.
      },
    },
    {
      name: 'delete',
      shown: true,
      active: false,
      width: 1,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
  ];
  return allColumns;
};

export default getDefaultColumns;

/**
 * Change event for adding new coprights to ProductLicenseDetails
 */
export interface ChangeEventProps {
  value: string;
}
