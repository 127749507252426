import { TFunction } from 'i18next';
import { Maybe } from '../../../../graphql/generated/graphql';
import { DistributionNamesLocalized } from '../../../wizard/WizardConstants';
import { convertISOtoLocalDate } from '../../productDetails/helpers/logics';

/**
 *
 * @description helper function to 'smartly' truncate text based on both characters and words.
 * @param {string} text input text
 * @param {number} maxChars limit amount of characters.
 * @param {number} maxWords limit amount of words.
 * @returns {string} truncated text
 */
export const smartTruncate = (
  text: string | undefined,
  maxChars: number,
  maxWords: number
): string => {
  if (!text) return '';

  // First cut the text to the maximum number of characters
  const trimmedText =
    text.length > maxChars ? text.substring(0, maxChars) : text;

  // Then trim by words, making sure not to cut off in the middle of a word
  const words = trimmedText.split(' ').slice(0, maxWords);
  const finalText = words.join(' ');

  // Check if we need to add ellipsis
  return finalText.length < text.length ? `${finalText}...` : finalText;
};

interface AdjustedValues {
  prevValue: Maybe<string>;
  newValue: Maybe<string>;
}

/**
 *
 * @description hook that adjust values for each history property.
 * @param {string} property property which is being changed
 * @param {string} prevValue prevValue
 * @param {string} newValue newValue
 * @param {TFunction} text translate function
 * @param {string} locale locale
 * @returns {AdjustedValues} adjusted valueas
 */
export const getAdjustedValues = (
  property: Maybe<string> | undefined,
  prevValue: Maybe<string> | undefined,
  newValue: Maybe<string> | undefined,
  text: TFunction,
  locale: string
): AdjustedValues => {
  const distributionNamesLocalized = DistributionNamesLocalized();
  switch (property) {
    case 'selectedDistribution':
      return {
        prevValue:
          Object.entries(distributionNamesLocalized).find(
            (dist) => dist[0] === prevValue
          )?.[1] ?? null,
        newValue:
          Object.entries(distributionNamesLocalized).find(
            (dist) => dist[0] === newValue
          )?.[1] ?? null,
      };
    case 'toDo':
    case 'highPriorityRequest':
    case 'protection':
      return {
        prevValue: text(`tableBody.values.${prevValue}`),
        newValue: text(`tableBody.values.${newValue}`),
      };
    case 'requiresWrittenOffer':
      return {
        prevValue: text(
          `tableBody.values.${prevValue === null ? 'default' : prevValue}`
        ),
        newValue: text(
          `tableBody.values.${newValue === null ? 'default' : newValue}`
        ),
      };
    case 'requirement':
      return {
        prevValue: text(`tableBody.values.${prevValue}`),
        newValue: text(`tableBody.values.${newValue}`),
      };
    case 'description':
      return {
        prevValue: smartTruncate(prevValue ?? '', 25, 5) || '-',
        newValue: smartTruncate(newValue ?? '', 25, 5) || '-',
      };
    case 'status':
      return {
        prevValue: text(`dashboard:filterPills.${prevValue}`),
        newValue: text(`dashboard:filterPills.${newValue}`),
      };
    case 'targetDate':
      return {
        prevValue: convertISOtoLocalDate(prevValue ?? '', locale),
        newValue: convertISOtoLocalDate(newValue ?? '', locale),
      };
    case 'standardSoftware':
      return {
        prevValue: text(
          `productDetails:${
            prevValue === 'true' ? 'standardSoftware' : 'individualSoftware'
          }`
        ),
        newValue: text(
          `productDetails:${
            newValue === 'true' ? 'standardSoftware' : 'individualSoftware'
          }`
        ),
      };
    case 'transferGroupCompany':
      return {
        prevValue: text(`users:brand.${prevValue}`),
        newValue: text(`users:brand.${newValue}`),
      };
    default:
      return { prevValue: prevValue ?? null, newValue: newValue ?? null };
  }
};
