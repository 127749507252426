import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Icon, Item, Label } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import ProductTagsModal from './ProductTagsModal';
import InfoIconPopup from '../../../ui/InfoIconPopup';

/**
 * Product Tags component.
 *
 * @returns {JSX.Element} ProductTags
 */
const ProductTags = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { versionId, authorizations, tags } = useContext(ProductDetailsContext);
  const [openTagsModal, setOpenTagsModal] = useState(false);

  return (
    <Header.Subheader
      style={{
        marginBottom: '3rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        gap: '0.75rem',
      }}
    >
      {tags && tags.length > 0 && (
        <div className="tag-container">
          {tags.length > 12 && (
            <>
              <div className="gradient-left" />
              <div className="gradient-right" />
            </>
          )}
          <div className="tags">
            {tags?.map((tag) => {
              return (
                <Label
                  id={`${tag}-tag`}
                  key={`${tag}-tag`}
                  className="productTag"
                >
                  {tag}
                </Label>
              );
            })}
          </div>
        </div>
      )}
      {authorizations.includes('UpdateTags') && (
        <>
          {tags && tags?.length > 0 ? (
            <Icon
              id="EditTagsIcon"
              name="pencil"
              link
              color="blue"
              onClick={() => setOpenTagsModal(true)}
            />
          ) : (
            <span>
              <Icon name="add" id="AddNewTagsIcon" color="blue" />
              <Item.Extra
                as="a"
                style={{
                  color: '#2185D0',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                content={t('tagsEmptyAdd')}
                onClick={() => setOpenTagsModal(true)}
              />
              <InfoIconPopup infoText={t('TagsInfo')} />
            </span>
          )}
          <ProductTagsModal
            openTagsModal={openTagsModal}
            setOpenTagsModal={setOpenTagsModal}
            tags={tags}
            versionId={versionId}
          />
        </>
      )}
    </Header.Subheader>
  );
};

export default ProductTags;
