import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  DropdownProps,
  Header,
  Message,
  Label,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  DependencySelection,
  RequirementInfo,
  RequirementType,
  SelectionType,
  useSelectRequirementMutation,
} from '../../../../graphql/generated/graphql';
import { GET_REQUIREMENTS } from '../../../../graphql/queries/RequirementQuerys';
import {
  FINISH_LEGAL_CHECK_POSSIBLE,
  LEGAL_CHECK_POSSIBLE,
  SUPPLIER_INPUT_DONE_POSSIBLE,
} from '../../../../graphql/queries/VersionQuerys';
import AutoExpandingTextarea from '../../../licenses/AutoExpandingTextarea';
import RequirementComponentsList from './RequirementComponentsList';
import useSelectionTypeOpts from './helper/logics';
import { SelectionMode } from './helper/types';

type Props = {
  requirementInfo: RequirementInfo;
  closeModal: () => void;
};

/**
 *@returns {JSX.Element} Modal Tab for Questions-preparation for coming Ticket and its implementation
 *@param {Props} props { requirementInfo }
 */
const RequirementsTab = (props: Props): JSX.Element => {
  const { i18n, t } = useTranslation(['requirements', 'buttons']);
  const { versionId, authorizations, processStatusAsString } = useContext(
    ProductDetailsContext
  );
  const { requirementInfo, closeModal } = props;

  const authorized =
    (requirementInfo?.requirement.type === RequirementType.user &&
      authorizations?.includes('SelectUserRequirements')) ||
    (requirementInfo?.requirement.type === RequirementType.legal &&
      authorizations?.includes('SelectLegalRequirements'));

  const description =
    i18n.language === 'de'
      ? requirementInfo?.requirement.description_de
      : requirementInfo?.requirement.description_en;

  const [showList, setShowList] = useState(false);
  const initialGlobalSelection = requirementInfo?.globalSelection || null;
  const [globalSelection, setGlobalSelection] = useState<SelectionType | null>(
    initialGlobalSelection
  );
  const [individualSelection, setIndividualSelection] = useState(
    requirementInfo?.individuallySelected
  );
  // Actuall dependency selections lifted from GetRequirementDetails query
  const [existingSelections, setExistingSelections] = useState<
    DependencySelection[]
  >([]);
  // Current pending selections in the session
  const [currentSelections, setCurrentSelections] = useState<
    DependencySelection[]
  >([]);

  // Refetch after Mutation
  const refetch = () => {
    if (processStatusAsString === 'LEGALCHECK') {
      return [GET_REQUIREMENTS, FINISH_LEGAL_CHECK_POSSIBLE];
    }
    if (processStatusAsString === 'SUPPLIERINPUT') {
      return [
        GET_REQUIREMENTS,
        SUPPLIER_INPUT_DONE_POSSIBLE,
        LEGAL_CHECK_POSSIBLE,
      ];
    }
    return [GET_REQUIREMENTS, LEGAL_CHECK_POSSIBLE];
  };

  // Checking if the dependencies are equal with same selectionType
  useEffect(() => {
    if (showList && currentSelections.length > 0) {
      const firstSelectionType = currentSelections[0].selectionType || null;
      const allSelectionsAreEqual = currentSelections.every(
        (selection) => selection.selectionType === firstSelectionType
      );
      if (allSelectionsAreEqual) {
        setGlobalSelection(firstSelectionType);
        setIndividualSelection(false);
      } else {
        setGlobalSelection(null);
        setIndividualSelection(true);
      }
    }
  }, [currentSelections, showList]);

  const [selectRequirements] = useSelectRequirementMutation();
  const handleSaveChanges = () => {
    // Filtering just the ones that are updated
    const onlyChangedDependencies = currentSelections.filter((dep) => {
      const original = existingSelections.find(
        (d) => d.dependencyId === dep.dependencyId
      );
      return original && original.selectionType !== dep.selectionType;
    });

    // Mutation beeing called with just changed dependencies
    selectRequirements({
      variables: {
        versionId,
        requirementId: requirementInfo.requirement.id,
        globalSelection,
        dependencySelections: onlyChangedDependencies,
      },
      refetchQueries: refetch,
    });
    closeModal();
  };
  // Button should be disabled if no changes made
  const changes = React.useMemo(() => {
    return (
      globalSelection !== initialGlobalSelection ||
      currentSelections.some((current) => {
        const original = existingSelections.find(
          (existing) => existing.dependencyId === current.dependencyId
        );
        return !original || original.selectionType !== current.selectionType;
      })
    );
  }, [
    globalSelection,
    initialGlobalSelection,
    currentSelections,
    existingSelections,
  ]);

  return (
    <>
      <Modal.Content id="RequirementsTabContent">
        <Segment.Group>
          <Segment
            id="RequirementsDescriptionSegment"
            className="text-area-segment"
          >
            <AutoExpandingTextarea content={description} maxRows={15} />
          </Segment>
          {!authorized && (
            <Message
              id="RequirementsTabWarning"
              warning
              icon="exclamation triangle"
              content={t('readOnly')}
            />
          )}
          <Segment
            id="RequirementsTabAnswerSelection"
            attached={authorized ? undefined : 'bottom'}
          >
            <Header content={t('selectAnswer')} />
            <span>
              <span>
                {t('selectForAllLeftPart')}
                &nbsp;
                <Label
                  id="RequirementsDependencyCount"
                  color="grey"
                  content={requirementInfo?.dependencyCount}
                />
                &nbsp;
                {t('selectForAllRightPart')}
              </span>
              <Dropdown
                id="RequirementsTabVersionSelect"
                options={useSelectionTypeOpts()}
                pointing="top"
                value={
                  individualSelection
                    ? SelectionMode.INDIVIDUAL
                    : globalSelection || undefined
                }
                disabled={!authorized}
                inline
                placeholder={t('selectionBar')}
                closeOnChange
                onChange={(e, data: DropdownProps) => {
                  setGlobalSelection(data.value as SelectionType);
                  setIndividualSelection(false);
                  setCurrentSelections([
                    ...currentSelections.map((d) => {
                      return {
                        dependencyId: d.dependencyId,
                        selectionType: data.value as SelectionType,
                      };
                    }),
                  ]);
                }}
              />
            </span>
            <Divider />
            <Checkbox
              id="ToggleCheckbox"
              toggle
              checked={showList}
              onChange={() => setShowList(!showList)}
              label={t(`showProducts`)}
            />
            {showList && requirementInfo && (
              <RequirementComponentsList
                authorized={authorized}
                requirementId={requirementInfo.requirement.id}
                setExistingSelections={setExistingSelections}
                globalSelection={globalSelection}
                currentSelections={currentSelections}
                setCurrentSelections={setCurrentSelections}
              />
            )}
          </Segment>
        </Segment.Group>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="RequirementsTabSaveChanges"
          primary
          disabled={!authorized || !changes}
          content={t('saveChanges', { ns: 'buttons' })}
          onClick={handleSaveChanges}
        />
      </Modal.Actions>
    </>
  );
};

export default RequirementsTab;
