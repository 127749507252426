import { useApolloClient, useMutation } from '@apollo/client';
import React, { useState, useEffect, useContext } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useRunningImportLazyQuery } from '../../../../graphql/generated/graphql';
import DependencyImportModal from './dependencyList/fossDependencyList/listComponents/DependencyImportModal';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import { INITIALIZE_DEFAULT_TODOS } from '../../../../graphql/mutations/ProductToDoMutation';

/**
 * Button that opens import modal
 *
 * @returns {JSX.Element} Button that opens import modal
 */
const ImportModalButton = (): JSX.Element => {
  const { versionId, authorizations, importPossible } = useContext(
    ProductDetailsContext
  );
  const [openModal, setOpenModal] = useState(false);
  const [text] = useTranslation('productDetails');
  const client = useApolloClient();

  const [
    initializeDefaultToDos,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(INITIALIZE_DEFAULT_TODOS, {
    variables: { versionId },
  });

  const [, runningImport] = useRunningImportLazyQuery({
    variables: { id: versionId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: async (data) => {
      if (!data.Version?.importPossible) {
        runningImport.stopPolling();
        initializeDefaultToDos();
        client.refetchQueries({
          include: [
            'DependenciesByVersionId',
            'DependenciesByVersionIdAmount',
            'ProductDetailsContext',
            'LegalCheckPossible',
          ],
        });
      }
    },
  });

  const notAuthorizedForImport = !authorizations.includes('JsonImport');
  const importDisabled = !importPossible || notAuthorizedForImport;

  const importPopupMessage = () => {
    if (notAuthorizedForImport) {
      return text('dependencyList.importModal.noAuthorized');
    }
    if (!importPossible) {
      return text('dependencyList.importModal.alreadyImported');
    }
    if (mutationError) {
      return mutationError.message;
    }
    return text('dependencyList.importModal.runningImport');
  };

  useEffect(() => {
    if (!importDisabled) {
      runningImport.startPolling(30000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Popup
        id="ImporterModalPopupOnButtonDisabled"
        content={importPopupMessage()}
        disabled={
          !importDisabled && !runningImport.data?.Version?.runningImport
        }
        hoverable
        wide
        on="hover"
        trigger={
          <span>
            <Button
              id="ImporterModal.Button.Open"
              primary
              basic
              content={
                runningImport.data?.Version?.runningImport ||
                mutationLoading ? (
                  <>
                    <Icon id="Spinner" loading name="spinner" />
                    {text('dependencyList.importModal.button.open')}
                  </>
                ) : (
                  text('dependencyList.importModal.button.open')
                )
              }
              disabled={importDisabled}
              onClick={() => {
                setOpenModal(true);
              }}
            />
          </span>
        }
      />

      {openModal && (
        <DependencyImportModal
          open={openModal}
          setOpen={setOpenModal}
          refetchRunningImport={runningImport.refetch}
        />
      )}
    </>
  );
};

export default ImportModalButton;
