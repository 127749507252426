import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  useBrowseVersionArtifactoryLazyQuery,
  useDownloadFromArtifactoryMutation,
} from '../../../../graphql/generated/graphql';
import Toast from '../../../ui/Toast';

interface ReportDownloadBtnProps {
  disabled: boolean;
}

/**
 * Button for downloading the report.
 *
 * @param {ReportDownloadBtnProps} props { disabled }
 * @returns {JSX.Element} ReportDownloadButton.
 */
const ReportDownloadButton = (props: ReportDownloadBtnProps): JSX.Element => {
  const { disabled } = props;
  const { t } = useTranslation('productDetails');
  const { versionId, processStatusAsString } = useContext(
    ProductDetailsContext
  );
  const [reportFilePath, setReportFilePath] = useState<string | null>(null);
  const [hasSignedReport, setHasSignedReport] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [errorState, setErrorState] = useState<ApolloError | null>(null);

  const filename = 'report_pdf.pdf';

  const [downloadFromArtifactory] = useDownloadFromArtifactoryMutation({
    onCompleted: (data) => {
      if (!data) return;
      const extension = filename.slice(filename.lastIndexOf('.') + 1);
      const mimetype = extension === 'pdf' ? 'application/pdf' : 'text/plain';
      const link = document.createElement('a');
      link.href = `data:${mimetype};base64,${data.DownloadFromArtifactory}`;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    onError: (error) => {
      setErrorState(error);
      setToastVisible(true);
    },
  });

  const [fetchReportData] = useBrowseVersionArtifactoryLazyQuery();

  const fetchAndSetReportData = async () => {
    const { data } = await fetchReportData({ variables: { versionId } });
    const { files = [] } = data?.BrowseVersionArtifactory || {};
    if (
      processStatusAsString === 'REPORT' ||
      processStatusAsString === 'COMPLETED'
    ) {
      const signedReports = files
        .filter((file) => file?.properties?.type === 'report_signed')
        .sort(
          (a, b) =>
            new Date(b?.created ?? 0).getTime() -
            new Date(a?.created ?? 0).getTime()
        );

      if (signedReports.length > 0) {
        setHasSignedReport(true);
        setReportFilePath(signedReports[0]?.path || null);
        return signedReports[0]?.path || null;
      }
    }
    setHasSignedReport(false);
    const reportFile = files.find((file) => file?.filename === filename);
    setReportFilePath(reportFile?.path || null);
    return reportFile?.path || null;
  };

  useEffect(() => {
    if (
      processStatusAsString === 'REPORT' ||
      processStatusAsString === 'COMPLETED'
    ) {
      fetchAndSetReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processStatusAsString]);

  const handleButtonClick = async () => {
    try {
      const path = reportFilePath || (await fetchAndSetReportData());
      if (path) {
        downloadFromArtifactory({
          variables: { versionId, url: path },
        });
      }
    } catch (error) {
      setErrorState(error as ApolloError);
      setToastVisible(true);
    }
  };

  const handleToastClose = () => {
    setToastVisible(false);
    setErrorState(null);
  };

  const buttonLabel =
    hasSignedReport &&
    (processStatusAsString === 'REPORT' ||
      processStatusAsString === 'COMPLETED')
      ? t('export.signedReport')
      : t('export.report');

  return (
    <>
      <Toast
        visible={toastVisible}
        type="error"
        header={errorState?.name}
        content={errorState?.message}
        onClose={handleToastClose}
        position="absolute bottom right"
        offset={{ y: -42 }}
      />
      <Button
        primary
        basic
        id="ReportDownloadButton"
        icon="download"
        disabled={toastVisible || disabled}
        content={buttonLabel}
        onClick={() => {
          handleButtonClick();
        }}
      />
    </>
  );
};

export default ReportDownloadButton;
