import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Grid,
  Icon,
  Input,
  InputOnChangeData,
  Item,
  Popup,
} from 'semantic-ui-react';
import { PartForSelection } from '../WizardConstants';
import WizardFormFieldLabel from '../WizardFormFieldLabel';
import IconCheckbox from '../WizardIconCheckbox';

type Props = {
  parts: PartForSelection[];
  isValidPart: (part: PartForSelection) => boolean;
  isValidPartName: (name: string) => boolean;
  onClickCheckbox: (part: PartForSelection) => void;
  onChangePartName: (part: PartForSelection, data: InputOnChangeData) => void;
  onClickAddPart: () => void;
};

/**
 * Parts | For all Parts Column of WizardPartsSegment
 *
 * @param {object} props onChangeTeamOption, onChangeUseOption
 *@returns {JSX.Element} Parts | For all Parts - Grid.Column
 */
const WizardPartsGeneralColumn = (props: Props): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const {
    parts,
    isValidPart,
    isValidPartName,
    onClickCheckbox,
    onChangePartName,
    onClickAddPart,
  } = props;

  /* Checks */
  const showPartError = (part: PartForSelection): boolean => {
    return !isValidPartName(part.name) && part.checked;
  };

  return (
    <Grid.Column
      style={{ height: '100%', padding: '0 0 0 1rem', overflow: 'auto' }}
    >
      <Item.Group relaxed="very">
        <Item id="General" style={{ margin: '0' }}>
          <Item.Content>
            <Item.Header>{t('parts.parts')}</Item.Header>
            <span
              style={{
                fontSize: '18px',
                display: 'inline',
              }}
            >
              {t('parts.general')}
            </span>
            <Item.Meta>{t('parts.description.general')}</Item.Meta>
          </Item.Content>
        </Item>
      </Item.Group>
      <Form id="IncludedPartsForm">
        <WizardFormFieldLabel
          nameText={t('parts.included.parts')}
          infoText={t('parts.popup.included.parts')}
        />
        {parts.map((part, index) => {
          return (
            <Popup
              id="Popup"
              key={part.id}
              position="top left"
              hoverable
              offset={[-10, 0]}
              open={showPartError(part)}
              content={t('parts.warning.min.name.length')}
              trigger={
                <Form.Field
                  id={`IncludedParts.Form.Field.${part.id}`}
                  error={showPartError(part)}
                >
                  {/* Part Checkbox */}
                  <IconCheckbox
                    id={`Checkbox${part.id}`}
                    checked={part.checked}
                    valid={isValidPart(part)}
                    size="large"
                    onClick={() => onClickCheckbox(part)}
                  />
                  {/* Part Name Input */}
                  <Input
                    key={`Input${part.id}`}
                    id={`Input${index}`}
                    transparent
                    inverted
                    value={part.name}
                    className={
                      isValidPart(part) ? 'valid-part' : 'invalid-part'
                    }
                    onChange={(event, data) => onChangePartName(part, data)}
                  />
                </Form.Field>
              }
            />
          );
        })}
        {/* Add Part Button-Icon */}
        <Icon
          id="IncludedParts.AddButtonIcon"
          name="plus square outline"
          size="large"
          color="blue"
          link
          onClick={() => onClickAddPart()}
        />
      </Form>
    </Grid.Column>
  );
};

export default WizardPartsGeneralColumn;
