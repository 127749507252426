import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Popup } from 'semantic-ui-react';

type PropsType = {
  verified: boolean | undefined;
};

/**
 * Returns a unverified icon if the license is unverified
 *
 * @param {PropsType} props - {verified}
 * @returns {JSX.Element} icon with a popup
 */
const UnverifiedIcon = ({ verified }: PropsType): JSX.Element | null => {
  const { t } = useTranslation('productDetails');

  if (verified) {
    return null;
  }

  return (
    <Popup
      id="UnverifiedIconPopup"
      hoverable
      position="top center"
      content={<div>{t('dependencyList.tableBody.unverified')}</div>}
      trigger={
        <Icon
          id="UnverifiedIcon"
          className="margin-left"
          name="exclamation triangle"
          color="yellow"
        />
      }
    />
  );
};

export default UnverifiedIcon;
