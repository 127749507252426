import React, { HTMLAttributes, ReactElement, useState } from 'react';
import { Icon, Popup, StrictPopupProps } from 'semantic-ui-react';

interface InfoIconPopupProps extends HTMLAttributes<HTMLDivElement> {
  infoText?: string | ReactElement;
  on?: 'hover' | 'click';
  children?: ReactElement | null;
  styleClass?: string;
}

/**
 * Info Icon with Popup, accepts children(optional) which can then be part of the popup trigger.
 * All standard HTML Div attributes can be used, to customize the container.
 *
 * @param {string}  infoText a string to be displayed within the Popup.
 * @returns {boolean} ReactElement(if provided) followed by the info icon, triggering the popup (Popup)
 */
const InfoIconPopup = ({
  infoText = '',
  on = 'hover',
  children,
  styleClass = 'aosd-info-popup',
  ...props
}: InfoIconPopupProps): JSX.Element => {
  const className = `info-icon-popup-trigger ${
    children ? 'with-children' : 'standalone'
  }`;

  const [popupPosition, setPopupPosition] =
    useState<StrictPopupProps['position']>('top center');

  const handlePopupOpen = (e: React.MouseEvent<HTMLElement>) => {
    const minDistance = 250;
    const triggerElement = e.currentTarget;
    const rect = triggerElement.getBoundingClientRect();
    const isCloseToRightEdge = window.innerWidth - rect.right < minDistance;
    const isCloseToLeftEdge = rect.left < minDistance;

    if (isCloseToRightEdge && !isCloseToLeftEdge) {
      setPopupPosition('top right');
    } else if (isCloseToLeftEdge && !isCloseToRightEdge) {
      setPopupPosition('top left');
    }
  };

  return (
    <Popup
      id="InfoIconPopup"
      on={on}
      className={styleClass}
      content={infoText}
      position={popupPosition}
      onOpen={handlePopupOpen}
      hideOnScroll
      offset={() => {
        if (popupPosition === 'top left') {
          return [-12, 0];
        }
        if (popupPosition === 'top right') {
          return [12, 0];
        }
        return [];
      }}
      trigger={
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={className} {...props}>
          {children}
          <Icon id="InfoIcon" className="info aosd" color="blue" />
        </div>
      }
    />
  );
};

export default InfoIconPopup;
