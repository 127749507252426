import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import {
  AllUsersQuery,
  AllUsersQueryVariables,
  ChangeCategory,
  ChangeLog,
  DependenciesByVersionIdQuery,
  DependenciesByVersionIdQueryVariables,
  Dependency,
  GetRequirementsQuery,
  GetRequirementsQueryVariables,
  GetToDosQuery,
  GetToDosQueryVariables,
  Maybe,
  Product,
  ProductVersionNamesQuery,
  ProductVersionNamesQueryVariables,
  PropertyChange,
  Requirement,
  ToDo,
  User,
  Version,
} from '../../../../graphql/generated/graphql';
import { GET_TODOS } from '../../../../graphql/queries/ToDoQueries';
import { GET_REQUIREMENTS } from '../../../../graphql/queries/RequirementQuerys';
import { GET_ALL_USERNAMES } from '../../../../graphql/queries/UserQuerys';
import { PRODUCT_VERSION_NAMES } from '../../../../graphql/queries/ProductQuerys';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import { DEPENDENCIES_BY_VERSION_ID } from '../../../../graphql/queries/DependencyQuerys';

/**
 * Change details of various entities.
 *
 * @interface ChangeDetails
 * @property {ToDo} [todo] - ToDo details.
 * @property {Requirement} [requirement] - Requirement details.
 * @property {User} [user] - User details.
 * @property {User} [prevUser] - Previous user details.
 * @property {Version} [version] - Version details.
 * @property {Product} [product] - Product details.
 * @property {Dependency} [dependency] - Dependency details.
 */
export interface ChangeDetails {
  todo?: Pick<
    ToDo,
    'id' | 'name_en' | 'name_de' | 'description_en' | 'description_de'
  >;
  requirement?: Pick<
    Requirement,
    'id' | 'name_en' | 'name_de' | 'description_en' | 'description_de' | 'type'
  >;
  user?: Pick<User, 'id' | 'name'>;
  prevUser?: Pick<User, 'id' | 'name'>;
  version?: Pick<Version, 'id' | 'name'>;
  product?: Pick<Product, 'id' | 'name'>;
  dependency?: Pick<
    Dependency,
    'id' | 'componentName' | 'componentVersion' | 'legalResult'
  >;
}

/**
 *
 * @description Custom hook to get specific (ToDo, Requirement or anything else) from the cache
 * @param {string} refNode afflicted node reference. (id)
 * @param {ChangeCategory} changeCategory changeCategory
 * @param {string} refType afflicted relationship(?) reference.
 * @param {string} property afflicted property.
 * @param {string} prevValue previous value of the property.
 * @returns {void}
 */
export const useGetChangeDetails = (
  refNode: string | null | undefined,
  changeCategory?: ChangeCategory,
  refType?: string | null | undefined,
  property?: string | null | undefined,
  prevValue?: string | null | undefined
): ChangeDetails => {
  const { productId } = useContext(ProductDetailsContext);
  const client = useApolloClient();
  const changeDetails: ChangeDetails = {};

  if (refNode && property === 'toDo') {
    const todo = client.readFragment({
      id: `ToDo:${refNode}`,
      fragment: gql`
        fragment changedTodo on ToDo {
          id
          name_en
          name_de
          description_en
          description_de
        }
      `,
    });
    if (todo) {
      changeDetails.todo = todo;
    }
  } else if (refNode && property === 'requirement') {
    const requirement = client.readFragment({
      id: `Requirement:${refNode}`,
      fragment: gql`
        fragment changedRequirement on Requirement {
          id
          name_en
          name_de
          description_en
          description_de
          type
        }
      `,
    });
    if (requirement) {
      changeDetails.requirement = requirement;
    }
  } else if (
    refNode &&
    (refType === 'has_access' ||
      refType === 'reviewed_by_legal' ||
      refType === 'reviewed_by_oso')
  ) {
    const user = client.readFragment({
      id: `User:${refNode}`,
      fragment: gql`
        fragment changedUser on User {
          id
          name
        }
      `,
    });
    if (user) {
      changeDetails.user = user;
    }
  }

  if (refType === 'reviewed_by_legal' || refType === 'reviewed_by_oso') {
    const prevUser = client.readFragment({
      id: `User:${prevValue}`,
      fragment: gql`
        fragment oldUser on User {
          id
          name
        }
      `,
    });
    if (prevUser) {
      changeDetails.prevUser = prevUser;
    }
  }

  if (productId && refNode && property === 'version') {
    const version = client.readFragment({
      id: `Version:${refNode}`,
      fragment: gql`
        fragment afflictedVersion on Version {
          id
          name
        }
      `,
    });
    if (version) {
      changeDetails.version = version;
    }
    const product = client.readFragment({
      id: `Product:${productId}`,
      fragment: gql`
        fragment currentProduct on Product {
          id
          name
        }
      `,
    });
    if (product) {
      changeDetails.product = product;
    }
  }

  if (refNode && changeCategory === ChangeCategory.DEPENDENCY_CHANGE) {
    const dependency = client.readFragment({
      id: `Dependency:${refNode}`,
      fragment: gql`
        fragment changedDependency on Dependency {
          id
          componentName
          componentVersion
          legalResult {
            note
            status
            history {
              ... on PropertyChange {
                newValue
                prevValue
                property
                changeType
                category
                createdAt
              }
            }
          }
        }
      `,
    });
    if (dependency) {
      changeDetails.dependency = dependency;
    }
  }

  return changeDetails;
};

/**
 *
 * @description Hook that lazily fetches todos or requirements if any are present in history log
 * so their details can be shown.
 * @param {string} versionId version id
 * @param {ChangeLog[]} historyElements list of history/change logs.
 * @returns {void}
 */
export const useFetchHistoryDetails = (
  versionId: string,
  historyElements: ChangeLog[] | []
) => {
  const { productId } = useContext(ProductDetailsContext);
  const fetchStatus = useRef({
    todos: false,
    requirements: false,
    usernames: false,
    versionNames: false,
    dependencies: false,
  });

  const [getTodos, { data: toDoData, loading: toDoLoading, error: toDoError }] =
    useLazyQuery<GetToDosQuery, GetToDosQueryVariables>(GET_TODOS, {
      variables: { versionId },
    });

  const [getReqs, { data: reqData, loading: reqLoading, error: reqError }] =
    useLazyQuery<GetRequirementsQuery, GetRequirementsQueryVariables>(
      GET_REQUIREMENTS,
      {
        variables: { versionId },
      }
    );

  const [
    getUsernames,
    { data: usersData, loading: usersLoading, error: usersError },
  ] = useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(GET_ALL_USERNAMES);

  const [
    getVersionNames,
    { data: versionData, loading: versionLoading, error: versionError },
  ] = useLazyQuery<ProductVersionNamesQuery, ProductVersionNamesQueryVariables>(
    PRODUCT_VERSION_NAMES,
    {
      variables: { where: { id: productId } },
    }
  );

  const [
    getDependenciesByVersionId,
    {
      data: dependencyData,
      loading: dependencyLoading,
      error: dependencyError,
    },
  ] = useLazyQuery<
    DependenciesByVersionIdQuery,
    DependenciesByVersionIdQueryVariables
  >(DEPENDENCIES_BY_VERSION_ID, {
    variables: { versionId },
  });

  const collectDependencyIdsFromHistory = useCallback(() => {
    return historyElements
      .filter(
        (element) =>
          element.__typename === 'NodeChange' &&
          element.category === ChangeCategory.DEPENDENCY_CHANGE &&
          element.refNode
      )
      .map((element) => element.refNode) // dep id
      .filter((id): id is string => Boolean(id));
  }, [historyElements]);

  const changedDependencies = collectDependencyIdsFromHistory();

  useEffect(() => {
    const checks = {
      todos: () =>
        historyElements.some(
          (e) => e.__typename === 'RelationshipChange' && e.property === 'toDo'
        ),
      requirements: () =>
        historyElements.some(
          (e) =>
            e.__typename === 'RelationshipChange' &&
            e.property === 'requirement'
        ),
      usernames: () =>
        historyElements.some(
          (e) =>
            e.__typename === 'RelationshipChange' && e.refType === 'has_access'
        ),
      versionNames: () =>
        historyElements.some(
          (e) =>
            e.__typename === 'RelationshipChange' && e.refType === 'has_version'
        ),
      dependencies: () =>
        historyElements.some(
          (e) =>
            e.__typename === 'NodeChange' &&
            e.category === ChangeCategory.DEPENDENCY_CHANGE
        ),
    };

    const fetchFunctions = {
      todos: () => {
        getTodos();
        fetchStatus.current.todos = true;
      },
      requirements: () => {
        getReqs();
        fetchStatus.current.requirements = true;
      },
      usernames: () => {
        getUsernames();
        fetchStatus.current.usernames = true;
      },
      versionNames: () => {
        getVersionNames();
        fetchStatus.current.versionNames = true;
      },
      dependencies: () => {
        const dependencyCount = changedDependencies.length;
        // Fetch only changed dependencies if <= 500, fetch all if > 500
        const dependencyVariables =
          dependencyCount > 500
            ? { versionId, options: { limit: 0 } }
            : {
                versionId,
                options: { limit: 0 },
                where: { dependencyIds: changedDependencies },
              };
        getDependenciesByVersionId({ variables: dependencyVariables });
        fetchStatus.current.dependencies = true;
      },
    };

    (Object.keys(checks) as Array<keyof typeof checks>).forEach((key) => {
      if (checks[key]() && !fetchStatus.current[key]) {
        fetchFunctions[key]();
      }
    });
  }, [
    changedDependencies,
    getDependenciesByVersionId,
    getReqs,
    getTodos,
    getUsernames,
    getVersionNames,
    historyElements,
    versionId,
  ]);

  useEffect(() => {
    // Reset fetch status when versionId changes
    Object.keys(fetchStatus.current).forEach((key) => {
      fetchStatus.current[key as keyof typeof fetchStatus.current] = false;
    });
  }, [versionId]);

  return {
    toDoLoading,
    toDoError,
    reqLoading,
    reqError,
    usersLoading,
    usersError,
    toDoData,
    reqData,
    usersData,
    versionData,
    versionLoading,
    versionError,
    dependencyData,
    dependencyError,
    dependencyLoading,
  };
};

/**
 * property change pairs of dependency note & status
 */
export interface PairedDependencyHistoryEntry {
  createdAt: string;
  note?: PropertyChange;
  status?: PropertyChange;
}

/**
 *
 * @description pairs dependency propertyChange based on provided timestamp
 * @param {ChangeLog[]} depHistory history of a dependency
 * @param {string} timestamp timestamp
 * @returns {PairedDependencyHistoryEntry[]} paired entries
 */
export const usePairPropertyChanges = (
  depHistory: ChangeLog[] | undefined,
  timestamp: Maybe<string> | undefined
): PairedDependencyHistoryEntry[] => {
  return useMemo(() => {
    const pairedEntries: PairedDependencyHistoryEntry[] = [];

    if (depHistory && timestamp) {
      const filteredHistory = depHistory.filter(
        (entry) => entry.createdAt === timestamp
      );
      filteredHistory.forEach((entry) => {
        if (entry.__typename === 'PropertyChange') {
          let pair = pairedEntries.find((e) => e.createdAt === timestamp);

          if (!pair) {
            pair = { createdAt: timestamp };
            pairedEntries.push(pair);
          }

          if (entry.property === 'note') {
            pair.note = entry;
          } else if (entry.property === 'status') {
            pair.status = entry;
          }
        }
      });
    }

    return pairedEntries;
  }, [depHistory, timestamp]);
};
