import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  Icon,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import { Version } from '../../../../../graphql/generated/graphql';
import { UPDATE_PRODUCT_PARTS } from '../../../../../graphql/mutations/ProductMutations';
import {
  DIRECT_PRODUCT_VERSION_DEPEPENDENCIES_BY_VERSION_ID,
  GET_PROJECT_VERSION_PARTS,
} from '../../../../../graphql/queries/VersionQuerys';
import SearchForProductParts from './SearchForProductParts';
import { EditParts } from '../dependencyList/helper/types';
import {
  checkIfArrayIsDifferent,
  makePartObject,
} from '../dependencyList/helper/logics';

type EditProductPartsProps = {
  projectVersionId: string; // projects version id
  updatePartsEnabled: boolean;
};

/**
 *@returns {JSX.Element} Edit products parts in Project view
 *@param {string} projectVersionId ID of the Projects version
 */
const EditProductPartsModal = ({
  projectVersionId,
  updatePartsEnabled,
}: EditProductPartsProps): JSX.Element => {
  const { t } = useTranslation(['productDetails']);
  const [open, setOpen] = useState(false);
  const [openSession, setOpenSession] = useState<Array<EditParts>>([]); // *currentArr in the modal-populated only on opened modal

  const { data: projectDeps, loading: partsLoading } = useQuery(
    GET_PROJECT_VERSION_PARTS,
    {
      variables: { id: projectVersionId },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (result) =>
        makePartObject(result?.Version.productDependencies, setOpenSession),
    }
  );
  const projectPartsInfos = projectDeps?.Version.productDependencies || [];
  const currentPartIds: string[] =
    projectPartsInfos?.map((dep: Version) => dep.id) || [];
  const openSessionVersionIds = openSession?.map((p) => p.partVersionId) || [];

  const resetAndClose = () => {
    setOpen(false);
    makePartObject(projectPartsInfos, setOpenSession);
  };
  const [UpdateProductParts, { error: mutationError }] = useMutation(
    UPDATE_PRODUCT_PARTS,
    {
      variables: {
        projectVersionId,
        partVersionIds: openSessionVersionIds, // mutation sends an Array of version IDs
      },
      refetchQueries: [
        GET_PROJECT_VERSION_PARTS,
        DIRECT_PRODUCT_VERSION_DEPEPENDENCIES_BY_VERSION_ID,
        'ProjectCompletionPossible',
      ],
      onCompleted: () => {
        resetAndClose();
      },
    }
  );

  const removePart = (ereased: string | undefined) => {
    setOpenSession(
      openSession?.filter((part) => part.partVersionId !== ereased)
    );
  };
  const newAdded = (partID: string) => {
    if (currentPartIds.includes(partID)) {
      return false;
    }
    return true;
  };
  const removedNr = currentPartIds.filter(
    (part: string) =>
      !openSession?.some((sessionPart) => sessionPart.partVersionId === part)
  ).length; // only the ones from DataBase
  const addedNr = openSession.filter(
    (part) => !currentPartIds.includes(part.partVersionId)
  ).length; // new ones we want to add

  return (
    <Modal
      id="EditProductPartsModal"
      open={open}
      onOpen={() => setOpen(true)}
      size="tiny"
      closeOnDimmerClick={false}
      trigger={
        <Button
          id="EditProductPartsModalButtonOpen"
          primary
          basic
          labelPosition="left"
          icon="pencil"
          disabled={!updatePartsEnabled}
          content={t('dependencyList.editPartsModal.button.open')}
        />
      }
    >
      <Modal.Header id="EditProductPartsModalHeader">
        {`${t('dependencyList.editPartsModal.header')}(${openSession?.length})`}
        <Icon
          id="EditProductPartsModalCloseBtn"
          name="close"
          link
          onClick={resetAndClose}
        />
      </Modal.Header>
      <Modal.Content id="EditProductPartsModalContent">
        <Modal.Description>
          <Segment.Group>
            {partsLoading && <Loader active />}
            {/*  when no parts Message */}
            {openSession.length === 0 ? (
              <Message
                id="EditProductPartsModalContentEmptyArrMsg"
                info
                icon="info aosd filled"
                content={t('dependencyList.editPartsModal.emptyArrMsg')}
              />
            ) : (
              openSession.map((aPart: EditParts) => {
                return (
                  aPart && (
                    <Segment
                      id="EditProductPartsModalList"
                      key={`${aPart.partVersionId || ''}`}
                    >
                      <Grid>
                        <Grid.Column floated="left" width={8}>
                          {`${aPart?.partName} @ ${
                            aPart?.partVersionName || ''
                          }`}
                          {newAdded(aPart?.partVersionId || '') && (
                            <span className="EditProductPartsModalListNew">
                              {t('dependencyList.editPartsModal.new')}
                            </span>
                          )}
                        </Grid.Column>
                        <Grid.Column floated="right" width={2}>
                          <Icon
                            id="EditProductPartsDeleteButton"
                            name="trash alternate outline"
                            color="red"
                            fitted
                            link
                            onClick={() => {
                              removePart(aPart?.partVersionId || '');
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </Segment>
                  )
                );
              })
            )}
          </Segment.Group>
        </Modal.Description>
        {/* SEARCH COMPONENT */}
        <SearchForProductParts
          openSession={openSession}
          setOpenSession={setOpenSession}
          ownerId={projectVersionId}
        />
        {removedNr > 0 && (
          <div className="NumberOfRemovedAndAdded">
            <span>
              {`* ${removedNr}${t('dependencyList.editPartsModal.removedNr')} `}
            </span>
            <span>{` / `}</span>
            <span>
              {`${addedNr}${t('dependencyList.editPartsModal.addedNr')} `}
            </span>
          </div>
        )}
        {mutationError && (
          <Message
            id="ProductPartsErrorMessage"
            error
            icon="times circle"
            content={mutationError.message}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="EditProductPartsModalAddPart"
          primary
          disabled={checkIfArrayIsDifferent(
            openSessionVersionIds,
            currentPartIds
          )}
          onClick={() => UpdateProductParts()}
          content={`(${addedNr + removedNr}) ${t(
            'dependencyList.editPartsModal.button.apply'
          )}`}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EditProductPartsModal;
