import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps, Popup } from 'semantic-ui-react';
import {
  DirectoryAccess,
  DirectoryRole,
  Maybe,
} from '../../../graphql/generated/graphql';
import getIdFromKey, {
  filteringOptions,
  noticeWhenEmptyOptions,
  useBuildOptions,
} from './helpers/logics';

type Props = {
  directoryAccessArr: DirectoryAccess[];
  setDirectoryAccessArr: React.Dispatch<
    React.SetStateAction<DirectoryAccess[]>
  >;
  brandDirectories: Maybe<Array<Maybe<DirectoryAccess>>> | undefined;
  usersWithAccess: boolean;
};

/**
 *@returns {JSX.Element} Add brand directories dropdown as a button component that adds directories for a user and to be given access to him
 *@param {Props} props props
 */
const AddDirectoriesDropdownButton = (props: Props): JSX.Element => {
  const { t } = useTranslation('users');
  const {
    directoryAccessArr,
    setDirectoryAccessArr,
    usersWithAccess,
    brandDirectories,
  } = props;

  const noMoreoptions = noticeWhenEmptyOptions(
    brandDirectories,
    directoryAccessArr
  );
  const buildOptions = useBuildOptions(
    filteringOptions(brandDirectories, directoryAccessArr)
  );

  return (
    <div>
      <Popup
        id="UserDirectoriesDropdownErrorMessage"
        disabled={!noMoreoptions}
        content={t('allDistsChosen')}
        position="top center"
        offset={[0, 10]}
        on="hover"
        trigger={
          <span>
            <Dropdown
              id="UserDirectoriesAddDirectories"
              disabled={!usersWithAccess || noMoreoptions}
              button
              selectOnBlur={false}
              value="" // disables automatic selection of items-we are not using the input filed
              className="icon"
              floating
              labeled
              icon="add"
              options={buildOptions || undefined}
              onChange={(_e, data) => {
                setDirectoryAccessArr([
                  ...directoryAccessArr,
                  {
                    directoryId: getIdFromKey(
                      data.options as DropdownItemProps[],
                      data.value as string
                    ),
                    directoryName: data.value as string,
                    directoryRoles: [] as DirectoryRole[],
                  },
                ]);
              }}
              text={t('directories.add')}
            />
          </span>
        }
      />
    </div>
  );
};

export default AddDirectoriesDropdownButton;
