import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Maybe } from '../graphql/generated/graphql';
import * as CharLimits from './helpers/constants';
import sanitizeInput from './helpers/logics';

interface UseTextAreaChangeReturn {
  maxCharLimit: number;
  charCount: number;
  handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

type CharLimitKeys = keyof typeof CharLimits;

/**
 * Hook handles textarea changes and keeps the state of the character amount.
 *
 * @template T - The type of the state.
 * @param {Dispatch<SetStateAction<T>>} setState sets state, currently works with string and object with property 'info'.
 * @param {Maybe<string>} initialText text which is being shown in the textarea on initial render.
 * @param {number} maxCharLimit maximum amount of characters (defaults to 280).
 * @param {number} maxEmptyLines maximum amount of empty lines (defaults to 2).
 * @returns {UseTextAreaChangeReturn} -  maxCharLimit, charCount, handleTextAreaChange, resetCharacterCount
 */
const useTextAreaChange = <T>(
  setState: Dispatch<SetStateAction<T>>,
  initialText: Maybe<string> = '',
  maxCharLimit: CharLimitKeys = 'STORAGE_DESCRIPTION_CHAR_LIMIT',
  maxEmptyLines = 2
): UseTextAreaChangeReturn => {
  const [charCount, setCharCount] = useState(0);

  const handleTextAreaChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      let newText = event.target.value;
      if (newText.length > CharLimits[maxCharLimit]) {
        newText = newText.substring(0, CharLimits[maxCharLimit]);
      }
      const sanitizedText = sanitizeInput(newText, maxEmptyLines);
      setState((prevState) => {
        if (typeof prevState === 'string') {
          return sanitizedText as unknown as T;
        }
        return { ...prevState, info: sanitizedText } as unknown as T;
      });
      setCharCount(sanitizedText.length);
    },
    [maxCharLimit, setState, maxEmptyLines]
  );

  useEffect(() => {
    if (initialText) setCharCount(initialText.length);
  }, [initialText]);

  return {
    maxCharLimit: CharLimits[maxCharLimit],
    charCount,
    handleTextAreaChange,
  };
};

export default useTextAreaChange;
