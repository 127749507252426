import { useTranslation } from 'react-i18next';
import { DropdownItemProps } from 'semantic-ui-react';
import { SelectionType } from '../../../../../graphql/generated/graphql';
import { SelectionMode } from './types';

/**
 * Dropdown options for general version selection of all dependencies
 *
 * @returns {DropdownItemProps[]} dropdown options
 */
const useSelectionTypeOpts = (): DropdownItemProps[] => {
  const { t } = useTranslation('requirements');
  const options = [
    {
      key: 1,
      text: t('deselected'),
      value: SelectionType.userdeselected,
      icon: { name: 'ban' },
    },
    {
      key: 2,
      text: t('selected'),
      value: SelectionType.userselected,
      icon: { name: 'checkmark' },
    },
    {
      key: 3,
      text: t('unknown'),
      value: SelectionType.unselected,
      icon: { name: 'question' },
    },
    {
      key: 4,
      text: t('individual'),
      value: SelectionMode.INDIVIDUAL,
      style: { display: 'none' }, // Make this option invisible
    },
  ];
  return options;
};

export default useSelectionTypeOpts;
