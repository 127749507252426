import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, SemanticICONS, Table } from 'semantic-ui-react';
import FilterMenu from './FilterMenu';
import { sortByAttribute, toggleColumnActive } from './helpers/formatters';
import { Columns, Column } from './ProductListTypes';

type PropsType = {
  columns: Columns;
  setColumns: React.Dispatch<React.SetStateAction<Columns>>;
};

/**
 * Header element of products list
 *
 * @param {PropsType} props takes in active columns and builds the header accordingly.
 * @returns {JSX.Element} table row containing table cells with the according values.
 */
const ProductsListHeader = (props: PropsType): JSX.Element => {
  const { columns, setColumns } = props;
  const [text] = useTranslation('dashboard');
  const [sortIconName, setSortIconName] = useState<SemanticICONS | undefined>(
    'sort'
  );

  return (
    <Table.Row key="headerRow">
      {columns.map((column: Column) => {
        // id does not work with column.name - columnName works though
        const columnName = column.name;

        return (
          column.permitted &&
          column.shown && (
            <Table.HeaderCell
              className={column.active ? 'active' : ''}
              width={column.width}
              key={`tableHeader.${column.name}`}
              id={`tableHeader.${column.name}`}
            >
              <div
                style={{
                  minWidth: column.minWidth,
                }}
              >
                <span
                  id={`productsList.${columnName}.Paragraph`}
                  style={{
                    width: 'calc(100% - 1.5em)',
                    display: 'inline-block',
                    whiteSpace: 'normal',
                    wordWrap: 'normal',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <span>{text(`tableHeader.${column.name}`)}&nbsp;</span>
                  {column.name === 'todos' ? (
                    <>
                      <br />
                      <div>{text(`tableHeader.todos2`)}&nbsp;</div>
                    </>
                  ) : (
                    ''
                  )}
                </span>
                <span style={{ float: 'right' }}>
                  {column.sorterInfo.sortable && column.name !== 'comments' && (
                    <Icon
                      id="productsList.Sort"
                      link
                      onClick={() => {
                        setColumns((oldColumns) => {
                          return sortByAttribute(
                            column,
                            oldColumns,
                            setSortIconName
                          );
                        });
                        setColumns((oldColumns) => {
                          return toggleColumnActive(column, oldColumns);
                        });
                        return null;
                      }}
                      name={column.active ? sortIconName : 'sort'}
                    />
                  )}
                  {column.filterInfo.filterable &&
                    !column.sorterInfo.sortable && (
                      <FilterMenu column={column} setColumns={setColumns} />
                    )}
                </span>
              </div>
            </Table.HeaderCell>
          )
        );
      })}
    </Table.Row>
  );
};

export default ProductsListHeader;
