/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import {
  Column,
  Columns,
} from '../products/productsList/listComponents/ProductListTypes';
import {
  ProcessStatusGroups,
  ProcessStatusGroupTypeKey,
} from '../products/productDetails/dependencySegment/dependencyList/helper/types';

type PropsType = {
  column: Column | undefined;
  setColumns: React.Dispatch<React.SetStateAction<Columns>>;
};

/**
 * ProductFilterPills
 *
 * @param {PropsType} props takes in ...
 * @returns {JSX.Element} ProductFilterPills
 */
const ProductFilterPills = (props: PropsType): JSX.Element => {
  const { column, setColumns } = props;
  const [text] = useTranslation('dashboard');

  const filterByAttribute = (triggeredColumn: Column, filteringKey: string) => {
    // TODO: Auslagern & Merge with filterByAttributes in
    // creating temporary column, finding correct column
    // and replacing it in the state object with the temporary column
    const tempFiltering: { [K: string]: boolean } = {
      ...triggeredColumn.filterInfo.filtering,
      [filteringKey]: !triggeredColumn.filterInfo.filtering[filteringKey],
    };
    const tempFilterInfo = {
      filterable: true,
      filtering: tempFiltering,
    };
    const tempColumn: Column = {
      ...triggeredColumn,
      filterInfo: tempFilterInfo,
    };

    setColumns((oldColumns) => {
      return oldColumns.map((col) =>
        col.name === tempColumn.name ? tempColumn : col
      );
    });
  };
  if (column && column.filterInfo.filtering) {
    const processStatusGroups = [];
    for (const statusGroup of Object.keys(
      ProcessStatusGroups
    ) as ProcessStatusGroupTypeKey[]) {
      const processStatusPills = [];
      for (const status of ProcessStatusGroups[statusGroup]) {
        processStatusPills.push(
          <Button
            id={`FilterMenu.Segment.FilterPill.${status}`}
            key={status}
            size="small"
            className={`filterpill ${status.toLowerCase()}`}
            active={column?.filterInfo.filtering[status]}
            onClick={() => filterByAttribute(column, status)}
            content={text(`filterPills.${status}`)}
          />
        );
      }
      processStatusGroups.push(
        <div key={statusGroup} className={`filtergroup ${statusGroup}`}>
          <h5>
            {statusGroup !== 'other'
              ? text(`department.${statusGroup}`, { ns: 'users' })
              : null}
          </h5>
          {processStatusPills}
        </div>
      );
    }
    return <div className="filterpills"> {processStatusGroups} </div>;
  }

  return <div />;
};

export default ProductFilterPills;
