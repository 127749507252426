import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Select } from 'semantic-ui-react';
import {
  BinaryCodeOptions,
  DistributionOptions,
  DropdownOptions,
  OptionTypes,
  ServerUsageOptions,
  SourceRecipientsOptions,
} from '../../../../wizard/WizardConstants';
import WizardFormFieldLabel from '../../../../wizard/WizardFormFieldLabel';
import { ProductInfoBoxContext } from '../../../../../context/ProductContext';

interface DistributionEditingModalProps {
  open: boolean;
  setOpen(openDistribution: boolean): void;
  /* distribution: string | undefined;
  setPartialVersionInput: Dispatch<SetStateAction<UpdateVersionInput>>; */
}

/**
 *@param {string} openDistribution openModal
 *@returns {JSX.Element}  Editing modal for User updating distribution property
 */
const DistributionModal = ({
  open,
  setOpen,
}: /* distribution: distribution,
  setPartialVersionInput, */
DistributionEditingModalProps): JSX.Element => {
  const [t] = useTranslation(['newProductWizard', 'buttons', 'productDetails']);
  const { version, versionInput, setPartialVersionInput } = useContext(
    ProductInfoBoxContext
  );
  const distribution =
    versionInput.selectedDistribution ??
    (version?.product && 'selectedDistribution' in version.product
      ? version.product.selectedDistribution?.id
      : undefined);

  const [modalDistribution, setModalDistribution] = useState<
    string | undefined
  >();
  const [recipient, setRecipient] = useState<string | undefined>(distribution);

  const serverUsageOptions: Array<DropdownOptions> = ServerUsageOptions();
  const sourceRecipientsOptions = SourceRecipientsOptions();
  const binaryCodeOptions = BinaryCodeOptions();

  useEffect(() => {
    if (distribution && modalDistribution === undefined) {
      if (serverUsageOptions.find((opt) => opt.value === distribution)) {
        setModalDistribution('noDist');
      } else if (
        sourceRecipientsOptions.find((opt) => opt.value === distribution)
      ) {
        setModalDistribution('distSource');
      } else if (binaryCodeOptions.find((opt) => opt.value === distribution)) {
        setModalDistribution('binaryCode');
      }
    }
  }, [
    distribution,
    binaryCodeOptions,
    modalDistribution,
    serverUsageOptions,
    sourceRecipientsOptions,
  ]);

  const handleDistUpdate = () => {
    setPartialVersionInput({ selectedDistribution: recipient });
    setOpen(false);
    setModalDistribution(undefined);
  };

  return (
    <Modal
      size="tiny"
      id="DistributionEditModal"
      open={open === true}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Modal.Header content={t('distribution', { ns: 'productDetails' })} />
      <Modal.Content>
        <Modal.Description>
          <h5 style={{ color: 'lightgrey' }}>
            {t('description', { ns: 'productDetails' })}
          </h5>
          <p id="DistributionDescription">
            {t('distributionDescription', { ns: 'productDetails' })}
          </p>
        </Modal.Description>
        <Form
          fluid="true"
          id="DistributionForm"
          className="distribution-form mt-40"
        >
          <Form.Group widths="equal">
            <span className="field">
              <WizardFormFieldLabel
                nameText={t('intendedUsePart.distribution')}
                infoText={t('intendedUsePart.distribution-info')}
              />
              <Form.Field
                control={Select}
                options={DistributionOptions()}
                defaultOpen={false}
                value={modalDistribution || ''}
                placeholder={t('pleaseSelect')}
                onChange={(_e: ChangeEvent, { value }: OptionTypes) => {
                  setModalDistribution(value);
                  setRecipient(undefined);
                }}
              />
            </span>
            {modalDistribution === 'noDist' && (
              <span className="field">
                <WizardFormFieldLabel
                  nameText={t('intendedUsePart.serverUsage')}
                  infoText={t('intendedUsePart.serverUsage-info')}
                />
                <Form.Field
                  control={Select}
                  options={serverUsageOptions}
                  name="distribution"
                  value={recipient || ''}
                  placeholder={t('pleaseSelect')}
                  onChange={(_e: ChangeEvent, { value }: OptionTypes) => {
                    setRecipient(value);
                  }}
                />
              </span>
            )}
            {modalDistribution === 'distSource' && (
              <span className="field">
                <WizardFormFieldLabel
                  nameText={t('intendedUsePart.sourceRecipients')}
                  infoText={t('intendedUsePart.sourceRecipients-info')}
                />
                <Form.Field
                  control={Select}
                  options={sourceRecipientsOptions}
                  name="distribution"
                  value={recipient || ''}
                  placeholder={t('pleaseSelect')}
                  onChange={(_e: ChangeEvent, { value }: OptionTypes) => {
                    setRecipient(value);
                  }}
                />
              </span>
            )}
            {modalDistribution === 'binaryCode' && (
              <span className="field">
                <WizardFormFieldLabel
                  nameText={t('intendedUsePart.binaryRecipients')}
                  infoText={t('intendedUsePart.binaryRecipients-info')}
                />
                <Form.Field
                  control={Select}
                  options={binaryCodeOptions}
                  name="distribution"
                  value={recipient || ''}
                  placeholder={t('pleaseSelect')}
                  onChange={(_e: ChangeEvent, { value }: OptionTypes) => {
                    setRecipient(value);
                  }}
                />
              </span>
            )}
          </Form.Group>
        </Form>
      </Modal.Content>

      <Modal.Actions style={{ textAlign: 'center' }}>
        {recipient !== distribution && recipient !== undefined ? (
          <Button
            style={{ margin: '0 auto', padding: '0, 15px' }}
            primary
            id="UpdateDistributionBtn"
            content={t('saveChanges', { ns: 'buttons' })}
            onClick={() => {
              handleDistUpdate();
            }}
          />
        ) : (
          <Button
            style={{ margin: '0 auto' }}
            basic
            primary
            id="CloseDistributionBtn"
            content={t('close', { ns: 'buttons' })}
            onClick={() => {
              setOpen(false);
              setRecipient(distribution);
              setModalDistribution(undefined);
            }}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default DistributionModal;
