import { DocumentNode } from '@apollo/client';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Label, Segment } from 'semantic-ui-react';
import { Comment } from '../../../../graphql/generated/graphql';
import { toLocalDateAndTime } from '../helpers/logics';
import CreateCommentComponent from './CreateCommentComponent';
import { filterReplies } from './helper/logics';
import { UserContext } from '../../../../context/UserContext';
import { displayedInputType } from './helper/types';

type CommentProps = {
  comment: Comment;
  commentIndex: number;
  refersToId?: string;
  replies?: Comment[];
  allComments: Comment[];
  refetch: (string | DocumentNode)[];
  iconsOnlyReplies?: boolean;
  setDisplayedInput: (displayedInput: displayedInputType) => void;
  displayedInput: displayedInputType;
};

/**
 *@returns {JSX.Element} Comment content element
 @param {CommentProps} props Props for the comment
 */
const CommentContentComponent = (props: CommentProps): JSX.Element => {
  const {
    comment,
    commentIndex,
    refersToId,
    replies,
    allComments,
    refetch,
    iconsOnlyReplies = false,
    setDisplayedInput,
    displayedInput,
  } = props;
  const [activeReply, setActiveReply] = useState(false);
  const { i18n } = useTranslation();
  const reply = comment.replied; // for the comments that are replies
  const date = toLocalDateAndTime(comment.createdAt, i18n.language);
  const currentUser = useContext(UserContext);

  // const { displayedInput, setDisplayedInput } = useContext(CommentsContext);

  const findParentComment = () => {
    return allComments.find((par) => par.id === comment.replied?.id);
  };
  const parentComment = findParentComment();

  const parentDate = toLocalDateAndTime(
    parentComment?.createdAt,
    i18n.language
  );

  const getCurrentTimeAsString = (): string => {
    const now = new Date();
    return now.toISOString();
  };

  const currentTime = toLocalDateAndTime(
    getCurrentTimeAsString(),
    i18n.language
  );

  const handleReply = () => {
    setActiveReply(true);
    setDisplayedInput('reply');
  };

  return (
    <>
      {!reply ? (
        <Segment
          id="CommentContentComponentSegment"
          className="CommentContentComponentSegment"
          secondary
        >
          <div>
            <div className="CommentComponentInfoDisplay">
              <div className="CommentComponentInfoDisplayTime">
                <Label
                  id="CommentContentComponentIndexCommentLabel"
                  className="CommentContentComponentLabel"
                  content={commentIndex + 1} // place on the list of comments in component
                  circular
                />
                <span>{comment.createdBy.name}</span>
                <span>{' | '}</span>
                <span>{date}</span>
              </div>
              {iconsOnlyReplies && (
                <span style={{ marginLeft: 'auto' }}>
                  <Icon
                    id="CommentContentComponentReplyIcon"
                    className="CommentContentComponentIcons"
                    name="reply"
                    onClick={handleReply}
                    link
                    size="large"
                  />
                </span>
              )}
            </div>
            <div className="CommentContentComponentText">{comment.text}</div>
          </div>
        </Segment>
      ) : (
        // REPLY component
        <Segment
          id="CommentContentReplyToComponentSegment"
          className="CommentContentComponentSegment"
          secondary
          style={{
            width: '80%',
          }}
        >
          <div>
            <div className="CommentComponentInfoDisplay">
              <div className="CommentComponentInfoDisplayTime">
                {/* here no LABEL with index number -it was causing order confusion */}
                <span>{comment.createdBy.name}</span>
                <span>{' | '}</span>
                <span>{date}</span>
              </div>
              <span style={{ marginLeft: 'auto' }}>
                <Icon
                  id="CommentContentComponentReplyToReplyIcon"
                  className="CommentContentComponentIcons"
                  link
                  onClick={handleReply}
                  name="reply"
                  size="large"
                />
              </span>
            </div>
            {parentComment && parentComment.replied && (
              <Segment
                id="CommentContentReplyToComponentSegmentNested111"
                tertiary
                style={{ border: 'none', boxShadow: 'none' }}
              >
                <div className="CommentComponentInfoDisplay">
                  <div className="CommentComponentInfoDisplayTime">
                    <span>{parentComment.createdBy.name}</span>
                    <span>{' | '}</span>
                    <span>{parentDate}</span>
                  </div>
                </div>
                <div className="CommentContentNestedComment">
                  {parentComment.text}
                </div>
              </Segment>
            )}
            <div className="CommentContentComponentText">{comment.text}</div>
          </div>
        </Segment>
      )}
      {replies &&
        replies
          .sort((a, b) => {
            const dateA = new Date(a.createdAt).getTime();
            const dateB = new Date(b.createdAt).getTime();
            return dateA - dateB; // sorts replies in ascending order (oldest first)
          })
          .map((r) => (
            <CommentContentComponent
              key={`ReplyCommentsModalWindowSegment${r.id}`}
              replies={filterReplies(r, allComments)}
              allComments={allComments}
              comment={r}
              commentIndex={replies.indexOf(r)} // not used for replies -confusing the order of comments
              refersToId={refersToId}
              refetch={refetch}
              displayedInput={displayedInput}
              setDisplayedInput={setDisplayedInput}
            />
          ))}
      {displayedInput === 'reply' && activeReply && (
        <Segment
          id="CommentContentReplyToComponentSegmentReply"
          className="CommentContentComponentSegment"
          secondary
          style={{
            width: '80%',
          }}
        >
          <div>
            <div className="CommentComponentInfoDisplay">
              <span>{currentUser.name}</span>
              <span>{' | '}</span>
              <span>{currentTime}</span>
            </div>

            <Segment
              id="CommentContentReplyToComponentSegmentNested"
              tertiary
              style={{ border: 'none', boxShadow: 'none' }}
            >
              <div className="CommentComponentInfoDisplay">
                <div className="CommentComponentInfoDisplayTime">
                  <span>{comment?.createdBy.name}</span>
                  <span>{' | '}</span>
                  <span>{date}</span>
                </div>
              </div>
              <div className="CommentContentNestedComment">{comment.text}</div>
            </Segment>

            <CreateCommentComponent
              refersToId={refersToId}
              isReplied={comment.id}
              refetch={refetch}
              createComment={activeReply}
              setCreateComment={setActiveReply}
              setDisplayedInput={setDisplayedInput}
            />
          </div>
        </Segment>
      )}
    </>
  );
};

export default CommentContentComponent;
