import React from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';

/**
 * Message displayed along with comments after the legal check is completed
 *
 *@returns {JSX.Element} element
 */
const CommentsDisclaimer = (): JSX.Element => {
  const [t] = useTranslation('comments');
  const email = t('legalCheckDoneEmail');
  const content = (
    <span>
      <span>{t('legalCheckDoneDisclaimer')}</span>
      <a id="LegalCheckDoneMailto" href={`mailto:${email}`}>
        {email}
      </a>
      .
    </span>
  );
  return (
    <Message
      id="LegalCheckDoneMessage"
      info
      icon="aosd info filled"
      content={content}
    />
  );
};
export default CommentsDisclaimer;
