import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Popup, PopupProps } from 'semantic-ui-react';
import useDebounce from '../../../../../hooks/useDebounce';

type Props = {
  content: JSX.Element | string;
  trigger?: JSX.Element;
  triggerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  triggerClassName?: string;
  contentClassName?: string;
  on?: Array<'click' | 'hover' | 'focus'>;
  isProject?: boolean;
  isHistoryWithModal?: boolean;
  position?: PopupProps['position'];
};

/**
 * @param {Props} props { content, trigger, triggerStyle, contentStyle, triggerClassName, contentClassName, on, isProject }
 * @returns {JSX.Element} Popup with text.
 */
const OverflowPopup = (props: Props): JSX.Element => {
  const {
    content,
    trigger,
    triggerStyle,
    contentStyle,
    triggerClassName,
    contentClassName,
    on,
    isProject,
    isHistoryWithModal,
    position = 'top center',
  } = props;

  const ref = useRef<HTMLElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const checkOverflowRecursive = (element: HTMLElement | null): boolean => {
    if (!element) return false;
    if (element.scrollWidth > element.clientWidth) {
      return true;
    }

    const children = Array.from(element.children) as HTMLElement[];
    for (let i = 0; i < children.length; i += 1) {
      const child = children[i];
      if (child.scrollWidth > child.clientWidth) {
        return true;
      }
      if (checkOverflowRecursive(child)) {
        return true;
      }
    }
    return false;
  };

  const checkOverflow = () => {
    setIsOverflowing(checkOverflowRecursive(ref.current));
  };

  const debouncedCheckOverflow = useDebounce(checkOverflow, 200);

  useEffect(() => {
    checkOverflow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]); // ref.current?.scrollWidth, ref.current?.clientWidth

  useLayoutEffect(() => {
    let isMounted = true;

    const delayedCheckOverflow = async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
      if (isMounted) {
        checkOverflow();
      }
    };

    delayedCheckOverflow();
    window.addEventListener('resize', debouncedCheckOverflow);

    return () => {
      isMounted = false; //
      window.removeEventListener('resize', debouncedCheckOverflow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePopupClick = () => {
    setIsOpen(false); // Close the popup when clicked
  };

  return (
    <Popup
      id="OverflowPopup"
      disabled={!(isOverflowing || (isHistoryWithModal ?? false))}
      open={isOpen}
      on={on || ['hover', 'focus']}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      content={
        <div
          role="button"
          tabIndex={0}
          onClick={handlePopupClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handlePopupClick();
            }
          }}
        >
          <span style={contentStyle} className={contentClassName}>
            {content}
          </span>
        </div>
      }
      trigger={
        trigger ? (
          <span ref={ref} style={triggerStyle} className={triggerClassName}>
            {trigger}
          </span>
        ) : (
          <span
            ref={ref}
            className={triggerClassName || 'overflow-popup-trigger'}
            style={triggerStyle}
          >
            {/* if its`a Project than display "bold"  */}
            {isProject ? <b id="ProjectsAreBold">{content}</b> : content}
          </span>
        )
      }
      position={position}
      hideOnScroll
      hoverable
      style={contentStyle}
    />
  );
};

export default OverflowPopup;
