import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Product, Version } from '../../../../../graphql/generated/graphql';
import { commentCountForProduct } from '../helpers/logics';
import OverflowPopup from './OverflowPopup';

type PropsType = {
  product?: Product;
  version?: Version;
};

/**
 * Comments Count element for Products in Dashboard and Product versions in Dependency List
 * - number of all comments in a product and/or version
 *
 * @param {PropsType} props props
 * @returns {JSX.Element} a span with text
 */
const CommentCountElement = (props: PropsType): JSX.Element => {
  const { product = null, version } = props;

  const commentCount = product
    ? commentCountForProduct(product)
    : version?.comments.length || 0;

  return (
    <OverflowPopup
      content={
        <span>
          <Icon id="ReadOnlyComments" name="comment outline" size="large" />
          <span>{commentCount}</span>
        </span>
      }
    />
  );
};

export default CommentCountElement;
