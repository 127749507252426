import React, { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Message, Popup, Segment } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  PossibleInfo,
  Role,
  StateChangePossible,
  useFinishLegalCheckPossibleLazyQuery,
  useCompletionPossibleLazyQuery,
  useProjectCompletionPossibleLazyQuery,
  useFinishSupplierReportPossibleLazyQuery,
  useGetLatestLcDocumentLazyQuery,
  useLegalCheckPossibleLazyQuery,
  useSupplierInputDonePossibleLazyQuery,
  useSupplierInputPossibleLazyQuery,
  useSupplierToDosDonePossibleLazyQuery,
  useSupplierToDosPossibleLazyQuery,
} from '../../../../graphql/generated/graphql';
import StateChangeButton from './StateChangeButton';
import {
  StateMapping,
  arraysEqualUnordered,
  getMutationsForRoleAndStatus,
  mappingStatesToMutation,
  osoAnsweredVar,
} from './helper/logics';
import {
  ProcessStatus,
  ProcessStatusType,
} from '../dependencySegment/dependencyList/helper/types';
import StepsToProceed from './StepsToProceed';
import ImportModalButton from '../dependencySegment/ImportModalButton';
import { renderAdditionalButtons } from './helper/renderLogics';

/**
 * Footbar with Dynamic Button for Product Workflow.
 *
 * @returns {JSX.Element} ProductDetailsFootbar.
 */
const ProductDetailsFootBar = (): JSX.Element | null => {
  const { t } = useTranslation('productDetails');
  const {
    versionId,
    authorizations,
    accessRoles,
    processStatusAsString,
    isProject,
    isGroupTransfer,
    isSupplierProduct,
  } = useContext(ProductDetailsContext);
  const { selectedRole } = useContext(SelectedRoleContext);

  const osoAnswered = useReactiveVar(osoAnsweredVar);

  const showTransferMessage =
    [Role.developer, Role.supplier].includes(selectedRole) &&
    isGroupTransfer &&
    processStatusAsString !== ProcessStatus[ProcessStatusType.COMPLETED];

  const showTransferCreationMessage =
    selectedRole === 'developer' &&
    isGroupTransfer &&
    processStatusAsString === ProcessStatus[ProcessStatusType.OSOREQUESTED];

  const transferInfo = showTransferCreationMessage
    ? t('transferCreationInfo')
    : t('transferInfo');

  const showProductClosedInfo =
    (authorizations.includes('DownloadLcDocument') ||
      authorizations.includes('DownloadReport')) &&
    processStatusAsString === 'COMPLETED';

  const preliminary = authorizations.includes('GetPreliminaryLcDocument');

  const [legalCheckPossible, { data: legalCheckPossibleData }] =
    useLegalCheckPossibleLazyQuery({
      variables: { versionId },
    });
  const [finishLegalCheckPossible, { data: finishLegalCheckPossibleData }] =
    useFinishLegalCheckPossibleLazyQuery({
      variables: { versionId },
    });
  const [supplierInputPossible, { data: supplierInputPossibleData }] =
    useSupplierInputPossibleLazyQuery({
      variables: { versionId },
    });
  const [supplierInputDonePossible, { data: supplierInputDonePossibleData }] =
    useSupplierInputDonePossibleLazyQuery({
      variables: { versionId },
    });
  const [supplierToDosPossible, { data: supplierToDosPossibleData }] =
    useSupplierToDosPossibleLazyQuery({
      variables: { versionId },
    });
  const [supplierToDosDonePossible, { data: supplierToDosDonePossibleData }] =
    useSupplierToDosDonePossibleLazyQuery({
      variables: { versionId },
    });
  const [
    finishSupplierReportPossible,
    { data: finishSupplierReportPossibleData },
  ] = useFinishSupplierReportPossibleLazyQuery({
    variables: { versionId },
  });
  const [completionPossible, { data: completionPossibleData }] =
    useCompletionPossibleLazyQuery({
      variables: { versionId },
    });
  const [projectCompletionPossible, { data: projectCompletionPossibleData }] =
    useProjectCompletionPossibleLazyQuery({
      variables: { versionId },
      fetchPolicy: 'cache-and-network',
    });
  const [getLatestLcDocument, { data: getLatestLcDocumentData }] =
    useGetLatestLcDocumentLazyQuery({
      variables: {
        versionId,
        preliminary,
      },
    });

  const disabled = (state: StateMapping) => {
    if (!state.authorized) {
      return true; // Disable if not authorized
    }
    if (state.mutationName === 'SubmitToLegal') {
      return !legalCheckPossibleData?.LegalCheckPossible?.stateChangePossible;
    }
    if (state.mutationName === 'RequestSupplierInput') {
      return !supplierInputPossibleData?.SupplierInputPossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'RequestSupplierToDos') {
      return !supplierToDosPossibleData?.SupplierToDosPossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'FinishLegalCheck') {
      return !finishLegalCheckPossibleData?.FinishLegalCheckPossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'FinishProductCheck') {
      return !completionPossibleData?.CompletionPossible?.stateChangePossible;
    }
    if (state.mutationName === 'SupplierInput') {
      return !supplierInputPossibleData?.SupplierInputPossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'SupplierInputDone') {
      return !supplierInputDonePossibleData?.SupplierInputDonePossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'SupplierToDosDone') {
      return !supplierToDosDonePossibleData?.SupplierToDosDonePossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'FinishProjectCheck') {
      return !projectCompletionPossibleData?.ProjectCompletionPossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'FinishSupplierReport') {
      return !finishSupplierReportPossibleData?.FinishSupplierReportPossible
        ?.stateChangePossible;
    }
    return false;
  };

  const getStateDetails = (state: StateMapping): StateChangePossible => {
    const mapping: Record<string, StateChangePossible | undefined | null> = {
      SubmitToLegal: legalCheckPossibleData?.LegalCheckPossible,
      RequestSupplierInput: supplierInputPossibleData?.SupplierInputPossible,
      SupplierInputDone:
        supplierInputDonePossibleData?.SupplierInputDonePossible,
      RequestSupplierToDos: supplierToDosPossibleData?.SupplierToDosPossible,
      FinishLegalCheck: finishLegalCheckPossibleData?.FinishLegalCheckPossible,
      FinishProductCheck: completionPossibleData?.CompletionPossible,
      SupplierToDosDone:
        supplierToDosDonePossibleData?.SupplierToDosDonePossible,
      FinishProjectCheck:
        projectCompletionPossibleData?.ProjectCompletionPossible,
      FinishSupplierReport:
        finishSupplierReportPossibleData?.FinishSupplierReportPossible,
      // adding steps to mutations without 'Possible' resolvers manually
      JsonImport: {
        stateChangePossible: true,
        reason: null,
        steps: [
          {
            step: PossibleInfo.JSON_NOT_IMPORTED,
            fulfilled: processStatusAsString !== 'INCREATION',
          },
        ],
      },
      SubmitToUser: {
        stateChangePossible: true,
        reason: null,
        steps:
          selectedRole === Role.oso
            ? [
                {
                  step: PossibleInfo.OSO_ANSWER,
                  fulfilled: osoAnswered,
                },
              ]
            : [],
      },
      RequestSupplierReport: {
        stateChangePossible: authorizations.includes('RequestSupplierReport'),
        reason: null,
        steps: [],
      },
      FinishReport: {
        stateChangePossible: true,
        reason: null,
        steps: [
          {
            step: PossibleInfo.CHECK_SIGNED_REPORT,
            fulfilled: false,
          },
        ],
      },
      SubmitToBrandOSO: {
        stateChangePossible: true,
        reason: null,
        steps:
          selectedRole === Role.oso
            ? [
                {
                  step: PossibleInfo.REQUEST_BRANDOSO_DOCS,
                  fulfilled: true,
                },
              ]
            : [],
      },
    };

    const data = mapping[state.mutationName as keyof typeof mapping];

    if (!data) {
      return {
        stateChangePossible: false,
        reason: undefined,
        steps: undefined,
      };
    }

    return {
      stateChangePossible: data.stateChangePossible || false,
      reason: data.reason || PossibleInfo.INVALID_STATE,
      steps: data.steps || [],
    };
  };

  useEffect(() => {
    if (authorizations?.includes('LegalCheckPossible')) legalCheckPossible();
    if (authorizations?.includes('SupplierInputPossible'))
      supplierInputPossible();
    if (authorizations?.includes('SupplierInputDonePossible'))
      supplierInputDonePossible();
    if (authorizations?.includes('SupplierToDosPossible'))
      supplierToDosPossible();
    if (authorizations?.includes('FinishLegalCheckPossible'))
      finishLegalCheckPossible();
    if (authorizations?.includes('CompletionPossible')) completionPossible();
    if (authorizations?.includes('SupplierToDosDonePossible'))
      supplierToDosDonePossible();
    if (authorizations?.includes('ProjectCompletionPossible'))
      projectCompletionPossible();
    if (authorizations?.includes('FinishSupplierReportPossible'))
      finishSupplierReportPossible();
    if (authorizations?.includes('GetLatestLcDocument')) getLatestLcDocument();
  }, [
    authorizations,
    finishLegalCheckPossible,
    completionPossible,
    legalCheckPossible,
    supplierInputPossible,
    supplierInputDonePossible,
    supplierToDosPossible,
    supplierToDosDonePossible,
    projectCompletionPossible,
    finishSupplierReportPossible,
    getLatestLcDocument,
    processStatusAsString,
  ]);

  // adding state: "authorized" regarding authorizations for each stateChangeBtn mutations
  const allMutations = mappingStatesToMutation.map((state) => {
    return {
      ...state,
      authorized: authorizations?.includes(state.mutationName),
    };
  });
  // filtering mutations to render buttons
  const applicableMutations = getMutationsForRoleAndStatus(
    allMutations,
    processStatusAsString,
    accessRoles,
    isProject,
    isGroupTransfer,
    selectedRole as string
  ).sort((a, b) => {
    const aDetails = getStateDetails(a);
    const bDetails = getStateDetails(b);

    // Prioritize mutations with steps
    if (aDetails.steps?.length && !bDetails.steps?.length) return -1;
    if (!aDetails.steps?.length && bDetails.steps?.length) return 1;
    return 0;
  });

  return (
    <Segment id="ProductDetailsFootBar" className="footbar" basic>
      {showTransferMessage && (
        <span>
          <Message
            id="GroupTransferInfo"
            info
            icon="info circle"
            content={transferInfo}
          />
        </span>
      )}
      {showProductClosedInfo && (
        <span>
          <Message
            id="ProductClosedMessage"
            info
            icon="info circle"
            content={
              getLatestLcDocumentData?.GetLatestLcDocument.file
                ? t('productClosedLcDocument')
                : t('productClosed')
            }
          />
        </span>
      )}
      {applicableMutations.map((state: StateMapping, index: number) => {
        const id = `StateChangeButton${state.mutationName}`;
        const { stateChangePossible, reason, steps } = getStateDetails(state);
        const hideSupplierRequests =
          (state.mutationName === 'RequestSupplierInput' ||
            state.mutationName === 'RequestSupplierToDos') &&
          !isSupplierProduct;

        // Handle duplicate steps
        const previousSteps: StateChangePossible[] =
          applicableMutations
            .slice(0, index)
            .flatMap((s) => getStateDetails(s)) || [];
        const previousStepsFlattened = previousSteps.flatMap((s) =>
          s.steps ? s.steps.map((step) => step.step) : []
        );

        const duplicateSteps = steps
          ? arraysEqualUnordered(
              steps.map((s) => s.step),
              previousStepsFlattened
            )
          : false;

        const allStepsCompleted = steps?.every((step) => step.fulfilled);
        const hasSteps = steps && steps.length > 0;
        const isButtonDisabled =
          !stateChangePossible ||
          (hasSteps && !stateChangePossible && !allStepsCompleted) ||
          disabled(state);

        if (
          state.mutationName === 'DownloadLcDocument' ||
          state.mutationName === 'DownloadReport' ||
          state.mutationName === 'GetEmailsByBrand'
        ) {
          const additionalButton = renderAdditionalButtons(
            state,
            isGroupTransfer,
            getLatestLcDocumentData,
            preliminary
          );
          return (
            <Popup
              key={`popup-${state.mutationName}`}
              id="popup"
              content={t(state.content)}
              position="top center"
              disabled={
                state.authorized || state.mutationName === 'GetEmailsByBrand'
              }
              trigger={<span className="right">{additionalButton}</span>}
            />
          );
        }
        return (
          <Fragment key={id}>
            {hasSteps && !hideSupplierRequests && (
              <StepsToProceed
                steps={steps}
                duplicateSteps={duplicateSteps}
                disabled={!state.authorized}
              />
            )}

            {state.mutationName === 'JsonImport' ? (
              <ImportModalButton />
            ) : (
              !hideSupplierRequests && (
                <StateChangeButton
                  id={id}
                  state={state}
                  disabled={isButtonDisabled}
                  reason={reason}
                />
              )
            )}
          </Fragment>
        );
      })}
    </Segment>
  );
};

export default ProductDetailsFootBar;
