/* eslint-disable enforce-ids-in-jsx/unique-ids */ // it does not recognize that the ids are actually unique 🤯
import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import CommentsModalWindow from '../comments/CommentsModalWindow';
import { CommentsModalContext } from '../requirements/helper/types';
import { OpenCommentsModalState } from '../comments/helper/types';
import InfoIconPopup from '../../../ui/InfoIconPopup';
import ToDoPartPopupContent from './ToDoPartPopupContent';

interface TodosInfoPopupProps {
  toDoId: string;
  toDoName: string;
  versionId: string | undefined;
  commentCount: number;
}

/**
 * ToDoIcons Component
 *
 * Component that holds info icon & comments.
 *
 * @param {string} [todoId] - ID of ToDo or Task.
 * @returns {JSX.Element} The rendered `TodosBadgesIcons` component.
 */
const ToDoIcons = ({
  toDoId,
  toDoName,
  versionId,
  commentCount,
}: TodosInfoPopupProps): JSX.Element => {
  const [openCommentsModal, setOpenCommentsModal] =
    useState<OpenCommentsModalState>([false, null]);

  return (
    <>
      <CommentsModalWindow
        openCommentsModal={openCommentsModal}
        setOpenCommentsModal={setOpenCommentsModal}
        elementName={toDoName}
        refersToId={toDoId}
      />
      <span className="todo-icons-container">
        <InfoIconPopup
          on="hover"
          infoText={
            <ToDoPartPopupContent toDoId={toDoId} versionId={versionId} />
          }
        />
        <Icon
          id={`taskComment_${toDoId}`}
          name="comment outline"
          color="blue"
          link
          onClick={() =>
            setOpenCommentsModal([true, CommentsModalContext.Todos])
          }
        />
        {commentCount}
      </span>
    </>
  );
};

export default ToDoIcons;
