import { useContext, useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import useDebounce from '../../../../../hooks/useDebounce';
import {
  SelectionType,
  useSelectToDoMutation,
} from '../../../../../graphql/generated/graphql';
import { ProductDetailsContext } from '../../../../../context/ProductContext';
import { ToDoType } from './types';
import { siblingTasksVar, updateSiblingTasksVar } from './utils';

type UseOptimisticSelectionReturnType = {
  selectionType: SelectionType;
  toggleSelection: () => void;
  loading: boolean;
  error: ApolloError | undefined;
};

/**
 * Custom hook for managing optimistic UI updates in selection toggling.
 *
 * This hook encapsulates the logic for toggling selection states and handling corresponding mutations.
 * It provides an optimistic UI update feature, immediately reflecting the new state in the UI
 * while the mutation request is still in progress.
 *
 * @param {SelectionType} initialSelectionType - The initial selection type of the item (ToDo or Task).
 * @param {string} versionId - The version ID.
 * @param {string} toDoId - The unique identifier of the ToDo or Task.
 * @param {ToDoType[]} siblingTasks - if task is selected, we need to make sure its siblings are deselected.
 * @returns {{
 *   selectionType: SelectionType,
 *   toggleSelection: () => void,
 *   loading: boolean,
 *   error: ApolloError | undefined,
 * }} An object containing the current selection type, a function to toggle selection,
 *    and states indicating the loading status and any errors.
 */
const useOptimisticSelection = (
  initialSelectionType: SelectionType,
  versionId: string | undefined,
  toDoId: string,
  siblingTasks?: ToDoType[]
): UseOptimisticSelectionReturnType => {
  const { authorizations } = useContext(ProductDetailsContext);
  const refetchQueries = [
    'GetToDos',
    'CompletionPossible',
    'SupplierToDosPossible',
    'SupplierToDosDonePossible',
  ];
  const authorizedQueries = refetchQueries.filter((query) =>
    authorizations.includes(query)
  );

  const [isPending, setIsPending] = useState(false);
  const [selectionType, setSelectionType] =
    useState<SelectionType>(initialSelectionType);
  const [SelectToDo, { loading, error }] = useSelectToDoMutation({
    refetchQueries: authorizedQueries,
    onCompleted: () => {
      setIsPending(false);
    },
  });

  useEffect(() => {
    setSelectionType(initialSelectionType);
  }, [initialSelectionType]);

  // Update reactive var with sibling tasks on mount, we need it to handle effectively the optimistic UI of radio buttons
  useEffect(() => {
    if (siblingTasks) {
      const currentTasks = siblingTasksVar();
      const newTasks = Array.from(new Set([...currentTasks, ...siblingTasks]));

      // Only update if tasks actually change
      const arraysAreEqual =
        currentTasks.length === newTasks.length &&
        currentTasks.every((task) => newTasks.includes(task));

      if (!arraysAreEqual) {
        siblingTasksVar(newTasks);
      }
    }
  }, [siblingTasks]);

  const selectTodoMutation = () => {
    if (versionId) {
      SelectToDo({
        variables: { versionId, toDoId },
        // if mutation fails it falls back to the initial value
      }).catch(() => {
        setSelectionType(initialSelectionType);
      });
    }
  };

  const debouncedMutation = useDebounce(selectTodoMutation, 400);

  // toggle selection that updates UI immediately
  const toggleSelection = () => {
    if (isPending) return;

    // this early return prevents deselection by rapid clicking between tasks
    if (
      siblingTasks &&
      siblingTasks.length > 0 &&
      (selectionType === SelectionType.userselected ||
        selectionType === SelectionType.autoselected)
    ) {
      return;
    }

    const isSelected =
      selectionType === SelectionType.userselected ||
      selectionType === SelectionType.autoselected;
    const newSelectionType = isSelected
      ? SelectionType.userdeselected
      : SelectionType.userselected;

    setSelectionType(newSelectionType);
    setIsPending(true);

    // this block mimicks radio button behaviour (only one task can be selected)
    if (siblingTasks && siblingTasks.length > 0) {
      const affectedTask = siblingTasks.find((task) => task.toDo.id === toDoId);
      if (affectedTask) {
        updateSiblingTasksVar((tasks) =>
          tasks.map((task) =>
            task.parentToDoId === affectedTask.parentToDoId
              ? {
                  ...task,
                  selectionType:
                    task.toDo.id === toDoId
                      ? newSelectionType
                      : SelectionType.unselected,
                }
              : task
          )
        );
      }
    }

    debouncedMutation();
  };

  return {
    selectionType,
    toggleSelection,
    loading,
    error,
  };
};

export default useOptimisticSelection;
