import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Icon, List, Message, Modal } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../../../context/ProductContext';
import {
  DeleteDependencyMutation,
  DeleteDependencyMutationVariables,
  Dependency,
  DominatedDependenciesQuery,
  DominatedDependenciesQueryVariables,
} from '../../../../../../../graphql/generated/graphql';
import {
  DEPENDENCIES_BY_VERSION_ID_AMOUNT,
  DOMINATED_DEPENDENCIES,
} from '../../../../../../../graphql/queries/DependencyQuerys';
import { DELETE_DEPENDENCY } from '../../../../../../../graphql/mutations/VersionMutations';
import {
  LEGAL_CHECK_POSSIBLE,
  PRODUCT_DETAILS_CONTEXT,
  VERSION_RESULTS,
} from '../../../../../../../graphql/queries/VersionQuerys';

type Props = {
  dependency: Dependency;
};

/**
 *
 *@returns {JSX.Element} Delete Dependency and all its transitive dependencies
 *@param {string} dependencyId - id of the dependency to be deleted
 */
const DeleteDependency = ({ dependency }: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [open, setOpen] = useState(false);
  const { versionId } = useContext(ProductDetailsContext);

  const [dominatedDependencies, { data }] = useLazyQuery<
    DominatedDependenciesQuery,
    DominatedDependenciesQueryVariables
  >(DOMINATED_DEPENDENCIES, {
    variables: {
      versionId,
      dependencyId: dependency.id,
    },
  });

  const [deleteDominatedDependencies] = useMutation<
    DeleteDependencyMutation,
    DeleteDependencyMutationVariables
  >(DELETE_DEPENDENCY, {
    refetchQueries: [
      PRODUCT_DETAILS_CONTEXT,
      DEPENDENCIES_BY_VERSION_ID_AMOUNT,
      VERSION_RESULTS,
      LEGAL_CHECK_POSSIBLE,
    ],
  });

  const resetAndClose = () => {
    setOpen(false);
  };

  const saveChanges = () => {
    deleteDominatedDependencies({
      variables: {
        versionId,
        dependencyId: dependency.id,
      },
    });
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      dominatedDependencies();
    }
  }, [open, dominatedDependencies]);

  return (
    <Modal
      id="DeleteDependencyModal"
      size="small"
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      open={open && Boolean(data)}
      onOpen={() => setOpen(true)}
      trigger={
        <Icon
          id="DeleteDependencyIcon"
          name="trash alternate outline"
          color="red"
          link
          onClick={() => setOpen(open)}
        />
      }
    >
      <Modal.Header id="DeleteDependencyHeader">
        {t('deleteModal.titleDependency', {
          componentName: dependency.componentName,
        })}
        <Icon
          id="DeleteDependencyCloseButton"
          name="close"
          link
          onClick={resetAndClose}
        />
      </Modal.Header>
      <Modal.Content>
        <h5>{t('deleteModal.descriptionTitle')}</h5>
        <div>
          <span>
            <Trans>
              {t('deleteModal.deleteDependency', {
                componentName: dependency.componentName,
              })}
            </Trans>
          </span>
        </div>
        {data && (
          <List
            id="DeleteDependencyList"
            items={data.DominatedDependencies.map((dep) => ({
              content: `${dep.componentName} ${dep.componentVersion}`,
            }))}
            bulleted
          />
        )}
        <Message
          id="DeleteDependencyMessage"
          error
          icon="times circle"
          content={t('deleteModal.deleteMsgDependency')}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="DeleteDependencySaveChanges"
          color="red"
          content={t('deleteModal.deleteBtn')}
          onClick={() => saveChanges()}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteDependency;
