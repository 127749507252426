import { DocumentNode } from 'graphql';
import {
  ChangeLog,
  Comment,
  Requirement,
  ToDo,
} from '../../../../../graphql/generated/graphql';
import { CommentsModalContext } from '../../requirements/helper/Constants';
import i18n from '../../../../../i18n/i18n';
import {
  ProcessStatus,
  ProcessStatusType,
} from '../../dependencySegment/dependencyList/helper/types';
/**
 * @returns {Comment[]} array of filtered replies to comment
 * @param {Comment | undefined} comment  comment
 * @param {Comment[]} allComments - all comments
 */
export const filterReplies = (
  comment: Comment,
  allComments: Comment[]
): Comment[] => {
  const filteredReplies = allComments?.filter(
    (c) => c.replied && c.replied.id === comment.id
  );
  return filteredReplies;
};

/**
 * Determines the appropriate GraphQL query to refetch based on the given comments modal context.
 *
 * @param {CommentsModalContext | null} openContext - The current context of the comments modal.
 * @returns {string | DocumentNode} - refetch query
 */
export const getRefetchQuery = (
  openContext: CommentsModalContext | null
): string | DocumentNode => {
  switch (openContext) {
    case CommentsModalContext.Requirements:
      return 'GetCommentsForRequirementInVersion';
    case CommentsModalContext.Todos:
      return 'GetCommentsForToDoInVersion';
    case CommentsModalContext.History:
      return 'GetCommentsForHistoryLogInVersion';
    default:
      return 'DependenciesByVersionId';
  }
};

/**
 * Extracts the name or description of the reference (Requirement or ToDo) based on current language.
 *
 * @param {Comment} comment The comment with the reference.
 * @param {boolean} showDescription Flag to determine whether to show the description or name.
 * @returns {string | null} The name or description based on the  language, or null if type is not handled.
 */
export const getReferenceDetails = (
  comment: Comment,
  showDescription = false
): string | null => {
  const typeName = comment.refersTo?.__typename;

  // Type guard for the expected types
  if (typeName === 'Requirement' || typeName === 'ToDo') {
    const { language } = i18n;
    const refersTo = comment.refersTo as Requirement | ToDo;

    if (showDescription) {
      return language === 'de'
        ? refersTo.description_de
        : refersTo.description_en;
    }

    return language === 'de' ? refersTo.name_de : refersTo.name_en;
  }

  return null;
};

/**
 *@param {string} featureId The refersToId of the feature
 * @param {Comment[]} allComments allComments of all features in the version
 *@returns {Comment[]} comments for single feature
 */
export const extractFeaturesComments = (
  featureId: string,
  allComments: Comment[]
): Comment[] => {
  const comments = allComments.filter(
    (c) => c.refersTo && c.refersTo.id === featureId
  );
  return comments;
};
/**
 *  filtering and extracting components-ids and removing duplicates in order to get listing regarding components
 *
 * @param {Comment[]} majorComments Array of major comments
 * @returns {string[]} minorComments Array of
 */
export const filterReferences = (majorComments: Comment[]) => {
  const majorReferences = majorComments.map((c: Comment) => c.refersTo?.id);
  const filteredRefs = majorReferences?.filter(
    (el: string | undefined, index: number) => {
      return majorReferences.indexOf(el) === index;
    }
  );
  return filteredRefs;
};
/**
 * @param {string} logId The refersToId of the History Log Element
 * @param {Comment[]} allComments all history Comments in the version
 * @returns {ChangeLog} history leg/element
 */
export const extractHistoryElement = (
  logId: string,
  allComments: Comment[]
): ChangeLog => {
  const element = allComments.filter(
    (c) => c.refersTo && c.refersTo.id === logId
  );
  return element[0].refersTo as ChangeLog;
};

/**
 * @param {string} processStatus process status as string
 * @returns {boolean} isLegalCheckDone
 */
export const isLegalCheckDone = (processStatus: string) => {
  const processStatusIndex = ProcessStatus.indexOf(processStatus);
  const legalCheckIsDone =
    processStatusIndex >= ProcessStatusType.LEGALCHECKDONE &&
    processStatusIndex !== ProcessStatusType.BRANDOSO;
  return legalCheckIsDone;
};
