import { gql } from '@apollo/client';

/**
 * basic dependency query for dependency list
 *
 * @returns {string} list of the foss product versions dependencies
 */
export const DIRECT_DEPENDENCIES_BY_DEPENDENCY_ID = gql`
  query DirectDependenciesByDependencyId($parentDependencyId: ID!) {
    DirectDependenciesByDependencyId(parentDependencyId: $parentDependencyId) {
      id
      componentName
      comments {
        id
        version {
          id
        }
        createdAt
        createdBy {
          name
        }
        text
        lastModifiedAt
        lastModifiedBy {
          name
        }
        replied {
          id
        }
      }
      subComponents {
        licenses {
          id
          spdxId
          name
        }
        id
        lastModifiedAt
        createdById
      }
      subComponentsConnection {
        edges {
          spdxId
          verified
          copyrights
          permissionNote
        }
      }
      results {
        id
        status
        type
        statusNr
      }
      resolverResult {
        id
        status
        statusNr
      }
      legalResult {
        id
        status
        statusNr
        note
      }
      overallResult {
        id
        status
        statusNr
        note
      }
      linkingFeature
      modified
    }
    # toDos - aktuell noch unklar, siehe https://www.cip.audi.de/wiki/display/AEVAOSD/2022-09-29+AOSD2.0+Umstrukturierung+Feature+Groups+im+Zuge+der+Datenmodell+Umstellung
    # requirements - aktuell noch unklar, siehe ""
    # comments - aktuell nicht vorhanden für Dependencies oder Components
  }
`;

/**
 * data for dependency list in product details view.
 */
export const DEPENDENCIES_BY_VERSION_ID = gql`
  query DependenciesByVersionId(
    $versionId: ID!
    $where: CustomDependencyWhere
    $options: DependencyOptions = {
      sort: [{ componentName: ASC }]
      offset: 0
      limit: 5
    }
  ) {
    DependenciesByVersionId(
      versionId: $versionId
      where: $where
      options: $options
    ) {
      dependency {
        id
        componentName
        copyleft
        componentVersion
        comments {
          id
          createdAt
          createdBy {
            name
          }
          text
          lastModifiedAt
          lastModifiedBy {
            name
          }
          replied {
            id
          }
          version {
            id
          }
          createdAt
          createdBy {
            name
          }
        }
        subComponents {
          id
          lastModifiedAt
          createdById
          licenses {
            id
            spdxId
            name
          }
          component {
            id
            inputSpdxKey
          }
        }
        subComponentsConnection {
          edges {
            spdxId
            selected
            verified
            copyrights
            modified
            permissionNote            
            licenseTextVerificationChecksum
            licenseTextIdentificationChecksum
            subComponentName
            node {
              id
            }
          }
        }
        results {
          id
          status
          type
          statusNr
        }
        resolverResult {
          id
          status
          statusNr
        }
        legalResult {
          id
          status
          statusNr
          note
          history {
          ... on PropertyChange {
            newValue
            prevValue
            property
            changeType
            category
            createdAt
            }
          }
        }
        overallResult {
          id
          status
          statusNr
          note
        }
        linkingFeature
        linking
        modified
      }
      directDependency
      sourceDependencyIds
    }
  }
`;

/**
 * data for dependency list with amount in product details view. Very similar to DEPENDENCIES_BY_VERSION_ID,
 * but I was unsure as to where that query was already implemented and this here contains breaking changes.
 */
export const DEPENDENCIES_BY_VERSION_ID_AMOUNT = gql`
  query DependenciesByVersionIdAmount(
    $versionId: ID!
    $where: CustomDependencyWhere
  ) {
    DependenciesByVersionIdAmount(versionId: $versionId, where: $where)
  }
`;

/**
 * get list of all licenses for all dependencies of a specific product version
 *
 * @param {string} versionId - id of the product version
 */
export const DEPENDENCIES_BY_VERSION_ID_LICENSES = gql`
  query DependenciesByVersionIdLicenses($versionId: ID!) {
    GetUsedLicensesInProduct(versionId: $versionId)
  }
`;

/**
 * get some specific dependency by its id
 */
export const DEPENDENCY_BY_ID = gql`
  query Dependency($where: DependencyWhere) {
    Dependency(where: $where) {
      id
      componentVersion
      componentName
    }
  }
`;
/**
 * get dependency tree for specific dependency with specific src dependency on the path
 */
export const DEPENDENCY_TREE = gql`
  query DependencyTree(
    $versionId: ID!
    $sourceDependencyId: ID!
    $dependencyId: ID!
    $offset: Int = 0
  ) {
    DependencyTree(
      versionId: $versionId
      sourceDependencyId: $sourceDependencyId
      dependencyId: $dependencyId
      offset: $offset
    ) {
      id
      componentName
      componentVersion
      licenses {
        spdxId
        name
      }
      overallResult {
        status
      }
    }
  }
`;

/**
 * get amount of dependency trees for specific dependency with specific src dependency on the path
 */
export const DEPENDENCY_TREE_AMOUNT = gql`
  query DependencyTreeAmount(
    $versionId: ID!
    $sourceDependencyId: ID!
    $dependencyId: ID!
  ) {
    DependencyTreeAmount(
      versionId: $versionId
      sourceDependencyId: $sourceDependencyId
      dependencyId: $dependencyId
    )
  }
`;

/**
 * get dependency's children
 */
export const DEPENDENCY_CHILDREN = gql`
  query DependencyChildren($dependencyId: ID!) {
    DependencyChildren(dependencyId: $dependencyId) {
      componentName
      id
      componentVersion
      licenses {
        spdxId
        name
      }
      overallResult {
        status
      }
    }
  }
`;

/**
 * Get License Text from Artifactory
 */
export const GET_LICENSE_TEXT = gql`
  query GetLicenseText($licenseIdentificationChecksum: String!) {
    GetLicenseText(
      licenseIdentificationChecksum: $licenseIdentificationChecksum
    )
  }
`;

/**
 * Returns a list of all dependencies that must go through the given dependency to reach the version.
 */
export const DOMINATED_DEPENDENCIES = gql`
  query DominatedDependencies($versionId: ID!, $dependencyId: ID!) {
    DominatedDependencies(versionId: $versionId, dependencyId: $dependencyId){
      id
      componentName
      componentVersion
    }
  }
`;