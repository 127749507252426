import React from 'react';
import { Grid, Label, List } from 'semantic-ui-react';
import i18n from '../../../../i18n/i18n';
import TodosBadgesIcons from './ToDoBadgesIcons';
import ToDoCheckboxToggle from './ToDoCheckboxToggle';
import ToDoTask from './ToDoTask';
import useOptimisticSelection from './helpers/customHooks';
import { ToDoType } from './helpers/types';

interface ToDoProps {
  todos: ToDoType[];
  versionId: string | undefined;
  toDosIndex: number;
  todo: ToDoType[][number];
}
/**
 * ToDo Component. May contain Tasks (children).
 *
 *
 * @returns {JSX.Element} The TO DO Component
 * @param {ToDoProps}props description
 */
const ToDo = (props: ToDoProps): JSX.Element => {
  const { todos, versionId, todo, toDosIndex } = props;
  const { name_en: toDoNameEn, name_de: toDoNameDe, id: todoId } = todo.toDo;
  const { language } = i18n;
  const { selectionType, toggleSelection } = useOptimisticSelection(
    todo.selectionType,
    versionId,
    todoId
  );
  const tasks = todo.toDo?.tasks || [];

  const toDoName = language === 'de' ? toDoNameDe : toDoNameEn;

  const relevantTodoIds = todos
    .filter((td) => td.parentToDoId === todoId)
    .map((td) => td.toDo.id);

  const filteredTasks = tasks.filter((task) =>
    relevantTodoIds.includes(task.id)
  );

  const selected =
    selectionType === 'userselected' || selectionType === 'autoselected';

  return (
    <List.Item
      style={{ padding: 0, paddingBottom: '1.25rem' }}
      className="todo-list-item"
    >
      <List.Content>
        <Grid padded="vertically" columns="equal">
          <Grid.Row
            verticalAlign="middle"
            style={{ paddingBottom: filteredTasks.length > 0 ? 0 : '1rem' }}
          >
            <Grid.Column width={1}>
              <Label
                id="EditableProductToDoComponentOrderNumber"
                circular
                content={toDosIndex + 1}
                basic
              />
            </Grid.Column>

            {/* CHECKBOX for Updating selected vs non-selected toDos */}
            {!filteredTasks.length && (
              <Grid.Column width={1}>
                <ToDoCheckboxToggle
                  selectionType={selectionType}
                  onToggle={toggleSelection}
                />
              </Grid.Column>
            )}

            <Grid.Column>
              <div className="todoContainer">
                <p
                  id={`EditableToDoComponentToDoDescription_${todoId}`}
                  className="todo_name"
                  style={{ fontWeight: selected ? 'bold' : 'normal' }}
                >
                  {toDoName}
                </p>
                <TodosBadgesIcons
                  toDoId={todoId}
                  toDoName={toDoName}
                  versionId={versionId}
                  isInternal={todo.toDo.internal}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          {/* TASKS PART */}
          {filteredTasks.length > 0 && (
            <Grid.Row style={{ padding: 0, paddingBottom: '1rem' }}>
              <Grid.Column>
                <List id="EditableToDoListOfTasks">
                  {filteredTasks.map((task) => {
                    return (
                      <ToDoTask
                        key={`task_${task.id}`}
                        versionId={versionId}
                        todos={todos}
                        taskName={
                          language === 'de' ? task.name_de : task.name_en
                        }
                        taskId={task.id}
                        isInternal={task.internal}
                        isAlternative={filteredTasks.length > 1}
                      />
                    );
                  })}
                </List>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </List.Content>
    </List.Item>
  );
};
export default ToDo;
