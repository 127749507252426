import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import {
  ProductDetailsContext,
  ProductInfoBoxContext,
} from '../../../../../context/ProductContext';
import InfoIconPopup from '../../../../ui/InfoIconPopup';
import TypeDropdown from './TypeDropdown';

/**
 * @returns {JSX.Element}  Product Type Component for Info Box
 */
const ProductTypeItem = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { inLegalCheckOrDone } = useContext(ProductDetailsContext);
  const { version, versionInput, editing } = useContext(ProductInfoBoxContext);
  const productType = versionInput.types || version?.product?.type;

  return (
    <Item id="ProductType">
      <Item.Content>
        {editing && !inLegalCheckOrDone ? (
          <TypeDropdown />
        ) : (
          <>
            <Item.Meta>
              {t('productType')}
              <InfoIconPopup infoText={t('productType-info')} />
            </Item.Meta>
            <Item.Description>
              <strong>{productType?.join(' | ')}</strong>
            </Item.Description>
          </>
        )}
      </Item.Content>
    </Item>
  );
};

export default ProductTypeItem;
