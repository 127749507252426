import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { Comment } from '../../../../graphql/generated/graphql';
import CommentsModalWindow from './CommentsModalWindow';
import { CommentsModalContext } from '../requirements/helper/Constants';
import { OpenCommentsModalState } from './helper/types';

type PropsType = {
  comments?: Comment[];
  dependName: string;
  refersToId: string;
};

/**
 * returns element with comments refering to clicked element(dependency or requirement...)
 *
 * @param {PropsType} props takes in product/version
 * @returns {JSX.Element} a span with text
 */
const Comments = (props: PropsType): JSX.Element => {
  const { comments, dependName, refersToId } = props;
  const [openCommentsModal, setOpenCommentsModal] =
    useState<OpenCommentsModalState>([false, null]);

  const commentsContext =
    comments && comments.length > 0 ? CommentsModalContext.Comments : null;
  return (
    <>
      <CommentsModalWindow
        openCommentsModal={openCommentsModal}
        setOpenCommentsModal={setOpenCommentsModal}
        elementName={dependName}
        comments={comments}
        refersToId={refersToId}
      />
      <span className="ShowAddCommentsBtn">
        <Icon
          id="AddDependencyCommentIcon"
          name="comment outline"
          color="blue"
          link
          size="large"
          onClick={() => setOpenCommentsModal([true, commentsContext])}
        />
        <span>{comments?.length}</span>
      </span>
    </>
  );
};

export default Comments;
