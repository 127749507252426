import {
  DependencySubComponentsRelationship,
  Subcomponent,
} from '../../../../../../graphql/generated/graphql';

/**
 * Subcomponent with corresponding subcomponent edge.
 */
export interface SubcomponentInfo {
  subcomponent: Subcomponent;
  subcomponentEdge: DependencySubComponentsRelationship;
}

/**
 * Component with its subcomponents.
 */
export interface ComponentInfo {
  id: string;
  inputSpdxKey: string;
  hasORLicense: boolean;
  subcomponentInfos: SubcomponentInfo[];
}

/**
 * Gets component and subcomponent infos from the subcomponents and their edges.
 *
 * @param {Subcomponent[]} subcomponents - The subcomponents of the dependency.
 * @param {DependencySubComponentsRelationship[]} subcomponentEdges - Edges between the dependency and the subcomponents.
 * @returns {{ componentInfos: ComponentInfo[]; subcomponentInfos: SubcomponentInfo[] }} The components and subcomponents.
 */
export const getComponentAndSubcomponentInfos = (
  subcomponents: Subcomponent[],
  subcomponentEdges: DependencySubComponentsRelationship[]
): {
  componentInfos: ComponentInfo[];
  subcomponentInfos: SubcomponentInfo[];
} => {
  const subcomponentInfos: SubcomponentInfo[] = [];
  subcomponentEdges.forEach((subcomponentEdge) => {
    const subcomponent = subcomponents.find(
      (sub) => sub.id === subcomponentEdge.node.id
    );
    if (subcomponent) {
      subcomponentInfos.push({
        subcomponent,
        subcomponentEdge,
      });
    }
  });

  const componentInfoMap = new Map<string, ComponentInfo>();
  subcomponentInfos.forEach((subcomponentInfo) => {
    const componentId = subcomponentInfo.subcomponent.component.id;
    const componentInfo = componentInfoMap.get(componentId);

    if (componentInfo) {
      componentInfo.subcomponentInfos.push(subcomponentInfo);
    } else {
      componentInfoMap.set(componentId, {
        id: componentId,
        inputSpdxKey: subcomponentInfo.subcomponent.component.inputSpdxKey,
        hasORLicense:
          subcomponentInfo.subcomponent.component.inputSpdxKey.includes(' OR '),
        subcomponentInfos: [subcomponentInfo],
      });
    }
  });

  const componentInfos: ComponentInfo[] = Array.from(componentInfoMap.values());

  return { subcomponentInfos, componentInfos };
};

/**
 * This function finds the subComponent which should be displayed in LicensesElement component.
 * It first checks, whether there is 'main' subcomponent, if not it fallback to those containing
 *  'main_' and being selected, then just selected ones and finally just the first one.
 *
 * @function getDisplayedSubcomponent
 * @param {SubcomponentInfo[]} subcomponentInfos - The subcomponents with their edges.
 * @returns {SubcomponentInfo} Displayed subcomponent info.
 */
export const getDisplayedSubcomponent = (
  subcomponentInfos: SubcomponentInfo[]
): SubcomponentInfo => {
  return (
    subcomponentInfos.find(
      (sub) => sub.subcomponentEdge.subComponentName === 'main'
    ) ||
    subcomponentInfos.find(
      (sub) =>
        sub.subcomponentEdge.subComponentName?.includes('main_') &&
        sub.subcomponentEdge.selected
    ) ||
    subcomponentInfos.find((sub) => sub.subcomponentEdge.selected) ||
    subcomponentInfos[0]
  );
};
