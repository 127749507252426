import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Message, Segment, Table } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../context/ProductContext';
import {
  ChangeLog,
  ProductVersionHistoryQuery,
  ProductVersionHistoryQueryVariables,
} from '../../../graphql/generated/graphql';
import { PRODUCT_VERSION_HISTORY } from '../../../graphql/queries/VersionQuerys';
import LoadingTable from '../productsList/listComponents/LoadingTable';
import { Column } from '../productsList/listComponents/ProductListTypes';
import getDefaultColumns from './helpers/types';
import ProductHistoryListBody from './listComponents/ProductHistoryListBody';
import ProductHistoryListHeader from './listComponents/ProductHistoryListHeader';
import { useFetchHistoryDetails } from './helpers/customHooks';

/**
 * Named collapsable Segment for UI testing and development.
 *
 * @returns {JSX.Element} ProductHistoryList.
 */
const ProductHistoryList = (): JSX.Element => {
  const [text] = useTranslation('history');
  const { versionId } = useContext(ProductDetailsContext);

  const { data, error, loading } = useQuery<
    ProductVersionHistoryQuery,
    ProductVersionHistoryQueryVariables
  >(PRODUCT_VERSION_HISTORY, {
    variables: { id: versionId },
    fetchPolicy: 'cache-and-network',
  });

  const versionHistory = data?.Version?.history || [];
  const productHistory = data?.Version?.product?.history || [];

  const {
    toDoLoading,
    toDoError,
    reqLoading,
    reqError,
    usersError,
    usersLoading,
    versionError,
    versionLoading,
    dependencyError,
    dependencyLoading,
  } = useFetchHistoryDetails(
    versionId,
    versionHistory?.concat(productHistory) as unknown as ChangeLog[]
  );

  const sortedHistoryElements = versionHistory
    ?.concat(productHistory)
    .sort((a, b) => {
      if ('createdAt' in a && 'createdAt' in b) {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
      return 0;
    });

  const columns = getDefaultColumns();

  const isLoading =
    loading ||
    toDoLoading ||
    reqLoading ||
    usersLoading ||
    versionLoading ||
    dependencyLoading;
  const anyError =
    error ||
    toDoError ||
    reqError ||
    usersError ||
    versionError ||
    dependencyError;

  return (
    <Segment raised id="ProductHistoryList">
      {anyError && (
        <Message
          id="ProductHistoryListError"
          error
          icon="times circle"
          content={anyError.message}
        />
      )}
      <>
        {!anyError &&
          sortedHistoryElements &&
          sortedHistoryElements.length > 0 && (
            <Table id="ProductHistoryListTable" fixed singleLine selectable>
              <Table.Header>
                <ProductHistoryListHeader columns={columns} />
              </Table.Header>

              <Table.Body>
                {isLoading ? (
                  <LoadingTable
                    rowAmount={10}
                    columnAmount={
                      columns.filter((col: Column) => col.permitted === true)
                        .length
                    }
                  />
                ) : (
                  <ProductHistoryListBody
                    columns={columns}
                    elements={sortedHistoryElements as unknown as ChangeLog[]}
                  />
                )}
              </Table.Body>
            </Table>
          )}

        {!isLoading &&
          !anyError &&
          (!sortedHistoryElements || sortedHistoryElements.length === 0) && (
            <Message
              id="ProductHistoryListWarning"
              warning
              icon="exclamation triangle"
              content={text('message.noHistory')}
            />
          )}
      </>
    </Segment>
  );
};

export default ProductHistoryList;
