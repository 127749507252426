import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Item, Label, Popup } from 'semantic-ui-react';
import {
  ComponentInfo,
  SubcomponentInfo,
} from '../../helper/licenseElementHelpers';
import UnverifiedIcon from './UnverifiedIcon';
import MissingCopyrightIcon from './MissingCopyrightIcon';
import { ProductDetailsContext } from '../../../../../../../context/ProductContext';

type PropsType = {
  componentInfos: ComponentInfo[];
  subcomponentInfos: SubcomponentInfo[];
  handleLicenseClick: (subcomponentInfo: SubcomponentInfo) => void;
  isOr?: boolean;
};

/**
 * Returns a popup component containing OR and AND licenses of subcomponents.
 *
 * @param {PropsType} props takes in licenses
 * @returns {JSX.Element} a span with license names and icon with amount
 */
const LicensesPopup = (props: PropsType): JSX.Element => {
  const { distributionId } = useContext(ProductDetailsContext);
  const {
    componentInfos,
    subcomponentInfos,
    handleLicenseClick,
    isOr = false,
  } = props;
  const { t } = useTranslation('productDetails');

  const licensesTotalNumber = subcomponentInfos.filter(
    (sci) => sci.subcomponentEdge.selected
  ).length;

  const orComponentInfos = componentInfos.filter(
    (component) => component.hasORLicense
  );

  const additionalSubcomponentInfos = componentInfos
    .filter((ci) => !ci.hasORLicense)
    .flatMap((componentInfo) =>
      componentInfo.subcomponentInfos.filter(
        (sci) => sci.subcomponentEdge.subComponentName !== 'main'
      )
    );

  const uniqueAdditionalSubcomponentInfos = additionalSubcomponentInfos.filter(
    (sci, index, self) =>
      index === self.findIndex((e) => e.subcomponent.id === sci.subcomponent.id)
  );

  return (
    <Popup
      id="LicensesElementListOfLicensesPopup"
      className="license-popup"
      hoverable
      position="top center"
      content={
        <div>
          {orComponentInfos.length > 0 && (
            <>
              <strong>
                {t(`dependencyList.tableBody.orLicenses`, {
                  count: orComponentInfos.length,
                })}
              </strong>
              {orComponentInfos.map((componentInfo, index) => (
                <div key={`or-license-${componentInfo.id}`}>
                  <ul style={{ paddingLeft: '1em' }}>
                    {componentInfo.subcomponentInfos.map((subcomponentInfo) => (
                      <li
                        key={`or-license-part-${subcomponentInfo.subcomponent.id}`}
                      >
                        {subcomponentInfo.subcomponentEdge.selected ? (
                          <Item.Extra
                            onClick={() => handleLicenseClick(subcomponentInfo)}
                          >
                            <span>
                              {subcomponentInfo.subcomponentEdge.spdxId}
                            </span>
                            <UnverifiedIcon
                              verified={
                                subcomponentInfo.subcomponentEdge.verified
                              }
                            />
                            <MissingCopyrightIcon
                              verified={
                                subcomponentInfo.subcomponentEdge.verified
                              }
                              copyrights={
                                subcomponentInfo.subcomponentEdge.copyrights
                              }
                              binary={distributionId}
                            />
                          </Item.Extra>
                        ) : (
                          <Item.Extra
                            onClick={() => handleLicenseClick(subcomponentInfo)}
                          >
                            <s id="unselectedLicense">
                              {subcomponentInfo.subcomponentEdge.spdxId}
                              <UnverifiedIcon
                                verified={
                                  subcomponentInfo.subcomponentEdge.verified
                                }
                              />
                              <MissingCopyrightIcon
                                verified={
                                  subcomponentInfo.subcomponentEdge.verified
                                }
                                copyrights={
                                  subcomponentInfo.subcomponentEdge.copyrights
                                }
                                binary={distributionId}
                              />
                            </s>
                          </Item.Extra>
                        )}
                      </li>
                    ))}
                  </ul>
                  {index !==
                    componentInfos.filter((ci) => ci.hasORLicense).length -
                      1 && (
                    <Divider horizontal>
                      {t('dependencyList.tableBody.and')}
                    </Divider>
                  )}
                </div>
              ))}
            </>
          )}
          {uniqueAdditionalSubcomponentInfos.length > 0 && (
            <div id="AdditionalLicenses" style={{ paddingRight: '1em' }}>
              <strong>{t('dependencyList.tableBody.additional')}</strong>
              <ul style={{ paddingLeft: '1em' }}>
                {uniqueAdditionalSubcomponentInfos.map((subcomponentInfo) => (
                  <li
                    key={`additional-license-${subcomponentInfo.subcomponent.id}`}
                  >
                    <Item.Extra
                      onClick={() => handleLicenseClick(subcomponentInfo)}
                    >
                      <span>{subcomponentInfo.subcomponentEdge.spdxId}</span>
                      <UnverifiedIcon
                        verified={subcomponentInfo.subcomponentEdge.verified}
                      />
                      <MissingCopyrightIcon
                        verified={subcomponentInfo.subcomponentEdge.verified}
                        copyrights={
                          subcomponentInfo.subcomponentEdge.copyrights
                        }
                        binary={distributionId}
                      />
                    </Item.Extra>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      }
      trigger={
        <Label
          circular
          id="LicensesCountLabel"
          content={
            isOr
              ? `/+ ${orComponentInfos.length}`
              : `+${licensesTotalNumber - 1}`
          }
        />
      }
    />
  );
};

export default LicensesPopup;
