import React from 'react';
import { Segment, Menu, Divider } from 'semantic-ui-react';

import EditUserDetailsMenuItem from './EditUserDetailsMenuItem';
import UserDataDisplayPopup from './userComponents/UserDataDisplayPopup';
import UserRolesPopup from './UserRolesPopup';
import Logout from './Logout';

type UserDetailProps = {
  setOpen: (open: boolean) => void;
};

/**
 * UserMenu
 * provides the content to the UserPopup.
 * Shows the users name, brand (if not admin), email, the UserRolesMenu popup, the {@link Logout},
 * and the  {@link EditUserDetailsMenuItem}.
 *
 * @param {UserDetailProps} props calling props
 * @returns {JSX.Element} UserMenu
 */
const UserMenu = (props: UserDetailProps): JSX.Element => {
  const { setOpen } = props;

  return (
    <Segment.Group id="UserMenuSegment" style={{ width: 220, padding: 0 }}>
      <UserDataDisplayPopup />
      <Menu
        id="UserMenu"
        secondary
        vertical
        inverted
        fluid
        style={{ margin: 0 }}
      >
        <UserRolesPopup />
        <Divider fitted />
        <EditUserDetailsMenuItem onClick={() => setOpen(true)} />
        <Divider fitted />
        <Logout />
      </Menu>
    </Segment.Group>
  );
};

export default UserMenu;
