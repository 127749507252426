import { useMutation } from '@apollo/client';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  Item,
  Label,
  Message,
  Modal,
  Popup,
  TextArea,
  TextAreaProps,
} from 'semantic-ui-react';
import { UPDATE_PRODUCT_LICENSE_DETAILS_MUTATION } from '../../../../../../../../graphql/mutations/ProductMutations';
import ProductLicenseDetailsUpdateInputField from './ProductLicenseDetailsUpdateInputField';
import {
  DependencySubComponentsRelationship,
  Subcomponent,
} from '../../../../../../../../graphql/generated/graphql';

type UpdateProps = {
  licenseText: string | undefined;
  copyrightsArr: string[];
  setOpenUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  dependencyId: string | undefined;
  permissionState: boolean | undefined;
  canEditPermissionNote: boolean;
  licenseTextLoading: boolean;
  subComponent: Subcomponent;
  subComponentEdge: DependencySubComponentsRelationship;
};

/**
 *  Editing Product License Details - Copyrights and licenseText
 *
 * @param {UpdateProps} props props
 *@returns {JSX.Element} Returning Product License Details -Editing Mode
 */
const ProductLicenseDetailsUpdate = (props: UpdateProps): JSX.Element => {
  const {
    licenseText,
    copyrightsArr,
    setOpenUpdate,
    dependencyId,
    permissionState,
    canEditPermissionNote,
    licenseTextLoading,
    subComponent,
    subComponentEdge,
  } = props;

  const [text] = useTranslation('productDetails');
  const [copyrights, setCopyrights] = useState(copyrightsArr);
  const [newCopyright, setNewCopyright] = useState('');
  const [textState, setTextState] = useState(licenseText);
  const [newPermissionState, setNewPermissionState] = useState(permissionState); // boolean

  const closeUpdateModal = () => {
    setOpenUpdate(false);
  };

  const [UpdateProductLicenseDetails, { loading, error }] = useMutation(
    UPDATE_PRODUCT_LICENSE_DETAILS_MUTATION,
    {
      refetchQueries: ['DependenciesByVersionId'],
      onCompleted: () => {
        closeUpdateModal();
      },
    }
  );

  const resetAndClose = () => {
    setOpenUpdate(false);
    setCopyrights(copyrightsArr);
    setNewCopyright('');
    setNewPermissionState(permissionState);
  };

  const addCopyright = () => {
    setCopyrights([...copyrights, newCopyright.trim()]);
    setNewCopyright('');
  };

  const handleSaveChanges = () => {
    UpdateProductLicenseDetails({
      variables: {
        dependencyId,
        subcomponentId: subComponent.id,
        spdxId: subComponentEdge.spdxId,
        copyrights,
        licenseText: textState,
        permissionNote: newPermissionState,
        subComponentName: subComponentEdge.subComponentName,
      },
    });
  };

  return (
    <Modal.Content className="LicensesElementModalContent">
      {error && (
        <Message
          id="ProductLicenseDetailsUpdateError"
          error
          icon="times circle"
          content={`Error ${error.message}`}
        />
      )}
      <Item.Group>
        <Item id="ProductLicenseDetailsUpdateCopyrights">
          <Item.Content>
            <Item.Meta>
              <Header as="h5">
                {text('dependencyList.licenseModal.copyrights')}
              </Header>
              <Label
                id="ProductLicenseDetailsUpdateCopyrightsNumberLabel"
                className="LicensesElementModalCopyrightsNumberLabel"
                content={copyrights.length}
              />{' '}
              <Button
                floated="right"
                style={{ margin: '0 1em' }}
                type="button"
                id="ProductLicenseDetailsUpdateEditProductDetailsCancelEdit"
                size="medium"
                icon="close"
                color="grey"
                content={text('dependencyList.licenseModal.cancel')}
                onClick={resetAndClose}
              />
              <Button
                className="LicenseElementModalsEditProductDetailsButtons"
                floated="right"
                disabled={loading} // if loading !
                type="button"
                id="ProductLicenseDetailsUpdateEditProductDetailsSaveChanges"
                size="medium"
                icon={loading ? 'spinner' : 'save outline'}
                color="blue"
                content={text('dependencyList.licenseModal.saveChanges')}
                onClick={handleSaveChanges}
              />
            </Item.Meta>
            <div className="LicenseElementModalsEditProductDetailsCopyrights">
              {copyrights.length > 0
                ? copyrights.map((c: string) => (
                    <Item.Description key={`LicenseElement${c}`}>
                      <Icon
                        id="ProductLicenseDetailsUpdateCopyrightsIcon"
                        name="copyright outline"
                      />
                      {c}

                      <Icon
                        id="ProductLicenseDetailsUpdateCopyrightsDelete"
                        className="margin-left"
                        name="trash alternate outline"
                        link
                        color="red"
                        onClick={() => {
                          const slicedArr = copyrights.filter(
                            (copy) => copy !== c
                          );
                          setCopyrights(slicedArr);
                        }}
                      />
                    </Item.Description>
                  ))
                : text('dependencyList.tableBody.noCopyright')}
            </div>
            <ProductLicenseDetailsUpdateInputField
              newCopyright={newCopyright}
              copyrights={copyrights}
              setNewCopyright={setNewCopyright}
              addCopyright={addCopyright}
            />
          </Item.Content>
        </Item>
        <Item id="ProductLicenseDetailsUpdateLicenseText">
          <Item.Content>
            <div className="PermisssionNote">
              <Header as="h5">
                {text('dependencyList.licenseModal.licenseText')}
              </Header>
              <Popup
                id="NotAllowedPopup"
                disabled={canEditPermissionNote}
                content={text('dependencyList.licenseModal.cannotEdit')}
                on="hover"
                position="top center"
                eventsEnabled
                trigger={
                  <Checkbox
                    id="PermissionNoteCheckboxToggle"
                    toggle
                    disabled={!canEditPermissionNote}
                    checked={newPermissionState}
                    onChange={() => setNewPermissionState(!newPermissionState)}
                    label={text(`dependencyList.licenseModal.permissionNote`)}
                  />
                }
              />
            </div>
            {!licenseText && !licenseTextLoading ? (
              <Message
                id="DetailsUpdateNoLicenseTextMessage"
                error
                icon="times circle"
                header={text('dependencyList.licenseModal.noLicenseTextHeader')}
                content={text('dependencyList.licenseModal.noLicenseText')}
              />
            ) : (
              <Form id="DescriptionModal.TextArea">
                <TextArea
                  value={textState || ''}
                  rows={40}
                  className="LicensesElementModalLicenseTextEdit"
                  onChange={(_e: ChangeEvent, data: TextAreaProps) => {
                    setTextState(data.value as string);
                  }}
                />
              </Form>
            )}
          </Item.Content>
        </Item>
      </Item.Group>
    </Modal.Content>
  );
};

export default ProductLicenseDetailsUpdate;
