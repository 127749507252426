/* eslint-disable enforce-ids-in-jsx/missing-ids */
import React, { useState } from 'react';
import { Button, Segment, Message, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { LicenseFeaturesByIdQuery } from '../../graphql/generated/graphql';
import FeatureAccordion from './FeatureAccordion';
import {
  FeatureTypeName,
  useLicenseContext,
} from '../../context/LicenseContext';
import SelectFeature from './SelectFeature';

type Props = {
  licenceFeaturesObject: LicenseFeaturesByIdQuery | undefined;
  featuresErr: ApolloError | undefined;
  className?: string;
};

/**
 *  License Features
 *
 *@param {Props} props - Props
 *@returns {JSX.Element}  - License Features
 */
const LicenseFeatures = (props: Props): JSX.Element => {
  const { i18n } = useTranslation('productDetails');
  const { licenceFeaturesObject, featuresErr, className } = props;
  const [activeAccordion, setActiveAccordion] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    isEditMode,
    removeFeature,
    licenseFeatures: contextLicenseFeatures,
  } = useLicenseContext();

  const toggleAccordion = (name: string) => {
    setActiveAccordion(activeAccordion === name ? '' : name);
    setShowDropdown(false);
  };

  const licenseData =
    licenceFeaturesObject?.License && licenceFeaturesObject?.License[0];
  const licenseFeatures = isEditMode
    ? contextLicenseFeatures
    : licenseData?.licenseFeatures || [];
  const nameKey = `name_${i18n.language}` as 'name_en' | 'name_de';
  const sortedLicenseFeatures = [...licenseFeatures].sort((a, b) => {
    const nameA = a?.[nameKey] || '';
    const nameB = b?.[nameKey] || '';
    return nameA.localeCompare(nameB);
  });

  // array of feature-arrays
  const mappingFeatures = [
    {
      array: licenseData?.licenseConflictRules || [],
      name: 'LicenseConflictRule',
    },
    { array: licenseData?.conflictRules || [], name: 'ConflictRule' },
    { array: licenseData?.toDos || [], name: 'ToDo' },
    {
      array: licenseData?.additionalLicenseFeatures || [],
      name: 'AdditionalLicenseFeature',
    },
  ];

  return (
    <div id="LicenseFeaturesContainer" className={className}>
      {featuresErr && (
        <Message
          id="LicenseFeaturesError"
          error
          icon="times circle"
          content={`Error- ${featuresErr}`}
        />
      )}
      <span className="license-features-tags-container">
        {sortedLicenseFeatures.map((licFeature, i) => (
          <Button
            id={`FeatureTag${i}`}
            className="license-features-tags"
            key={`LicenseFeaturesTags${licenseFeatures.indexOf(licFeature)}`}
            content={
              <>
                <span>
                  {i18n.language === 'de'
                    ? licFeature?.name_de
                    : licFeature?.name_en}
                </span>

                {licFeature && licFeature.id && isEditMode && (
                  <span>
                    <Icon
                      id={`RemoveFeature_${licFeature?.id}`}
                      className="delete-feature-icon"
                      name="x"
                      color="grey"
                      link
                      onClick={() =>
                        removeFeature(
                          licFeature.__typename as FeatureTypeName,
                          licFeature?.id
                        )
                      }
                    />
                  </span>
                )}
              </>
            }
            basic
            size="medium"
          />
        ))}
        {isEditMode && (
          <div>
            {!showDropdown && (
              <Button
                id="AddLicenseFeature"
                icon="plus"
                circular
                primary
                basic
                onClick={() => {
                  setShowDropdown(true);
                  setActiveAccordion('');
                }}
              />
            )}
            {showDropdown && <SelectFeature type="LicenseFeature" />}
          </div>
        )}
      </span>
      <Segment id="LicenseFeatures" basic>
        {mappingFeatures.map(({ array, name }) => (
          <FeatureAccordion
            key={name}
            featureArray={array}
            isActive={activeAccordion === name}
            toggle={toggleAccordion}
            name={name as FeatureTypeName}
          />
        ))}
      </Segment>
    </div>
  );
};

export default LicenseFeatures;
