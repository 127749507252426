import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Header, Icon, Popup } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../context/ProductContext';
import { Product, Version } from '../../../../../graphql/generated/graphql';
import CopyIconPopup from '../../../../ui/CopyIconPopup';
import GroupTakeOverEditingModal from './GroupTakeOverEditingModal';
import GroupTakeOverStatusCard from './GroupTakeOverStatusCard';

type Props = {
  versionProduct: Product;
  readOnly?: boolean;
  version: Version;
};

/**
 *
 *@param {string} versionProduct Object of props to be passed on to the component
 *@returns {JSX.Element} Group Takeover element that will display when the group takover exists and will replace dependency list element!
 */
const GroupTakeoverElement = ({
  versionProduct,
  version,
  readOnly,
}: Props): JSX.Element => {
  const { authorizations } = useContext(ProductDetailsContext);
  const [t] = useTranslation(['productDetails', 'users']);
  const [openEdit, setOpenEdit] = useState<[boolean, string]>([false, '']);

  return (
    <>
      <GroupTakeOverEditingModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        versionProduct={versionProduct}
        versionId={version.id}
      />
      <GroupTakeOverStatusCard
        overallResultStatus={version.overallResult.status}
        overallResultNote={version.overallResult?.note}
        productName={versionProduct.name}
        versionId={version.id}
        versionName={version.name}
      />
      <Card.Group stackable className="GroupTakeoverElementContainer">
        <Card id="GroupTakeoverElementCompany">
          <div className="custom-card-container">
            <Icon
              size="large"
              name="building outline"
              id="GroupTakeoverElementCompanyIcon"
            />
            <div>
              <Header as="h4" style={{ margin: '0' }}>
                {t(`brand.${versionProduct?.transfer.groupCompany}`, {
                  ns: 'users',
                })}
              </Header>
              <Card.Meta>{t('transferGroupCompany')}</Card.Meta>
            </div>
            {!readOnly && (
              <Popup
                id="GroupTakeoverElementEditPopup1"
                disabled={authorizations.includes('UpdateVersion')}
                content={t('noChangesAuthorized')}
                position="top center"
                trigger={
                  <Icon
                    id="GroupTakeoverElementCompanyEditButton"
                    className="margin-left-auto"
                    name="pencil"
                    disabled={!authorizations.includes('UpdateVersion')}
                    color="blue"
                    link
                    onClick={() => setOpenEdit([true, 'transferGroupCompany'])}
                  />
                }
              />
            )}
          </div>
        </Card>
        <Card id="GroupTakeoverElementTransferContact">
          <div className="custom-card-container">
            <Icon
              size="large"
              name="user"
              id="GroupTakeoverElementTransferContactIcon"
            />
            <div>
              <Header as="h4" style={{ margin: '0' }}>
                {versionProduct?.transfer.contactPerson}
              </Header>
              <Card.Meta>{t('transferContactPerson')}</Card.Meta>
            </div>
            {!readOnly && (
              <Popup
                id="GroupTakeoverElementEditPopup2"
                disabled={authorizations.includes('UpdateVersion')}
                content={t('noChangesAuthorized')}
                position="top center"
                trigger={
                  <Icon
                    id="GroupTakeoverElementTransferContactPersonEditButton"
                    className="margin-left-auto"
                    name="pencil"
                    disabled={!authorizations.includes('UpdateVersion')}
                    color="blue"
                    link
                    onClick={() => setOpenEdit([true, 'transferContactPerson'])}
                  />
                }
              />
            )}
          </div>
        </Card>
        <Card id="GroupTakeoverElementIdentifier">
          <div className="custom-card-container">
            <Icon
              size="large"
              name="500px"
              id="GroupTakeoverElementIdentifierIcon"
            />
            <div>
              <Header as="h4" style={{ margin: '0' }}>
                {versionProduct?.transfer.externalId}
              </Header>
              <Card.Meta>{t('transferExternalId')}</Card.Meta>
            </div>
            <div>
              <CopyIconPopup
                copyContent={versionProduct?.transfer?.externalId}
                size="large"
                elementName="identifier"
                position="top center"
              />
            </div>
            {!readOnly && (
              <Popup
                id="GroupTakeoverElementEditPopup3"
                disabled={authorizations.includes('UpdateVersion')}
                content={t('noChangesAuthorized')}
                position="top center"
                trigger={
                  <Icon
                    id="GroupTakeoverElementIdentifierEditButton"
                    className="margin-left-auto"
                    name="pencil"
                    disabled={!authorizations.includes('UpdateVersion')}
                    color="blue"
                    link
                    onClick={() => setOpenEdit([true, 'transferExternalId'])}
                  />
                }
              />
            )}
          </div>
        </Card>
      </Card.Group>
    </>
  );
};

export default GroupTakeoverElement;
