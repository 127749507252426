import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Message, Popup, Radio } from 'semantic-ui-react';

/**
 * Notification Toggle, not yet active. Its disabled and shows informative popup.
 *
 * @returns {JSX.Element} NotificationToggle
 */
const NotificationToggle = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const [showPopup, setShowPopup] = useState(false);

  const handleOpen = () => {
    setShowPopup(true);
  };
  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <Popup
      id="ProductDetailsHeader.NotificationRadioPopup"
      content={
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Message
            id="notImplementedWarning"
            warning
            icon="exclamation triangle"
            content={t('notImplemented')}
          />
        </div>
      }
      position="top center"
      offset={[-10, 0]}
      on="click"
      open={showPopup}
      onOpen={handleOpen}
      onClose={handleClose}
      size="tiny"
      trigger={
        <div id="TriggerPopupButton" style={{ zIndex: 1, cursor: 'pointer' }}>
          <Radio
            toggle
            disabled
            style={{ pointerEvents: 'none' }}
            id="ProductDetailsHeader.NotificationRadio"
            label={t('disableNotifications')}
          />
        </div>
      }
    />
  );
};

export default NotificationToggle;
