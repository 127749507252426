/* eslint-disable import/prefer-default-export */
import { ToDoType } from './types';

/**
 * Filter out the selected ToDos
 *
 * @param {ToDoType} toDos - The ToDos to filter
 * @returns {ToDoType[]} - The selected ToDos
 */
export const selectedToDos = (toDos: ToDoType[]) => {
  return toDos.filter(
    (toDo) =>
      toDo.selectionType === 'userselected' ||
      toDo.selectionType === 'autoselected'
  );
};
