import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Item, Icon, Header } from 'semantic-ui-react';
import { UserContext } from '../../../context/UserContext';

/**
 *@returns {JSX.Element} Element
 */
const UserDataDisplayPopup = (): JSX.Element => {
  const [t] = useTranslation(['users', 'buttons']);
  const { name, email, brand, roles } = useContext(UserContext);
  // user in users list that has only admin role should not have "brand"
  const userRoleAdmin = roles?.length === 1 && roles[0] === 'admin';
  return (
    <>
      {' '}
      <Item id="UserDetailsDataDisplayImage" style={{ marginBottom: '1rem' }}>
        <Item.Image style={{ textAlign: 'center', marginBottom: '1rem' }}>
          <Icon
            id="UserDataDisplayPopupImageIcon"
            name="user circle"
            size="huge"
          />
        </Item.Image>
        <Item.Content style={{ textAlign: 'center' }}>
          <Header
            size="tiny"
            inverted
            textAlign="center"
            style={{ marginBottom: 0 }}
          >
            {name}
            {!userRoleAdmin && (
              <Item.Extra
                id="UserUserDetailsDataDisplayBrand"
                style={{
                  marginTop: 0,
                  display: 'inline',
                }}
                content={
                  brand === null || brand === 'No_brand'
                    ? t('modal.noBrandYet')
                    : ` | ${t(`brand.${brand}`)}`
                }
              />
            )}
          </Header>
          <span>{email}</span>
        </Item.Content>
      </Item>
    </>
  );
};

export default UserDataDisplayPopup;
