import { useQuery } from '@apollo/client';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Segment, Message } from 'semantic-ui-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  AuthorizationsContext,
  SelectedRoleContext,
  UserContext,
} from '../context/UserContext';
import {
  CURRENT_USER_QUERY,
  MAINTENANCE_MODE_QUERY,
} from '../graphql/queries/UserQuerys';
import {
  CheckMaintenanceModeQuery,
  Role,
  User,
  useGlobalAuthorizationsLazyQuery,
} from '../graphql/generated/graphql';
import SideBarMenu from '../components/navigation/SideBarMenu';
import Router from '../routes/Router';
import LoginPage from './LoginPage';
import MaintenancePage from './MaintenancePage';
import SideBarMenuSkeleton from '../components/navigation/SideBarMenuSkeleton';
import SignUpPage from './SignUpPage';

/**
 * Layout
 * Provides the {@link SideBarMenu} and the {@link Router} and the User and Role
 * contexts.
 *
 * @returns {JSX.Element} Layout of AOSD2
 */
const Layout = (): JSX.Element => {
  const { data: CheckMaintenanceMode } = useQuery<CheckMaintenanceModeQuery>(
    MAINTENANCE_MODE_QUERY
  );
  const [text] = useTranslation('maintenance');
  const isMaintenance = CheckMaintenanceMode?.CheckMaintenanceMode;
  const {
    data: currentUserData,
    loading: currentUserLoading,
    error: currentUserError,
  } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  const [selectedRole, setSelectedRole] = useState(
    undefined as unknown as Role
  );
  const [
    getAuthorizations,
    { data: authData, loading: authLoading, error: authError },
  ] = useGlobalAuthorizationsLazyQuery();

  const roleContextValue = useMemo(
    () => ({ selectedRole, setSelectedRole }),
    [selectedRole]
  );
  const setStartingRoleHandler = (user: User) => {
    const { roles } = user;
    const storedRole = localStorage.getItem('selectedRole');
    if (storedRole) {
      const role = roles.includes(storedRole as Role) ? storedRole : roles[0];
      localStorage.setItem('selectedRole', role);
      setSelectedRole(role as Role);
    } else {
      const role = roles[0];
      localStorage.setItem('selectedRole', role);
      setSelectedRole(role as Role);
    }
  };

  useEffect(() => {
    if (!currentUserLoading && !currentUserError && currentUserData) {
      const { CurrentUser: user } = currentUserData;
      setStartingRoleHandler(user);
    }
  }, [currentUserData, currentUserError, currentUserLoading]);

  useEffect(() => {
    if (selectedRole) {
      getAuthorizations({ variables: { selectedRole } });
    }
  }, [selectedRole, getAuthorizations]);

  if (currentUserLoading || authLoading) return <Loader active />;

  if (
    (currentUserError || authError) &&
    CheckMaintenanceMode?.CheckMaintenanceMode === true
  ) {
    let systemOffline = false;
    if (
      (currentUserError && currentUserError?.networkError !== null) ||
      (authError && authError?.networkError !== null)
    ) {
      systemOffline = true;
    }
    return (
      <Routes>
        <Route path="/login" element={<MaintenancePage />} />
        <Route
          path="/maintenance/login"
          element={<LoginPage systemOffline={systemOffline} />}
        />
      </Routes>
    );
  }

  if (currentUserError || authError) {
    let systemOffline = false;
    if (
      (currentUserError && currentUserError?.networkError !== null) ||
      (authError && authError?.networkError !== null)
    ) {
      systemOffline = true;
    }

    return (
      <Routes>
        <Route
          path="/login"
          element={<LoginPage systemOffline={systemOffline} />}
        />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  if (currentUserData && authData) {
    const { CurrentUser: user } = currentUserData;
    return (
      <UserContext.Provider value={user}>
        <AuthorizationsContext.Provider value={authData.Authorizations}>
          <SelectedRoleContext.Provider value={roleContextValue}>
            <Routes>
              <Route
                path="/:menuitem"
                element={
                  <Suspense fallback={<SideBarMenuSkeleton />}>
                    <SideBarMenu />
                  </Suspense>
                }
              >
                <Route path=":id" element={<div />} />
              </Route>
            </Routes>
            <Segment id="MainSegment" basic>
              {isMaintenance && (
                <Segment id="MaintenanceSegment" basic attached="top">
                  <Message
                    id="MaintenanceWarningBanner"
                    warning
                    icon="exclamation triangle"
                    header={text('header')}
                  />
                </Segment>
              )}
              <Router />
            </Segment>
          </SelectedRoleContext.Provider>
        </AuthorizationsContext.Provider>
      </UserContext.Provider>
    );
  }
  return <div />;
};

export default Layout;
