import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Loader, Message, Modal } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { CREATE_USER } from '../../graphql/mutations/UserMutations';
import {
  ALL_USERS_QUERY,
  CURRENT_USER_QUERY,
} from '../../graphql/queries/UserQuerys';
import {
  BrandDirectory,
  CreateUserMutationVariables,
  DirectoryAccess,
  Role,
} from '../../graphql/generated/graphql';
import UpdateSuccessMsg from './userComponents/UpdateSuccessMsg';

import { UPDATE_USER_BRAND_ACCESS } from '../../graphql/mutations/DirectoryMutations';
import { GET_ALL_BRAND_DIRECTORIES_FOR_USER } from '../../graphql/queries/DirectoryQuerys';
import AddNewUserContentContainer from './userComponents/AddNewUserContentContainer';
import { isValidEmail, isValidName } from './userComponents/helpers/logics';

interface AddUserProps {
  openAdd: boolean;
  setOpenAdd(value: boolean): void;
}

/**
 * Add new User Component
 *
 * @returns {JSX.Element} Add User Btn Element opening Modal with input Fields to add a new User
 * @param {boolean} setOpen setOpen state
 */
const AddNewUser = ({ openAdd, setOpenAdd }: AddUserProps): JSX.Element => {
  const { t } = useTranslation(['users', 'buttons']);

  const [openMsg, setOpenMsg] = useState(false); // Message Modal
  const [repeatPassword, setRepeatPassword] = useState({
    pass: '',
    enabled: false,
  });
  const [directoryAccessArr, setDirectoryAccessArr] = useState<
    DirectoryAccess[]
  >([]);
  const createUserProps: CreateUserMutationVariables = {
    name: '',
    email: '',
    password: '',
    brand: BrandDirectory.No_brand,
    roles: [Role.developer],
  };

  const [newUserDetails, setCreateUserDetails] =
    useState<CreateUserMutationVariables>(createUserProps);
  // Add Directory access mutation for directories and its access permissions
  const [UpdateBrandDirectoryAccess] = useMutation(UPDATE_USER_BRAND_ACCESS, {
    refetchQueries: [GET_ALL_BRAND_DIRECTORIES_FOR_USER],
  });
  // Create User Mutation Resolver / fires BrandDirectory afterwards cause it needs userId from data
  const [CreateUser, { loading: loadingCreation, error: creationError }] =
    useMutation(CREATE_USER, {
      refetchQueries: [ALL_USERS_QUERY, CURRENT_USER_QUERY],
      onCompleted: (data) => {
        UpdateBrandDirectoryAccess({
          variables: {
            userId: data.CreateUser.id,
            directoryAccess: directoryAccessArr,
          },
        });
      },
    });

  const CreateNewUser = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    CreateUser({ variables: newUserDetails });
    setOpenAdd(false);
    setRepeatPassword({ pass: '', enabled: false });
    setOpenMsg(true); // close the modal and show the success message.
  };

  const resetData = () => {
    setCreateUserDetails(createUserProps);
  };

  const closeAndReset = () => {
    setCreateUserDetails(createUserProps);
    setOpenAdd(false);
    setRepeatPassword({ pass: '', enabled: false });
    setDirectoryAccessArr([]);
  };

  //   Error and loading handling
  if (loadingCreation) return <Loader active />;
  if (creationError)
    return (
      <Message
        id="AddNewUserError"
        error
        icon="times circle"
        content={creationError.message}
      />
    );

  return (
    <>
      {/* SUCCESS MSG IF CREATION VALIDATED */}
      <UpdateSuccessMsg
        openMsg={openMsg}
        setOpenMsg={setOpenMsg}
        userName={newUserDetails.name}
        resetData={resetData}
        validNew
      />
      <Modal
        open={openAdd}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        id="CreateNewUser"
      >
        <Modal.Header>
          {t('user-create', { ns: 'users' })}
          <Icon
            id="UserDetailsCloseModal"
            data-cy="CloseUserDetails"
            name="close"
            link
            onClick={closeAndReset}
          />
        </Modal.Header>
        <AddNewUserContentContainer
          setDirectoryAccessArr={setDirectoryAccessArr}
          directoryAccessArr={directoryAccessArr}
          newUserDetails={newUserDetails}
          setCreateUserDetails={setCreateUserDetails}
          setRepeatPass={setRepeatPassword}
          repeatPass={repeatPassword}
        />

        <Modal.Actions>
          <Button
            disabled={
              newUserDetails.name.trim().length < 1 ||
              !isValidName(newUserDetails.name.trim()) ||
              newUserDetails.roles.length < 1 ||
              !newUserDetails.email ||
              !isValidEmail(newUserDetails.email) ||
              !newUserDetails.password ||
              !repeatPassword.pass ||
              repeatPassword.pass !== newUserDetails.password
            }
            primary
            content={t('create', { ns: 'buttons' })}
            id="AddNewUserBtnCreateUser"
            data-cy="CreateUser"
            onClick={CreateNewUser}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default AddNewUser;
