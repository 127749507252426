import { DocumentNode } from 'graphql';
import { makeVar } from '@apollo/client';
import {
  BrandDirectory,
  DirectoryRole,
  Maybe,
  PossibleInfo,
} from '../../../../../graphql/generated/graphql';
import {
  FINISH_LEGAL_CHECK,
  FINISH_PRODUCT_CHECK,
  REQUEST_SUPPLIER_INPUT,
  REQUEST_SUPPLIER_TODOS,
  SUBMIT_TO_LEGAL,
  SUBMIT_TO_OSO,
  SUBMIT_TO_USER,
  SUPPLIER_TODOS_DONE,
  SUPPLIER_INPUT_DONE,
  FINISH_PROJECT_CHECK,
  FINISH_SUPPLIER_REPORT,
  REQUEST_SUPPLIER_REPORT,
  FINISH_REPORT,
  SUBMIT_TO_BRAND_OSO,
} from '../../../../../graphql/mutations/VersionMutations';
import {
  applicableGroupTransferMutations,
  applicableProductMutations,
  applicableProjectMutations,
} from './applicableMutations';
import { JSON_IMPORT } from '../../../../../graphql/mutations/ImportMutations';
import { GET_LATEST_LC_DOCUMENT } from '../../../../../graphql/queries/ArtifactoryQuerys';
import { DOWNLOAD_FROM_ARTIFACTORY } from '../../../../../graphql/mutations/ArtifactoryMutations';
import { GET_EMAILS_BY_BRAND } from '../../../../../graphql/queries/DirectoryQuerys';

/**
 * Has OSO answered questions?
 */
export const osoAnsweredVar = makeVar<boolean>(false);

/**
 * type for state mapping
 * (mutationName(state change) -> button content -> graphQL mutation)
 */
export type StateMapping = {
  authorized: boolean;
  mutationName: string;
  content: string | string[];
  mutation: DocumentNode;
};

/**
 * return object for popup data
 */
export type PopupInfo = {
  popupContent: string;
  popupId: string;
};

/**
 * mapping of mutationName to button content and graphql mutation
 */
export const mappingStatesToMutation: Array<StateMapping> = [
  {
    authorized: false,
    mutationName: 'SubmitToLegal',
    content: 'stateChanges.submitToLegal',
    mutation: SUBMIT_TO_LEGAL,
  },
  {
    authorized: false,
    mutationName: 'RequestSupplierInput',
    content: 'stateChanges.requestSupplierInput',
    mutation: REQUEST_SUPPLIER_INPUT,
  },
  {
    authorized: false,
    mutationName: 'SupplierInputDone',
    content: 'stateChanges.supplierInputDone',
    mutation: SUPPLIER_INPUT_DONE,
  },
  {
    authorized: false,
    mutationName: 'SubmitToOSO',
    content: 'stateChanges.submitToOso',
    mutation: SUBMIT_TO_OSO,
  },
  {
    authorized: false,
    mutationName: 'SubmitToBrandOSO',
    content: [
      'stateChanges.submitToBrandOso',
      'stateChanges.questionsToBrandOso',
    ],
    mutation: SUBMIT_TO_BRAND_OSO,
  },
  {
    authorized: false,
    mutationName: 'SubmitToUser',
    content: 'stateChanges.submitToUser',
    mutation: SUBMIT_TO_USER,
  },
  {
    authorized: false,
    mutationName: 'FinishLegalCheck',
    content: 'stateChanges.finishLegalCheck',
    mutation: FINISH_LEGAL_CHECK,
  },
  {
    authorized: false,
    mutationName: 'RequestSupplierToDos',
    content: 'stateChanges.requestSupplierTodos',
    mutation: REQUEST_SUPPLIER_TODOS,
  },
  {
    authorized: false,
    mutationName: 'SupplierToDosDone',
    content: 'stateChanges.supplierToDosDone',
    mutation: SUPPLIER_TODOS_DONE,
  },
  {
    authorized: false,
    mutationName: 'FinishProductCheck',
    content: 'stateChanges.complete',
    mutation: FINISH_PRODUCT_CHECK,
  },
  {
    authorized: false,
    mutationName: 'FinishSupplierReport',
    content: 'stateChanges.finishSupplierReport',
    mutation: FINISH_SUPPLIER_REPORT,
  },
  {
    authorized: false,
    mutationName: 'RequestSupplierReport',
    content: 'stateChanges.requestSupplierReport',
    mutation: REQUEST_SUPPLIER_REPORT,
  },
  {
    authorized: false,
    mutationName: 'FinishReport',
    content: 'stateChanges.finishReport',
    mutation: FINISH_REPORT,
  },
  {
    authorized: false,
    mutationName: 'FinishProjectCheck',
    content: 'stateChanges.finishProjectCheck',
    mutation: FINISH_PROJECT_CHECK,
  },
  // JsonImport used just to display Json Import Button component, rework
  {
    authorized: false,
    mutationName: 'JsonImport',
    content: '',
    mutation: JSON_IMPORT,
  },
  {
    authorized: false,
    mutationName: 'DownloadReport',
    content: 'additionalButtonsPopups.reportPDF',
    mutation: DOWNLOAD_FROM_ARTIFACTORY,
  },
  {
    authorized: false,
    mutationName: 'DownloadLcDocument',
    content: 'additionalButtonsPopups.LC',
    mutation: GET_LATEST_LC_DOCUMENT,
  },
  {
    authorized: false,
    mutationName: 'GetEmailsByBrand',
    content: '',
    mutation: GET_EMAILS_BY_BRAND,
  },
];

/**
 *
 * @param {string} id buttonId for creating unique popupId
 * @param {PossibleInfo} reasonCode code returned from graphql
 * @returns {PopupInfo} return the generated popupId and popupContent
 */
export const getPopupInfo = (
  id: string,
  reasonCode: Maybe<PossibleInfo> | undefined
): PopupInfo => {
  let popupContent = '';
  if (reasonCode === 'DEACTIVATED') {
    popupContent = 'stateChangeNotPossibleReason.deactivated';
  }
  if (reasonCode === 'INVALID_STATE') {
    popupContent = 'stateChangeNotPossibleReason.invalidState';
  }
  if (reasonCode === 'NO_DEPENDENCIES') {
    popupContent = 'stateChangeNotPossibleReason.noDependencies';
  }
  if (reasonCode === 'OPEN_RESOLVER') {
    popupContent = 'stateChangeNotPossibleReason.openResolver';
  }
  if (reasonCode === 'OPEN_REQ') {
    popupContent = 'stateChangeNotPossibleReason.openRequirements';
  }
  if (reasonCode === 'OPEN_TODOS') {
    popupContent = 'stateChangeNotPossibleReason.openTodos';
  }
  if (reasonCode === 'WRONG_ROLE') {
    popupContent = 'stateChangeNotPossibleReason.wrongRole';
  }
  if (reasonCode === 'UNANSWERED_USER_REQUIREMENTS') {
    popupContent = 'stateChangeNotPossibleReason.unansweredUserRequirements';
  }
  if (reasonCode === 'UNANSWERED_LEGAL_REQUIREMENTS') {
    popupContent = 'stateChangeNotPossibleReason.unansweredLegalRequirements';
  }
  if (reasonCode === 'INVALID_VERSION_RESULT') {
    popupContent =
      'stateChangeNotPossibleReason.dependenciesRequireLegalResult';
  }
  if (reasonCode === 'NO_SUPPLIER') {
    popupContent = 'stateChangeNotPossibleReason.noSupplier';
  }
  if (reasonCode === 'PARTS_NOT_COMPLETED') {
    popupContent = 'stateChangeNotPossibleReason.partsNotCompleted';
  }
  if (reasonCode === 'NO_PARTS') {
    popupContent = 'stateChangeNotPossibleReason.noParts';
  }
  if (reasonCode === 'NO_LC_DOCUMENT') {
    popupContent = 'stateChangeNotPossibleReason.noLcDocument';
  }
  if (reasonCode === 'JSON_NOT_IMPORTED') {
    popupContent = 'stateChangeNotPossibleReason.jsonNotImported';
  }
  if (reasonCode === 'OPEN_INTERNAL_TODOS') {
    popupContent = 'stateChangeNotPossibleReason.openInternalTodos';
  }
  if (reasonCode === 'OPEN_LC_TODOS') {
    popupContent = 'stateChangeNotPossibleReason.openLcTodos';
  }
  if (reasonCode === 'NO_SIGNED_REPORT') {
    popupContent = 'stateChangeNotPossibleReason.noSignedReport';
  }
  if (reasonCode === 'EXTERNAL_TODOS_DONE') {
    popupContent = 'stateChangeNotPossibleReason.externalTodosAnswered';
  }
  if (reasonCode === 'STEPS_TO_BE_FULFILLED') {
    popupContent = 'stateChangeNotPossibleReason.stepsToBeFulfilled';
  }
  const popupId = `${id}popUp`;
  return { popupId, popupContent };
};
/**
 * @param {string} role selected role
 * @param {string[]} accessRoles - array of access roles
 * @returns {string[]} array of access roles
 */
const checkIfSelectedRoleCorrespondsToAccessRoles = (
  role: string,
  accessRoles: string[]
): string[] => {
  // if selected role corresponds to any of the accessRoles, return it alone
  if (role === 'developer' && accessRoles.includes('manager')) {
    return ['manager'];
  }
  if (accessRoles.includes(role)) {
    return [role];
  }
  return accessRoles;
};

/**
 * @param {StateMapping[]} allMutations allMutations
 * @param {string} currentProcessStatus current ProcessStatus as string
 * @param {DirectoryRole[]} currentUserDirectoryRoles Directory role
 * @param {boolean} isProject whether is project or not
 * @param {boolean} isGroupTransfer whether is transfer or not
 * @param {string} selectedRole currentUserRole selected
 * @returns {StateMapping[]} filtered Mutations for a User/Role in certain ProcessStatus
 */
export const getMutationsForRoleAndStatus = (
  allMutations: StateMapping[],
  currentProcessStatus: string,
  currentUserDirectoryRoles: DirectoryRole[],
  isProject: boolean,
  isGroupTransfer: boolean,
  selectedRole: string
): StateMapping[] => {
  const applicableRoles = checkIfSelectedRoleCorrespondsToAccessRoles(
    selectedRole,
    currentUserDirectoryRoles
  ) as DirectoryRole[];

  // Collect all applicable mutations for any of the roles
  const applicableMutationNames = new Set<string>();
  applicableRoles.forEach((role) => {
    let roleStatuses = [];

    if (isGroupTransfer) {
      roleStatuses = applicableGroupTransferMutations[role];
    } else if (isProject) {
      roleStatuses = applicableProjectMutations[role];
    } else {
      roleStatuses = applicableProductMutations[role];
    }
    const statusDetail = roleStatuses.find(
      (detail) => detail.status === currentProcessStatus
    );
    statusDetail?.mutations.forEach((mutation) =>
      applicableMutationNames.add(mutation)
    );
  });

  // Filter the mutations to include only those that are applicable
  // for any of the current statuses and roles
  const applicableMutations = allMutations.filter((mutation) =>
    applicableMutationNames.has(mutation.mutationName)
  );
  // different role might have different button text
  return applicableMutations.map((mutation) => {
    if (
      applicableRoles.includes(DirectoryRole.legal) &&
      mutation.mutationName === 'SubmitToBrandOSO'
    ) {
      return {
        ...mutation,
        content: Array.isArray(mutation.content)
          ? mutation.content[1]
          : mutation.content,
      };
    }

    if (
      applicableRoles.includes(DirectoryRole.oso) &&
      mutation.mutationName === 'SubmitToBrandOSO'
    ) {
      return {
        ...mutation,
        content: Array.isArray(mutation.content)
          ? mutation.content[0]
          : mutation.content,
      };
    }

    // Return the applicable mutation as it is if no special conditions are met
    return mutation;
  });
};

/**
 * @param {string} brand brand string
 * @returns {BrandDirectory} type Brand
 */
export const mapStringToBrandEnum = (
  brand: string | null | undefined
): BrandDirectory | null => {
  if (!brand) return null;

  // Normalize the input brand string
  const normalizedBrand = brand.toUpperCase();
  // map directly using the enum keys
  const enumKey = Object.keys(BrandDirectory).find(
    (key) => key.toUpperCase() === normalizedBrand
  );

  return enumKey
    ? BrandDirectory[enumKey as keyof typeof BrandDirectory]
    : null;
};

/**
 * Utility function to compare two arrays for equality regardless of order
 *
 * @template T
 * @param {T} arr1 first array
 * @param {T} arr2 second array to
 * @returns {boolean} true if arrays are equal, false otherwise
 */
export const arraysEqualUnordered = <T>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].slice().sort();
  const sortedArr2 = [...arr2].slice().sort();

  return sortedArr1.every((item, index) => item === sortedArr2[index]);
};
