import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  DependencySelection,
  SelectionType,
  useGetRequirementDetailsQuery,
} from '../../../../graphql/generated/graphql';
import CustomPagination from '../../listComponents/pagination/CustomPagination';
import { usePagination } from '../dependencySegment/dependencyList/productVersionDependencyList/PVDependencyListHooks';
import RequirementComponent from './RequirementComponent';

type Props = {
  authorized: boolean;
  requirementId: string;
  setExistingSelections: (existingSelections: DependencySelection[]) => void;
  globalSelection: SelectionType | null;
  currentSelections: DependencySelection[];
  setCurrentSelections: (currentSelections: DependencySelection[]) => void;
};

/**
 *@returns {boolean} List as a table of Products/dependencies for a requirement
 @param {Props} props props
 */
const RequirementComponentsList = (props: Props): JSX.Element => {
  const {
    authorized,
    requirementId,
    setExistingSelections,
    globalSelection,
    currentSelections,
    setCurrentSelections,
  } = props;
  const { t } = useTranslation(['requirements']);
  const { versionId } = useContext(ProductDetailsContext);
  const { data, loading, error } = useGetRequirementDetailsQuery({
    variables: { versionId, requirementId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const componentsAmount = data?.GetRequirementDetails?.length || 0;
  const [activePageState, setActivePageState] = useState(1);
  const [pages, setPages] = useState(1);
  const itemsPerPage = 5;
  const components =
    data?.GetRequirementDetails?.slice(
      (activePageState - 1) * itemsPerPage,
      activePageState * itemsPerPage
    ) || [];

  usePagination(
    activePageState,
    componentsAmount,
    pages,
    setPages,
    setActivePageState,
    itemsPerPage
  );

  useEffect(() => {
    if (data) {
      // Lifting data into existing selections for comparison before mutation
      setExistingSelections(
        data.GetRequirementDetails?.map((dep) => ({
          dependencyId: dep.dependencyId,
          selectionType: dep.selectionInfo,
        })) || []
      );

      // If currentSelections is empty and globalSelection exists, fill with globalSelection
      if (currentSelections.length === 0 && globalSelection) {
        setCurrentSelections(
          data.GetRequirementDetails?.map((dep) => ({
            dependencyId: dep.dependencyId,
            selectionType: globalSelection,
          })) || []
        );
      }

      // If currentSelections is empty and there is no globalSelection, fill it with data from the query
      if (currentSelections.length === 0 && !globalSelection) {
        setCurrentSelections(
          data.GetRequirementDetails?.map((dep) => ({
            dependencyId: dep.dependencyId,
            selectionType: dep.selectionInfo,
          })) || []
        );
      }
    }
  }, [
    data,
    setExistingSelections,
    setCurrentSelections,
    globalSelection,
    currentSelections.length,
  ]);

  return (
    <>
      <Table id="RequirementsProductsListTable" celled compact definition>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>{t('tableHeader.product')}</Table.HeaderCell>
            <Table.HeaderCell>{t('tableHeader.license')}</Table.HeaderCell>
            <Table.HeaderCell>{t('tableHeader.version')}</Table.HeaderCell>
            <Table.HeaderCell>{t('tableHeader.select')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {components.map((product) => (
          <Fragment key={product.dependencyId}>
            <RequirementComponent
              authorized={authorized}
              sourceDetails={product}
              currentSelections={currentSelections}
              setCurrentSelections={setCurrentSelections}
            />
          </Fragment>
        ))}
      </Table>
      {pages > 1 && (
        <CustomPagination
          loading={loading}
          error={error}
          totalPages={pages}
          activePage={activePageState}
          setActivePage={setActivePageState}
        />
      )}
    </>
  );
};

export default RequirementComponentsList;
