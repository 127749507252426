import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownProps, Form, Grid, Item } from 'semantic-ui-react';
import WizardContext from '../../../context/WizardContext';
import WizardFormFieldLabel from '../WizardFormFieldLabel';

type Props = {
  onChangeTeamOption: (data: DropdownProps) => void;
  onChangeUseOption: (data: DropdownProps) => void;
};

/**
 * Parts | For all Parts Column of WizardPartsSegment
 *
 * @param {object} props onChangeTeamOption, onChangeUseOption
 *@returns {JSX.Element} Parts | For all Parts - Grid.Column
 */
const WizardPartsForAllColumn = (props: Props): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const { onChangeTeamOption, onChangeUseOption } = props;
  const { identicalIntendedUse, identicalTeam } = useContext(WizardContext);

  const options = [
    { key: '1', text: t('parts.yes'), value: true },
    { key: '2', text: t('parts.no'), value: false },
  ];

  return (
    <Grid.Column style={{ padding: '0 0 0 2rem' }}>
      <Item.Group relaxed="very">
        <Item id="UseCase" style={{ margin: '0' }}>
          <Item.Content>
            <Item.Header>{t('parts.parts')}</Item.Header>
            <span
              style={{
                fontSize: '18px',
                display: 'inline',
              }}
            >
              {t('parts.use.case')}
            </span>
            <Item.Meta>{t('parts.description.use.case')}</Item.Meta>
          </Item.Content>
        </Item>
      </Item.Group>
      {/* Team Dropdown */}
      <Form id="PartsForAllForm">
        <WizardFormFieldLabel
          nameText={t('parts.identical.team')}
          infoText={t('parts.popup.identical.team')}
        />
        <Form.Field
          content={
            <Dropdown
              id="TeamDropdown"
              placeholder={t('parts.please.select')}
              selection
              options={options}
              value={identicalTeam}
              style={{ display: 'block', width: 'fit-content' }}
              onChange={(event, data) => onChangeTeamOption(data)}
            />
          }
        />
        {/* Use Dropdown */}
        <WizardFormFieldLabel
          nameText={t('parts.identical.use')}
          infoText={t('parts.popup.identical.use')}
        />
        <Form.Field
          content={
            <Dropdown
              id="UseDropdown"
              placeholder={t('parts.please.select')}
              selection
              options={options}
              value={identicalIntendedUse}
              style={{ display: 'block', width: 'fit-content' }}
              onChange={(event, data) => {
                onChangeUseOption(data);
              }}
            />
          }
        />
      </Form>
    </Grid.Column>
  );
};

export default WizardPartsForAllColumn;
