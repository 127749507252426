import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import {
  ProductDetailsContext,
  ProductInfoBoxContext,
} from '../../../../../context/ProductContext';
import InfoIconPopup from '../../../../ui/InfoIconPopup';
import { DistributionNamesLocalized } from '../../../../wizard/WizardConstants';
import DistributionModal from './DistributionModal';

/**
 * @returns {JSX.Element}  Distribution Component for Info Box
 */
const DistributionItem = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const [openModal, setOpenModal] = useState(false);
  const { inLegalCheckOrDone } = useContext(ProductDetailsContext);
  const { version, versionInput, editing } = useContext(ProductInfoBoxContext);
  const distribution =
    versionInput.selectedDistribution ??
    (version?.product && 'selectedDistribution' in version.product
      ? version.product.selectedDistribution?.id
      : undefined);

  const distributionNamesLocalized = DistributionNamesLocalized();
  const distributionName = Object.entries(distributionNamesLocalized).find(
    (dist) => dist[0] === distribution
  )?.[1];

  return (
    <>
      <DistributionModal open={openModal} setOpen={setOpenModal} />
      <Item id="DistributionItem">
        <Item.Content>
          <Item.Meta>
            {t('distribution')}
            <InfoIconPopup infoText={t('distribution-info')} />
            {editing && !inLegalCheckOrDone && (
              <Item.Extra
                className="ui inline clickable edit-link"
                onClick={() => {
                  setOpenModal(true);
                }}
                content={t('change', { ns: 'buttons' })}
              />
            )}
          </Item.Meta>
          <Item.Header>
            <strong>{distributionName}</strong>
          </Item.Header>
        </Item.Content>
      </Item>
    </>
  );
};

export default DistributionItem;
