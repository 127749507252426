import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'semantic-ui-react';
import { ChangeType, Maybe } from '../../../../../graphql/generated/graphql';
import { useGetChangeDetails } from '../../helpers/customHooks';
import HistoryElementDetailsModal from './HistoryElementDetailsModal';
import { getAdjustedValues, smartTruncate } from '../../helpers/logics';
import OverflowPopup from '../../../productsList/listComponents/columnComponents/OverflowPopup';

type PropsType = {
  changeType: ChangeType;
  refNode?: Maybe<string>;
  refInfo?: Maybe<string>;
  refType?: Maybe<string>;
  property?: Maybe<string>;
  prevValue?: Maybe<string>;
  newValue?: Maybe<string>;
};

/**
 * returns an element with the details of PropertyChange
 *
 * @param {PropsType} props takes PropertyChange changeType, prevValue, newValue
 * @returns {JSX.Element} a span with text
 */
const PropertyChangeElement = (props: PropsType): JSX.Element => {
  const [text, i18n] = useTranslation('history');
  const {
    changeType,
    refNode,
    refInfo,
    refType,
    property,
    prevValue,
    newValue,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeDetails = useGetChangeDetails(
    refNode,
    undefined,
    refType,
    property,
    prevValue
  );

  const lang = i18n.language.startsWith('de') ? 'de' : 'en';

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const translatedTeamName = text(
    `tableBody.properties.directoryRole.${property}`
  );

  const shouldOpenModal = ['toDo', 'requirement', 'description'].includes(
    property || ''
  );
  const isDescription = property === 'description';
  const isLegalRequirement =
    property === 'requirement' && changeDetails?.requirement?.type === 'legal';

  const nonEmptyValue = (value: Maybe<string> | undefined) =>
    value === '' || value === undefined ? ' - ' : value;
  const adjustedValues = getAdjustedValues(
    property,
    nonEmptyValue(prevValue),
    nonEmptyValue(newValue),
    text,
    i18n.language
  );

  switch (changeType) {
    case ChangeType.CREATE:
      return (
        <OverflowPopup
          contentClassName="overflow-popup-history-content"
          content={
            <List id="PropertyCreate" horizontal>
              <List.Item className="changeType">
                <List.Content>
                  <List.Header>
                    {text(`tableBody.operations.${changeType}`)}
                  </List.Header>
                </List.Content>
              </List.Item>
              {(refType === 'has_access' ||
                refType === 'reviewed_by_legal' ||
                refType === 'reviewed_by_oso') && (
                <List.Item>
                  {text(`tableBody.create.${refType}`, {
                    user: changeDetails?.user?.name,
                    team: translatedTeamName,
                  })}
                </List.Item>
              )}
              {(refType === 'has_version' ||
                refType === 'depends_on_version') && (
                <List.Item>
                  {text(`tableBody.create.${refType}`, {
                    version: changeDetails?.version?.name,
                    product: changeDetails?.product?.name,
                  })}
                </List.Item>
              )}
              {!refType &&
                property && [
                  <List.Item
                    key="propertyItem"
                    className="property"
                    as={shouldOpenModal ? 'a' : undefined}
                    onClick={shouldOpenModal ? toggleModal : undefined}
                  >
                    <span>{text(`tableBody.properties.${property}`)}</span>
                    <span> - </span>
                  </List.Item>,
                  <List.Item key="newValueItem">{newValue}</List.Item>,
                ]}
              {!(
                refType === 'has_access' ||
                refType === 'has_version' ||
                refType === 'depends_on_version' ||
                refType === 'reviewed_by_legal' ||
                refType === 'reviewed_by_oso' ||
                property
              ) && (
                <List.Item>
                  {text(`tableBody.properties.${property}`)} - {newValue}
                </List.Item>
              )}
            </List>
          }
        />
      );
    case ChangeType.DELETE:
      return (
        <OverflowPopup
          contentClassName="overflow-popup-history-content"
          content={
            <List id="PropertyDelete" horizontal>
              <List.Item className="changeType">
                <List.Content>
                  <List.Header>
                    {text(`tableBody.operations.${changeType}`)}
                  </List.Header>
                </List.Content>
              </List.Item>
              {(refType === 'has_access' ||
                refType === 'reviewed_by_legal' ||
                refType === 'reviewed_by_oso') && (
                <List.Item>
                  {text(`tableBody.delete.${refType}`, {
                    user: changeDetails?.user?.name,
                    team: translatedTeamName,
                  })}
                </List.Item>
              )}
              {(refType === 'has_version' ||
                refType === 'depends_on_version') && (
                <List.Item>
                  {text(`tableBody.delete.${refType}`, {
                    version: changeDetails?.version?.name,
                    product: changeDetails?.product?.name,
                  })}
                </List.Item>
              )}
              {refType === 'depends_on' && (
                <List.Item>
                  {text(`tableBody.delete.depends_on`, {
                    refInfo,
                  })}
                </List.Item>
              )}
              {!(
                refType === 'has_access' ||
                refType === 'has_version' ||
                refType === 'depends_on_version' ||
                refType === 'reviewed_by_legal' ||
                refType === 'reviewed_by_oso' ||
                refType === 'depends_on'
              ) && (
                <List.Item>
                  {text(`tableBody.properties.${property}`)} - {newValue}
                </List.Item>
              )}
            </List>
          }
        />
      );
    case ChangeType.UPDATE:
      return (
        <>
          <OverflowPopup
            contentClassName="overflow-popup-history-content"
            isHistoryWithModal={shouldOpenModal}
            content={
              <List id="PropertyUpdate" horizontal>
                <List.Item className="changeType">
                  <List.Content>
                    <List.Header>
                      {text(`tableBody.operations.${changeType}`)}
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item
                  className="property"
                  as={shouldOpenModal ? 'a' : ''}
                  onClick={shouldOpenModal ? toggleModal : undefined}
                >
                  <span>
                    {isLegalRequirement
                      ? text('tableBody.properties.legalReq')
                      : text(`tableBody.properties.${property}`)}
                  </span>
                  <span> - </span>
                  {property === 'toDo' && (
                    <span>
                      {smartTruncate(
                        changeDetails?.todo?.[`name_${lang}`] ?? '',
                        25,
                        5
                      )}
                    </span>
                  )}
                  {property === 'requirement' && (
                    <span>
                      {smartTruncate(
                        changeDetails?.requirement?.[`name_${lang}`] ?? '',
                        25,
                        5
                      )}
                    </span>
                  )}
                </List.Item>
                <List.Item>
                  {text(`tableBody.operations.fromPrevToNew`, {
                    prev: adjustedValues.prevValue,
                    new: adjustedValues.newValue,
                    interpolation: { escapeValue: false },
                  })}
                </List.Item>
                {/* 'show more' item is visible only in popup - see css */}
                {shouldOpenModal && (
                  <List.Item
                    as={shouldOpenModal ? 'a' : ''}
                    onClick={shouldOpenModal ? toggleModal : undefined}
                    className="show-more-history-log"
                  >
                    {text('buttons:showMore')}
                  </List.Item>
                )}
              </List>
            }
          />
          <HistoryElementDetailsModal
            open={isModalOpen}
            setOpen={toggleModal}
            property={isLegalRequirement ? 'legalReq' : property}
            changeDetails={changeDetails}
            prevValue={isDescription ? prevValue : adjustedValues.prevValue}
            newValue={isDescription ? newValue : adjustedValues.newValue}
          />
        </>
      );
    default:
      break;
  }

  return <span>-</span>;
};

export default PropertyChangeElement;
