import { gql } from '@apollo/client';
/**
 * Get all requirements for a version
 *
 * @returns {string}} Data for all products' Requirements.
 */
export const GET_REQUIREMENTS = gql`
  query GetRequirements($versionId: ID!) {
    GetRequirements(versionId: $versionId) {
      requirement {
        id
        name_en
        name_de
        description_en
        description_de
        type  
      }
      selectionInfo
      dependencyCount
      individuallySelected
      dependencySelections {
        userselected
        userdeselected
        unselected
      }
      requirementAnswered
      commentCount
    }
  }
`;
/**
 * Get all components for a requirement
 *
 * @returns {string} Infos for each requirement.
 */
export const GET_REQUIREMENT_DETAILS = gql`
  query GetRequirementDetails($versionId: ID!, $requirementId: ID!) {
    GetRequirementDetails(
      versionId: $versionId
      requirementId: $requirementId
    ) {
      componentName
      componentVersion
      spdxIds
      selectionInfo
      dependencyId
    }
  }
`;
