import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import {
  CustomDependencyWhere,
  DependencyOptions,
  Linking,
} from '../../../../../../../graphql/generated/graphql';
import {
  Columns,
  ListType,
} from '../../../../../productsList/listComponents/ProductListTypes';
import {
  activeFilters,
  activeSorters,
} from '../../../../../productsList/listComponents/helpers/formatters';
import { collatingElementType } from '../../helper/types';
import { DEPENDENCIES_BY_VERSION_ID_LICENSES } from '../../../../../../../graphql/queries/DependencyQuerys';
import LINKING_LIST from '../../../../../../../graphql/queries/LinkingQuerys';

/**
 * @description RefetchType is the type for the apollo refetch function
 */
type RefetchType = (
  variables?:
    | Partial<{
        versionId: string;
        options?: DependencyOptions | null;
        where?: CustomDependencyWhere | null;
        licenses?: collatingElementType[] | null;
      }>
    | undefined
) => void;

/* ---------- Helper functions ---------- */

/**
 * @description This hook is used to fetch a list of all linkings
 * @returns {Array} linkingList - list of all linkings
 */
export const useFetchLinkingList = (): Array<Partial<Linking>> => {
  const { data: linkingListData } = useQuery(LINKING_LIST, {
    variables: {
      where: {},
      options: {},
    },
  });
  const [linkingList, setLinkingList] = useState<Array<Partial<Linking>>>([]);
  useEffect(() => {
    // overwriting the license filtering object with the new data
    if (linkingListData) {
      setLinkingList(linkingListData.Linking);
    }
  }, [linkingListData]);
  return linkingList;
};

const replaceLinkingNameWithId = (
  linkingFilterArray: Array<string>,
  linkingDataArray: Array<Partial<Linking>>
): Array<string> => {
  // query to get all linking ids
  // TODO: this can be done better.
  let newLinkingFilterArray: Array<string> = [];

  if (linkingDataArray && linkingDataArray.length > 0) {
    // replace linking names with ids in new array
    const tempLinkingArray = linkingFilterArray.map((linking) => {
      const linkingId = linkingDataArray.find(
        (linkingObj: Partial<Linking>) => linkingObj.name_en === linking
      );
      return linkingId && linkingId.id ? linkingId.id : '';
    });
    newLinkingFilterArray = tempLinkingArray;
  }

  if (newLinkingFilterArray.length > 0) {
    return newLinkingFilterArray;
  }
  return [];
};

const combineDependencyWhereObjWithFilters = (
  activeFiltersArray: collatingElementType[],
  searchObject: { searchName?: string | undefined },
  linkingDataArray: Array<Partial<Linking>>
): CustomDependencyWhere => {
  const filterObject:
    | { [x: string]: Array<string | number> }
    | Record<string, never> = {};

  activeFiltersArray.forEach((filter) => {
    // rebuild filters from array of objects to objects with arrays and save it in filterObject
    const filterKey = Object.keys(filter)[0];
    const filterValue = Object.values(filter)[0];
    if (filterKey && filterValue) {
      if (filterObject[filterKey] === undefined) {
        filterObject[filterKey] = [filterValue];
      } else {
        filterObject[filterKey].push(filterValue);
      }
    }
  });

  if (Object.keys(filterObject).length > 0) {
    // if the filterObject contains linking filters, replace their names with ids
    if (Object.keys(filterObject).includes('linking')) {
      filterObject.linking = replaceLinkingNameWithId(
        filterObject.linking as Array<string>,
        linkingDataArray
      );
    }
    if (Object.keys(searchObject).length > 0) {
      return {
        ...searchObject,
        ...filterObject,
      };
    }
    return {
      ...filterObject,
    };
  }
  return searchObject;
};

/* ---------- Hooks ---------- */
/**
 *
 * @param {string} searchString - search string from search input given by user
 * @param {Array} columns - array containing the columns of the table
 * @param {number} activePageState - current page
 * @param {number} itemsPerPage - amount of items per page
 * @param {string} versionId - id of the version
 * @param {RefetchType} refetch - refetch function from apollo
 * @param {RefetchType} refetchAmount - refetch function from apollo
 * @param {Array} linkingDataArray - array containing all linkings
 * @param {Array} dependenciesAmount - amount of dependencies
 */
export const useRefetchSortedAndFilteredFOSSDepList = (
  searchString: string,
  columns: Columns,
  activePageState: number,
  itemsPerPage: number,
  versionId: string,
  refetch: RefetchType,
  refetchAmount: RefetchType,
  linkingDataArray: Array<Partial<Linking>>,
  dependenciesAmount: number
): void => {
  useEffect(() => {
    const activeFiltersArray = activeFilters(columns, false, true);
    const activeSortersArray = activeSorters(
      columns,
      ListType.FOSSDEPENDENCYLIST
    );
    let whereObj: CustomDependencyWhere = {};
    // splitting off the array containing the filter pills is not needed anymore
    // the logic that made this neccessary is in backend now
    const searchObj = searchString ? { searchName: searchString } : {};

    whereObj = combineDependencyWhereObjWithFilters(
      activeFiltersArray,
      searchObj,
      linkingDataArray
    );
    refetch({
      versionId,
      where: whereObj,
      options: {
        limit: itemsPerPage,
        offset: (activePageState - 1) * itemsPerPage,
        sort: [...activeSortersArray],
      },
    });
    refetchAmount({
      where: whereObj,
      versionId,
    });
  }, [
    searchString,
    columns,
    itemsPerPage,
    activePageState,
    versionId,
    refetch,
    refetchAmount,
    linkingDataArray,
    dependenciesAmount,
  ]);
};

/**
 * @description This hook is used to fetch a list of all licenses attached to all the dependencies of a version
 * @param {Function} setColumns - function to set the columns
 * @param {string} versionId - id of the version
 * @returns {void}
 */
export const useFetchLicenseList = (
  setColumns: React.Dispatch<React.SetStateAction<Columns>>,
  versionId: string
): void => {
  const { data: licenseListData } = useQuery(
    DEPENDENCIES_BY_VERSION_ID_LICENSES,
    {
      variables: { versionId },
    }
  );

  useEffect(() => {
    // overwriting the license filtering object with the incomming data
    // it is be empty up to this point
    if (licenseListData) {
      setColumns((oldColumns) => {
        const licenseColumn = oldColumns.find(
          (column) => column.name === 'licenses'
        );
        if (licenseColumn && licenseListData) {
          const flatLicenseList: string[] =
            licenseListData.GetUsedLicensesInProduct || [];
          // for every element in flatLicenseList there shall be an attribute in the filter object with the value false
          flatLicenseList.forEach((license) => {
            licenseColumn.filterInfo.filtering[license] = false;
          });
          const licenseColumnIndex = oldColumns.indexOf(licenseColumn);
          const newColumns = oldColumns.filter(
            (column) => column.name !== 'licenses'
          );
          // insert the license column at the licenseColumnIndex
          newColumns.splice(licenseColumnIndex, 0, licenseColumn);
          return newColumns;
        }
        return oldColumns;
      });
    }
  }, [setColumns, licenseListData]);
};
