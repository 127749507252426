import { useMutation } from '@apollo/client/react/hooks/useMutation';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Grid,
  Icon,
  Menu,
  Message,
  Popup,
  Segment,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  DirectoryRole,
  useSystemsAllOnlineQuery,
} from '../../../../graphql/generated/graphql';
import { PERSIST_FILE_TO_ARTIFACTORY } from '../../../../graphql/mutations/ArtifactoryMutations';
import { BROWSE_ARTIFACTORY } from '../../../../graphql/queries/ArtifactoryQuerys';
import {
  FINISH_SUPPLIER_REPORT_POSSIBLE,
  FINISH_LEGAL_CHECK_POSSIBLE,
  LEGAL_CHECK_POSSIBLE,
  LC_UPLOAD_INFO,
  SUPPLIER_INPUT_DONE_POSSIBLE,
  COMPLETION_POSSIBLE,
} from '../../../../graphql/queries/VersionQuerys';
import Toast from '../../../ui/Toast';
import { acceptedFileTypes, categoryOptions } from './helper/constants';
import useAuthorizeLC from './helper/customHooks';
import {
  onCategoryChange,
  onCategoryClick,
  onFileChange,
  onUploadButtonClick,
  resetUploadForm,
  resetUploadStates,
} from './helper/logics';
import useTextAreaChange from '../../../../hooks/useTextAreaChange';

type Props = {
  show: boolean;
};

/**
 *
 * @param {object} props show if it should be collapsed by default.
 * @returns {JSX.Element} StorageUpload.
 */
const StorageUpload = (props: Props): JSX.Element => {
  const { show = false } = props;
  const { versionId, authorizations, processStatusAsString, accessRoles } =
    useContext(ProductDetailsContext);

  const { i18n } = useTranslation();
  const i18nLang = i18n.language;
  const [text] = useTranslation('productStorage');

  const canUploadLC = useAuthorizeLC();

  const disableWhenProjectCompleted =
    processStatusAsString === 'COMPLETED' &&
    accessRoles.includes(DirectoryRole.supplier);
  const canUploadSignedReport = authorizations.includes(
    'PersistSignedReportToArtifactory'
  );

  const fileTypeAuthorizations = {
    canUploadLC,
    disableWhenProjectCompleted,
    canUploadSignedReport,
  };
  const canUpload =
    authorizations.includes('PersistFileToArtifactory') ||
    canUploadSignedReport ||
    canUploadLC;

  const [open, setOpen] = useState(show);
  const [touched, setTouched] = useState({
    category: false,
    file: false,
    info: false,
  });
  const [error, setError] = useState({
    category: false,
    file: false,
    info: false,
    size: false,
  });
  const [data, setData] = useState({
    category: '',
    filename: '',
    file: null as File | null,
    info: '',
    accept: acceptedFileTypes,
  });
  const [uploadConditionsMet, setUploadConditionsMet] = useState(false);

  const { maxCharLimit, charCount, handleTextAreaChange } = useTextAreaChange(
    setData,
    data.info
  );

  const [toastVisible, setToastVisible] = useState(false);

  const handleToastClose = () => {
    setToastVisible(false);
  };

  const systemOnlineQuery = useSystemsAllOnlineQuery({
    fetchPolicy: 'no-cache',
    onCompleted: async () => {
      if (systemOnlineQuery.data?.SystemsAllOnline === false) {
        setError((prevError) => ({
          ...prevError,
          systemOffline: true,
        }));
      }
    },
  });

  const allSystemsOnline = systemOnlineQuery?.data
    ? systemOnlineQuery?.data?.SystemsAllOnline
    : true;

  const [
    persistFileToArtifactory,
    {
      loading: persistFileToArtifactoryLoading,
      error: persistFileToArtifactoryError,
    },
  ] = useMutation(PERSIST_FILE_TO_ARTIFACTORY, {
    refetchQueries: [
      BROWSE_ARTIFACTORY,
      LEGAL_CHECK_POSSIBLE,
      FINISH_LEGAL_CHECK_POSSIBLE,
      LC_UPLOAD_INFO,
      SUPPLIER_INPUT_DONE_POSSIBLE,
      FINISH_SUPPLIER_REPORT_POSSIBLE,
      COMPLETION_POSSIBLE,
    ],
    onCompleted: () => {
      resetUploadStates(setData, setTouched, setError);
      resetUploadForm(text);
    },
  });

  useEffect(() => {
    if (
      persistFileToArtifactoryError?.message.includes(
        'file extension is not allowed'
      )
    ) {
      setError((prevError) => {
        return {
          ...prevError,
          category: true,
        };
      });
    }
  }, [persistFileToArtifactoryError]);

  useEffect(() => {
    setUploadConditionsMet(
      data.category !== '' &&
        data.file !== null &&
        data.file !== undefined &&
        persistFileToArtifactoryLoading === false
    );
  }, [data, persistFileToArtifactoryLoading]);

  useEffect(() => {
    if (persistFileToArtifactoryError) {
      setToastVisible(true);
    }
  }, [persistFileToArtifactoryError]);

  useEffect(() => {
    if (!error.category) {
      setToastVisible(false);
    }
  }, [error.category]);

  return (
    <Segment raised id="StorageUpload">
      <Menu id="ProductDependencyMenuTab" secondary compact>
        <Menu.Item>
          <Icon
            id="FileUploadAccordion"
            name={open ? 'chevron up' : 'chevron down'}
            color={open ? 'blue' : 'black'}
            onClick={() =>
              setOpen((prevOpen) => {
                return !prevOpen;
              })
            }
          />
        </Menu.Item>
        <Menu.Item style={{ fontWeight: 'bold', fontSize: '16px' }}>
          {text('storageUpload')}
          <span>
            <Icon
              size="large"
              name="question circle"
              id={`ProductStorageGuideIcon-${i18nLang}`}
              style={{ display: 'none' }} // TODO: delete display none when guide is ready
            />
          </span>
        </Menu.Item>
      </Menu>

      {open && (
        <Grid padded>
          {!canUpload && (
            <Grid.Row>
              <Grid.Column>
                <Message
                  id="StorageUploadMessageNoUpload"
                  warning
                  icon="exclamation triangle"
                  content={text('noUpload')}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={10}>
              <Form id="StorageUploadForm">
                <Form.Group id="StorageUploadFormGroup" widths="equal">
                  <Form.Field>
                    <Form.Select
                      id="FileCategory"
                      label={text('label.category')}
                      options={categoryOptions(text, fileTypeAuthorizations)}
                      disabled={!canUpload || !allSystemsOnline}
                      required
                      selectOnBlur={false}
                      placeholder={text('pleaseSelectType')}
                      onClick={() => onCategoryClick(setTouched)}
                      onChange={(evt, inputData) =>
                        onCategoryChange(
                          evt,
                          inputData,
                          setData,
                          setError,
                          error
                        )
                      }
                      value={data.category}
                      error={error.category}
                    />
                    <Message
                      id="FileCategoryErrorMessage"
                      visible={error.category}
                      error
                      icon="times circle"
                      header={text('fileExtensionErrorHeader')}
                      content={text('fileExtensionErrorMessage')}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Button
                      id="FileUploadButton"
                      label={text('label.file')}
                      className={`file_upload ${
                        touched.file ? '' : 'placeholder'
                      }`}
                      basic
                      content={data.filename || text('pleaseSelectFile')}
                      disabled={
                        !canUpload || !allSystemsOnline || !touched.category
                      }
                      required
                      style={{
                        width: '100%',
                        textAlign: 'left',
                      }}
                      onClick={(evt) => {
                        const target = evt.target as HTMLButtonElement;
                        target.focus();

                        target.addEventListener('focus', () => {
                          target.classList.add('focus');
                        });

                        target.addEventListener('blur', () => {
                          target.classList.remove('focus');
                        });

                        document.getElementById('FileUploadInput')?.click();
                      }}
                    />
                    {/* invisible form input, because it's unsightly! */}
                    <Form.Input
                      id="FileUploadInput"
                      className="invisible"
                      type="file"
                      accept={data.accept}
                      onChange={(evt) =>
                        onFileChange(evt, setData, setTouched, setError)
                      }
                      style={{
                        display: 'none',
                        position: 'absolute',
                        width: '0',
                      }}
                    />
                    {error.size ? (
                      <Message
                        id="StorageUploadMessageFileSizeError"
                        visible
                        error
                        icon="times circle"
                        header={text('fileSizeErrorHeader')}
                        content={text('fileSizeError')}
                      />
                    ) : (
                      <Message
                        id="StorageUploadMessageFileSizeWarning"
                        visible={allSystemsOnline}
                        warning
                        icon="exclamation triangle"
                        header={text('fileSizeWarningHeader')}
                        content={text('fileSizeWarning')}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                {!allSystemsOnline && (
                  <Message
                    id="StorageUploadMessageSystemOffline"
                    error
                    icon="times circle"
                    visible={!allSystemsOnline}
                    content={text(
                      'productDetails:dependencyList.importModal.systemOffline'
                    )}
                  />
                )}
                <Form.TextArea
                  id="FileAdditionalInfo"
                  label={text('label.additionalInfo')}
                  value={data.info}
                  onChange={handleTextAreaChange}
                  disabled={!canUpload || !allSystemsOnline}
                />
                <span>
                  {charCount} / {maxCharLimit}
                </span>
              </Form>
            </Grid.Column>
            <Grid.Column width={6}>
              <Popup
                id="NotAllowedUploadPopup"
                disabled={canUpload}
                content={text('noUpload')}
                on="hover"
                position="top center"
                eventsEnabled
                trigger={
                  <div className="popup-trigger-wrapper">
                    <Button
                      id="UploadFileButton"
                      primary
                      content={text('uploadFile')}
                      loading={persistFileToArtifactoryLoading}
                      onClick={() =>
                        onUploadButtonClick(
                          setError,
                          data,
                          error,
                          persistFileToArtifactory,
                          versionId
                        )
                      }
                      disabled={
                        !uploadConditionsMet ||
                        !canUpload ||
                        toastVisible ||
                        Object.values(error).some((value) => value === true)
                      }
                    />
                  </div>
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <Toast
        visible={toastVisible}
        type="error"
        header={persistFileToArtifactoryError?.name}
        content={persistFileToArtifactoryError?.message}
        onClose={handleToastClose}
        position="absolute bottom right"
        offset={{ y: -42 }}
      />
    </Segment>
  );
};

export default StorageUpload;
