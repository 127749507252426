import { ApolloError, ApolloQueryResult } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Grid, Table } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../../context/ProductContext';
import {
  DependencyListElement,
  Linking,
  useDependenciesByVersionIdQuery,
} from '../../../../../../graphql/generated/graphql';
import Search from '../../../../listComponents/Search';
import CustomPagination from '../../../../listComponents/pagination/CustomPagination';
import ItemsPerPage from '../../../../listComponents/pagination/ItemsPerPage';
import LoadingTable from '../../../../productsList/listComponents/LoadingTable';
import {
  Column,
  Columns,
} from '../../../../productsList/listComponents/ProductListTypes';
import ResolverSegment from '../../ResolverSegment';
import DependencyListBody from '../commonListComponents/DependencyListBody';
import DependencyListHeader from '../commonListComponents/DependencyListHeader';
import { usePagination } from '../productVersionDependencyList/PVDependencyListHooks';
import getDefaultColumns from './helpers/DependencyListTypes';
import {
  useFetchLicenseList,
  useFetchLinkingList,
  useRefetchSortedAndFilteredFOSSDepList,
} from './helpers/logics';
import ResolverFilterPills from '../../../../../ui/ResolverFilterPills';

type Props = {
  dependenciesAmount: number;
  refetchAmount: () => Promise<ApolloQueryResult<unknown>>;
  loadingAmount: boolean;
  errorAmount: ApolloError | undefined;
};
/**
 * fossDependencyList
 *
 * @returns {JSX.Element} table containing list with dependencies
 * @param {Props} props Amount of dependencies
 */
const DependencyList = (props: Props): JSX.Element => {
  const { versionId } = useContext(ProductDetailsContext);
  const { dependenciesAmount, refetchAmount, loadingAmount, errorAmount } =
    props;

  const { data, error, loading, refetch } = useDependenciesByVersionIdQuery({
    variables: {
      versionId,
    },
    fetchPolicy: 'cache-and-network', // 🌶️ TODO: Check if we can remove this somehow
    notifyOnNetworkStatusChange: true,
  });
  // TODO: hier kommt DependencyListElement[] zurück
  const fossDependencies = data?.DependenciesByVersionId;

  const linkingDataArray: Array<Partial<Linking>> = useFetchLinkingList();

  const [activePageState, setActivePageState] = useState(1);
  const [pages, setPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [columns, setColumns] = useState<Columns>(getDefaultColumns());
  const [searchString, setSearchString] = useState('');

  const handleComponentClick = (name: string) => {
    setSearchString(name);
  };

  useRefetchSortedAndFilteredFOSSDepList(
    searchString,
    columns,
    activePageState,
    itemsPerPage,
    versionId,
    refetch,
    refetchAmount,
    linkingDataArray,
    dependenciesAmount
  );

  useFetchLicenseList(setColumns, versionId);

  usePagination(
    activePageState,
    dependenciesAmount,
    pages,
    setPages,
    setActivePageState,
    itemsPerPage
  );

  return (
    <>
      {data && <ResolverSegment refetch={refetch} />}
      <Grid>
        <Grid.Column width={12}>
          <ResolverFilterPills
            column={columns.find(
              (column: Column) => column.name === 'resolverStatus'
            )}
            setColumns={setColumns}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Search
            loading={loading}
            error={error}
            searchString={searchString}
            id="DependencyListSearch"
            onSearchChange={(searchInput: string) => {
              setSearchString(searchInput);
            }}
          />
        </Grid.Column>
      </Grid>
      <Table
        style={{ width: '100%' }}
        fixed
        singleLine
        selectable
        id="DependencyListTable"
      >
        <Table.Header>
          <DependencyListHeader columns={columns} setColumns={setColumns} />
        </Table.Header>
        <Table.Body>
          {data && (
            <DependencyListBody
              columns={columns}
              fossDependencies={fossDependencies as DependencyListElement[]}
              onComponentClick={handleComponentClick}
            />
          )}
          {!data && (error || loading) && (
            <LoadingTable
              rowAmount={itemsPerPage}
              columnAmount={
                columns.filter((col: Column) => col.permitted === true).length
              }
            />
          )}
        </Table.Body>
      </Table>
      <Grid columns="equal" className="tableFooter">
        <Grid.Column />
        <Grid.Column width={6}>
          {data && dependenciesAmount > 0 && (
            <CustomPagination
              loading={loadingAmount}
              error={errorAmount}
              totalPages={pages}
              activePage={activePageState}
              setActivePage={setActivePageState}
            />
          )}
        </Grid.Column>
        <Grid.Column>
          <ItemsPerPage
            loading={loading || loadingAmount}
            error={error || errorAmount}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            itemAmount={dependenciesAmount}
            activePage={activePageState}
          />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default DependencyList;
