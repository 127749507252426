import React, { ChangeEvent, useEffect, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Input, Message, Modal } from 'semantic-ui-react';
import { Maybe, Version } from '../../../../../graphql/generated/graphql';
import {
  PRODUCTS_LIST,
  PRODUCT_VERSION_NAMES,
} from '../../../../../graphql/queries/ProductQuerys';
import { CREATE_VERSION } from '../../../../../graphql/mutations/VersionMutations';
import WizardFormFieldLabel from '../../../../wizard/WizardFormFieldLabel';
import CalendarForm from '../../../../ui/CalendarForm';
import checkIfVersionNameIsValid from './helpers/addVersionHooks';

type PropsType = {
  productId?: string;
  description?: Maybe<string>;
  productName: string;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
};

/**
 * Modal enables users to add a new product version.
 *
 * @param {PropsType} props takes in version
 * @returns {JSX.Element} a Modal with functionality to add a new product version
 */
const AddVersionModal = (props: PropsType): JSX.Element => {
  const { productId, description, productName, openModal, setOpenModal } =
    props;
  const { t: text } = useTranslation([
    'dashboard',
    'productDetails',
    'newProductWizard',
  ]);

  const [versionName, setVersionName] = useState('');
  const [targetDate, setTargetDate] = useState<string>();
  const [mutationError, setMutationError] = useState<ApolloError>();
  const [validDate, setValidDate] = useState(true);
  const [versionNameCheck, setValidVersionNameCheck] = useState({
    errorMessage: '',
    isValid: true,
  });
  const [productVersionNames, setProductVersionNames] = useState<string[]>([]);

  const [fetchVersions, { data }] = useLazyQuery(PRODUCT_VERSION_NAMES);

  useEffect(() => {
    if (openModal) {
      fetchVersions({ variables: { where: { id: productId } } });
      const names: string[] =
        data?.Product[0]?.versions?.map(
          (version: Version) => version?.name.trim().toLowerCase() || ''
        ) || [];
      setProductVersionNames(names);
    }
  }, [setProductVersionNames, data, openModal, fetchVersions, productId]);

  const closeAndReset = () => {
    setVersionName('');
    setTargetDate(undefined);
    setValidVersionNameCheck({ errorMessage: '', isValid: true });
    setValidDate(true);
    setOpenModal(false);
    setMutationError(undefined);
  };

  const [createVersion] = useMutation(CREATE_VERSION, {
    variables: {
      productId,
      targetDate,
      newVersionName: versionName,
    },
    onCompleted: () => {
      closeAndReset();
    },
    refetchQueries: [PRODUCTS_LIST, PRODUCT_VERSION_NAMES],
    onError: (error) => {
      setMutationError(error);
    },
  });

  const handleSave = () => {
    const newVersionNameCheck = checkIfVersionNameIsValid(
      versionName?.trim(),
      productVersionNames,
      text
    );
    if (newVersionNameCheck.isValid && targetDate) {
      createVersion();
    } else {
      setValidVersionNameCheck({
        errorMessage: newVersionNameCheck.errorMessage,
        isValid: newVersionNameCheck.isValid,
      });
      setValidDate(false);
    }
  };

  return (
    <Modal
      id="AddVersionModal"
      size="tiny"
      open={openModal}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Header>
        <span>
          {text('newVersionModal.newVersionHeader', { ns: 'dashboard' })}{' '}
          {productName}
        </span>
        <Icon
          id="iconClose1"
          name="close"
          link
          floated="right"
          onClick={() => closeAndReset()}
        />
      </Modal.Header>
      <Modal.Content>
        {mutationError ? (
          <Message
            id="AddVersionModalError"
            error
            icon="times circle"
            header={text('newVersionModal.createVersionError', {
              ns: 'dashboard',
            })}
            content={mutationError.message}
          />
        ) : (
          <>
            <Modal.Description style={{ marginBottom: '30px' }}>
              {description ? (
                <div>
                  <h4>{text('description', { ns: 'productDetails' })}</h4>
                  <span>{description}</span>
                </div>
              ) : (
                <span>{text('noDescription', { ns: 'productDetails' })}</span>
              )}
            </Modal.Description>
            <section>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                }}
              >
                <WizardFormFieldLabel
                  nameText={text('productDetails.version', {
                    ns: 'newProductWizard',
                  })}
                  infoText={text('productDetails.version-info', {
                    ns: 'newProductWizard',
                  })}
                  htmlFor="versionName"
                />
                <Input
                  id="versionName"
                  name="versionName"
                  value={versionName || ''}
                  placeholder={text('productDetails.version', {
                    ns: 'newProductWizard',
                  })}
                  onChange={(_e: ChangeEvent, { value }) => {
                    setVersionName(value);
                  }}
                />
                {!versionNameCheck.isValid && (
                  <small className="alert-text">
                    {
                      checkIfVersionNameIsValid(
                        versionName?.trim(),
                        productVersionNames,
                        text
                      ).errorMessage
                    }
                  </small>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                }}
              >
                <WizardFormFieldLabel
                  nameText={text('productDetails.targetDate', {
                    ns: 'newProductWizard',
                  })}
                  infoText={text('productDetails.targetDate-info', {
                    ns: 'newProductWizard',
                  })}
                  htmlFor="targetDate"
                />
                <CalendarForm
                  targetDate={targetDate}
                  setTargetDate={setTargetDate}
                  placeholder={text('productDetails.targetDatePlaceholder', {
                    ns: 'newProductWizard',
                  })}
                  format="full"
                />
                {!validDate && !targetDate && (
                  <small className="alert-text">
                    {text('newVersionModal.invalidDate', { ns: 'dashboard' })}
                  </small>
                )}
              </div>
            </section>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          id="SaveVersionButton"
          content={text('createNewVersion', {
            ns: 'buttons',
          })}
          onClick={() => {
            handleSave();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AddVersionModal;
