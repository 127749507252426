import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { Product, Role, Version } from '../../../../graphql/generated/graphql';
import CopyPopup from './CopyPopup';
import { Column, Columns } from './ProductListTypes';
import checkForSupplier, { distributionLocale } from './ProductsListConstants';
import CommentCountElement from './columnComponents/CommentCountElement';
import LegalElement from './columnComponents/LegalElement';
import NameElement from './columnComponents/NameElement';
import OsoElement from './columnComponents/OsoElement';
import OverflowPopup from './columnComponents/OverflowPopup';
import ProjectStatusElement from './columnComponents/ProjectStatusElement';
import TargetDateElement from './columnComponents/TargetDateElement';
import ToDosElement from './columnComponents/ToDosElement';
import ResultElement from './columnComponents/ResultElement';
import DeleteProductAndVersion from './columnComponents/DeleteProductAndVersion';
import ReviewersOverview from './columnComponents/ReviewersOverview';
import PriorityElement from '../../../ui/PriorityElement';
import {
  ProcessStatus,
  ProcessStatusType,
} from '../../productDetails/dependencySegment/dependencyList/helper/types';

type PropsType = {
  columns: Columns;
  product?: Product;
  version?: Version;
  versionsParentName?: string;
  onClick?: () => void;
  open?: boolean;
};

/**
 * list element of products list
 *
 * @param {PropsType} props takes in version or Product
 * Checks which one came in and delivers the according table row.
 * @returns {JSX.Element} table row containing table cells with the accprding values.
 */
const ProductsListElement = (props: PropsType): JSX.Element => {
  const { columns, version, product, onClick, open, versionsParentName } =
    props;
  const { t } = useTranslation('dashboard');

  const { selectedRole } = useContext(SelectedRoleContext);
  const role: Role = selectedRole;
  const elementId = version ? version.id : product?.id || '';
  const statusClassName = [
    Role.legal,
    Role.brandlegal,
    Role.rulesetlegal,
    Role.externlegal,
  ].includes(role)
    ? version?.overallResult.status || ''
    : '';
  const elementName = version ? version.name : product?.name;
  const versionClassName = version ? 'version' : 'product';
  const rowClassNames = `${statusClassName} ${versionClassName}`;
  return (
    <Table.Row
      id={`ProductsList.${elementId}`}
      key={elementId}
      className={rowClassNames}
    >
      {columns.map((column: Column) => {
        if (column.permitted && column.shown) {
          return (
            <Table.Cell
              key={column.name}
              className={column.name}
              width={column.width}
            >
              {(() => {
                switch (column.name) {
                  case 'priority':
                    return (
                      <PriorityElement
                        priority={
                          product?.priority || version?.priority || null
                        }
                      />
                    );
                  case 'projectStatus':
                    return (
                      <ProjectStatusElement
                        status={
                          product?.projectStatus ||
                          version?.processStatusAsString
                        }
                      />
                    );
                  case 'projectID':
                    return (
                      <OverflowPopup
                        content={product?.id || version?.id || ''}
                        trigger={
                          <>
                            <CopyPopup elementId={elementId} />
                            <span
                              className="overflow-popup-trigger"
                              style={{
                                minWidth: column.minWidth,
                                maxWidth: '50px', // open for better ideas.
                                position: 'relative',
                                top: '2px',
                              }}
                            >
                              {product?.id || version?.id || ''}
                            </span>
                          </>
                        }
                      />
                    );
                  case 'resolverStatus':
                    return (
                      <ResultElement
                        overallResultString={
                          product?.resolverResult ||
                          version?.overallResult.status
                        }
                      />
                    );
                  case 'name':
                    return (
                      <NameElement
                        product={product}
                        version={version}
                        name={product?.name || version?.name || ''}
                        onClick={onClick}
                        open={open}
                      />
                    );
                  case 'targetDate':
                    if (
                      product &&
                      product.targetDate &&
                      product.projectStatus !==
                        ProcessStatus[ProcessStatusType.COMPLETED]
                    ) {
                      return (
                        <TargetDateElement targetDate={product.targetDate} />
                      );
                    }
                    if (
                      version &&
                      version.targetDate &&
                      version.processStatusAsString !==
                        ProcessStatus[ProcessStatusType.COMPLETED]
                    ) {
                      return (
                        <TargetDateElement targetDate={version.targetDate} />
                      );
                    }
                    return <span>-</span>;
                  case 'completeness':
                    return (
                      <span style={{ display: 'block', textAlign: 'center' }}>
                        {product && product.completeness
                          ? product.completeness
                          : ''}
                      </span>
                    );
                  case 'todos':
                    return <ToDosElement product={product} version={version} />;
                  case 'supplierParticipation':
                    return (
                      product && (
                        <OverflowPopup
                          content={
                            <>
                              <div className="overflow-popup-trigger">
                                {product
                                  ? t(checkForSupplier(product.supplierTeam))
                                  : '-'}
                              </div>
                              <div className="overflow-popup-trigger">
                                {t(
                                  product?.selectedDistribution
                                    ? distributionLocale(
                                        product?.selectedDistribution
                                      )
                                    : 'tableElements.distribution.null'
                                )}
                              </div>
                            </>
                          }
                          triggerClassName="flex-column"
                        />
                      )
                    );
                  case 'legal':
                    if (version) {
                      return <LegalElement version={version} />;
                    }
                    return (
                      <ReviewersOverview product={product} role={Role.legal} />
                    );
                  case 'comments':
                    return (
                      <CommentCountElement
                        product={product}
                        version={version}
                      />
                    );
                  case 'requestor':
                    return (
                      <OverflowPopup
                        content={
                          product?.createdBy.name ||
                          version?.createdBy.name ||
                          ''
                        }
                      />
                    );
                  case 'oso':
                    if (version) {
                      return <OsoElement version={version} />;
                    }
                    return (
                      <ReviewersOverview product={product} role={Role.oso} />
                    );
                  case 'delete':
                    return (
                      <DeleteProductAndVersion
                        elementId={elementId}
                        isItProduct={!version}
                        elementName={elementName}
                        versionsParentName={versionsParentName}
                        productVersions={product?.versions}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </Table.Cell>
          );
        }
        return null;
      })}
    </Table.Row>
  );
};

ProductsListElement.defaultProps = {
  product: null,
  version: null,
  onClick: () => null,
  open: null,
};

export default ProductsListElement;
