import { ApolloError, useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  GetLatestLcDocumentQuery,
  DownloadLcDocumentMutation,
  DownloadLcDocumentMutationVariables,
} from '../../../../graphql/generated/graphql';
import { DOWNLOAD_LC_DOCUMENT } from '../../../../graphql/mutations/ArtifactoryMutations';
import Toast from '../../../ui/Toast';

interface LCDownloadBtnProps {
  disabled: boolean;
  preliminary: boolean;
  lcDocumentData: GetLatestLcDocumentQuery | undefined;
}

/**
 * Button for downloading the licenses & copyrights.
 *
 * @param {LCDownloadBtnProps} props { disabled }
 * @returns {JSX.Element} LCDownloadButton.
 */
const LCDownloadButton = (props: LCDownloadBtnProps): JSX.Element => {
  const { disabled, preliminary, lcDocumentData } = props;
  const { t } = useTranslation('productDetails');
  const { versionId } = useContext(ProductDetailsContext);
  const [toastVisible, setToastVisible] = useState(false);
  const [errorState, setErrorState] = useState<ApolloError | null>(null);

  const [downloadLcDocument] = useMutation<
    DownloadLcDocumentMutation,
    DownloadLcDocumentMutationVariables
  >(DOWNLOAD_LC_DOCUMENT, {
    variables: {
      versionId,
      downloadUri: lcDocumentData?.GetLatestLcDocument.file?.downloadUri || '',
    },
    onCompleted: (data) => {
      if (!data || !lcDocumentData?.GetLatestLcDocument.file?.filename) return;
      const filename = lcDocumentData.GetLatestLcDocument.file?.filename;
      const extension = filename?.slice(filename.lastIndexOf('.') + 1);
      const mimetype = extension === 'pdf' ? 'application/pdf' : 'text/plain';
      const link = document.createElement('a');
      link.href = `data:${mimetype};base64,${data.DownloadLcDocument}`;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    onError: (error) => {
      setErrorState(error);
      setToastVisible(true);
    },
  });

  const handleToastClose = () => {
    setToastVisible(false);
    setErrorState(null);
  };

  return (
    <>
      <Toast
        visible={toastVisible}
        type="error"
        header={errorState?.name}
        content={errorState?.message}
        onClose={handleToastClose}
        position="absolute bottom right"
        offset={{ y: -42 }}
      />
      <Button
        primary
        basic
        id="LCDownloadButton"
        icon="download"
        disabled={toastVisible || disabled}
        content={t(
          preliminary ? 'export.preliminaryLcDocument' : 'export.lcDocument'
        )}
        onClick={() => {
          downloadLcDocument();
        }}
      />
    </>
  );
};

export default LCDownloadButton;
