import { gql } from '@apollo/client';

/**
 * @description uploading file to artifactory
 */
export const PERSIST_FILE_TO_ARTIFACTORY = gql`
  mutation PersistFileToArtifactory(
    $versionId: ID!
    $filename: String!
    $file: Upload!
    $type: String!
    $info: String!
  ) {
    PersistFileToArtifactory(
      versionId: $versionId
      filename: $filename
      file: $file
      type: $type
      info: $info
    )
  }
`;

/**
 * @description fetching file in form of a base64 string from artifactory
 */
export const DOWNLOAD_FROM_ARTIFACTORY = gql`
  mutation DownloadFromArtifactory($versionId: ID!, $url: String!) {
    DownloadFromArtifactory(versionId: $versionId, url: $url)
  }
`;

/**
 * @description deleting file from artifactory
 */
export const REMOVE_FROM_ARTIFACTORY = gql`
  mutation RemoveFromArtifactory($versionId: ID!, $url: String!, $fileType: String) {
    RemoveFromArtifactory(versionId: $versionId, url: $url, fileType: $fileType)
  }
`;

/**
 * Download an unencoded public file from artifactory
 */
export const DOWNLOAD_PUBLIC_FROM_ARTIFACTORY = gql`
  mutation DownloadPublicFromArtifactory($url: String!) {
    DownloadPublicFromArtifactory(url: $url)
  }
`;

/**
 * Download L&C Document
 */
export const DOWNLOAD_LC_DOCUMENT = gql`
  mutation DownloadLcDocument($versionId: ID!, $downloadUri: String!) {
    DownloadLcDocument(versionId: $versionId, downloadUri: $downloadUri)
  }
`;