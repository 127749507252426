import React from 'react';
import { Accordion, Grid, Icon, Label, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { LicenseFeatureArray } from './helpers/types';
import { isToDo } from './helpers/logics';
import LicenseTodoList from './LicenseTodoList';
import {
  FeatureTypeName,
  useLicenseContext,
} from '../../context/LicenseContext';
import SelectFeature from './SelectFeature';
import SelectLicenseFeatures from './SelectLicenseFeatures';
import { useFeatureCounter } from './helpers/customHooks';
import LicenseFeatureListItem from './LicenseFeatureListItem';
import ConditionalSegment from '../ui/ConditionalSegment';
import i18n from '../../i18n/i18n';

type Props = {
  featureArray: LicenseFeatureArray;
  isActive: boolean;
  toggle: (name: string) => void;
  name: FeatureTypeName;
};

/**
 *  License Features Accordion
 *
 *@param {Props} props - Props
 *@returns {JSX.Element}  - License Features Accordion
 */
const FeatureAccordion = (props: Props): JSX.Element => {
  const { featureArray, isActive, toggle, name } = props;
  const { t } = useTranslation('productDetails');
  const {
    isEditMode,
    todos: ctxTodos,
    licenseConflictRules: ctxLicenseConflictRules,
    conflictRules: ctxConflictRules,
    additionalLicenseFeatures: ctxAdditionalLicenseFeatures,
    countFeatures,
    wasSaved,
  } = useLicenseContext();

  const getFeatureArray = () => {
    let features;
    if (!isEditMode) {
      features = featureArray;
    } else {
      switch (name) {
        case 'ToDo':
          features = ctxTodos;
          break;
        case 'LicenseConflictRule':
          features = ctxLicenseConflictRules;
          break;
        case 'ConflictRule':
          features = ctxConflictRules;
          break;
        case 'AdditionalLicenseFeature':
          features = ctxAdditionalLicenseFeatures;
          break;
        default:
          features = featureArray;
      }
    }

    const featuresCopy = [...features];
    const nameKey = i18n.language === 'de' ? 'name_de' : 'name_en';

    return featuresCopy.sort((a, b) => {
      const nameA = a?.[nameKey] || '';
      const nameB = b?.[nameKey] || '';
      return nameA.localeCompare(nameB);
    });
  };

  const activeFeatures = getFeatureArray();
  const featureCounter = useFeatureCounter(
    isEditMode,
    name,
    featureArray,
    countFeatures,
    wasSaved
  );

  return (
    <Accordion
      id={`LicenseFeaturesAccordion-${name}`}
      className="license-features-accordion"
    >
      <Accordion.Title onClick={() => toggle(name)}>
        <Icon
          id="FeatureAccordionIcon"
          name="chevron down"
          className={isActive ? 'accordion-icon active' : 'accordion-icon'}
        />
        <span style={{ margin: '0 10px' }}>
          {t(`dependencyList.licenseModal.${name}`)}
        </span>
        <Label
          id="FeatureAccordionCounter"
          basic
          size="mini"
          content={featureCounter}
        />
      </Accordion.Title>
      <Accordion.Content active={isActive}>
        {isEditMode && isActive && (
          <Grid columns={2} className="edit-features-block">
            <Grid.Row>
              <Grid.Column width={6}>
                <SelectLicenseFeatures type={name} />
              </Grid.Column>
              <Grid.Column width={10}>
                <SelectFeature type={name} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <ConditionalSegment visible={name !== 'ToDo'} mustHaveGrandchildren>
          <List
            id="FeatureAccordionList"
            className="feature-accordion-list"
            as="ol"
          >
            {activeFeatures.map((rule) => {
              if (isToDo(rule)) {
                return (
                  <LicenseTodoList
                    items={[rule]}
                    allFeatures={featureArray}
                    key={`FeatureAccordionList-${rule.id}`}
                  />
                );
              }
              return (
                <LicenseFeatureListItem
                  rule={rule}
                  key={`LicenseFeaturesListItem${rule?.id}`}
                />
              );
            })}
          </List>
        </ConditionalSegment>
      </Accordion.Content>
    </Accordion>
  );
};

export default FeatureAccordion;
