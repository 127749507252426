import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useDownloadFromArtifactoryMutation } from '../../../../../../../graphql/generated/graphql';
import DeleteFileElement from './DeleteFileElement';

type PropsType = {
  url: string;
  filename: string;
  fileType: string;
  versionId: string;
  refetchAdditionalData: (
    variables?:
      | Partial<{
          versionId: string;
          filename: string;
        }>
      | undefined
  ) => void;
};

/**
 *
 * @param {object} props contains url and versionId.
 * @returns {JSX.Element} ActionsElement.
 */
const ActionsElement = (props: PropsType) => {
  const { versionId, url, filename, fileType, refetchAdditionalData } = props;

  const [downloadFromArtifactory, { data: downloadData }] =
    useDownloadFromArtifactoryMutation({
      variables: { versionId, url },
      onCompleted: () => {
        const extension = filename.slice(filename.lastIndexOf('.') + 1);
        const mimetype = extension === 'pdf' ? 'application/pdf' : 'text/plain';
        const link = document.createElement('a');
        link.href = `data:${mimetype};base64,${downloadData?.DownloadFromArtifactory}`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      },
    });

  return (
    <span>
      <DeleteFileElement url={url} fileType={fileType} filename={filename} />
      <Icon
        id="downloadFromArtifactory"
        className="primary"
        link
        name="download"
        onClick={() => {
          downloadFromArtifactory();
          refetchAdditionalData({
            versionId,
            filename,
          });
        }}
      />
    </span>
  );
};

export default ActionsElement;
