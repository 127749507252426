import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import {
  DependencySubComponentsRelationship,
  Subcomponent,
} from '../../../../../../../graphql/generated/graphql';
import {
  SubcomponentInfo,
  getComponentAndSubcomponentInfos,
  getDisplayedSubcomponent,
} from '../../helper/licenseElementHelpers';
import OrLicensesPopup from './LicensesPopup';
import UnverifiedIcon from './UnverifiedIcon';
import MissingCopyrightIcon from './MissingCopyrightIcon';
import LicenseElementModal from './LicenseModalComponents/LicenseElementModal';
import { ProductDetailsContext } from '../../../../../../../context/ProductContext';

type PropsType = {
  subcomponents: Array<Subcomponent>;
  dependencyId: string;
  subcomponentEdges: Array<DependencySubComponentsRelationship>;
};

/**
 * returns a span with the name of the first license followed by the amount of the rest
 * nothing too fancy
 *
 * @param {PropsType} props takes in licenses
 * @returns {JSX.Element} a span with license names and icon with amount
 */
const LicensesElement = (props: PropsType): JSX.Element => {
  const { distributionId } = useContext(ProductDetailsContext);
  const { subcomponents, subcomponentEdges, dependencyId } = props;
  const [text] = useTranslation('productDetails');
  const [openLicensesModal, setOpenLicensesModal] = useState(false);
  const [selectedSubcomponentName, setSelectedSubcomponentName] = useState('');
  const [selectedSubcomponent, setSelectedSubcomponent] =
    useState<SubcomponentInfo>();

  const { componentInfos, subcomponentInfos } =
    getComponentAndSubcomponentInfos(subcomponents, subcomponentEdges);

  const displayedSubcomponent = getDisplayedSubcomponent(subcomponentInfos);
  const hasOrLicense = Object.values(componentInfos).some(
    (componentInfo) => componentInfo.hasORLicense
  );

  const handleLicenseClick = (subcomponentInfo: SubcomponentInfo) => {
    setSelectedSubcomponentName(
      subcomponentInfo.subcomponentEdge.subComponentName || ''
    );
    setOpenLicensesModal(true);
  };

  useEffect(() => {
    const allSubcomponents = Object.values(subcomponentInfos).flat();
    const subComponent = allSubcomponents.find(
      (groupedSubcomponent) =>
        groupedSubcomponent.subcomponentEdge.subComponentName ===
        selectedSubcomponentName
    );
    setSelectedSubcomponent(subComponent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(componentInfos), selectedSubcomponentName]);

  return (
    <>
      {openLicensesModal && selectedSubcomponent && (
        <LicenseElementModal
          openLicensesModal={openLicensesModal}
          setOpenLicensesModal={setOpenLicensesModal}
          dependencyId={dependencyId}
          subComponent={selectedSubcomponent.subcomponent}
          subComponentEdge={selectedSubcomponent.subcomponentEdge}
        />
      )}
      <div className="licenses-table-cell">
        {subcomponents && subcomponents.length > 0 && (
          <Item.Extra onClick={() => handleLicenseClick(displayedSubcomponent)}>
            <div className="clickable-license">
              {displayedSubcomponent?.subcomponentEdge.spdxId}
            </div>
            <UnverifiedIcon
              verified={displayedSubcomponent?.subcomponentEdge.verified}
            />
            <MissingCopyrightIcon
              verified={displayedSubcomponent?.subcomponentEdge.verified}
              copyrights={displayedSubcomponent?.subcomponentEdge.copyrights}
              binary={distributionId}
            />
          </Item.Extra>
        )}
        {hasOrLicense && (
          <OrLicensesPopup
            componentInfos={componentInfos}
            subcomponentInfos={subcomponentInfos}
            handleLicenseClick={handleLicenseClick}
          />
        )}
        {subcomponents && subcomponents.length > 1 && (
          <OrLicensesPopup
            componentInfos={componentInfos}
            subcomponentInfos={subcomponentInfos}
            handleLicenseClick={handleLicenseClick}
          />
        )}
        {(!subcomponents || subcomponents.length === 0) && (
          <div className="license-names">
            {text('dependencyList.tableBody.noInfo')}
          </div>
        )}
      </div>
    </>
  );
};

export default LicensesElement;
