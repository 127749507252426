import React, { useContext } from 'react';
import { List } from 'semantic-ui-react';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { Role } from '../../../../graphql/generated/graphql';
import useOptimisticSelection from './helpers/customHooks';
import { ToDoType } from './helpers/types';
import ToDoCheckboxToggle from './ToDoCheckboxToggle';
import ToDoIcons from './ToDoIcons';
import TodoBadges from './TodoBadges';

interface ToDosTaskProps {
  todos: ToDoType[];
  versionId: string | undefined;
  taskId: string;
  taskName: string;
  isInternal: boolean;
  selectDisabled: boolean;
}

/**
 * Renders Task, child of ToDo.
 *
 * @returns {JSX.Element} The Task Component
 * @param {string} versionId The ID of the version needed for the Popups Parts
 */
const ToDoTask = ({
  todos,
  versionId,
  taskId,
  taskName,
  isInternal,
  selectDisabled,
}: ToDosTaskProps): JSX.Element => {
  const { selectedRole } = useContext(SelectedRoleContext);
  const role: Role = selectedRole;
  const taskSelectionInfo = todos.find((t) => t.toDo.id === taskId)!; //eslint-disable-line

  const siblingTasks = todos.filter(
    (todo) => todo.parentToDoId === taskSelectionInfo.parentToDoId
  );

  const { selectionType, toggleSelection } = useOptimisticSelection(
    taskSelectionInfo.selectionType,
    versionId,
    taskId,
    siblingTasks
  );

  const hideInternal = role.includes('supplier') && isInternal;

  return hideInternal ? (
    <></> // eslint-disable-line
  ) : (
    <List.Item>
      <List.Content>
        <div className="todo-item-container">
          {taskSelectionInfo?.selectionType && (
            <ToDoCheckboxToggle
              key={taskId}
              todoId={taskId}
              selectionType={selectionType}
              onToggle={toggleSelection}
              parentId={taskSelectionInfo?.parentToDoId}
              isDisabled={selectDisabled}
            />
          )}
          <span className={`task-name ${selectDisabled ? 'read-only' : ''}`}>
            {taskName}
            <TodoBadges internal={isInternal} />
          </span>
          <ToDoIcons
            toDoId={taskId}
            toDoName={taskName}
            versionId={versionId}
            commentCount={taskSelectionInfo.commentCount}
          />
        </div>
      </List.Content>
    </List.Item>
  );
};
export default ToDoTask;
