import { gql } from '@apollo/client';

/**
 * Update Version with edited details in Product Details View
 */
export const UPDATE_VERSION = gql`
  mutation UpdateVersion(
    $versionId: String!
    $versionInput: UpdateVersionInput!
  ) {
    UpdateVersion(
      versionId: $versionId
      versionInput: $versionInput
    )
  }
`;

/**
 * Update Oso Reviewer of a Version by osoId
 * 
 * @param versionId
 * @param osoId
 */
export const UPDATE_OSO_REVIEWER = gql`
  mutation UpdateOsoReviewer($versionId: String!, $osoId: String) {
    UpdateOsoReviewer(versionId: $versionId, osoId: $osoId)
  }
`;

/**
 * Update Legal Reviewer of a Version by legalId
 * 
 * @param versionId
 * @param legalId
 */
export const UPDATE_LEGAL_REVIEWER = gql`
  mutation UpdateLegalReviewer($versionId: String!, $legalId: String) {
    UpdateLegalReviewer(versionId: $versionId, legalId: $legalId)
  }
`;

/**
 * Update Manager Team
 * 
 * @param versionId
 * @param managerTeam
 */
export const UPDATE_MANAGER_TEAM= gql`
  mutation UpdateManagerTeam(
    $versionId: ID!
    $team: [ID!]!
  ) {
    UpdateProductContacts(
      versionId: $versionId
      contacts: {
        managerTeam: $team
      }
    )
  }
`;

/**
 * Update Development Team
 * 
 * @param versionId
 * @param developmentTeam
 */
export const UPDATE_DEVELOPER_TEAM = gql`
  mutation UpdateDeveloperTeam(
    $versionId: ID!
    $team: [ID!]!
  ) {
    UpdateProductContacts(
      versionId: $versionId
      contacts: {
        developmentTeam: $team
      }
    )
  }
`;

/**
 * Update Supplier Team
 * 
 * @param versionId
 * @param supplierTeam
 */
export const UPDATE_SUPPLIER_TEAM = gql`
  mutation UpdateSupplierTeam(
    $versionId: ID!
    $team: [ID!]!
  ) {
    UpdateProductContacts(
      versionId: $versionId
      contacts: {
        supplierTeam: $team
      }
    )
  }
`;

/**
 * Update Guest Team
 * 
 * @param versionId
 * @param guestTeam
 */
export const UPDATE_GUEST_TEAM = gql`
  mutation UpdateGuestTeam(
    $versionId: ID!,
    $team: [ID!]!
  ) {
    UpdateProductContacts(
      versionId: $versionId
      contacts: { 
        guestTeam: $team 
      }
    )
  }
`;

/**
 * Update Distribution- Mutation for OSO Role
 *
 */
export const UPDATE_TRANSFER_PARAMS = gql`
  mutation UpdateTransfer(
    $versionId: String!
    $transferExternalId: String!
    $transferGroupCompany: String!
    $transferContactPerson: String!
  ) {
    UpdateVersion(
      versionId: $versionId
      versionInput: {
        transferExternalId: $transferExternalId
        transferGroupCompany: $transferGroupCompany
        transferContactPerson: $transferContactPerson
      }
    )
  }
`;

/**
 * update process status to SUPPLIER_INPUT
 *
 */
export const REQUEST_SUPPLIER_INPUT = gql`
  mutation RequestSupplierInput($versionId: ID!) {
    RequestSupplierInput(versionId: $versionId)
  }
`;

/**
 * update process status if supplier input is done
 *
 */
export const SUPPLIER_INPUT_DONE = gql`
  mutation SupplierInputDone($versionId: ID!) {
    SupplierInputDone(versionId: $versionId)
  }
`;

/**
 * update process status to LEGAL_CHECK
 *
 */
export const SUBMIT_TO_LEGAL = gql`
  mutation SubmitToLegal($versionId: ID!) {
    SubmitToLegal(versionId: $versionId)
  }
`;

/**
 * update process status to OSO_REQUESTED
 *
 */
export const SUBMIT_TO_OSO = gql`
  mutation SubmitToOSO($versionId: ID!) {
    SubmitToOSO(versionId: $versionId)
  }
`;

/**
 * update process status back to user
 *
 */
export const SUBMIT_TO_USER = gql`
  mutation SubmitToUser($versionId: ID!) {
    SubmitToUser(versionId: $versionId)
  }
`;

/**
 * update process status to LEGAL_CHECK_DONE
 *
 */
export const FINISH_LEGAL_CHECK = gql`
  mutation FinishLegalCheck($versionId: ID!) {
    FinishLegalCheck(versionId: $versionId)
  }
`;

/**
 * update process status to COMPLETED
 *
 */
export const FINISH_PRODUCT_CHECK = gql`
  mutation FinishProductCheck($versionId: ID!) {
    FinishProductCheck(versionId: $versionId)
  }
`;
/**
 * update process status to SUPPLIER_TODOS
 *
 */
export const REQUEST_SUPPLIER_TODOS = gql`
  mutation RequestSupplierToDos($versionId: ID!) {
    RequestSupplierToDos(versionId: $versionId)
  }
`;

/**
 * update process status to LEGAL_CHECK
 *
 */
export const SUPPLIER_TODOS_DONE = gql`
  mutation SupplierToDosDone($versionId: ID!) {
    SupplierToDosDone(versionId: $versionId)
  }
`;

/**
 * update process status to REPORT
 *
 */
export const FINISH_SUPPLIER_REPORT = gql`
  mutation FinishSupplierReport($versionId: ID!) {
    FinishSupplierReport(versionId: $versionId)
  }
`;

/**
 * update process status to REPORT PENDING
 *
 */
export const REQUEST_SUPPLIER_REPORT = gql`
  mutation RequestSupplierReport($versionId: ID!) {
    RequestSupplierReport(versionId: $versionId)
  }
`;

/**
 * update process status to COMPLETED
 *
 */
export const FINISH_REPORT = gql`
  mutation FinishReport($versionId: ID!) {
    FinishReport(versionId: $versionId)
  }
`;

/**
 * update process status to PROJECT COMPLETED
 *
 */
export const FINISH_PROJECT_CHECK = gql`
  mutation FinishProjectCheck($versionId: ID!) {
    FinishProjectCheck(versionId: $versionId)
  }
`;
/**
 * Delete version
 *
 */
export const DELETE_VERSION = gql `
mutation DeleteVersion($id:ID!) {
  DeleteVersion(id: $id)
}
`;

/**
 * Creates new Version - just a placeholder for now
 *
 */
export const CREATE_VERSION = gql`
mutation CreateVersion(
$productId: String!
$newVersionName: String!
$targetDate: String!
) {
  CreateVersion(
    productId: $productId, 
    newVersionName: $newVersionName,
    targetDate: $targetDate)
}`

/**
 * update process status to BRAND OSO
 *
 */
export const SUBMIT_TO_BRAND_OSO = gql`
  mutation SubmitToBrandOSO($versionId: ID!) {
    SubmitToBrandOSO(versionId: $versionId)
  }
`;

/**
 * Deletes a dependency and all its dominated dependencies.
 */
export const DELETE_DEPENDENCY = gql`
  mutation DeleteDependency($versionId: ID!, $dependencyId: ID!) {
    DeleteDependency(versionId: $versionId, dependencyId: $dependencyId)
  }
`;