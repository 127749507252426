import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Popup } from 'semantic-ui-react';
import { DistributionIds } from '../../../../../../wizard/WizardConstants';

type PropsType = {
  verified: boolean | undefined;
  copyrights: Array<string>;
  binary: string | undefined;
};

/**
 * Returns a missing copyright icon if the license is verified but has no copyrights
 *
 * @param {PropsType} props - {verified, copyrights, binary}
 * @returns {JSX.Element} icon with a popup
 */
const MissingCopyrightIcon = ({
  verified,
  copyrights,
  binary,
}: PropsType): JSX.Element | null => {
  const { t } = useTranslation('productDetails');

  if (
    !verified ||
    copyrights.length > 0 ||
    (binary !== DistributionIds.DISTRIBUTIONBINARY &&
      binary !== DistributionIds.DISTRIBUTIONBINARYEXTERNAL &&
      binary !== DistributionIds.DISTRIBUTIONBINARYINTERNAL)
  ) {
    return null;
  }

  return (
    <Popup
      id="MissingCopyrightsIconPopup"
      hoverable
      position="top center"
      content={<div>{t('dependencyList.tableBody.noCopyright')}</div>}
      trigger={
        <Icon
          id="MissingCopyrightsIcon"
          className="margin-left"
          name="info circle"
          color="blue"
        />
      }
    />
  );
};
export default MissingCopyrightIcon;
