import React, { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Select } from 'semantic-ui-react';
import { ProductInfoBoxContext } from '../../../../../context/ProductContext';
import {
  SelectProtection,
  SelectionTypes,
} from '../../../../wizard/WizardConstants';
import WizardFormFieldLabel from '../../../../wizard/WizardFormFieldLabel';

interface Props {
  open: boolean;
  setOpen(open: boolean): void;
}

/**
 * The Modal is used for InfoBox Standard Software Request Editing
 *
 * @param {object} props {open, setOpen, protection, setProtection }
 * @returns {JSX.Element} The Modal is used for UserInfoBox Protection Editing
 */
const ProtectionModal = (props: Props): JSX.Element => {
  const [t] = useTranslation(['buttons', 'productDetails', 'newProductWizard']);
  const { open, setOpen } = props;
  const { version, versionInput, setPartialVersionInput } = useContext(
    ProductInfoBoxContext
  );
  const protection =
    versionInput.protection ??
    (version?.product && 'protection' in version.product
      ? version.product.protection
      : undefined);

  return (
    <Modal
      size="tiny"
      id="ProtectionModal"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Modal.Header content={t('editProtection', { ns: 'buttons' })} />
      <Modal.Content>
        <Form id="ProtectionModalForm" className="modal-centered-form">
          <WizardFormFieldLabel
            nameText={t('intendedUsePart.protection', {
              ns: 'newProductWizard',
            })}
            infoText={t('intendedUsePart.protection-info', {
              ns: 'newProductWizard',
            })}
          />
          <Form.Field
            control={Select}
            options={SelectProtection()}
            value={protection}
            placeholder={t('pleaseSelect', {
              ns: 'newProductWizard',
            })}
            onChange={(_e: ChangeEvent, { value }: SelectionTypes) => {
              setPartialVersionInput({ protection: value });
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="ProtectionModalSaveChangesButton"
          style={{ margin: '0 auto', padding: '1rem 5rem' }}
          disabled={!!protection && protection === undefined}
          primary
          onClick={() => {
            setOpen(false);
          }}
        >
          {t('close', { ns: 'buttons' })}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProtectionModal;
