/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, List } from 'semantic-ui-react';
import {
  DependencySelections,
  RequirementInfo,
} from '../../../../graphql/generated/graphql';
import CommentsModalWindow from '../comments/CommentsModalWindow';
import { CommentsModalContext, RequirementContext } from './helper/types';
import { OpenCommentsModalState } from '../comments/helper/types';

interface Props {
  reqIndex: number;
  requirementInfo: RequirementInfo;
}

/**
 * @returns {JSX.Element} The Requirement  Component
 * @param {Props} props { reqIndex, requirementInfo }
 */
const RequirementsListItem = (props: Props): JSX.Element => {
  const { i18n, t } = useTranslation('requirements');
  const { requirementInfo, reqIndex } = props;
  const { name_en, name_de } = requirementInfo.requirement;
  const [openComments, setOpenComments] = useState<OpenCommentsModalState>([
    false,
    null,
    null,
  ]);
  const requirementName = i18n.language === 'de' ? name_de : name_en;

  const dependencySelections: DependencySelections =
    requirementInfo?.dependencySelections ?? {
      userselected: 0,
      userdeselected: 0,
      unselected: 0,
    };
  const disabled = Object.entries(dependencySelections)
    .filter(([key]) => key !== '__typename')
    .every(([, val]) => val === 0);

  return (
    <>
      <CommentsModalWindow
        openCommentsModal={openComments}
        setOpenCommentsModal={setOpenComments}
        elementName={requirementName}
        refersToId={requirementInfo.requirement.id}
        requirementInfo={requirementInfo}
      />
      <List.Item>
        <List.Content className="requirement-list-item">
          <div className="requirement-header">
            <span>
              {requirementInfo.requirementAnswered ? (
                <Icon
                  id="RequirementAnsweredIcon"
                  name="check circle"
                  color="green"
                  size="large"
                />
              ) : (
                <Label
                  id="RequirementIndex"
                  circular
                  content={reqIndex + 1}
                  basic
                />
              )}
            </span>
            <span>{requirementName}</span>
          </div>
          <div className="requirement-actions">
            <Button
              id="OpenRequirementAnswer"
              className="open-requirement-button"
              onClick={() =>
                setOpenComments([
                  true,
                  CommentsModalContext.Requirements,
                  RequirementContext.AnswerRequirement,
                ])
              }
            >
              <Label
                id="AffectedComponentsNumber"
                content={requirementInfo.dependencyCount}
              />
              <span>
                {t('affectedComponent', {
                  count: requirementInfo.dependencyCount,
                })}
              </span>
            </Button>
            <div
              className={`affected-components-selection ${
                disabled ? 'disabled' : ''
              }`}
            >
              <Icon id="SelectedCount" name="check" />
              <span>{dependencySelections.userselected}</span>

              <Icon id="DeselectedCount" name="ban" />
              <span>{dependencySelections.userdeselected}</span>

              <Icon id="UnselectedCount" name="question" />
              <span>{dependencySelections.unselected}</span>
            </div>
            <div className="requirement-comment">
              <Icon
                id="OpenRequirementComment"
                name="comment outline"
                color="blue"
                link
                onClick={() =>
                  setOpenComments([
                    true,
                    CommentsModalContext.Requirements,
                    RequirementContext.CommentRequirement,
                  ])
                }
              />
              <span>{requirementInfo.commentCount}</span>
            </div>
          </div>
        </List.Content>
      </List.Item>
    </>
  );
};
export default RequirementsListItem;
