import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { ProductDetailsContext } from '../../../../../context/ProductContext';
import {
  LcUploadPossibleQuery,
  LcUploadPossibleQueryVariables,
} from '../../../../../graphql/generated/graphql';
import { LC_UPLOAD_POSSIBLE } from '../../../../../graphql/queries/ArtifactoryQuerys';

/**
 * Custom hook checks whether a user is authorized to upload an LC document
 *
 * @returns {boolean} is or isn't authorized to upload LC
 */
const useAuthorizeLC = (): boolean => {
  const { versionId, authorizations } = useContext(ProductDetailsContext);

  const { data } = useQuery<
    LcUploadPossibleQuery,
    LcUploadPossibleQueryVariables
  >(LC_UPLOAD_POSSIBLE, {
    variables: { versionId },
    fetchPolicy: 'network-only',
    skip: !authorizations?.includes('PersistFileToArtifactory'),
  });

  return data?.LcUploadPossible.valueOf() ?? false;
};

export default useAuthorizeLC;
