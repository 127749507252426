import React from 'react';
import { Form, FormProps, Input, Popup, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import {
  CreateUserMutationVariables,
  UpdateUserMutationVariables,
} from '../../../graphql/generated/graphql';

interface FormValidationProps<TDetailProps> {
  userDetails: TDetailProps;
  setUsersPass: (values: TDetailProps) => void;
  repeatPass: { pass: string; enabled: boolean };
  setRepeatPass: (repeatPass: { pass: string; enabled: boolean }) => void;
  required?: boolean;
}
/**
 * @param  {string} usersPassword state
 *@returns {JSX.Element} Password and repeat password component with validation
 */
// Types are assigned regarding either Update existing or Creation/Adding new user
const PasswordsFormValidations = <
  TDetailProps extends CreateUserMutationVariables | UpdateUserMutationVariables
>({
  userDetails,
  setUsersPass,
  repeatPass,
  setRepeatPass,
  required, // required only for Create User
}: FormValidationProps<TDetailProps>): JSX.Element => {
  const [t] = useTranslation(['users', 'buttons']);

  const passwordRegex = /^(?=.{8,32}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*/;

  const passwordValidation = () => {
    if (userDetails.password && userDetails.password !== '') {
      if (!passwordRegex.test(userDetails.password.trim())) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <Segment
      id="UserDetailsProfilePasswords"
      className={
        required ? 'AddNewUserProfilePasswords' : 'UserDetailsProfilePasswords'
      }
    >
      <Form id="UserDetailsProfilePasswordsForm">
        {/* PASSWORDS- password field enables the repeatPassword and are then both required and validated-confirm Update Btn is disabled until passwords are Validated */}
        <Popup
          id="UserDetailsProfilePasswordsFormErrorMessage"
          // className="UserPasswordError"
          open={passwordValidation()}
          content={t('modal.passError')}
          position="top center"
          offset={[0, -10]}
          trigger={
            <Form.Field
              required={required ? true : userDetails.password !== ''}
              value={userDetails.password}
              data-cy="PasswordUser"
              type="password"
              placeholder={t('password.placeholder')}
              control={Input}
              label={t('password')}
              onChange={(
                event: React.FormEvent<HTMLFormElement>,
                data: FormProps
              ) =>
                setUsersPass({
                  ...userDetails,
                  password: data.value.trim(),
                })
              }
            />
          }
        />
        <Popup
          id="UserDetailsProfilePasswordsFormErrorMessage2"
          // className="UserPasswordError"
          open={
            userDetails.password !== repeatPass.pass && repeatPass.pass !== ''
          }
          content={t('modal.repeatPassError')}
          position="top center"
          offset={[0, -10]}
          trigger={
            <Form.Field
              required={userDetails.password !== ''}
              disabled={
                !userDetails.password ||
                !passwordRegex.test(userDetails.password)
              }
              value={repeatPass.pass}
              placeholder={t('password.placeholder')}
              control={Input}
              data-cy="repeatPasswordUser"
              type="password"
              label={t('repeatPassword')}
              onChange={(
                e: React.FormEvent<HTMLFormElement>,
                data: FormProps
              ) => setRepeatPass({ ...repeatPass, pass: data.value.trim() })}
            />
          }
        />
      </Form>
    </Segment>
  );
};

export default PasswordsFormValidations;
