import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Grid, Icon } from 'semantic-ui-react';
import AddVersionModal from './AddVersionModal';
import { SelectedRoleContext } from '../../../../../context/UserContext';
import {
  Product,
  Role,
  Version,
} from '../../../../../graphql/generated/graphql';
import OverflowPopup from './OverflowPopup';

type PropsType = {
  onClick?: () => void;
  open?: boolean;
  version?: Version;
  product?: Product;
  name: string;
};

/**
 * basically only returns an element with the time till targetDate
 * nothing too fancy
 *
 * @param {PropsType} props takes in targetDate
 * @returns {JSX.Element} a span with text
 */
const NameElement = (props: PropsType): JSX.Element => {
  const [text] = useTranslation('dashboard');
  const { selectedRole } = useContext(SelectedRoleContext);
  const role: Role = selectedRole;
  const { onClick, name, open, version, product } = props;
  const noVersion = product?.versions.length === 0;
  const [openModal, setOpenModal] = useState(false);

  return (
    <Grid>
      <Grid.Column tablet={16} computer={9} style={{ flexGrow: '1' }}>
        {product && !noVersion && (
          <Icon
            id="ProductsList.ShowVersionsIcon"
            style={{ top: '-0.6em', position: 'relative' }}
            onClick={onClick}
            name={open ? 'angle up' : 'angle down'}
          />
        )}
        {version && (
          <Link to={`/product-details/${version.id}?view=Details`}>
            <Icon
              id="ProductsList.VersionLinkIcon"
              className="move-up"
              link
              color="blue"
              name="external alternate"
            />
          </Link>
        )}
        <OverflowPopup
          content={name}
          isProject={product?.isProject} // it displays a Project bold
          triggerClassName="overflow-popup-trigger-productName"
        />
      </Grid.Column>
      {role === Role.developer || role === Role.supplier ? (
        <Grid.Column tablet={16} computer={7}>
          {product ? (
            <>
              <AddVersionModal
                productId={product.id}
                description={product.description}
                productName={product.name}
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
              <Button
                size="tiny"
                primary
                inverted
                icon="plus"
                style={{
                  width: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                id="ProductsList.AddVersion"
                content={text('buttons.addVersion')}
                onClick={() => setOpenModal(true)}
              />
            </>
          ) : (
            <Button
              size="tiny"
              primary
              inverted
              disabled
              icon="plus"
              style={{
                width: '100%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
              id="ProductsList.CompareVersion"
              content={text('buttons.compareVersion')}
            />
          )}
        </Grid.Column>
      ) : (
        ''
      )}
    </Grid>
  );
};

NameElement.defaultProps = {
  product: null,
  version: null,
  onClick: () => null,
  open: null,
};

export default NameElement;
