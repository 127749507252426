/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';
import { REFRESH_TOKEN } from './mutations/UserMutations';
import { OperationBundle } from './helpers/types';
import { isLoginPath, waitForRequestsToSettle } from './helpers/logics';

type PropTypes = {
  children: JSX.Element;
};

/**
 * Creates the Apollo Client to connect to the Backend.
 *
 * @param {PropTypes} props children wrapped inside this JSX.Element.
 * @returns {JSX.Element} Apollo Provier.
 */
const createApolloClient = (props: PropTypes): JSX.Element => {
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            GetRequirements: {
              merge(_, incoming = []) {
                return [...incoming];
              },
            },
          },
        },
      },
    }),
  });

  const { hostname = 'localhost', pathname } = window.location;
  let uri = 'http://localhost:4000/graphql';
  if (hostname !== 'localhost') {
    uri = `https://${hostname}/backend`;
  }

  let token = localStorage.getItem('token');
  let isRefreshing = false;
  let pendingRequests: OperationBundle[] = [];

  const authLink = setContext(async (_, { headers }) => {
    // Retrieve the token from localStorage
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpLink = createUploadLink({
    uri,
    headers: {
      'Apollo-Require-Preflight': 'true',
    },
    credentials: 'include',
  });

  const refreshToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: REFRESH_TOKEN })
        .then(({ data }) => {
          const newToken = data.RefreshToken;
          localStorage.setItem('token', newToken);
          token = newToken;
          resolve(newToken);
        })
        .catch((err) => {
          window.location.assign('/login');
          reject(err);
        });
    });
  };

  const tokenRefreshLink = new ApolloLink(
    (operation: Operation, forward: NextLink) => {
      return new Observable((observer) => {
        if (!operation.getContext().isUnauthenticated) {
          // Pass through the operation normally
          return forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        }

        pendingRequests.push({ operation, forward, observer });

        if (!isRefreshing) {
          isRefreshing = true;
          waitForRequestsToSettle(pendingRequests).then(() => {
            refreshToken()
              .then((newToken) => {
                isRefreshing = false;

                // Update the context of all pending requests with the new token
                pendingRequests.forEach((pendingOperation) => {
                  pendingOperation.operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      authorization: `Bearer ${newToken}`,
                    },
                  }));
                });

                const requestsToProcess = [...pendingRequests]; // Copy array for processing
                pendingRequests = [];

                requestsToProcess.forEach(
                  ({
                    operation: pendingOperation,
                    forward: forwardRequest,
                    observer: pendingObserver,
                  }) => {
                    forwardRequest(pendingOperation).subscribe({
                      next: pendingObserver.next?.bind(pendingObserver),
                      error: pendingObserver.error?.bind(pendingObserver),
                      complete: pendingObserver.complete?.bind(pendingObserver),
                    });
                  }
                );
              })
              .catch((err) => {
                // If refreshing the token fails, forward the error
                isRefreshing = false;
                pendingRequests.forEach(({ observer: pendingObserver }) => {
                  pendingObserver.error?.(err);
                });
                pendingRequests = [];
                localStorage.clear();
                window.location.assign('/login');
                console.error('[Token refresh error]:', err);
              });
          });
        }
        return undefined;
      });
    }
  );

  const linkError = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        const unauthenticatedError = graphQLErrors.find(({ extensions }) => {
          return extensions?.code === 'UNAUTHENTICATED';
        });
        const errorPath = unauthenticatedError?.extensions.path;

        if (
          unauthenticatedError &&
          !(Array.isArray(errorPath) && errorPath.includes('CurrentUser'))
        ) {
          const tokenAvailable = localStorage.getItem('token');
          localStorage.clear();
          if (!isLoginPath(pathname)) {
            if (unauthenticatedError.message !== 'TokenExpiredError: jwt expired') {
              window.location.assign('/login');
            }
            localStorage.setItem('lastUrl', window.location.pathname);
            if (tokenAvailable) localStorage.setItem('expired', 'true');
            operation.setContext({
              isUnauthenticated: true,
              timestamp: Date.now(),
            });
            return tokenRefreshLink.request(operation, forward) || undefined;
          }
        }

        graphQLErrors.forEach(({ extensions, message, locations, path }) => {
          if (extensions) {
            switch (extensions.code) {
              case 'DATABASE_NOT_REACHABLE':
                if (!(Array.isArray(path) && path.includes('CurrentUser'))) {
                  const tokenAvailable = localStorage.getItem('token');
                  localStorage.clear();
                  if (!isLoginPath(pathname)) {
                    localStorage.setItem('lastUrl', window.location.pathname);
                    if (tokenAvailable) localStorage.setItem('expired', 'true');
                    window.location.assign('/login');
                  }
                }
                break;
              case 'ACTIVATION_PENDING':
                // Handle activation pending logic if needed
                break;
              default:
                // Handle other specific errors if needed
                break;
            }
          }
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }

      if (networkError && !graphQLErrors) {
        console.error(`[Network error]: ${networkError}`);
      }

      return undefined;
    }
  );

  apolloClient.setLink(from([tokenRefreshLink, authLink, linkError, httpLink]));

  const { children } = props;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default createApolloClient;
